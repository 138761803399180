jQuery(document).ready(function($) {

    var e = jQuery("#slider1-index").revolution({
            sliderType: "standard",
            sliderLayout: "fullscreen",
            extensions: "/website/js/revolutionslider5/extensions/",
            delay: 5e3,
            disableProgressBar: "on",
            lazyType: "all",
            navigation: {
                onHoverStop: "off",
                keyboardNavigation: "off",
                touch: {
                    touchenabled: "on",
                    swipe_treshold: 75,
                    swipe_min_touches: 1,
                    drag_block_vertical: !1,
                    swipe_direction: "horizontal"
                },
                arrows: {
                    enable: true,
                    style: 'hesperiden',
                    hide_onleave: false
                }
            },
            responsiveLevels: [4064, 1600, 1024, 778, 480],
            minHeight: [600, 500, 490, 460, 450],
            gridwidth: [1530, 1230, 1130, 900, 700],
            gridheight: [600, 500, 490, 460, 450]
        }),
        t = jQuery("#slider1-inner").revolution({
            sliderType: "standard",
            sliderLayout: "fullwidth",
            extensions: "/website/js/revolutionslider5/extensions/",
            delay: 5e3,
            disableProgressBar: "on",
            responsiveLevels: [4064, 1024, 778, 480],
            minHeight: [500, 500, 450, 300],
            gridwidth: [1230, 1230, 800, 400],
            gridheight: [500, 500, 450, 300]
        });
    e.on("revolution.slide.onloaded", function() {
        $("section#slider").css("min-height", "0"), maxslides = $("section#slider").revmaxslide(), $("span.allslides").empty().append(maxslides)
    }), t.on("revolution.slide.onloaded", function() {
        $("section#slider").css("min-height", "0")
    }), e.on("revolution.slide.onafterswap", function(e) {
        currentslide = $("section#slider").revcurrentslide(), $("span.current").empty().append(currentslide)
    });

    $(".slide").slick({
        autoplay: !1,
        arrows: !0,
        variableWidth: !1,
        dots: false,
        autoplaySpeed: 3e3,
        rows: 1,
        easing: "linear",
        infinite: !1,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [{
            breakpoint: 2000,
            settings: "unslick",
        }, {
            breakpoint: 400,
            settings: {
                arrows: true,
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }]
    });
    $('a[href*="#"]').on("click", function(e) {
        e.preventDefault();
        var t = this.hash,
            i = $(t);
        $("html, body").stop().animate({
            scrollTop: i.offset().top
        }, 900, "swing", function() {
            window.location.hash = t
        })
    });
    $("#grid-container").cubeportfolio({
        layoutMode: "mosaic",
        sortToPreventGaps: !0,
        mediaQueries: [{
            width: 1500,
            cols: 3
        }, {
            width: 1100,
            cols: 3
        }, {
            width: 800,
            cols: 3
        }, {
            width: 480,
            cols: 2
        }, {
            width: 320,
            cols: 1
        }],
        animationType: "quicksand",
        gapHorizontal: 0,
        gapVertical: 0,
        gridAdjustment: "responsive",
        caption: "fadeIn",
        displayType: "lazyLoading",
        displayTypeSpeed: 100,
        lightboxDelegate: ".cbp-lightbox",
        lightboxGallery: !0,
        lightboxTitleSrc: "data-title"
    }), $("#grid-container-kareta1").cubeportfolio({
        filters: "#filters-container",
        layoutMode: "mosaic",
        sortToPreventGaps: !0,
        mediaQueries: [{
            width: 1500,
            cols: 3
        }, {
            width: 1100,
            cols: 3
        }, {
            width: 800,
            cols: 3
        }, {
            width: 480,
            cols: 2
        }, {
            width: 320,
            cols: 1
        }],
        animationType: "quicksand",
        gapHorizontal: 12,
        gapVertical: 12,
        gridAdjustment: "responsive",
        caption: "zoom",
        displayType: "sequentially",
        displayTypeSpeed: 100,
        lightboxDelegate: ".cbp-lightbox",
        lightboxGallery: !0,
        lightboxTitleSrc: "data-title"
    }), $(".grid-container-gallery").cubeportfolio({
        layoutMode: "grid",
        sortToPreventGaps: !0,
        animationType: "fadeOutTop",
        gapHorizontal: 5,
        gapVertical: 5,
        gridAdjustment: "responsive",
        mediaQueries: [{
            width: 1500,
            cols: 4
        }, {
            width: 1100,
            cols: 4
        }, {
            width: 800,
            cols: 3
        }, {
            width: 480,
            cols: 2
        }, {
            width: 320,
            cols: 1
        }],
        caption: "zoom",
        displayType: "lazyLoading",
        displayTypeSpeed: 100,
        lightboxDelegate: ".cbp-lightbox",
        lightboxGallery: !0,
        lightboxTitleSrc: "data-title"
    });
    $(".opengallery").on("click", function(e) {
        e.preventDefault();
        var t = $(this).data("json");
        t && $(this).lightGallery({
            download: !1,
            dynamic: !0,
            dynamicEl: t
        })
    });
    $(".cd-primary-nav-trigger").on("click", function() {
        $(".cd-primary-nav").hasClass("is-visible") ? $(".cd-primary-nav").removeClass("is-visible") : $(".cd-primary-nav").addClass("is-visible")
    });
    $(".lines-button").click(function() {
        $(this).toggleClass("close2")
    });
    $("#likeButtonOpen").click(function() {
        $("#like_butt_hold").toggle("fast")
    });
    $(".img").mouseenter(function() {
        $(this).addClass("hover")
    }).mouseleave(function() {
        $(this).removeClass("hover")
    });
    $("#myModal").on("hidden.bs.modal", function() {
        $(this).removeData("bs.modal")
    });
    $("a#toggleBtn").on("click", function() {
        $(this).next("ul").toggle("fast", function() {
            $(this).toggleClass("closed opened")
        }), $(this).toggleClass("collapse-plus collapse-minus")
    });

    $(window).on('scroll', function() {
      if( $(this).scrollTop() > 10 ) {
        $('.has-sticky').addClass('sticky-menu');
      } else {
        $('.has-sticky').removeClass('sticky-menu');
      }
  });

});

$(window).load(function() {
  $('.gallery a').each(function() {
    getMeta($(this).attr('href'), $(this));
  })
});

function getMeta(url, th){

  $("<img/>").attr("src", url).load(function(){
     s = {w:this.width, h:this.height};
     th.attr('data-size', s.w+'x'+s.h);
  });
}
var initPhotoSwipeFromDOM = function(gallerySelector) {

    // parse slide data (url, title, size ...) from DOM elements
    // (children of gallerySelector)
    var parseThumbnailElements = function(el) {
        var thumbElements = el.childNodes,
            numNodes = thumbElements.length,
            items = [],
            figureEl,
            linkEl,
            size,
            item;

        for(var i = 0; i < numNodes; i++) {

            figureEl = thumbElements[i]; // <figure> element

            // include only element nodes
            if(figureEl.nodeType !== 1) {
                continue;
            }

            linkEl = figureEl.children[0]; // <a> element

            size = linkEl.getAttribute('data-size').split('x');

            // create slide object
            item = {
                src: linkEl.getAttribute('href'),
                w: parseInt(size[0], 10),
                h: parseInt(size[1], 10)
            };



            if(figureEl.children.length > 1) {
                // <figcaption> content
                item.title = figureEl.children[1].innerHTML;
            }

            if(linkEl.children.length > 0) {
                // <img> thumbnail element, retrieving thumbnail url
                item.msrc = linkEl.children[0].getAttribute('src');
            }

            item.el = figureEl; // save link to element for getThumbBoundsFn
            items.push(item);
        }

        return items;
    };

    // find nearest parent element
    var closest = function closest(el, fn) {
        return el && ( fn(el) ? el : closest(el.parentNode, fn) );
    };

    // triggers when user clicks on thumbnail
    var onThumbnailsClick = function(e) {
        e = e || window.event;
        e.preventDefault ? e.preventDefault() : e.returnValue = false;

        var eTarget = e.target || e.srcElement;

        // find root element of slide
        var clickedListItem = closest(eTarget, function(el) {
            return (el.tagName && el.tagName.toUpperCase() === 'DIV');
        });

        if(!clickedListItem) {
            return;
        }

        // find index of clicked item by looping through all child nodes
        // alternatively, you may define index via data- attribute
        var clickedGallery = clickedListItem.parentNode,
            childNodes = clickedListItem.parentNode.childNodes,
            numChildNodes = childNodes.length,
            nodeIndex = 0,
            index;

        for (var i = 0; i < numChildNodes; i++) {
            if(childNodes[i].nodeType !== 1) {
                continue;
            }

            if(childNodes[i] === clickedListItem) {
                index = nodeIndex;
                break;
            }
            nodeIndex++;
        }



        if(index >= 0) {
            // open PhotoSwipe if valid index found
            openPhotoSwipe( index, clickedGallery );
        }
        return false;
    };

    // parse picture index and gallery index from URL (#&pid=1&gid=2)
    var photoswipeParseHash = function() {
        var hash = window.location.hash.substring(1),
        params = {};

        if(hash.length < 5) {
            return params;
        }

        var vars = hash.split('&');
        for (var i = 0; i < vars.length; i++) {
            if(!vars[i]) {
                continue;
            }
            var pair = vars[i].split('=');
            if(pair.length < 2) {
                continue;
            }
            params[pair[0]] = pair[1];
        }

        if(params.gid) {
            params.gid = parseInt(params.gid, 10);
        }

        return params;
    };

    var openPhotoSwipe = function(index, galleryElement, disableAnimation, fromURL) {
        var pswpElement = document.querySelectorAll('.pswp')[0],
            gallery,
            options,
            items;

        items = parseThumbnailElements(galleryElement);

        // define options (if needed)
        options = {

            // define gallery index (for URL)
            galleryUID: galleryElement.getAttribute('data-pswp-uid'),

            getThumbBoundsFn: function(index) {
                // See Options -> getThumbBoundsFn section of documentation for more info
                var thumbnail = items[index].el.getElementsByTagName('a')[0], // find thumbnail
                    pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
                    rect = thumbnail.getBoundingClientRect();

                return {x:rect.left, y:rect.top + pageYScroll, w:rect.width};
            }

        };

        // PhotoSwipe opened from URL
        if(fromURL) {
            if(options.galleryPIDs) {
                // parse real index when custom PIDs are used
                // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
                for(var j = 0; j < items.length; j++) {
                    if(items[j].pid == index) {
                        options.index = j;
                        break;
                    }
                }
            } else {
                // in URL indexes start from 1
                options.index = parseInt(index, 10) - 1;
            }
        } else {
            options.index = parseInt(index, 10);
        }

        // exit if index not found
        if( isNaN(options.index) ) {
            return;
        }

        if(disableAnimation) {
            options.showAnimationDuration = 0;
        }

        // Pass data to PhotoSwipe and initialize it
        gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, items, options);
        gallery.init();
    };

    // loop through all gallery elements and bind events
    var galleryElements = document.querySelectorAll( gallerySelector );

    for(var i = 0, l = galleryElements.length; i < l; i++) {
        galleryElements[i].setAttribute('data-pswp-uid', i+1);
        galleryElements[i].onclick = onThumbnailsClick;
    }

    // Parse URL and open gallery if it contains #&pid=3&gid=1
    var hashData = photoswipeParseHash();
    if(hashData.pid && hashData.gid) {
        openPhotoSwipe( hashData.pid ,  galleryElements[ hashData.gid - 1 ], true, true );
    }
};
