if (! function(e, t) {
        "object" == typeof module && "object" == typeof module.exports ? module.exports = e.document ? t(e, !0) : function(e) {
            if (!e.document) throw new Error("jQuery requires a window with a document");
            return t(e)
        } : t(e)
    }("undefined" != typeof window ? window : this, function(e, t) {
        function i(e) {
            var t = "length" in e && e.length,
                i = oe.type(e);
            return "function" !== i && !oe.isWindow(e) && (!(1 !== e.nodeType || !t) || ("array" === i || 0 === t || "number" == typeof t && t > 0 && t - 1 in e))
        }

        function n(e, t, i) {
            if (oe.isFunction(t)) return oe.grep(e, function(e, n) {
                return !!t.call(e, n, e) !== i
            });
            if (t.nodeType) return oe.grep(e, function(e) {
                return e === t !== i
            });
            if ("string" == typeof t) {
                if (pe.test(t)) return oe.filter(t, e, i);
                t = oe.filter(t, e)
            }
            return oe.grep(e, function(e) {
                return oe.inArray(e, t) >= 0 !== i
            })
        }

        function o(e, t) {
            do e = e[t]; while (e && 1 !== e.nodeType);
            return e
        }

        function r(e) {
            var t = we[e] = {};
            return oe.each(e.match(ye) || [], function(e, i) {
                t[i] = !0
            }), t
        }

        function s() {
            fe.addEventListener ? (fe.removeEventListener("DOMContentLoaded", a, !1), e.removeEventListener("load", a, !1)) : (fe.detachEvent("onreadystatechange", a), e.detachEvent("onload", a))
        }

        function a() {
            (fe.addEventListener || "load" === event.type || "complete" === fe.readyState) && (s(), oe.ready())
        }

        function l(e, t, i) {
            if (void 0 === i && 1 === e.nodeType) {
                var n = "data-" + t.replace(Se, "-$1").toLowerCase();
                if (i = e.getAttribute(n), "string" == typeof i) {
                    try {
                        i = "true" === i || "false" !== i && ("null" === i ? null : +i + "" === i ? +i : ke.test(i) ? oe.parseJSON(i) : i)
                    } catch (o) {}
                    oe.data(e, t, i)
                } else i = void 0
            }
            return i
        }

        function c(e) {
            var t;
            for (t in e)
                if (("data" !== t || !oe.isEmptyObject(e[t])) && "toJSON" !== t) return !1;
            return !0
        }

        function d(e, t, i, n) {
            if (oe.acceptData(e)) {
                var o, r, s = oe.expando,
                    a = e.nodeType,
                    l = a ? oe.cache : e,
                    c = a ? e[s] : e[s] && s;
                if (c && l[c] && (n || l[c].data) || void 0 !== i || "string" != typeof t) return c || (c = a ? e[s] = U.pop() || oe.guid++ : s), l[c] || (l[c] = a ? {} : {
                    toJSON: oe.noop
                }), ("object" == typeof t || "function" == typeof t) && (n ? l[c] = oe.extend(l[c], t) : l[c].data = oe.extend(l[c].data, t)), r = l[c], n || (r.data || (r.data = {}), r = r.data), void 0 !== i && (r[oe.camelCase(t)] = i), "string" == typeof t ? (o = r[t], null == o && (o = r[oe.camelCase(t)])) : o = r, o
            }
        }

        function u(e, t, i) {
            if (oe.acceptData(e)) {
                var n, o, r = e.nodeType,
                    s = r ? oe.cache : e,
                    a = r ? e[oe.expando] : oe.expando;
                if (s[a]) {
                    if (t && (n = i ? s[a] : s[a].data)) {
                        oe.isArray(t) ? t = t.concat(oe.map(t, oe.camelCase)) : t in n ? t = [t] : (t = oe.camelCase(t), t = t in n ? [t] : t.split(" ")), o = t.length;
                        for (; o--;) delete n[t[o]];
                        if (i ? !c(n) : !oe.isEmptyObject(n)) return
                    }(i || (delete s[a].data, c(s[a]))) && (r ? oe.cleanData([e], !0) : ie.deleteExpando || s != s.window ? delete s[a] : s[a] = null)
                }
            }
        }

        function p() {
            return !0
        }

        function h() {
            return !1
        }

        function f() {
            try {
                return fe.activeElement
            } catch (e) {}
        }

        function g(e) {
            var t = Ne.split("|"),
                i = e.createDocumentFragment();
            if (i.createElement)
                for (; t.length;) i.createElement(t.pop());
            return i
        }

        function m(e, t) {
            var i, n, o = 0,
                r = typeof e.getElementsByTagName !== _e ? e.getElementsByTagName(t || "*") : typeof e.querySelectorAll !== _e ? e.querySelectorAll(t || "*") : void 0;
            if (!r)
                for (r = [], i = e.childNodes || e; null != (n = i[o]); o++) !t || oe.nodeName(n, t) ? r.push(n) : oe.merge(r, m(n, t));
            return void 0 === t || t && oe.nodeName(e, t) ? oe.merge([e], r) : r
        }

        function v(e) {
            Oe.test(e.type) && (e.defaultChecked = e.checked)
        }

        function b(e, t) {
            return oe.nodeName(e, "table") && oe.nodeName(11 !== t.nodeType ? t : t.firstChild, "tr") ? e.getElementsByTagName("tbody")[0] || e.appendChild(e.ownerDocument.createElement("tbody")) : e
        }

        function y(e) {
            return e.type = (null !== oe.find.attr(e, "type")) + "/" + e.type, e
        }

        function w(e) {
            var t = Ve.exec(e.type);
            return t ? e.type = t[1] : e.removeAttribute("type"), e
        }

        function x(e, t) {
            for (var i, n = 0; null != (i = e[n]); n++) oe._data(i, "globalEval", !t || oe._data(t[n], "globalEval"))
        }

        function T(e, t) {
            if (1 === t.nodeType && oe.hasData(e)) {
                var i, n, o, r = oe._data(e),
                    s = oe._data(t, r),
                    a = r.events;
                if (a) {
                    delete s.handle, s.events = {};
                    for (i in a)
                        for (n = 0, o = a[i].length; o > n; n++) oe.event.add(t, i, a[i][n])
                }
                s.data && (s.data = oe.extend({}, s.data))
            }
        }

        function _(e, t) {
            var i, n, o;
            if (1 === t.nodeType) {
                if (i = t.nodeName.toLowerCase(), !ie.noCloneEvent && t[oe.expando]) {
                    o = oe._data(t);
                    for (n in o.events) oe.removeEvent(t, n, o.handle);
                    t.removeAttribute(oe.expando)
                }
                "script" === i && t.text !== e.text ? (y(t).text = e.text, w(t)) : "object" === i ? (t.parentNode && (t.outerHTML = e.outerHTML), ie.html5Clone && e.innerHTML && !oe.trim(t.innerHTML) && (t.innerHTML = e.innerHTML)) : "input" === i && Oe.test(e.type) ? (t.defaultChecked = t.checked = e.checked, t.value !== e.value && (t.value = e.value)) : "option" === i ? t.defaultSelected = t.selected = e.defaultSelected : ("input" === i || "textarea" === i) && (t.defaultValue = e.defaultValue)
            }
        }

        function k(t, i) {
            var n, o = oe(i.createElement(t)).appendTo(i.body),
                r = e.getDefaultComputedStyle && (n = e.getDefaultComputedStyle(o[0])) ? n.display : oe.css(o[0], "display");
            return o.detach(), r
        }

        function S(e) {
            var t = fe,
                i = Ke[e];
            return i || (i = k(e, t), "none" !== i && i || (Je = (Je || oe("<iframe frameborder='0' width='0' height='0'/>")).appendTo(t.documentElement), t = (Je[0].contentWindow || Je[0].contentDocument).document, t.write(), t.close(), i = k(e, t), Je.detach()), Ke[e] = i), i
        }

        function C(e, t) {
            return {
                get: function() {
                    var i = e();
                    if (null != i) return i ? void delete this.get : (this.get = t).apply(this, arguments)
                }
            }
        }

        function $(e, t) {
            if (t in e) return t;
            for (var i = t.charAt(0).toUpperCase() + t.slice(1), n = t, o = pt.length; o--;)
                if (t = pt[o] + i, t in e) return t;
            return n
        }

        function P(e, t) {
            for (var i, n, o, r = [], s = 0, a = e.length; a > s; s++) n = e[s], n.style && (r[s] = oe._data(n, "olddisplay"), i = n.style.display, t ? (r[s] || "none" !== i || (n.style.display = ""), "" === n.style.display && Pe(n) && (r[s] = oe._data(n, "olddisplay", S(n.nodeName)))) : (o = Pe(n), (i && "none" !== i || !o) && oe._data(n, "olddisplay", o ? i : oe.css(n, "display"))));
            for (s = 0; a > s; s++) n = e[s], n.style && (t && "none" !== n.style.display && "" !== n.style.display || (n.style.display = t ? r[s] || "" : "none"));
            return e
        }

        function A(e, t, i) {
            var n = lt.exec(t);
            return n ? Math.max(0, n[1] - (i || 0)) + (n[2] || "px") : t
        }

        function O(e, t, i, n, o) {
            for (var r = i === (n ? "border" : "content") ? 4 : "width" === t ? 1 : 0, s = 0; 4 > r; r += 2) "margin" === i && (s += oe.css(e, i + $e[r], !0, o)), n ? ("content" === i && (s -= oe.css(e, "padding" + $e[r], !0, o)), "margin" !== i && (s -= oe.css(e, "border" + $e[r] + "Width", !0, o))) : (s += oe.css(e, "padding" + $e[r], !0, o), "padding" !== i && (s += oe.css(e, "border" + $e[r] + "Width", !0, o)));
            return s
        }

        function E(e, t, i) {
            var n = !0,
                o = "width" === t ? e.offsetWidth : e.offsetHeight,
                r = et(e),
                s = ie.boxSizing && "border-box" === oe.css(e, "boxSizing", !1, r);
            if (0 >= o || null == o) {
                if (o = tt(e, t, r), (0 > o || null == o) && (o = e.style[t]), nt.test(o)) return o;
                n = s && (ie.boxSizingReliable() || o === e.style[t]), o = parseFloat(o) || 0
            }
            return o + O(e, t, i || (s ? "border" : "content"), n, r) + "px"
        }

        function M(e, t, i, n, o) {
            return new M.prototype.init(e, t, i, n, o)
        }

        function I() {
            return setTimeout(function() {
                ht = void 0
            }), ht = oe.now()
        }

        function D(e, t) {
            var i, n = {
                    height: e
                },
                o = 0;
            for (t = t ? 1 : 0; 4 > o; o += 2 - t) i = $e[o], n["margin" + i] = n["padding" + i] = e;
            return t && (n.opacity = n.width = e), n
        }

        function j(e, t, i) {
            for (var n, o = (yt[t] || []).concat(yt["*"]), r = 0, s = o.length; s > r; r++)
                if (n = o[r].call(i, t, e)) return n
        }

        function N(e, t, i) {
            var n, o, r, s, a, l, c, d, u = this,
                p = {},
                h = e.style,
                f = e.nodeType && Pe(e),
                g = oe._data(e, "fxshow");
            i.queue || (a = oe._queueHooks(e, "fx"), null == a.unqueued && (a.unqueued = 0, l = a.empty.fire, a.empty.fire = function() {
                a.unqueued || l()
            }), a.unqueued++, u.always(function() {
                u.always(function() {
                    a.unqueued--, oe.queue(e, "fx").length || a.empty.fire()
                })
            })), 1 === e.nodeType && ("height" in t || "width" in t) && (i.overflow = [h.overflow, h.overflowX, h.overflowY], c = oe.css(e, "display"), d = "none" === c ? oe._data(e, "olddisplay") || S(e.nodeName) : c, "inline" === d && "none" === oe.css(e, "float") && (ie.inlineBlockNeedsLayout && "inline" !== S(e.nodeName) ? h.zoom = 1 : h.display = "inline-block")), i.overflow && (h.overflow = "hidden", ie.shrinkWrapBlocks() || u.always(function() {
                h.overflow = i.overflow[0], h.overflowX = i.overflow[1], h.overflowY = i.overflow[2]
            }));
            for (n in t)
                if (o = t[n], gt.exec(o)) {
                    if (delete t[n], r = r || "toggle" === o, o === (f ? "hide" : "show")) {
                        if ("show" !== o || !g || void 0 === g[n]) continue;
                        f = !0
                    }
                    p[n] = g && g[n] || oe.style(e, n)
                } else c = void 0;
            if (oe.isEmptyObject(p)) "inline" === ("none" === c ? S(e.nodeName) : c) && (h.display = c);
            else {
                g ? "hidden" in g && (f = g.hidden) : g = oe._data(e, "fxshow", {}), r && (g.hidden = !f), f ? oe(e).show() : u.done(function() {
                    oe(e).hide()
                }), u.done(function() {
                    var t;
                    oe._removeData(e, "fxshow");
                    for (t in p) oe.style(e, t, p[t])
                });
                for (n in p) s = j(f ? g[n] : 0, n, u), n in g || (g[n] = s.start, f && (s.end = s.start, s.start = "width" === n || "height" === n ? 1 : 0))
            }
        }

        function L(e, t) {
            var i, n, o, r, s;
            for (i in e)
                if (n = oe.camelCase(i), o = t[n], r = e[i], oe.isArray(r) && (o = r[1], r = e[i] = r[0]), i !== n && (e[n] = r, delete e[i]), s = oe.cssHooks[n], s && "expand" in s) {
                    r = s.expand(r), delete e[n];
                    for (i in r) i in e || (e[i] = r[i], t[i] = o)
                } else t[n] = o
        }

        function z(e, t, i) {
            var n, o, r = 0,
                s = bt.length,
                a = oe.Deferred().always(function() {
                    delete l.elem
                }),
                l = function() {
                    if (o) return !1;
                    for (var t = ht || I(), i = Math.max(0, c.startTime + c.duration - t), n = i / c.duration || 0, r = 1 - n, s = 0, l = c.tweens.length; l > s; s++) c.tweens[s].run(r);
                    return a.notifyWith(e, [c, r, i]), 1 > r && l ? i : (a.resolveWith(e, [c]), !1)
                },
                c = a.promise({
                    elem: e,
                    props: oe.extend({}, t),
                    opts: oe.extend(!0, {
                        specialEasing: {}
                    }, i),
                    originalProperties: t,
                    originalOptions: i,
                    startTime: ht || I(),
                    duration: i.duration,
                    tweens: [],
                    createTween: function(t, i) {
                        var n = oe.Tween(e, c.opts, t, i, c.opts.specialEasing[t] || c.opts.easing);
                        return c.tweens.push(n), n
                    },
                    stop: function(t) {
                        var i = 0,
                            n = t ? c.tweens.length : 0;
                        if (o) return this;
                        for (o = !0; n > i; i++) c.tweens[i].run(1);
                        return t ? a.resolveWith(e, [c, t]) : a.rejectWith(e, [c, t]), this
                    }
                }),
                d = c.props;
            for (L(d, c.opts.specialEasing); s > r; r++)
                if (n = bt[r].call(c, e, d, c.opts)) return n;
            return oe.map(d, j, c), oe.isFunction(c.opts.start) && c.opts.start.call(e, c), oe.fx.timer(oe.extend(l, {
                elem: e,
                anim: c,
                queue: c.opts.queue
            })), c.progress(c.opts.progress).done(c.opts.done, c.opts.complete).fail(c.opts.fail).always(c.opts.always)
        }

        function F(e) {
            return function(t, i) {
                "string" != typeof t && (i = t, t = "*");
                var n, o = 0,
                    r = t.toLowerCase().match(ye) || [];
                if (oe.isFunction(i))
                    for (; n = r[o++];) "+" === n.charAt(0) ? (n = n.slice(1) || "*", (e[n] = e[n] || []).unshift(i)) : (e[n] = e[n] || []).push(i)
            }
        }

        function R(e, t, i, n) {
            function o(a) {
                var l;
                return r[a] = !0, oe.each(e[a] || [], function(e, a) {
                    var c = a(t, i, n);
                    return "string" != typeof c || s || r[c] ? s ? !(l = c) : void 0 : (t.dataTypes.unshift(c), o(c), !1)
                }), l
            }
            var r = {},
                s = e === Bt;
            return o(t.dataTypes[0]) || !r["*"] && o("*")
        }

        function H(e, t) {
            var i, n, o = oe.ajaxSettings.flatOptions || {};
            for (n in t) void 0 !== t[n] && ((o[n] ? e : i || (i = {}))[n] = t[n]);
            return i && oe.extend(!0, e, i), e
        }

        function W(e, t, i) {
            for (var n, o, r, s, a = e.contents, l = e.dataTypes;
                "*" === l[0];) l.shift(), void 0 === o && (o = e.mimeType || t.getResponseHeader("Content-Type"));
            if (o)
                for (s in a)
                    if (a[s] && a[s].test(o)) {
                        l.unshift(s);
                        break
                    }
            if (l[0] in i) r = l[0];
            else {
                for (s in i) {
                    if (!l[0] || e.converters[s + " " + l[0]]) {
                        r = s;
                        break
                    }
                    n || (n = s)
                }
                r = r || n
            }
            return r ? (r !== l[0] && l.unshift(r), i[r]) : void 0
        }

        function B(e, t, i, n) {
            var o, r, s, a, l, c = {},
                d = e.dataTypes.slice();
            if (d[1])
                for (s in e.converters) c[s.toLowerCase()] = e.converters[s];
            for (r = d.shift(); r;)
                if (e.responseFields[r] && (i[e.responseFields[r]] = t), !l && n && e.dataFilter && (t = e.dataFilter(t, e.dataType)), l = r, r = d.shift())
                    if ("*" === r) r = l;
                    else if ("*" !== l && l !== r) {
                if (s = c[l + " " + r] || c["* " + r], !s)
                    for (o in c)
                        if (a = o.split(" "), a[1] === r && (s = c[l + " " + a[0]] || c["* " + a[0]])) {
                            s === !0 ? s = c[o] : c[o] !== !0 && (r = a[0], d.unshift(a[1]));
                            break
                        }
                if (s !== !0)
                    if (s && e["throws"]) t = s(t);
                    else try {
                        t = s(t)
                    } catch (u) {
                        return {
                            state: "parsererror",
                            error: s ? u : "No conversion from " + l + " to " + r
                        }
                    }
            }
            return {
                state: "success",
                data: t
            }
        }

        function q(e, t, i, n) {
            var o;
            if (oe.isArray(t)) oe.each(t, function(t, o) {
                i || Vt.test(e) ? n(e, o) : q(e + "[" + ("object" == typeof o ? t : "") + "]", o, i, n)
            });
            else if (i || "object" !== oe.type(t)) n(e, t);
            else
                for (o in t) q(e + "[" + o + "]", t[o], i, n)
        }

        function X() {
            try {
                return new e.XMLHttpRequest
            } catch (t) {}
        }

        function G() {
            try {
                return new e.ActiveXObject("Microsoft.XMLHTTP")
            } catch (t) {}
        }

        function V(e) {
            return oe.isWindow(e) ? e : 9 === e.nodeType && (e.defaultView || e.parentWindow)
        }
        var U = [],
            Y = U.slice,
            Q = U.concat,
            Z = U.push,
            J = U.indexOf,
            K = {},
            ee = K.toString,
            te = K.hasOwnProperty,
            ie = {},
            ne = "1.11.3",
            oe = function(e, t) {
                return new oe.fn.init(e, t)
            },
            re = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g,
            se = /^-ms-/,
            ae = /-([\da-z])/gi,
            le = function(e, t) {
                return t.toUpperCase()
            };
        oe.fn = oe.prototype = {
            jquery: ne,
            constructor: oe,
            selector: "",
            length: 0,
            toArray: function() {
                return Y.call(this)
            },
            get: function(e) {
                return null != e ? 0 > e ? this[e + this.length] : this[e] : Y.call(this)
            },
            pushStack: function(e) {
                var t = oe.merge(this.constructor(), e);
                return t.prevObject = this, t.context = this.context, t
            },
            each: function(e, t) {
                return oe.each(this, e, t)
            },
            map: function(e) {
                return this.pushStack(oe.map(this, function(t, i) {
                    return e.call(t, i, t)
                }))
            },
            slice: function() {
                return this.pushStack(Y.apply(this, arguments))
            },
            first: function() {
                return this.eq(0)
            },
            last: function() {
                return this.eq(-1)
            },
            eq: function(e) {
                var t = this.length,
                    i = +e + (0 > e ? t : 0);
                return this.pushStack(i >= 0 && t > i ? [this[i]] : [])
            },
            end: function() {
                return this.prevObject || this.constructor(null)
            },
            push: Z,
            sort: U.sort,
            splice: U.splice
        }, oe.extend = oe.fn.extend = function() {
            var e, t, i, n, o, r, s = arguments[0] || {},
                a = 1,
                l = arguments.length,
                c = !1;
            for ("boolean" == typeof s && (c = s, s = arguments[a] || {}, a++), "object" == typeof s || oe.isFunction(s) || (s = {}), a === l && (s = this, a--); l > a; a++)
                if (null != (o = arguments[a]))
                    for (n in o) e = s[n], i = o[n], s !== i && (c && i && (oe.isPlainObject(i) || (t = oe.isArray(i))) ? (t ? (t = !1, r = e && oe.isArray(e) ? e : []) : r = e && oe.isPlainObject(e) ? e : {}, s[n] = oe.extend(c, r, i)) : void 0 !== i && (s[n] = i));
            return s
        }, oe.extend({
            expando: "jQuery" + (ne + Math.random()).replace(/\D/g, ""),
            isReady: !0,
            error: function(e) {
                throw new Error(e)
            },
            noop: function() {},
            isFunction: function(e) {
                return "function" === oe.type(e)
            },
            isArray: Array.isArray || function(e) {
                return "array" === oe.type(e)
            },
            isWindow: function(e) {
                return null != e && e == e.window
            },
            isNumeric: function(e) {
                return !oe.isArray(e) && e - parseFloat(e) + 1 >= 0
            },
            isEmptyObject: function(e) {
                var t;
                for (t in e) return !1;
                return !0
            },
            isPlainObject: function(e) {
                var t;
                if (!e || "object" !== oe.type(e) || e.nodeType || oe.isWindow(e)) return !1;
                try {
                    if (e.constructor && !te.call(e, "constructor") && !te.call(e.constructor.prototype, "isPrototypeOf")) return !1
                } catch (i) {
                    return !1
                }
                if (ie.ownLast)
                    for (t in e) return te.call(e, t);
                for (t in e);
                return void 0 === t || te.call(e, t)
            },
            type: function(e) {
                return null == e ? e + "" : "object" == typeof e || "function" == typeof e ? K[ee.call(e)] || "object" : typeof e
            },
            globalEval: function(t) {
                t && oe.trim(t) && (e.execScript || function(t) {
                    e.eval.call(e, t)
                })(t)
            },
            camelCase: function(e) {
                return e.replace(se, "ms-").replace(ae, le)
            },
            nodeName: function(e, t) {
                return e.nodeName && e.nodeName.toLowerCase() === t.toLowerCase()
            },
            each: function(e, t, n) {
                var o, r = 0,
                    s = e.length,
                    a = i(e);
                if (n) {
                    if (a)
                        for (; s > r && (o = t.apply(e[r], n), o !== !1); r++);
                    else
                        for (r in e)
                            if (o = t.apply(e[r], n), o === !1) break
                } else if (a)
                    for (; s > r && (o = t.call(e[r], r, e[r]), o !== !1); r++);
                else
                    for (r in e)
                        if (o = t.call(e[r], r, e[r]), o === !1) break; return e
            },
            trim: function(e) {
                return null == e ? "" : (e + "").replace(re, "")
            },
            makeArray: function(e, t) {
                var n = t || [];
                return null != e && (i(Object(e)) ? oe.merge(n, "string" == typeof e ? [e] : e) : Z.call(n, e)), n
            },
            inArray: function(e, t, i) {
                var n;
                if (t) {
                    if (J) return J.call(t, e, i);
                    for (n = t.length, i = i ? 0 > i ? Math.max(0, n + i) : i : 0; n > i; i++)
                        if (i in t && t[i] === e) return i
                }
                return -1
            },
            merge: function(e, t) {
                for (var i = +t.length, n = 0, o = e.length; i > n;) e[o++] = t[n++];
                if (i !== i)
                    for (; void 0 !== t[n];) e[o++] = t[n++];
                return e.length = o, e
            },
            grep: function(e, t, i) {
                for (var n, o = [], r = 0, s = e.length, a = !i; s > r; r++) n = !t(e[r], r), n !== a && o.push(e[r]);
                return o
            },
            map: function(e, t, n) {
                var o, r = 0,
                    s = e.length,
                    a = i(e),
                    l = [];
                if (a)
                    for (; s > r; r++) o = t(e[r], r, n), null != o && l.push(o);
                else
                    for (r in e) o = t(e[r], r, n), null != o && l.push(o);
                return Q.apply([], l)
            },
            guid: 1,
            proxy: function(e, t) {
                var i, n, o;
                return "string" == typeof t && (o = e[t], t = e, e = o), oe.isFunction(e) ? (i = Y.call(arguments, 2), n = function() {
                    return e.apply(t || this, i.concat(Y.call(arguments)))
                }, n.guid = e.guid = e.guid || oe.guid++, n) : void 0
            },
            now: function() {
                return +new Date
            },
            support: ie
        }), oe.each("Boolean Number String Function Array Date RegExp Object Error".split(" "), function(e, t) {
            K["[object " + t + "]"] = t.toLowerCase()
        });
        var ce = function(e) {
            function t(e, t, i, n) {
                var o, r, s, a, l, c, u, h, f, g;
                if ((t ? t.ownerDocument || t : R) !== M && E(t), t = t || M, i = i || [], a = t.nodeType, "string" != typeof e || !e || 1 !== a && 9 !== a && 11 !== a) return i;
                if (!n && D) {
                    if (11 !== a && (o = be.exec(e)))
                        if (s = o[1]) {
                            if (9 === a) {
                                if (r = t.getElementById(s), !r || !r.parentNode) return i;
                                if (r.id === s) return i.push(r), i
                            } else if (t.ownerDocument && (r = t.ownerDocument.getElementById(s)) && z(t, r) && r.id === s) return i.push(r), i
                        } else {
                            if (o[2]) return J.apply(i, t.getElementsByTagName(e)), i;
                            if ((s = o[3]) && x.getElementsByClassName) return J.apply(i, t.getElementsByClassName(s)), i
                        }
                    if (x.qsa && (!j || !j.test(e))) {
                        if (h = u = F, f = t, g = 1 !== a && e, 1 === a && "object" !== t.nodeName.toLowerCase()) {
                            for (c = S(e), (u = t.getAttribute("id")) ? h = u.replace(we, "\\$&") : t.setAttribute("id", h), h = "[id='" + h + "'] ", l = c.length; l--;) c[l] = h + p(c[l]);
                            f = ye.test(e) && d(t.parentNode) || t, g = c.join(",")
                        }
                        if (g) try {
                            return J.apply(i, f.querySelectorAll(g)), i
                        } catch (m) {} finally {
                            u || t.removeAttribute("id")
                        }
                    }
                }
                return $(e.replace(le, "$1"), t, i, n)
            }

            function i() {
                function e(i, n) {
                    return t.push(i + " ") > T.cacheLength && delete e[t.shift()], e[i + " "] = n
                }
                var t = [];
                return e
            }

            function n(e) {
                return e[F] = !0, e
            }

            function o(e) {
                var t = M.createElement("div");
                try {
                    return !!e(t)
                } catch (i) {
                    return !1
                } finally {
                    t.parentNode && t.parentNode.removeChild(t), t = null
                }
            }

            function r(e, t) {
                for (var i = e.split("|"), n = e.length; n--;) T.attrHandle[i[n]] = t
            }

            function s(e, t) {
                var i = t && e,
                    n = i && 1 === e.nodeType && 1 === t.nodeType && (~t.sourceIndex || V) - (~e.sourceIndex || V);
                if (n) return n;
                if (i)
                    for (; i = i.nextSibling;)
                        if (i === t) return -1;
                return e ? 1 : -1
            }

            function a(e) {
                return function(t) {
                    var i = t.nodeName.toLowerCase();
                    return "input" === i && t.type === e
                }
            }

            function l(e) {
                return function(t) {
                    var i = t.nodeName.toLowerCase();
                    return ("input" === i || "button" === i) && t.type === e
                }
            }

            function c(e) {
                return n(function(t) {
                    return t = +t, n(function(i, n) {
                        for (var o, r = e([], i.length, t), s = r.length; s--;) i[o = r[s]] && (i[o] = !(n[o] = i[o]))
                    })
                })
            }

            function d(e) {
                return e && "undefined" != typeof e.getElementsByTagName && e
            }

            function u() {}

            function p(e) {
                for (var t = 0, i = e.length, n = ""; i > t; t++) n += e[t].value;
                return n
            }

            function h(e, t, i) {
                var n = t.dir,
                    o = i && "parentNode" === n,
                    r = W++;
                return t.first ? function(t, i, r) {
                    for (; t = t[n];)
                        if (1 === t.nodeType || o) return e(t, i, r)
                } : function(t, i, s) {
                    var a, l, c = [H, r];
                    if (s) {
                        for (; t = t[n];)
                            if ((1 === t.nodeType || o) && e(t, i, s)) return !0
                    } else
                        for (; t = t[n];)
                            if (1 === t.nodeType || o) {
                                if (l = t[F] || (t[F] = {}), (a = l[n]) && a[0] === H && a[1] === r) return c[2] = a[2];
                                if (l[n] = c, c[2] = e(t, i, s)) return !0
                            }
                }
            }

            function f(e) {
                return e.length > 1 ? function(t, i, n) {
                    for (var o = e.length; o--;)
                        if (!e[o](t, i, n)) return !1;
                    return !0
                } : e[0]
            }

            function g(e, i, n) {
                for (var o = 0, r = i.length; r > o; o++) t(e, i[o], n);
                return n
            }

            function m(e, t, i, n, o) {
                for (var r, s = [], a = 0, l = e.length, c = null != t; l > a; a++)(r = e[a]) && (!i || i(r, n, o)) && (s.push(r), c && t.push(a));
                return s
            }

            function v(e, t, i, o, r, s) {
                return o && !o[F] && (o = v(o)), r && !r[F] && (r = v(r, s)), n(function(n, s, a, l) {
                    var c, d, u, p = [],
                        h = [],
                        f = s.length,
                        v = n || g(t || "*", a.nodeType ? [a] : a, []),
                        b = !e || !n && t ? v : m(v, p, e, a, l),
                        y = i ? r || (n ? e : f || o) ? [] : s : b;
                    if (i && i(b, y, a, l), o)
                        for (c = m(y, h), o(c, [], a, l), d = c.length; d--;)(u = c[d]) && (y[h[d]] = !(b[h[d]] = u));
                    if (n) {
                        if (r || e) {
                            if (r) {
                                for (c = [], d = y.length; d--;)(u = y[d]) && c.push(b[d] = u);
                                r(null, y = [], c, l)
                            }
                            for (d = y.length; d--;)(u = y[d]) && (c = r ? ee(n, u) : p[d]) > -1 && (n[c] = !(s[c] = u))
                        }
                    } else y = m(y === s ? y.splice(f, y.length) : y), r ? r(null, s, y, l) : J.apply(s, y)
                })
            }

            function b(e) {
                for (var t, i, n, o = e.length, r = T.relative[e[0].type], s = r || T.relative[" "], a = r ? 1 : 0, l = h(function(e) {
                        return e === t
                    }, s, !0), c = h(function(e) {
                        return ee(t, e) > -1
                    }, s, !0), d = [function(e, i, n) {
                        var o = !r && (n || i !== P) || ((t = i).nodeType ? l(e, i, n) : c(e, i, n));
                        return t = null, o
                    }]; o > a; a++)
                    if (i = T.relative[e[a].type]) d = [h(f(d), i)];
                    else {
                        if (i = T.filter[e[a].type].apply(null, e[a].matches), i[F]) {
                            for (n = ++a; o > n && !T.relative[e[n].type]; n++);
                            return v(a > 1 && f(d), a > 1 && p(e.slice(0, a - 1).concat({
                                value: " " === e[a - 2].type ? "*" : ""
                            })).replace(le, "$1"), i, n > a && b(e.slice(a, n)), o > n && b(e = e.slice(n)), o > n && p(e))
                        }
                        d.push(i)
                    }
                return f(d)
            }

            function y(e, i) {
                var o = i.length > 0,
                    r = e.length > 0,
                    s = function(n, s, a, l, c) {
                        var d, u, p, h = 0,
                            f = "0",
                            g = n && [],
                            v = [],
                            b = P,
                            y = n || r && T.find.TAG("*", c),
                            w = H += null == b ? 1 : Math.random() || .1,
                            x = y.length;
                        for (c && (P = s !== M && s); f !== x && null != (d = y[f]); f++) {
                            if (r && d) {
                                for (u = 0; p = e[u++];)
                                    if (p(d, s, a)) {
                                        l.push(d);
                                        break
                                    }
                                c && (H = w)
                            }
                            o && ((d = !p && d) && h--, n && g.push(d))
                        }
                        if (h += f, o && f !== h) {
                            for (u = 0; p = i[u++];) p(g, v, s, a);
                            if (n) {
                                if (h > 0)
                                    for (; f--;) g[f] || v[f] || (v[f] = Q.call(l));
                                v = m(v)
                            }
                            J.apply(l, v), c && !n && v.length > 0 && h + i.length > 1 && t.uniqueSort(l)
                        }
                        return c && (H = w, P = b), g
                    };
                return o ? n(s) : s
            }
            var w, x, T, _, k, S, C, $, P, A, O, E, M, I, D, j, N, L, z, F = "sizzle" + 1 * new Date,
                R = e.document,
                H = 0,
                W = 0,
                B = i(),
                q = i(),
                X = i(),
                G = function(e, t) {
                    return e === t && (O = !0), 0
                },
                V = 1 << 31,
                U = {}.hasOwnProperty,
                Y = [],
                Q = Y.pop,
                Z = Y.push,
                J = Y.push,
                K = Y.slice,
                ee = function(e, t) {
                    for (var i = 0, n = e.length; n > i; i++)
                        if (e[i] === t) return i;
                    return -1
                },
                te = "checked|selected|async|autofocus|autoplay|controls|defer|disabled|hidden|ismap|loop|multiple|open|readonly|required|scoped",
                ie = "[\\x20\\t\\r\\n\\f]",
                ne = "(?:\\\\.|[\\w-]|[^\\x00-\\xa0])+",
                oe = ne.replace("w", "w#"),
                re = "\\[" + ie + "*(" + ne + ")(?:" + ie + "*([*^$|!~]?=)" + ie + "*(?:'((?:\\\\.|[^\\\\'])*)'|\"((?:\\\\.|[^\\\\\"])*)\"|(" + oe + "))|)" + ie + "*\\]",
                se = ":(" + ne + ")(?:\\((('((?:\\\\.|[^\\\\'])*)'|\"((?:\\\\.|[^\\\\\"])*)\")|((?:\\\\.|[^\\\\()[\\]]|" + re + ")*)|.*)\\)|)",
                ae = new RegExp(ie + "+", "g"),
                le = new RegExp("^" + ie + "+|((?:^|[^\\\\])(?:\\\\.)*)" + ie + "+$", "g"),
                ce = new RegExp("^" + ie + "*," + ie + "*"),
                de = new RegExp("^" + ie + "*([>+~]|" + ie + ")" + ie + "*"),
                ue = new RegExp("=" + ie + "*([^\\]'\"]*?)" + ie + "*\\]", "g"),
                pe = new RegExp(se),
                he = new RegExp("^" + oe + "$"),
                fe = {
                    ID: new RegExp("^#(" + ne + ")"),
                    CLASS: new RegExp("^\\.(" + ne + ")"),
                    TAG: new RegExp("^(" + ne.replace("w", "w*") + ")"),
                    ATTR: new RegExp("^" + re),
                    PSEUDO: new RegExp("^" + se),
                    CHILD: new RegExp("^:(only|first|last|nth|nth-last)-(child|of-type)(?:\\(" + ie + "*(even|odd|(([+-]|)(\\d*)n|)" + ie + "*(?:([+-]|)" + ie + "*(\\d+)|))" + ie + "*\\)|)", "i"),
                    bool: new RegExp("^(?:" + te + ")$", "i"),
                    needsContext: new RegExp("^" + ie + "*[>+~]|:(even|odd|eq|gt|lt|nth|first|last)(?:\\(" + ie + "*((?:-\\d)?\\d*)" + ie + "*\\)|)(?=[^-]|$)", "i")
                },
                ge = /^(?:input|select|textarea|button)$/i,
                me = /^h\d$/i,
                ve = /^[^{]+\{\s*\[native \w/,
                be = /^(?:#([\w-]+)|(\w+)|\.([\w-]+))$/,
                ye = /[+~]/,
                we = /'|\\/g,
                xe = new RegExp("\\\\([\\da-f]{1,6}" + ie + "?|(" + ie + ")|.)", "ig"),
                Te = function(e, t, i) {
                    var n = "0x" + t - 65536;
                    return n !== n || i ? t : 0 > n ? String.fromCharCode(n + 65536) : String.fromCharCode(n >> 10 | 55296, 1023 & n | 56320)
                },
                _e = function() {
                    E()
                };
            try {
                J.apply(Y = K.call(R.childNodes), R.childNodes), Y[R.childNodes.length].nodeType
            } catch (ke) {
                J = {
                    apply: Y.length ? function(e, t) {
                        Z.apply(e, K.call(t))
                    } : function(e, t) {
                        for (var i = e.length, n = 0; e[i++] = t[n++];);
                        e.length = i - 1
                    }
                }
            }
            x = t.support = {}, k = t.isXML = function(e) {
                var t = e && (e.ownerDocument || e).documentElement;
                return !!t && "HTML" !== t.nodeName
            }, E = t.setDocument = function(e) {
                var t, i, n = e ? e.ownerDocument || e : R;
                return n !== M && 9 === n.nodeType && n.documentElement ? (M = n, I = n.documentElement, i = n.defaultView, i && i !== i.top && (i.addEventListener ? i.addEventListener("unload", _e, !1) : i.attachEvent && i.attachEvent("onunload", _e)), D = !k(n), x.attributes = o(function(e) {
                    return e.className = "i", !e.getAttribute("className")
                }), x.getElementsByTagName = o(function(e) {
                    return e.appendChild(n.createComment("")), !e.getElementsByTagName("*").length
                }), x.getElementsByClassName = ve.test(n.getElementsByClassName), x.getById = o(function(e) {
                    return I.appendChild(e).id = F, !n.getElementsByName || !n.getElementsByName(F).length
                }), x.getById ? (T.find.ID = function(e, t) {
                    if ("undefined" != typeof t.getElementById && D) {
                        var i = t.getElementById(e);
                        return i && i.parentNode ? [i] : []
                    }
                }, T.filter.ID = function(e) {
                    var t = e.replace(xe, Te);
                    return function(e) {
                        return e.getAttribute("id") === t
                    }
                }) : (delete T.find.ID, T.filter.ID = function(e) {
                    var t = e.replace(xe, Te);
                    return function(e) {
                        var i = "undefined" != typeof e.getAttributeNode && e.getAttributeNode("id");
                        return i && i.value === t
                    }
                }), T.find.TAG = x.getElementsByTagName ? function(e, t) {
                    return "undefined" != typeof t.getElementsByTagName ? t.getElementsByTagName(e) : x.qsa ? t.querySelectorAll(e) : void 0
                } : function(e, t) {
                    var i, n = [],
                        o = 0,
                        r = t.getElementsByTagName(e);
                    if ("*" === e) {
                        for (; i = r[o++];) 1 === i.nodeType && n.push(i);
                        return n
                    }
                    return r
                }, T.find.CLASS = x.getElementsByClassName && function(e, t) {
                    return D ? t.getElementsByClassName(e) : void 0
                }, N = [], j = [], (x.qsa = ve.test(n.querySelectorAll)) && (o(function(e) {
                    I.appendChild(e).innerHTML = "<a id='" + F + "'></a><select id='" + F + "-\f]' msallowcapture=''><option selected=''></option></select>", e.querySelectorAll("[msallowcapture^='']").length && j.push("[*^$]=" + ie + "*(?:''|\"\")"), e.querySelectorAll("[selected]").length || j.push("\\[" + ie + "*(?:value|" + te + ")"), e.querySelectorAll("[id~=" + F + "-]").length || j.push("~="), e.querySelectorAll(":checked").length || j.push(":checked"), e.querySelectorAll("a#" + F + "+*").length || j.push(".#.+[+~]")
                }), o(function(e) {
                    var t = n.createElement("input");
                    t.setAttribute("type", "hidden"), e.appendChild(t).setAttribute("name", "D"), e.querySelectorAll("[name=d]").length && j.push("name" + ie + "*[*^$|!~]?="), e.querySelectorAll(":enabled").length || j.push(":enabled", ":disabled"), e.querySelectorAll("*,:x"), j.push(",.*:")
                })), (x.matchesSelector = ve.test(L = I.matches || I.webkitMatchesSelector || I.mozMatchesSelector || I.oMatchesSelector || I.msMatchesSelector)) && o(function(e) {
                    x.disconnectedMatch = L.call(e, "div"), L.call(e, "[s!='']:x"), N.push("!=", se)
                }), j = j.length && new RegExp(j.join("|")), N = N.length && new RegExp(N.join("|")), t = ve.test(I.compareDocumentPosition), z = t || ve.test(I.contains) ? function(e, t) {
                    var i = 9 === e.nodeType ? e.documentElement : e,
                        n = t && t.parentNode;
                    return e === n || !(!n || 1 !== n.nodeType || !(i.contains ? i.contains(n) : e.compareDocumentPosition && 16 & e.compareDocumentPosition(n)))
                } : function(e, t) {
                    if (t)
                        for (; t = t.parentNode;)
                            if (t === e) return !0;
                    return !1
                }, G = t ? function(e, t) {
                    if (e === t) return O = !0, 0;
                    var i = !e.compareDocumentPosition - !t.compareDocumentPosition;
                    return i ? i : (i = (e.ownerDocument || e) === (t.ownerDocument || t) ? e.compareDocumentPosition(t) : 1, 1 & i || !x.sortDetached && t.compareDocumentPosition(e) === i ? e === n || e.ownerDocument === R && z(R, e) ? -1 : t === n || t.ownerDocument === R && z(R, t) ? 1 : A ? ee(A, e) - ee(A, t) : 0 : 4 & i ? -1 : 1)
                } : function(e, t) {
                    if (e === t) return O = !0, 0;
                    var i, o = 0,
                        r = e.parentNode,
                        a = t.parentNode,
                        l = [e],
                        c = [t];
                    if (!r || !a) return e === n ? -1 : t === n ? 1 : r ? -1 : a ? 1 : A ? ee(A, e) - ee(A, t) : 0;
                    if (r === a) return s(e, t);
                    for (i = e; i = i.parentNode;) l.unshift(i);
                    for (i = t; i = i.parentNode;) c.unshift(i);
                    for (; l[o] === c[o];) o++;
                    return o ? s(l[o], c[o]) : l[o] === R ? -1 : c[o] === R ? 1 : 0
                }, n) : M
            }, t.matches = function(e, i) {
                return t(e, null, null, i)
            }, t.matchesSelector = function(e, i) {
                if ((e.ownerDocument || e) !== M && E(e), i = i.replace(ue, "='$1']"), !(!x.matchesSelector || !D || N && N.test(i) || j && j.test(i))) try {
                    var n = L.call(e, i);
                    if (n || x.disconnectedMatch || e.document && 11 !== e.document.nodeType) return n
                } catch (o) {}
                return t(i, M, null, [e]).length > 0
            }, t.contains = function(e, t) {
                return (e.ownerDocument || e) !== M && E(e), z(e, t)
            }, t.attr = function(e, t) {
                (e.ownerDocument || e) !== M && E(e);
                var i = T.attrHandle[t.toLowerCase()],
                    n = i && U.call(T.attrHandle, t.toLowerCase()) ? i(e, t, !D) : void 0;
                return void 0 !== n ? n : x.attributes || !D ? e.getAttribute(t) : (n = e.getAttributeNode(t)) && n.specified ? n.value : null
            }, t.error = function(e) {
                throw new Error("Syntax error, unrecognized expression: " + e)
            }, t.uniqueSort = function(e) {
                var t, i = [],
                    n = 0,
                    o = 0;
                if (O = !x.detectDuplicates, A = !x.sortStable && e.slice(0), e.sort(G), O) {
                    for (; t = e[o++];) t === e[o] && (n = i.push(o));
                    for (; n--;) e.splice(i[n], 1)
                }
                return A = null, e
            }, _ = t.getText = function(e) {
                var t, i = "",
                    n = 0,
                    o = e.nodeType;
                if (o) {
                    if (1 === o || 9 === o || 11 === o) {
                        if ("string" == typeof e.textContent) return e.textContent;
                        for (e = e.firstChild; e; e = e.nextSibling) i += _(e)
                    } else if (3 === o || 4 === o) return e.nodeValue
                } else
                    for (; t = e[n++];) i += _(t);
                return i
            }, T = t.selectors = {
                cacheLength: 50,
                createPseudo: n,
                match: fe,
                attrHandle: {},
                find: {},
                relative: {
                    ">": {
                        dir: "parentNode",
                        first: !0
                    },
                    " ": {
                        dir: "parentNode"
                    },
                    "+": {
                        dir: "previousSibling",
                        first: !0
                    },
                    "~": {
                        dir: "previousSibling"
                    }
                },
                preFilter: {
                    ATTR: function(e) {
                        return e[1] = e[1].replace(xe, Te), e[3] = (e[3] || e[4] || e[5] || "").replace(xe, Te), "~=" === e[2] && (e[3] = " " + e[3] + " "), e.slice(0, 4)
                    },
                    CHILD: function(e) {
                        return e[1] = e[1].toLowerCase(), "nth" === e[1].slice(0, 3) ? (e[3] || t.error(e[0]), e[4] = +(e[4] ? e[5] + (e[6] || 1) : 2 * ("even" === e[3] || "odd" === e[3])), e[5] = +(e[7] + e[8] || "odd" === e[3])) : e[3] && t.error(e[0]), e
                    },
                    PSEUDO: function(e) {
                        var t, i = !e[6] && e[2];
                        return fe.CHILD.test(e[0]) ? null : (e[3] ? e[2] = e[4] || e[5] || "" : i && pe.test(i) && (t = S(i, !0)) && (t = i.indexOf(")", i.length - t) - i.length) && (e[0] = e[0].slice(0, t), e[2] = i.slice(0, t)), e.slice(0, 3))
                    }
                },
                filter: {
                    TAG: function(e) {
                        var t = e.replace(xe, Te).toLowerCase();
                        return "*" === e ? function() {
                            return !0
                        } : function(e) {
                            return e.nodeName && e.nodeName.toLowerCase() === t
                        }
                    },
                    CLASS: function(e) {
                        var t = B[e + " "];
                        return t || (t = new RegExp("(^|" + ie + ")" + e + "(" + ie + "|$)")) && B(e, function(e) {
                            return t.test("string" == typeof e.className && e.className || "undefined" != typeof e.getAttribute && e.getAttribute("class") || "")
                        })
                    },
                    ATTR: function(e, i, n) {
                        return function(o) {
                            var r = t.attr(o, e);
                            return null == r ? "!=" === i : !i || (r += "", "=" === i ? r === n : "!=" === i ? r !== n : "^=" === i ? n && 0 === r.indexOf(n) : "*=" === i ? n && r.indexOf(n) > -1 : "$=" === i ? n && r.slice(-n.length) === n : "~=" === i ? (" " + r.replace(ae, " ") + " ").indexOf(n) > -1 : "|=" === i && (r === n || r.slice(0, n.length + 1) === n + "-"))
                        }
                    },
                    CHILD: function(e, t, i, n, o) {
                        var r = "nth" !== e.slice(0, 3),
                            s = "last" !== e.slice(-4),
                            a = "of-type" === t;
                        return 1 === n && 0 === o ? function(e) {
                            return !!e.parentNode
                        } : function(t, i, l) {
                            var c, d, u, p, h, f, g = r !== s ? "nextSibling" : "previousSibling",
                                m = t.parentNode,
                                v = a && t.nodeName.toLowerCase(),
                                b = !l && !a;
                            if (m) {
                                if (r) {
                                    for (; g;) {
                                        for (u = t; u = u[g];)
                                            if (a ? u.nodeName.toLowerCase() === v : 1 === u.nodeType) return !1;
                                        f = g = "only" === e && !f && "nextSibling"
                                    }
                                    return !0
                                }
                                if (f = [s ? m.firstChild : m.lastChild], s && b) {
                                    for (d = m[F] || (m[F] = {}), c = d[e] || [], h = c[0] === H && c[1], p = c[0] === H && c[2], u = h && m.childNodes[h]; u = ++h && u && u[g] || (p = h = 0) || f.pop();)
                                        if (1 === u.nodeType && ++p && u === t) {
                                            d[e] = [H, h, p];
                                            break
                                        }
                                } else if (b && (c = (t[F] || (t[F] = {}))[e]) && c[0] === H) p = c[1];
                                else
                                    for (;
                                        (u = ++h && u && u[g] || (p = h = 0) || f.pop()) && ((a ? u.nodeName.toLowerCase() !== v : 1 !== u.nodeType) || !++p || (b && ((u[F] || (u[F] = {}))[e] = [H, p]), u !== t)););
                                return p -= o, p === n || p % n === 0 && p / n >= 0
                            }
                        }
                    },
                    PSEUDO: function(e, i) {
                        var o, r = T.pseudos[e] || T.setFilters[e.toLowerCase()] || t.error("unsupported pseudo: " + e);
                        return r[F] ? r(i) : r.length > 1 ? (o = [e, e, "", i], T.setFilters.hasOwnProperty(e.toLowerCase()) ? n(function(e, t) {
                            for (var n, o = r(e, i), s = o.length; s--;) n = ee(e, o[s]), e[n] = !(t[n] = o[s])
                        }) : function(e) {
                            return r(e, 0, o)
                        }) : r
                    }
                },
                pseudos: {
                    not: n(function(e) {
                        var t = [],
                            i = [],
                            o = C(e.replace(le, "$1"));
                        return o[F] ? n(function(e, t, i, n) {
                            for (var r, s = o(e, null, n, []), a = e.length; a--;)(r = s[a]) && (e[a] = !(t[a] = r))
                        }) : function(e, n, r) {
                            return t[0] = e, o(t, null, r, i), t[0] = null, !i.pop()
                        }
                    }),
                    has: n(function(e) {
                        return function(i) {
                            return t(e, i).length > 0
                        }
                    }),
                    contains: n(function(e) {
                        return e = e.replace(xe, Te),
                            function(t) {
                                return (t.textContent || t.innerText || _(t)).indexOf(e) > -1
                            }
                    }),
                    lang: n(function(e) {
                        return he.test(e || "") || t.error("unsupported lang: " + e), e = e.replace(xe, Te).toLowerCase(),
                            function(t) {
                                var i;
                                do
                                    if (i = D ? t.lang : t.getAttribute("xml:lang") || t.getAttribute("lang")) return i = i.toLowerCase(), i === e || 0 === i.indexOf(e + "-");
                                while ((t = t.parentNode) && 1 === t.nodeType);
                                return !1
                            }
                    }),
                    target: function(t) {
                        var i = e.location && e.location.hash;
                        return i && i.slice(1) === t.id
                    },
                    root: function(e) {
                        return e === I
                    },
                    focus: function(e) {
                        return e === M.activeElement && (!M.hasFocus || M.hasFocus()) && !!(e.type || e.href || ~e.tabIndex)
                    },
                    enabled: function(e) {
                        return e.disabled === !1
                    },
                    disabled: function(e) {
                        return e.disabled === !0
                    },
                    checked: function(e) {
                        var t = e.nodeName.toLowerCase();
                        return "input" === t && !!e.checked || "option" === t && !!e.selected
                    },
                    selected: function(e) {
                        return e.parentNode && e.parentNode.selectedIndex, e.selected === !0
                    },
                    empty: function(e) {
                        for (e = e.firstChild; e; e = e.nextSibling)
                            if (e.nodeType < 6) return !1;
                        return !0
                    },
                    parent: function(e) {
                        return !T.pseudos.empty(e)
                    },
                    header: function(e) {
                        return me.test(e.nodeName)
                    },
                    input: function(e) {
                        return ge.test(e.nodeName)
                    },
                    button: function(e) {
                        var t = e.nodeName.toLowerCase();
                        return "input" === t && "button" === e.type || "button" === t
                    },
                    text: function(e) {
                        var t;
                        return "input" === e.nodeName.toLowerCase() && "text" === e.type && (null == (t = e.getAttribute("type")) || "text" === t.toLowerCase())
                    },
                    first: c(function() {
                        return [0]
                    }),
                    last: c(function(e, t) {
                        return [t - 1]
                    }),
                    eq: c(function(e, t, i) {
                        return [0 > i ? i + t : i]
                    }),
                    even: c(function(e, t) {
                        for (var i = 0; t > i; i += 2) e.push(i);
                        return e
                    }),
                    odd: c(function(e, t) {
                        for (var i = 1; t > i; i += 2) e.push(i);
                        return e
                    }),
                    lt: c(function(e, t, i) {
                        for (var n = 0 > i ? i + t : i; --n >= 0;) e.push(n);
                        return e
                    }),
                    gt: c(function(e, t, i) {
                        for (var n = 0 > i ? i + t : i; ++n < t;) e.push(n);
                        return e
                    })
                }
            }, T.pseudos.nth = T.pseudos.eq;
            for (w in {
                    radio: !0,
                    checkbox: !0,
                    file: !0,
                    password: !0,
                    image: !0
                }) T.pseudos[w] = a(w);
            for (w in {
                    submit: !0,
                    reset: !0
                }) T.pseudos[w] = l(w);
            return u.prototype = T.filters = T.pseudos, T.setFilters = new u, S = t.tokenize = function(e, i) {
                var n, o, r, s, a, l, c, d = q[e + " "];
                if (d) return i ? 0 : d.slice(0);
                for (a = e, l = [], c = T.preFilter; a;) {
                    (!n || (o = ce.exec(a))) && (o && (a = a.slice(o[0].length) || a), l.push(r = [])), n = !1, (o = de.exec(a)) && (n = o.shift(), r.push({
                        value: n,
                        type: o[0].replace(le, " ")
                    }), a = a.slice(n.length));
                    for (s in T.filter) !(o = fe[s].exec(a)) || c[s] && !(o = c[s](o)) || (n = o.shift(),
                        r.push({
                            value: n,
                            type: s,
                            matches: o
                        }), a = a.slice(n.length));
                    if (!n) break
                }
                return i ? a.length : a ? t.error(e) : q(e, l).slice(0)
            }, C = t.compile = function(e, t) {
                var i, n = [],
                    o = [],
                    r = X[e + " "];
                if (!r) {
                    for (t || (t = S(e)), i = t.length; i--;) r = b(t[i]), r[F] ? n.push(r) : o.push(r);
                    r = X(e, y(o, n)), r.selector = e
                }
                return r
            }, $ = t.select = function(e, t, i, n) {
                var o, r, s, a, l, c = "function" == typeof e && e,
                    u = !n && S(e = c.selector || e);
                if (i = i || [], 1 === u.length) {
                    if (r = u[0] = u[0].slice(0), r.length > 2 && "ID" === (s = r[0]).type && x.getById && 9 === t.nodeType && D && T.relative[r[1].type]) {
                        if (t = (T.find.ID(s.matches[0].replace(xe, Te), t) || [])[0], !t) return i;
                        c && (t = t.parentNode), e = e.slice(r.shift().value.length)
                    }
                    for (o = fe.needsContext.test(e) ? 0 : r.length; o-- && (s = r[o], !T.relative[a = s.type]);)
                        if ((l = T.find[a]) && (n = l(s.matches[0].replace(xe, Te), ye.test(r[0].type) && d(t.parentNode) || t))) {
                            if (r.splice(o, 1), e = n.length && p(r), !e) return J.apply(i, n), i;
                            break
                        }
                }
                return (c || C(e, u))(n, t, !D, i, ye.test(e) && d(t.parentNode) || t), i
            }, x.sortStable = F.split("").sort(G).join("") === F, x.detectDuplicates = !!O, E(), x.sortDetached = o(function(e) {
                return 1 & e.compareDocumentPosition(M.createElement("div"))
            }), o(function(e) {
                return e.innerHTML = "<a href='#'></a>", "#" === e.firstChild.getAttribute("href")
            }) || r("type|href|height|width", function(e, t, i) {
                return i ? void 0 : e.getAttribute(t, "type" === t.toLowerCase() ? 1 : 2)
            }), x.attributes && o(function(e) {
                return e.innerHTML = "<input/>", e.firstChild.setAttribute("value", ""), "" === e.firstChild.getAttribute("value")
            }) || r("value", function(e, t, i) {
                return i || "input" !== e.nodeName.toLowerCase() ? void 0 : e.defaultValue
            }), o(function(e) {
                return null == e.getAttribute("disabled")
            }) || r(te, function(e, t, i) {
                var n;
                return i ? void 0 : e[t] === !0 ? t.toLowerCase() : (n = e.getAttributeNode(t)) && n.specified ? n.value : null
            }), t
        }(e);
        oe.find = ce, oe.expr = ce.selectors, oe.expr[":"] = oe.expr.pseudos, oe.unique = ce.uniqueSort, oe.text = ce.getText, oe.isXMLDoc = ce.isXML, oe.contains = ce.contains;
        var de = oe.expr.match.needsContext,
            ue = /^<(\w+)\s*\/?>(?:<\/\1>|)$/,
            pe = /^.[^:#\[\.,]*$/;
        oe.filter = function(e, t, i) {
            var n = t[0];
            return i && (e = ":not(" + e + ")"), 1 === t.length && 1 === n.nodeType ? oe.find.matchesSelector(n, e) ? [n] : [] : oe.find.matches(e, oe.grep(t, function(e) {
                return 1 === e.nodeType
            }))
        }, oe.fn.extend({
            find: function(e) {
                var t, i = [],
                    n = this,
                    o = n.length;
                if ("string" != typeof e) return this.pushStack(oe(e).filter(function() {
                    for (t = 0; o > t; t++)
                        if (oe.contains(n[t], this)) return !0
                }));
                for (t = 0; o > t; t++) oe.find(e, n[t], i);
                return i = this.pushStack(o > 1 ? oe.unique(i) : i), i.selector = this.selector ? this.selector + " " + e : e, i
            },
            filter: function(e) {
                return this.pushStack(n(this, e || [], !1))
            },
            not: function(e) {
                return this.pushStack(n(this, e || [], !0))
            },
            is: function(e) {
                return !!n(this, "string" == typeof e && de.test(e) ? oe(e) : e || [], !1).length
            }
        });
        var he, fe = e.document,
            ge = /^(?:\s*(<[\w\W]+>)[^>]*|#([\w-]*))$/,
            me = oe.fn.init = function(e, t) {
                var i, n;
                if (!e) return this;
                if ("string" == typeof e) {
                    if (i = "<" === e.charAt(0) && ">" === e.charAt(e.length - 1) && e.length >= 3 ? [null, e, null] : ge.exec(e), !i || !i[1] && t) return !t || t.jquery ? (t || he).find(e) : this.constructor(t).find(e);
                    if (i[1]) {
                        if (t = t instanceof oe ? t[0] : t, oe.merge(this, oe.parseHTML(i[1], t && t.nodeType ? t.ownerDocument || t : fe, !0)), ue.test(i[1]) && oe.isPlainObject(t))
                            for (i in t) oe.isFunction(this[i]) ? this[i](t[i]) : this.attr(i, t[i]);
                        return this
                    }
                    if (n = fe.getElementById(i[2]), n && n.parentNode) {
                        if (n.id !== i[2]) return he.find(e);
                        this.length = 1, this[0] = n
                    }
                    return this.context = fe, this.selector = e, this
                }
                return e.nodeType ? (this.context = this[0] = e, this.length = 1, this) : oe.isFunction(e) ? "undefined" != typeof he.ready ? he.ready(e) : e(oe) : (void 0 !== e.selector && (this.selector = e.selector, this.context = e.context), oe.makeArray(e, this))
            };
        me.prototype = oe.fn, he = oe(fe);
        var ve = /^(?:parents|prev(?:Until|All))/,
            be = {
                children: !0,
                contents: !0,
                next: !0,
                prev: !0
            };
        oe.extend({
            dir: function(e, t, i) {
                for (var n = [], o = e[t]; o && 9 !== o.nodeType && (void 0 === i || 1 !== o.nodeType || !oe(o).is(i));) 1 === o.nodeType && n.push(o), o = o[t];
                return n
            },
            sibling: function(e, t) {
                for (var i = []; e; e = e.nextSibling) 1 === e.nodeType && e !== t && i.push(e);
                return i
            }
        }), oe.fn.extend({
            has: function(e) {
                var t, i = oe(e, this),
                    n = i.length;
                return this.filter(function() {
                    for (t = 0; n > t; t++)
                        if (oe.contains(this, i[t])) return !0
                })
            },
            closest: function(e, t) {
                for (var i, n = 0, o = this.length, r = [], s = de.test(e) || "string" != typeof e ? oe(e, t || this.context) : 0; o > n; n++)
                    for (i = this[n]; i && i !== t; i = i.parentNode)
                        if (i.nodeType < 11 && (s ? s.index(i) > -1 : 1 === i.nodeType && oe.find.matchesSelector(i, e))) {
                            r.push(i);
                            break
                        }
                return this.pushStack(r.length > 1 ? oe.unique(r) : r)
            },
            index: function(e) {
                return e ? "string" == typeof e ? oe.inArray(this[0], oe(e)) : oe.inArray(e.jquery ? e[0] : e, this) : this[0] && this[0].parentNode ? this.first().prevAll().length : -1
            },
            add: function(e, t) {
                return this.pushStack(oe.unique(oe.merge(this.get(), oe(e, t))))
            },
            addBack: function(e) {
                return this.add(null == e ? this.prevObject : this.prevObject.filter(e))
            }
        }), oe.each({
            parent: function(e) {
                var t = e.parentNode;
                return t && 11 !== t.nodeType ? t : null
            },
            parents: function(e) {
                return oe.dir(e, "parentNode")
            },
            parentsUntil: function(e, t, i) {
                return oe.dir(e, "parentNode", i)
            },
            next: function(e) {
                return o(e, "nextSibling")
            },
            prev: function(e) {
                return o(e, "previousSibling")
            },
            nextAll: function(e) {
                return oe.dir(e, "nextSibling")
            },
            prevAll: function(e) {
                return oe.dir(e, "previousSibling")
            },
            nextUntil: function(e, t, i) {
                return oe.dir(e, "nextSibling", i)
            },
            prevUntil: function(e, t, i) {
                return oe.dir(e, "previousSibling", i)
            },
            siblings: function(e) {
                return oe.sibling((e.parentNode || {}).firstChild, e)
            },
            children: function(e) {
                return oe.sibling(e.firstChild)
            },
            contents: function(e) {
                return oe.nodeName(e, "iframe") ? e.contentDocument || e.contentWindow.document : oe.merge([], e.childNodes)
            }
        }, function(e, t) {
            oe.fn[e] = function(i, n) {
                var o = oe.map(this, t, i);
                return "Until" !== e.slice(-5) && (n = i), n && "string" == typeof n && (o = oe.filter(n, o)), this.length > 1 && (be[e] || (o = oe.unique(o)), ve.test(e) && (o = o.reverse())), this.pushStack(o)
            }
        });
        var ye = /\S+/g,
            we = {};
        oe.Callbacks = function(e) {
            e = "string" == typeof e ? we[e] || r(e) : oe.extend({}, e);
            var t, i, n, o, s, a, l = [],
                c = !e.once && [],
                d = function(r) {
                    for (i = e.memory && r, n = !0, s = a || 0, a = 0, o = l.length, t = !0; l && o > s; s++)
                        if (l[s].apply(r[0], r[1]) === !1 && e.stopOnFalse) {
                            i = !1;
                            break
                        }
                    t = !1, l && (c ? c.length && d(c.shift()) : i ? l = [] : u.disable())
                },
                u = {
                    add: function() {
                        if (l) {
                            var n = l.length;
                            ! function r(t) {
                                oe.each(t, function(t, i) {
                                    var n = oe.type(i);
                                    "function" === n ? e.unique && u.has(i) || l.push(i) : i && i.length && "string" !== n && r(i)
                                })
                            }(arguments), t ? o = l.length : i && (a = n, d(i))
                        }
                        return this
                    },
                    remove: function() {
                        return l && oe.each(arguments, function(e, i) {
                            for (var n;
                                (n = oe.inArray(i, l, n)) > -1;) l.splice(n, 1), t && (o >= n && o--, s >= n && s--)
                        }), this
                    },
                    has: function(e) {
                        return e ? oe.inArray(e, l) > -1 : !(!l || !l.length)
                    },
                    empty: function() {
                        return l = [], o = 0, this
                    },
                    disable: function() {
                        return l = c = i = void 0, this
                    },
                    disabled: function() {
                        return !l
                    },
                    lock: function() {
                        return c = void 0, i || u.disable(), this
                    },
                    locked: function() {
                        return !c
                    },
                    fireWith: function(e, i) {
                        return !l || n && !c || (i = i || [], i = [e, i.slice ? i.slice() : i], t ? c.push(i) : d(i)), this
                    },
                    fire: function() {
                        return u.fireWith(this, arguments), this
                    },
                    fired: function() {
                        return !!n
                    }
                };
            return u
        }, oe.extend({
            Deferred: function(e) {
                var t = [
                        ["resolve", "done", oe.Callbacks("once memory"), "resolved"],
                        ["reject", "fail", oe.Callbacks("once memory"), "rejected"],
                        ["notify", "progress", oe.Callbacks("memory")]
                    ],
                    i = "pending",
                    n = {
                        state: function() {
                            return i
                        },
                        always: function() {
                            return o.done(arguments).fail(arguments), this
                        },
                        then: function() {
                            var e = arguments;
                            return oe.Deferred(function(i) {
                                oe.each(t, function(t, r) {
                                    var s = oe.isFunction(e[t]) && e[t];
                                    o[r[1]](function() {
                                        var e = s && s.apply(this, arguments);
                                        e && oe.isFunction(e.promise) ? e.promise().done(i.resolve).fail(i.reject).progress(i.notify) : i[r[0] + "With"](this === n ? i.promise() : this, s ? [e] : arguments)
                                    })
                                }), e = null
                            }).promise()
                        },
                        promise: function(e) {
                            return null != e ? oe.extend(e, n) : n
                        }
                    },
                    o = {};
                return n.pipe = n.then, oe.each(t, function(e, r) {
                    var s = r[2],
                        a = r[3];
                    n[r[1]] = s.add, a && s.add(function() {
                        i = a
                    }, t[1 ^ e][2].disable, t[2][2].lock), o[r[0]] = function() {
                        return o[r[0] + "With"](this === o ? n : this, arguments), this
                    }, o[r[0] + "With"] = s.fireWith
                }), n.promise(o), e && e.call(o, o), o
            },
            when: function(e) {
                var t, i, n, o = 0,
                    r = Y.call(arguments),
                    s = r.length,
                    a = 1 !== s || e && oe.isFunction(e.promise) ? s : 0,
                    l = 1 === a ? e : oe.Deferred(),
                    c = function(e, i, n) {
                        return function(o) {
                            i[e] = this, n[e] = arguments.length > 1 ? Y.call(arguments) : o, n === t ? l.notifyWith(i, n) : --a || l.resolveWith(i, n)
                        }
                    };
                if (s > 1)
                    for (t = new Array(s), i = new Array(s), n = new Array(s); s > o; o++) r[o] && oe.isFunction(r[o].promise) ? r[o].promise().done(c(o, n, r)).fail(l.reject).progress(c(o, i, t)) : --a;
                return a || l.resolveWith(n, r), l.promise()
            }
        });
        var xe;
        oe.fn.ready = function(e) {
            return oe.ready.promise().done(e), this
        }, oe.extend({
            isReady: !1,
            readyWait: 1,
            holdReady: function(e) {
                e ? oe.readyWait++ : oe.ready(!0)
            },
            ready: function(e) {
                if (e === !0 ? !--oe.readyWait : !oe.isReady) {
                    if (!fe.body) return setTimeout(oe.ready);
                    oe.isReady = !0, e !== !0 && --oe.readyWait > 0 || (xe.resolveWith(fe, [oe]), oe.fn.triggerHandler && (oe(fe).triggerHandler("ready"), oe(fe).off("ready")))
                }
            }
        }), oe.ready.promise = function(t) {
            if (!xe)
                if (xe = oe.Deferred(), "complete" === fe.readyState) setTimeout(oe.ready);
                else if (fe.addEventListener) fe.addEventListener("DOMContentLoaded", a, !1), e.addEventListener("load", a, !1);
            else {
                fe.attachEvent("onreadystatechange", a), e.attachEvent("onload", a);
                var i = !1;
                try {
                    i = null == e.frameElement && fe.documentElement
                } catch (n) {}
                i && i.doScroll && ! function o() {
                    if (!oe.isReady) {
                        try {
                            i.doScroll("left")
                        } catch (e) {
                            return setTimeout(o, 50)
                        }
                        s(), oe.ready()
                    }
                }()
            }
            return xe.promise(t)
        };
        var Te, _e = "undefined";
        for (Te in oe(ie)) break;
        ie.ownLast = "0" !== Te, ie.inlineBlockNeedsLayout = !1, oe(function() {
                var e, t, i, n;
                i = fe.getElementsByTagName("body")[0], i && i.style && (t = fe.createElement("div"), n = fe.createElement("div"), n.style.cssText = "position:absolute;border:0;width:0;height:0;top:0;left:-9999px", i.appendChild(n).appendChild(t), typeof t.style.zoom !== _e && (t.style.cssText = "display:inline;margin:0;border:0;padding:1px;width:1px;zoom:1", ie.inlineBlockNeedsLayout = e = 3 === t.offsetWidth, e && (i.style.zoom = 1)), i.removeChild(n))
            }),
            function() {
                var e = fe.createElement("div");
                if (null == ie.deleteExpando) {
                    ie.deleteExpando = !0;
                    try {
                        delete e.test
                    } catch (t) {
                        ie.deleteExpando = !1
                    }
                }
                e = null
            }(), oe.acceptData = function(e) {
                var t = oe.noData[(e.nodeName + " ").toLowerCase()],
                    i = +e.nodeType || 1;
                return (1 === i || 9 === i) && (!t || t !== !0 && e.getAttribute("classid") === t)
            };
        var ke = /^(?:\{[\w\W]*\}|\[[\w\W]*\])$/,
            Se = /([A-Z])/g;
        oe.extend({
            cache: {},
            noData: {
                "applet ": !0,
                "embed ": !0,
                "object ": "clsid:D27CDB6E-AE6D-11cf-96B8-444553540000"
            },
            hasData: function(e) {
                return e = e.nodeType ? oe.cache[e[oe.expando]] : e[oe.expando], !!e && !c(e)
            },
            data: function(e, t, i) {
                return d(e, t, i)
            },
            removeData: function(e, t) {
                return u(e, t)
            },
            _data: function(e, t, i) {
                return d(e, t, i, !0)
            },
            _removeData: function(e, t) {
                return u(e, t, !0)
            }
        }), oe.fn.extend({
            data: function(e, t) {
                var i, n, o, r = this[0],
                    s = r && r.attributes;
                if (void 0 === e) {
                    if (this.length && (o = oe.data(r), 1 === r.nodeType && !oe._data(r, "parsedAttrs"))) {
                        for (i = s.length; i--;) s[i] && (n = s[i].name, 0 === n.indexOf("data-") && (n = oe.camelCase(n.slice(5)), l(r, n, o[n])));
                        oe._data(r, "parsedAttrs", !0)
                    }
                    return o
                }
                return "object" == typeof e ? this.each(function() {
                    oe.data(this, e)
                }) : arguments.length > 1 ? this.each(function() {
                    oe.data(this, e, t)
                }) : r ? l(r, e, oe.data(r, e)) : void 0
            },
            removeData: function(e) {
                return this.each(function() {
                    oe.removeData(this, e)
                })
            }
        }), oe.extend({
            queue: function(e, t, i) {
                var n;
                return e ? (t = (t || "fx") + "queue", n = oe._data(e, t), i && (!n || oe.isArray(i) ? n = oe._data(e, t, oe.makeArray(i)) : n.push(i)), n || []) : void 0
            },
            dequeue: function(e, t) {
                t = t || "fx";
                var i = oe.queue(e, t),
                    n = i.length,
                    o = i.shift(),
                    r = oe._queueHooks(e, t),
                    s = function() {
                        oe.dequeue(e, t)
                    };
                "inprogress" === o && (o = i.shift(), n--), o && ("fx" === t && i.unshift("inprogress"), delete r.stop, o.call(e, s, r)), !n && r && r.empty.fire()
            },
            _queueHooks: function(e, t) {
                var i = t + "queueHooks";
                return oe._data(e, i) || oe._data(e, i, {
                    empty: oe.Callbacks("once memory").add(function() {
                        oe._removeData(e, t + "queue"), oe._removeData(e, i)
                    })
                })
            }
        }), oe.fn.extend({
            queue: function(e, t) {
                var i = 2;
                return "string" != typeof e && (t = e, e = "fx", i--), arguments.length < i ? oe.queue(this[0], e) : void 0 === t ? this : this.each(function() {
                    var i = oe.queue(this, e, t);
                    oe._queueHooks(this, e), "fx" === e && "inprogress" !== i[0] && oe.dequeue(this, e)
                })
            },
            dequeue: function(e) {
                return this.each(function() {
                    oe.dequeue(this, e)
                })
            },
            clearQueue: function(e) {
                return this.queue(e || "fx", [])
            },
            promise: function(e, t) {
                var i, n = 1,
                    o = oe.Deferred(),
                    r = this,
                    s = this.length,
                    a = function() {
                        --n || o.resolveWith(r, [r])
                    };
                for ("string" != typeof e && (t = e, e = void 0), e = e || "fx"; s--;) i = oe._data(r[s], e + "queueHooks"), i && i.empty && (n++, i.empty.add(a));
                return a(), o.promise(t)
            }
        });
        var Ce = /[+-]?(?:\d*\.|)\d+(?:[eE][+-]?\d+|)/.source,
            $e = ["Top", "Right", "Bottom", "Left"],
            Pe = function(e, t) {
                return e = t || e, "none" === oe.css(e, "display") || !oe.contains(e.ownerDocument, e)
            },
            Ae = oe.access = function(e, t, i, n, o, r, s) {
                var a = 0,
                    l = e.length,
                    c = null == i;
                if ("object" === oe.type(i)) {
                    o = !0;
                    for (a in i) oe.access(e, t, a, i[a], !0, r, s)
                } else if (void 0 !== n && (o = !0, oe.isFunction(n) || (s = !0), c && (s ? (t.call(e, n), t = null) : (c = t, t = function(e, t, i) {
                        return c.call(oe(e), i)
                    })), t))
                    for (; l > a; a++) t(e[a], i, s ? n : n.call(e[a], a, t(e[a], i)));
                return o ? e : c ? t.call(e) : l ? t(e[0], i) : r
            },
            Oe = /^(?:checkbox|radio)$/i;
        ! function() {
            var e = fe.createElement("input"),
                t = fe.createElement("div"),
                i = fe.createDocumentFragment();
            if (t.innerHTML = "  <link/><table></table><a href='/a'>a</a><input type='checkbox'/>", ie.leadingWhitespace = 3 === t.firstChild.nodeType, ie.tbody = !t.getElementsByTagName("tbody").length, ie.htmlSerialize = !!t.getElementsByTagName("link").length, ie.html5Clone = "<:nav></:nav>" !== fe.createElement("nav").cloneNode(!0).outerHTML, e.type = "checkbox", e.checked = !0, i.appendChild(e), ie.appendChecked = e.checked, t.innerHTML = "<textarea>x</textarea>", ie.noCloneChecked = !!t.cloneNode(!0).lastChild.defaultValue, i.appendChild(t), t.innerHTML = "<input type='radio' checked='checked' name='t'/>", ie.checkClone = t.cloneNode(!0).cloneNode(!0).lastChild.checked, ie.noCloneEvent = !0, t.attachEvent && (t.attachEvent("onclick", function() {
                    ie.noCloneEvent = !1
                }), t.cloneNode(!0).click()), null == ie.deleteExpando) {
                ie.deleteExpando = !0;
                try {
                    delete t.test
                } catch (n) {
                    ie.deleteExpando = !1
                }
            }
        }(),
        function() {
            var t, i, n = fe.createElement("div");
            for (t in {
                    submit: !0,
                    change: !0,
                    focusin: !0
                }) i = "on" + t, (ie[t + "Bubbles"] = i in e) || (n.setAttribute(i, "t"), ie[t + "Bubbles"] = n.attributes[i].expando === !1);
            n = null
        }();
        var Ee = /^(?:input|select|textarea)$/i,
            Me = /^key/,
            Ie = /^(?:mouse|pointer|contextmenu)|click/,
            De = /^(?:focusinfocus|focusoutblur)$/,
            je = /^([^.]*)(?:\.(.+)|)$/;
        oe.event = {
            global: {},
            add: function(e, t, i, n, o) {
                var r, s, a, l, c, d, u, p, h, f, g, m = oe._data(e);
                if (m) {
                    for (i.handler && (l = i, i = l.handler, o = l.selector), i.guid || (i.guid = oe.guid++), (s = m.events) || (s = m.events = {}), (d = m.handle) || (d = m.handle = function(e) {
                            return typeof oe === _e || e && oe.event.triggered === e.type ? void 0 : oe.event.dispatch.apply(d.elem, arguments)
                        }, d.elem = e), t = (t || "").match(ye) || [""], a = t.length; a--;) r = je.exec(t[a]) || [], h = g = r[1], f = (r[2] || "").split(".").sort(), h && (c = oe.event.special[h] || {}, h = (o ? c.delegateType : c.bindType) || h, c = oe.event.special[h] || {}, u = oe.extend({
                        type: h,
                        origType: g,
                        data: n,
                        handler: i,
                        guid: i.guid,
                        selector: o,
                        needsContext: o && oe.expr.match.needsContext.test(o),
                        namespace: f.join(".")
                    }, l), (p = s[h]) || (p = s[h] = [], p.delegateCount = 0, c.setup && c.setup.call(e, n, f, d) !== !1 || (e.addEventListener ? e.addEventListener(h, d, !1) : e.attachEvent && e.attachEvent("on" + h, d))), c.add && (c.add.call(e, u), u.handler.guid || (u.handler.guid = i.guid)), o ? p.splice(p.delegateCount++, 0, u) : p.push(u), oe.event.global[h] = !0);
                    e = null
                }
            },
            remove: function(e, t, i, n, o) {
                var r, s, a, l, c, d, u, p, h, f, g, m = oe.hasData(e) && oe._data(e);
                if (m && (d = m.events)) {
                    for (t = (t || "").match(ye) || [""], c = t.length; c--;)
                        if (a = je.exec(t[c]) || [], h = g = a[1], f = (a[2] || "").split(".").sort(), h) {
                            for (u = oe.event.special[h] || {}, h = (n ? u.delegateType : u.bindType) || h, p = d[h] || [], a = a[2] && new RegExp("(^|\\.)" + f.join("\\.(?:.*\\.|)") + "(\\.|$)"), l = r = p.length; r--;) s = p[r], !o && g !== s.origType || i && i.guid !== s.guid || a && !a.test(s.namespace) || n && n !== s.selector && ("**" !== n || !s.selector) || (p.splice(r, 1), s.selector && p.delegateCount--, u.remove && u.remove.call(e, s));
                            l && !p.length && (u.teardown && u.teardown.call(e, f, m.handle) !== !1 || oe.removeEvent(e, h, m.handle), delete d[h])
                        } else
                            for (h in d) oe.event.remove(e, h + t[c], i, n, !0);
                    oe.isEmptyObject(d) && (delete m.handle, oe._removeData(e, "events"))
                }
            },
            trigger: function(t, i, n, o) {
                var r, s, a, l, c, d, u, p = [n || fe],
                    h = te.call(t, "type") ? t.type : t,
                    f = te.call(t, "namespace") ? t.namespace.split(".") : [];
                if (a = d = n = n || fe, 3 !== n.nodeType && 8 !== n.nodeType && !De.test(h + oe.event.triggered) && (h.indexOf(".") >= 0 && (f = h.split("."), h = f.shift(), f.sort()), s = h.indexOf(":") < 0 && "on" + h, t = t[oe.expando] ? t : new oe.Event(h, "object" == typeof t && t), t.isTrigger = o ? 2 : 3, t.namespace = f.join("."), t.namespace_re = t.namespace ? new RegExp("(^|\\.)" + f.join("\\.(?:.*\\.|)") + "(\\.|$)") : null, t.result = void 0, t.target || (t.target = n), i = null == i ? [t] : oe.makeArray(i, [t]), c = oe.event.special[h] || {}, o || !c.trigger || c.trigger.apply(n, i) !== !1)) {
                    if (!o && !c.noBubble && !oe.isWindow(n)) {
                        for (l = c.delegateType || h, De.test(l + h) || (a = a.parentNode); a; a = a.parentNode) p.push(a), d = a;
                        d === (n.ownerDocument || fe) && p.push(d.defaultView || d.parentWindow || e)
                    }
                    for (u = 0;
                        (a = p[u++]) && !t.isPropagationStopped();) t.type = u > 1 ? l : c.bindType || h, r = (oe._data(a, "events") || {})[t.type] && oe._data(a, "handle"), r && r.apply(a, i), r = s && a[s], r && r.apply && oe.acceptData(a) && (t.result = r.apply(a, i), t.result === !1 && t.preventDefault());
                    if (t.type = h, !o && !t.isDefaultPrevented() && (!c._default || c._default.apply(p.pop(), i) === !1) && oe.acceptData(n) && s && n[h] && !oe.isWindow(n)) {
                        d = n[s], d && (n[s] = null), oe.event.triggered = h;
                        try {
                            n[h]()
                        } catch (g) {}
                        oe.event.triggered = void 0, d && (n[s] = d)
                    }
                    return t.result
                }
            },
            dispatch: function(e) {
                e = oe.event.fix(e);
                var t, i, n, o, r, s = [],
                    a = Y.call(arguments),
                    l = (oe._data(this, "events") || {})[e.type] || [],
                    c = oe.event.special[e.type] || {};
                if (a[0] = e, e.delegateTarget = this, !c.preDispatch || c.preDispatch.call(this, e) !== !1) {
                    for (s = oe.event.handlers.call(this, e, l), t = 0;
                        (o = s[t++]) && !e.isPropagationStopped();)
                        for (e.currentTarget = o.elem, r = 0;
                            (n = o.handlers[r++]) && !e.isImmediatePropagationStopped();)(!e.namespace_re || e.namespace_re.test(n.namespace)) && (e.handleObj = n, e.data = n.data, i = ((oe.event.special[n.origType] || {}).handle || n.handler).apply(o.elem, a), void 0 !== i && (e.result = i) === !1 && (e.preventDefault(), e.stopPropagation()));
                    return c.postDispatch && c.postDispatch.call(this, e), e.result
                }
            },
            handlers: function(e, t) {
                var i, n, o, r, s = [],
                    a = t.delegateCount,
                    l = e.target;
                if (a && l.nodeType && (!e.button || "click" !== e.type))
                    for (; l != this; l = l.parentNode || this)
                        if (1 === l.nodeType && (l.disabled !== !0 || "click" !== e.type)) {
                            for (o = [], r = 0; a > r; r++) n = t[r], i = n.selector + " ", void 0 === o[i] && (o[i] = n.needsContext ? oe(i, this).index(l) >= 0 : oe.find(i, this, null, [l]).length), o[i] && o.push(n);
                            o.length && s.push({
                                elem: l,
                                handlers: o
                            })
                        }
                return a < t.length && s.push({
                    elem: this,
                    handlers: t.slice(a)
                }), s
            },
            fix: function(e) {
                if (e[oe.expando]) return e;
                var t, i, n, o = e.type,
                    r = e,
                    s = this.fixHooks[o];
                for (s || (this.fixHooks[o] = s = Ie.test(o) ? this.mouseHooks : Me.test(o) ? this.keyHooks : {}), n = s.props ? this.props.concat(s.props) : this.props, e = new oe.Event(r), t = n.length; t--;) i = n[t], e[i] = r[i];
                return e.target || (e.target = r.srcElement || fe), 3 === e.target.nodeType && (e.target = e.target.parentNode), e.metaKey = !!e.metaKey, s.filter ? s.filter(e, r) : e
            },
            props: "altKey bubbles cancelable ctrlKey currentTarget eventPhase metaKey relatedTarget shiftKey target timeStamp view which".split(" "),
            fixHooks: {},
            keyHooks: {
                props: "char charCode key keyCode".split(" "),
                filter: function(e, t) {
                    return null == e.which && (e.which = null != t.charCode ? t.charCode : t.keyCode), e
                }
            },
            mouseHooks: {
                props: "button buttons clientX clientY fromElement offsetX offsetY pageX pageY screenX screenY toElement".split(" "),
                filter: function(e, t) {
                    var i, n, o, r = t.button,
                        s = t.fromElement;
                    return null == e.pageX && null != t.clientX && (n = e.target.ownerDocument || fe, o = n.documentElement, i = n.body, e.pageX = t.clientX + (o && o.scrollLeft || i && i.scrollLeft || 0) - (o && o.clientLeft || i && i.clientLeft || 0), e.pageY = t.clientY + (o && o.scrollTop || i && i.scrollTop || 0) - (o && o.clientTop || i && i.clientTop || 0)), !e.relatedTarget && s && (e.relatedTarget = s === e.target ? t.toElement : s), e.which || void 0 === r || (e.which = 1 & r ? 1 : 2 & r ? 3 : 4 & r ? 2 : 0), e
                }
            },
            special: {
                load: {
                    noBubble: !0
                },
                focus: {
                    trigger: function() {
                        if (this !== f() && this.focus) try {
                            return this.focus(), !1
                        } catch (e) {}
                    },
                    delegateType: "focusin"
                },
                blur: {
                    trigger: function() {
                        return this === f() && this.blur ? (this.blur(), !1) : void 0
                    },
                    delegateType: "focusout"
                },
                click: {
                    trigger: function() {
                        return oe.nodeName(this, "input") && "checkbox" === this.type && this.click ? (this.click(), !1) : void 0
                    },
                    _default: function(e) {
                        return oe.nodeName(e.target, "a")
                    }
                },
                beforeunload: {
                    postDispatch: function(e) {
                        void 0 !== e.result && e.originalEvent && (e.originalEvent.returnValue = e.result)
                    }
                }
            },
            simulate: function(e, t, i, n) {
                var o = oe.extend(new oe.Event, i, {
                    type: e,
                    isSimulated: !0,
                    originalEvent: {}
                });
                n ? oe.event.trigger(o, null, t) : oe.event.dispatch.call(t, o), o.isDefaultPrevented() && i.preventDefault()
            }
        }, oe.removeEvent = fe.removeEventListener ? function(e, t, i) {
            e.removeEventListener && e.removeEventListener(t, i, !1)
        } : function(e, t, i) {
            var n = "on" + t;
            e.detachEvent && (typeof e[n] === _e && (e[n] = null), e.detachEvent(n, i))
        }, oe.Event = function(e, t) {
            return this instanceof oe.Event ? (e && e.type ? (this.originalEvent = e, this.type = e.type, this.isDefaultPrevented = e.defaultPrevented || void 0 === e.defaultPrevented && e.returnValue === !1 ? p : h) : this.type = e, t && oe.extend(this, t), this.timeStamp = e && e.timeStamp || oe.now(), void(this[oe.expando] = !0)) : new oe.Event(e, t)
        }, oe.Event.prototype = {
            isDefaultPrevented: h,
            isPropagationStopped: h,
            isImmediatePropagationStopped: h,
            preventDefault: function() {
                var e = this.originalEvent;
                this.isDefaultPrevented = p, e && (e.preventDefault ? e.preventDefault() : e.returnValue = !1)
            },
            stopPropagation: function() {
                var e = this.originalEvent;
                this.isPropagationStopped = p, e && (e.stopPropagation && e.stopPropagation(), e.cancelBubble = !0)
            },
            stopImmediatePropagation: function() {
                var e = this.originalEvent;
                this.isImmediatePropagationStopped = p, e && e.stopImmediatePropagation && e.stopImmediatePropagation(), this.stopPropagation()
            }
        }, oe.each({
            mouseenter: "mouseover",
            mouseleave: "mouseout",
            pointerenter: "pointerover",
            pointerleave: "pointerout"
        }, function(e, t) {
            oe.event.special[e] = {
                delegateType: t,
                bindType: t,
                handle: function(e) {
                    var i, n = this,
                        o = e.relatedTarget,
                        r = e.handleObj;
                    return (!o || o !== n && !oe.contains(n, o)) && (e.type = r.origType, i = r.handler.apply(this, arguments), e.type = t), i
                }
            }
        }), ie.submitBubbles || (oe.event.special.submit = {
            setup: function() {
                return !oe.nodeName(this, "form") && void oe.event.add(this, "click._submit keypress._submit", function(e) {
                    var t = e.target,
                        i = oe.nodeName(t, "input") || oe.nodeName(t, "button") ? t.form : void 0;
                    i && !oe._data(i, "submitBubbles") && (oe.event.add(i, "submit._submit", function(e) {
                        e._submit_bubble = !0
                    }), oe._data(i, "submitBubbles", !0))
                })
            },
            postDispatch: function(e) {
                e._submit_bubble && (delete e._submit_bubble, this.parentNode && !e.isTrigger && oe.event.simulate("submit", this.parentNode, e, !0))
            },
            teardown: function() {
                return !oe.nodeName(this, "form") && void oe.event.remove(this, "._submit")
            }
        }), ie.changeBubbles || (oe.event.special.change = {
            setup: function() {
                return Ee.test(this.nodeName) ? (("checkbox" === this.type || "radio" === this.type) && (oe.event.add(this, "propertychange._change", function(e) {
                    "checked" === e.originalEvent.propertyName && (this._just_changed = !0)
                }), oe.event.add(this, "click._change", function(e) {
                    this._just_changed && !e.isTrigger && (this._just_changed = !1), oe.event.simulate("change", this, e, !0)
                })), !1) : void oe.event.add(this, "beforeactivate._change", function(e) {
                    var t = e.target;
                    Ee.test(t.nodeName) && !oe._data(t, "changeBubbles") && (oe.event.add(t, "change._change", function(e) {
                        !this.parentNode || e.isSimulated || e.isTrigger || oe.event.simulate("change", this.parentNode, e, !0)
                    }), oe._data(t, "changeBubbles", !0))
                })
            },
            handle: function(e) {
                var t = e.target;
                return this !== t || e.isSimulated || e.isTrigger || "radio" !== t.type && "checkbox" !== t.type ? e.handleObj.handler.apply(this, arguments) : void 0
            },
            teardown: function() {
                return oe.event.remove(this, "._change"), !Ee.test(this.nodeName)
            }
        }), ie.focusinBubbles || oe.each({
            focus: "focusin",
            blur: "focusout"
        }, function(e, t) {
            var i = function(e) {
                oe.event.simulate(t, e.target, oe.event.fix(e), !0)
            };
            oe.event.special[t] = {
                setup: function() {
                    var n = this.ownerDocument || this,
                        o = oe._data(n, t);
                    o || n.addEventListener(e, i, !0), oe._data(n, t, (o || 0) + 1)
                },
                teardown: function() {
                    var n = this.ownerDocument || this,
                        o = oe._data(n, t) - 1;
                    o ? oe._data(n, t, o) : (n.removeEventListener(e, i, !0), oe._removeData(n, t))
                }
            }
        }), oe.fn.extend({
            on: function(e, t, i, n, o) {
                var r, s;
                if ("object" == typeof e) {
                    "string" != typeof t && (i = i || t, t = void 0);
                    for (r in e) this.on(r, t, i, e[r], o);
                    return this
                }
                if (null == i && null == n ? (n = t, i = t = void 0) : null == n && ("string" == typeof t ? (n = i, i = void 0) : (n = i, i = t, t = void 0)), n === !1) n = h;
                else if (!n) return this;
                return 1 === o && (s = n, n = function(e) {
                    return oe().off(e), s.apply(this, arguments)
                }, n.guid = s.guid || (s.guid = oe.guid++)), this.each(function() {
                    oe.event.add(this, e, n, i, t)
                })
            },
            one: function(e, t, i, n) {
                return this.on(e, t, i, n, 1)
            },
            off: function(e, t, i) {
                var n, o;
                if (e && e.preventDefault && e.handleObj) return n = e.handleObj, oe(e.delegateTarget).off(n.namespace ? n.origType + "." + n.namespace : n.origType, n.selector, n.handler), this;
                if ("object" == typeof e) {
                    for (o in e) this.off(o, t, e[o]);
                    return this
                }
                return (t === !1 || "function" == typeof t) && (i = t, t = void 0), i === !1 && (i = h), this.each(function() {
                    oe.event.remove(this, e, i, t)
                })
            },
            trigger: function(e, t) {
                return this.each(function() {
                    oe.event.trigger(e, t, this)
                })
            },
            triggerHandler: function(e, t) {
                var i = this[0];
                return i ? oe.event.trigger(e, t, i, !0) : void 0
            }
        });
        var Ne = "abbr|article|aside|audio|bdi|canvas|data|datalist|details|figcaption|figure|footer|header|hgroup|mark|meter|nav|output|progress|section|summary|time|video",
            Le = / jQuery\d+="(?:null|\d+)"/g,
            ze = new RegExp("<(?:" + Ne + ")[\\s/>]", "i"),
            Fe = /^\s+/,
            Re = /<(?!area|br|col|embed|hr|img|input|link|meta|param)(([\w:]+)[^>]*)\/>/gi,
            He = /<([\w:]+)/,
            We = /<tbody/i,
            Be = /<|&#?\w+;/,
            qe = /<(?:script|style|link)/i,
            Xe = /checked\s*(?:[^=]|=\s*.checked.)/i,
            Ge = /^$|\/(?:java|ecma)script/i,
            Ve = /^true\/(.*)/,
            Ue = /^\s*<!(?:\[CDATA\[|--)|(?:\]\]|--)>\s*$/g,
            Ye = {
                option: [1, "<select multiple='multiple'>", "</select>"],
                legend: [1, "<fieldset>", "</fieldset>"],
                area: [1, "<map>", "</map>"],
                param: [1, "<object>", "</object>"],
                thead: [1, "<table>", "</table>"],
                tr: [2, "<table><tbody>", "</tbody></table>"],
                col: [2, "<table><tbody></tbody><colgroup>", "</colgroup></table>"],
                td: [3, "<table><tbody><tr>", "</tr></tbody></table>"],
                _default: ie.htmlSerialize ? [0, "", ""] : [1, "X<div>", "</div>"]
            },
            Qe = g(fe),
            Ze = Qe.appendChild(fe.createElement("div"));
        Ye.optgroup = Ye.option, Ye.tbody = Ye.tfoot = Ye.colgroup = Ye.caption = Ye.thead, Ye.th = Ye.td, oe.extend({
            clone: function(e, t, i) {
                var n, o, r, s, a, l = oe.contains(e.ownerDocument, e);
                if (ie.html5Clone || oe.isXMLDoc(e) || !ze.test("<" + e.nodeName + ">") ? r = e.cloneNode(!0) : (Ze.innerHTML = e.outerHTML, Ze.removeChild(r = Ze.firstChild)), !(ie.noCloneEvent && ie.noCloneChecked || 1 !== e.nodeType && 11 !== e.nodeType || oe.isXMLDoc(e)))
                    for (n = m(r), a = m(e), s = 0; null != (o = a[s]); ++s) n[s] && _(o, n[s]);
                if (t)
                    if (i)
                        for (a = a || m(e), n = n || m(r), s = 0; null != (o = a[s]); s++) T(o, n[s]);
                    else T(e, r);
                return n = m(r, "script"), n.length > 0 && x(n, !l && m(e, "script")), n = a = o = null, r
            },
            buildFragment: function(e, t, i, n) {
                for (var o, r, s, a, l, c, d, u = e.length, p = g(t), h = [], f = 0; u > f; f++)
                    if (r = e[f], r || 0 === r)
                        if ("object" === oe.type(r)) oe.merge(h, r.nodeType ? [r] : r);
                        else if (Be.test(r)) {
                    for (a = a || p.appendChild(t.createElement("div")), l = (He.exec(r) || ["", ""])[1].toLowerCase(), d = Ye[l] || Ye._default, a.innerHTML = d[1] + r.replace(Re, "<$1></$2>") + d[2], o = d[0]; o--;) a = a.lastChild;
                    if (!ie.leadingWhitespace && Fe.test(r) && h.push(t.createTextNode(Fe.exec(r)[0])), !ie.tbody)
                        for (r = "table" !== l || We.test(r) ? "<table>" !== d[1] || We.test(r) ? 0 : a : a.firstChild, o = r && r.childNodes.length; o--;) oe.nodeName(c = r.childNodes[o], "tbody") && !c.childNodes.length && r.removeChild(c);
                    for (oe.merge(h, a.childNodes), a.textContent = ""; a.firstChild;) a.removeChild(a.firstChild);
                    a = p.lastChild
                } else h.push(t.createTextNode(r));
                for (a && p.removeChild(a), ie.appendChecked || oe.grep(m(h, "input"), v), f = 0; r = h[f++];)
                    if ((!n || -1 === oe.inArray(r, n)) && (s = oe.contains(r.ownerDocument, r), a = m(p.appendChild(r), "script"), s && x(a), i))
                        for (o = 0; r = a[o++];) Ge.test(r.type || "") && i.push(r);
                return a = null, p
            },
            cleanData: function(e, t) {
                for (var i, n, o, r, s = 0, a = oe.expando, l = oe.cache, c = ie.deleteExpando, d = oe.event.special; null != (i = e[s]); s++)
                    if ((t || oe.acceptData(i)) && (o = i[a], r = o && l[o])) {
                        if (r.events)
                            for (n in r.events) d[n] ? oe.event.remove(i, n) : oe.removeEvent(i, n, r.handle);
                        l[o] && (delete l[o], c ? delete i[a] : typeof i.removeAttribute !== _e ? i.removeAttribute(a) : i[a] = null, U.push(o))
                    }
            }
        }), oe.fn.extend({
            text: function(e) {
                return Ae(this, function(e) {
                    return void 0 === e ? oe.text(this) : this.empty().append((this[0] && this[0].ownerDocument || fe).createTextNode(e))
                }, null, e, arguments.length)
            },
            append: function() {
                return this.domManip(arguments, function(e) {
                    if (1 === this.nodeType || 11 === this.nodeType || 9 === this.nodeType) {
                        var t = b(this, e);
                        t.appendChild(e)
                    }
                })
            },
            prepend: function() {
                return this.domManip(arguments, function(e) {
                    if (1 === this.nodeType || 11 === this.nodeType || 9 === this.nodeType) {
                        var t = b(this, e);
                        t.insertBefore(e, t.firstChild)
                    }
                })
            },
            before: function() {
                return this.domManip(arguments, function(e) {
                    this.parentNode && this.parentNode.insertBefore(e, this)
                })
            },
            after: function() {
                return this.domManip(arguments, function(e) {
                    this.parentNode && this.parentNode.insertBefore(e, this.nextSibling)
                })
            },
            remove: function(e, t) {
                for (var i, n = e ? oe.filter(e, this) : this, o = 0; null != (i = n[o]); o++) t || 1 !== i.nodeType || oe.cleanData(m(i)), i.parentNode && (t && oe.contains(i.ownerDocument, i) && x(m(i, "script")), i.parentNode.removeChild(i));
                return this
            },
            empty: function() {
                for (var e, t = 0; null != (e = this[t]); t++) {
                    for (1 === e.nodeType && oe.cleanData(m(e, !1)); e.firstChild;) e.removeChild(e.firstChild);
                    e.options && oe.nodeName(e, "select") && (e.options.length = 0)
                }
                return this
            },
            clone: function(e, t) {
                return e = null != e && e, t = null == t ? e : t, this.map(function() {
                    return oe.clone(this, e, t)
                })
            },
            html: function(e) {
                return Ae(this, function(e) {
                    var t = this[0] || {},
                        i = 0,
                        n = this.length;
                    if (void 0 === e) return 1 === t.nodeType ? t.innerHTML.replace(Le, "") : void 0;
                    if (!("string" != typeof e || qe.test(e) || !ie.htmlSerialize && ze.test(e) || !ie.leadingWhitespace && Fe.test(e) || Ye[(He.exec(e) || ["", ""])[1].toLowerCase()])) {
                        e = e.replace(Re, "<$1></$2>");
                        try {
                            for (; n > i; i++) t = this[i] || {}, 1 === t.nodeType && (oe.cleanData(m(t, !1)), t.innerHTML = e);
                            t = 0
                        } catch (o) {}
                    }
                    t && this.empty().append(e)
                }, null, e, arguments.length)
            },
            replaceWith: function() {
                var e = arguments[0];
                return this.domManip(arguments, function(t) {
                    e = this.parentNode, oe.cleanData(m(this)), e && e.replaceChild(t, this)
                }), e && (e.length || e.nodeType) ? this : this.remove()
            },
            detach: function(e) {
                return this.remove(e, !0)
            },
            domManip: function(e, t) {
                e = Q.apply([], e);
                var i, n, o, r, s, a, l = 0,
                    c = this.length,
                    d = this,
                    u = c - 1,
                    p = e[0],
                    h = oe.isFunction(p);
                if (h || c > 1 && "string" == typeof p && !ie.checkClone && Xe.test(p)) return this.each(function(i) {
                    var n = d.eq(i);
                    h && (e[0] = p.call(this, i, n.html())), n.domManip(e, t)
                });
                if (c && (a = oe.buildFragment(e, this[0].ownerDocument, !1, this), i = a.firstChild, 1 === a.childNodes.length && (a = i), i)) {
                    for (r = oe.map(m(a, "script"), y), o = r.length; c > l; l++) n = a, l !== u && (n = oe.clone(n, !0, !0), o && oe.merge(r, m(n, "script"))), t.call(this[l], n, l);
                    if (o)
                        for (s = r[r.length - 1].ownerDocument, oe.map(r, w), l = 0; o > l; l++) n = r[l], Ge.test(n.type || "") && !oe._data(n, "globalEval") && oe.contains(s, n) && (n.src ? oe._evalUrl && oe._evalUrl(n.src) : oe.globalEval((n.text || n.textContent || n.innerHTML || "").replace(Ue, "")));
                    a = i = null
                }
                return this
            }
        }), oe.each({
            appendTo: "append",
            prependTo: "prepend",
            insertBefore: "before",
            insertAfter: "after",
            replaceAll: "replaceWith"
        }, function(e, t) {
            oe.fn[e] = function(e) {
                for (var i, n = 0, o = [], r = oe(e), s = r.length - 1; s >= n; n++) i = n === s ? this : this.clone(!0), oe(r[n])[t](i), Z.apply(o, i.get());
                return this.pushStack(o)
            }
        });
        var Je, Ke = {};
        ! function() {
            var e;
            ie.shrinkWrapBlocks = function() {
                if (null != e) return e;
                e = !1;
                var t, i, n;
                return i = fe.getElementsByTagName("body")[0], i && i.style ? (t = fe.createElement("div"), n = fe.createElement("div"), n.style.cssText = "position:absolute;border:0;width:0;height:0;top:0;left:-9999px", i.appendChild(n).appendChild(t), typeof t.style.zoom !== _e && (t.style.cssText = "-webkit-box-sizing:content-box;-moz-box-sizing:content-box;box-sizing:content-box;display:block;margin:0;border:0;padding:1px;width:1px;zoom:1", t.appendChild(fe.createElement("div")).style.width = "5px", e = 3 !== t.offsetWidth), i.removeChild(n), e) : void 0
            }
        }();
        var et, tt, it = /^margin/,
            nt = new RegExp("^(" + Ce + ")(?!px)[a-z%]+$", "i"),
            ot = /^(top|right|bottom|left)$/;
        e.getComputedStyle ? (et = function(t) {
            return t.ownerDocument.defaultView.opener ? t.ownerDocument.defaultView.getComputedStyle(t, null) : e.getComputedStyle(t, null)
        }, tt = function(e, t, i) {
            var n, o, r, s, a = e.style;
            return i = i || et(e), s = i ? i.getPropertyValue(t) || i[t] : void 0, i && ("" !== s || oe.contains(e.ownerDocument, e) || (s = oe.style(e, t)), nt.test(s) && it.test(t) && (n = a.width, o = a.minWidth,
                r = a.maxWidth, a.minWidth = a.maxWidth = a.width = s, s = i.width, a.width = n, a.minWidth = o, a.maxWidth = r)), void 0 === s ? s : s + ""
        }) : fe.documentElement.currentStyle && (et = function(e) {
            return e.currentStyle
        }, tt = function(e, t, i) {
            var n, o, r, s, a = e.style;
            return i = i || et(e), s = i ? i[t] : void 0, null == s && a && a[t] && (s = a[t]), nt.test(s) && !ot.test(t) && (n = a.left, o = e.runtimeStyle, r = o && o.left, r && (o.left = e.currentStyle.left), a.left = "fontSize" === t ? "1em" : s, s = a.pixelLeft + "px", a.left = n, r && (o.left = r)), void 0 === s ? s : s + "" || "auto"
        }), ! function() {
            function t() {
                var t, i, n, o;
                i = fe.getElementsByTagName("body")[0], i && i.style && (t = fe.createElement("div"), n = fe.createElement("div"), n.style.cssText = "position:absolute;border:0;width:0;height:0;top:0;left:-9999px", i.appendChild(n).appendChild(t), t.style.cssText = "-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;display:block;margin-top:1%;top:1%;border:1px;padding:1px;width:4px;position:absolute", r = s = !1, l = !0, e.getComputedStyle && (r = "1%" !== (e.getComputedStyle(t, null) || {}).top, s = "4px" === (e.getComputedStyle(t, null) || {
                    width: "4px"
                }).width, o = t.appendChild(fe.createElement("div")), o.style.cssText = t.style.cssText = "-webkit-box-sizing:content-box;-moz-box-sizing:content-box;box-sizing:content-box;display:block;margin:0;border:0;padding:0", o.style.marginRight = o.style.width = "0", t.style.width = "1px", l = !parseFloat((e.getComputedStyle(o, null) || {}).marginRight), t.removeChild(o)), t.innerHTML = "<table><tr><td></td><td>t</td></tr></table>", o = t.getElementsByTagName("td"), o[0].style.cssText = "margin:0;border:0;padding:0;display:none", a = 0 === o[0].offsetHeight, a && (o[0].style.display = "", o[1].style.display = "none", a = 0 === o[0].offsetHeight), i.removeChild(n))
            }
            var i, n, o, r, s, a, l;
            i = fe.createElement("div"), i.innerHTML = "  <link/><table></table><a href='/a'>a</a><input type='checkbox'/>", o = i.getElementsByTagName("a")[0], (n = o && o.style) && (n.cssText = "float:left;opacity:.5", ie.opacity = "0.5" === n.opacity, ie.cssFloat = !!n.cssFloat, i.style.backgroundClip = "content-box", i.cloneNode(!0).style.backgroundClip = "", ie.clearCloneStyle = "content-box" === i.style.backgroundClip, ie.boxSizing = "" === n.boxSizing || "" === n.MozBoxSizing || "" === n.WebkitBoxSizing, oe.extend(ie, {
                reliableHiddenOffsets: function() {
                    return null == a && t(), a
                },
                boxSizingReliable: function() {
                    return null == s && t(), s
                },
                pixelPosition: function() {
                    return null == r && t(), r
                },
                reliableMarginRight: function() {
                    return null == l && t(), l
                }
            }))
        }(), oe.swap = function(e, t, i, n) {
            var o, r, s = {};
            for (r in t) s[r] = e.style[r], e.style[r] = t[r];
            o = i.apply(e, n || []);
            for (r in t) e.style[r] = s[r];
            return o
        };
        var rt = /alpha\([^)]*\)/i,
            st = /opacity\s*=\s*([^)]*)/,
            at = /^(none|table(?!-c[ea]).+)/,
            lt = new RegExp("^(" + Ce + ")(.*)$", "i"),
            ct = new RegExp("^([+-])=(" + Ce + ")", "i"),
            dt = {
                position: "absolute",
                visibility: "hidden",
                display: "block"
            },
            ut = {
                letterSpacing: "0",
                fontWeight: "400"
            },
            pt = ["Webkit", "O", "Moz", "ms"];
        oe.extend({
            cssHooks: {
                opacity: {
                    get: function(e, t) {
                        if (t) {
                            var i = tt(e, "opacity");
                            return "" === i ? "1" : i
                        }
                    }
                }
            },
            cssNumber: {
                columnCount: !0,
                fillOpacity: !0,
                flexGrow: !0,
                flexShrink: !0,
                fontWeight: !0,
                lineHeight: !0,
                opacity: !0,
                order: !0,
                orphans: !0,
                widows: !0,
                zIndex: !0,
                zoom: !0
            },
            cssProps: {
                "float": ie.cssFloat ? "cssFloat" : "styleFloat"
            },
            style: function(e, t, i, n) {
                if (e && 3 !== e.nodeType && 8 !== e.nodeType && e.style) {
                    var o, r, s, a = oe.camelCase(t),
                        l = e.style;
                    if (t = oe.cssProps[a] || (oe.cssProps[a] = $(l, a)), s = oe.cssHooks[t] || oe.cssHooks[a], void 0 === i) return s && "get" in s && void 0 !== (o = s.get(e, !1, n)) ? o : l[t];
                    if (r = typeof i, "string" === r && (o = ct.exec(i)) && (i = (o[1] + 1) * o[2] + parseFloat(oe.css(e, t)), r = "number"), null != i && i === i && ("number" !== r || oe.cssNumber[a] || (i += "px"), ie.clearCloneStyle || "" !== i || 0 !== t.indexOf("background") || (l[t] = "inherit"), !(s && "set" in s && void 0 === (i = s.set(e, i, n))))) try {
                        l[t] = i
                    } catch (c) {}
                }
            },
            css: function(e, t, i, n) {
                var o, r, s, a = oe.camelCase(t);
                return t = oe.cssProps[a] || (oe.cssProps[a] = $(e.style, a)), s = oe.cssHooks[t] || oe.cssHooks[a], s && "get" in s && (r = s.get(e, !0, i)), void 0 === r && (r = tt(e, t, n)), "normal" === r && t in ut && (r = ut[t]), "" === i || i ? (o = parseFloat(r), i === !0 || oe.isNumeric(o) ? o || 0 : r) : r
            }
        }), oe.each(["height", "width"], function(e, t) {
            oe.cssHooks[t] = {
                get: function(e, i, n) {
                    return i ? at.test(oe.css(e, "display")) && 0 === e.offsetWidth ? oe.swap(e, dt, function() {
                        return E(e, t, n)
                    }) : E(e, t, n) : void 0
                },
                set: function(e, i, n) {
                    var o = n && et(e);
                    return A(e, i, n ? O(e, t, n, ie.boxSizing && "border-box" === oe.css(e, "boxSizing", !1, o), o) : 0)
                }
            }
        }), ie.opacity || (oe.cssHooks.opacity = {
            get: function(e, t) {
                return st.test((t && e.currentStyle ? e.currentStyle.filter : e.style.filter) || "") ? .01 * parseFloat(RegExp.$1) + "" : t ? "1" : ""
            },
            set: function(e, t) {
                var i = e.style,
                    n = e.currentStyle,
                    o = oe.isNumeric(t) ? "alpha(opacity=" + 100 * t + ")" : "",
                    r = n && n.filter || i.filter || "";
                i.zoom = 1, (t >= 1 || "" === t) && "" === oe.trim(r.replace(rt, "")) && i.removeAttribute && (i.removeAttribute("filter"), "" === t || n && !n.filter) || (i.filter = rt.test(r) ? r.replace(rt, o) : r + " " + o)
            }
        }), oe.cssHooks.marginRight = C(ie.reliableMarginRight, function(e, t) {
            return t ? oe.swap(e, {
                display: "inline-block"
            }, tt, [e, "marginRight"]) : void 0
        }), oe.each({
            margin: "",
            padding: "",
            border: "Width"
        }, function(e, t) {
            oe.cssHooks[e + t] = {
                expand: function(i) {
                    for (var n = 0, o = {}, r = "string" == typeof i ? i.split(" ") : [i]; 4 > n; n++) o[e + $e[n] + t] = r[n] || r[n - 2] || r[0];
                    return o
                }
            }, it.test(e) || (oe.cssHooks[e + t].set = A)
        }), oe.fn.extend({
            css: function(e, t) {
                return Ae(this, function(e, t, i) {
                    var n, o, r = {},
                        s = 0;
                    if (oe.isArray(t)) {
                        for (n = et(e), o = t.length; o > s; s++) r[t[s]] = oe.css(e, t[s], !1, n);
                        return r
                    }
                    return void 0 !== i ? oe.style(e, t, i) : oe.css(e, t)
                }, e, t, arguments.length > 1)
            },
            show: function() {
                return P(this, !0)
            },
            hide: function() {
                return P(this)
            },
            toggle: function(e) {
                return "boolean" == typeof e ? e ? this.show() : this.hide() : this.each(function() {
                    Pe(this) ? oe(this).show() : oe(this).hide()
                })
            }
        }), oe.Tween = M, M.prototype = {
            constructor: M,
            init: function(e, t, i, n, o, r) {
                this.elem = e, this.prop = i, this.easing = o || "swing", this.options = t, this.start = this.now = this.cur(), this.end = n, this.unit = r || (oe.cssNumber[i] ? "" : "px")
            },
            cur: function() {
                var e = M.propHooks[this.prop];
                return e && e.get ? e.get(this) : M.propHooks._default.get(this)
            },
            run: function(e) {
                var t, i = M.propHooks[this.prop];
                return this.options.duration ? this.pos = t = oe.easing[this.easing](e, this.options.duration * e, 0, 1, this.options.duration) : this.pos = t = e, this.now = (this.end - this.start) * t + this.start, this.options.step && this.options.step.call(this.elem, this.now, this), i && i.set ? i.set(this) : M.propHooks._default.set(this), this
            }
        }, M.prototype.init.prototype = M.prototype, M.propHooks = {
            _default: {
                get: function(e) {
                    var t;
                    return null == e.elem[e.prop] || e.elem.style && null != e.elem.style[e.prop] ? (t = oe.css(e.elem, e.prop, ""), t && "auto" !== t ? t : 0) : e.elem[e.prop]
                },
                set: function(e) {
                    oe.fx.step[e.prop] ? oe.fx.step[e.prop](e) : e.elem.style && (null != e.elem.style[oe.cssProps[e.prop]] || oe.cssHooks[e.prop]) ? oe.style(e.elem, e.prop, e.now + e.unit) : e.elem[e.prop] = e.now
                }
            }
        }, M.propHooks.scrollTop = M.propHooks.scrollLeft = {
            set: function(e) {
                e.elem.nodeType && e.elem.parentNode && (e.elem[e.prop] = e.now)
            }
        }, oe.easing = {
            linear: function(e) {
                return e
            },
            swing: function(e) {
                return .5 - Math.cos(e * Math.PI) / 2
            }
        }, oe.fx = M.prototype.init, oe.fx.step = {};
        var ht, ft, gt = /^(?:toggle|show|hide)$/,
            mt = new RegExp("^(?:([+-])=|)(" + Ce + ")([a-z%]*)$", "i"),
            vt = /queueHooks$/,
            bt = [N],
            yt = {
                "*": [function(e, t) {
                    var i = this.createTween(e, t),
                        n = i.cur(),
                        o = mt.exec(t),
                        r = o && o[3] || (oe.cssNumber[e] ? "" : "px"),
                        s = (oe.cssNumber[e] || "px" !== r && +n) && mt.exec(oe.css(i.elem, e)),
                        a = 1,
                        l = 20;
                    if (s && s[3] !== r) {
                        r = r || s[3], o = o || [], s = +n || 1;
                        do a = a || ".5", s /= a, oe.style(i.elem, e, s + r); while (a !== (a = i.cur() / n) && 1 !== a && --l)
                    }
                    return o && (s = i.start = +s || +n || 0, i.unit = r, i.end = o[1] ? s + (o[1] + 1) * o[2] : +o[2]), i
                }]
            };
        oe.Animation = oe.extend(z, {
                tweener: function(e, t) {
                    oe.isFunction(e) ? (t = e, e = ["*"]) : e = e.split(" ");
                    for (var i, n = 0, o = e.length; o > n; n++) i = e[n], yt[i] = yt[i] || [], yt[i].unshift(t)
                },
                prefilter: function(e, t) {
                    t ? bt.unshift(e) : bt.push(e)
                }
            }), oe.speed = function(e, t, i) {
                var n = e && "object" == typeof e ? oe.extend({}, e) : {
                    complete: i || !i && t || oe.isFunction(e) && e,
                    duration: e,
                    easing: i && t || t && !oe.isFunction(t) && t
                };
                return n.duration = oe.fx.off ? 0 : "number" == typeof n.duration ? n.duration : n.duration in oe.fx.speeds ? oe.fx.speeds[n.duration] : oe.fx.speeds._default, (null == n.queue || n.queue === !0) && (n.queue = "fx"), n.old = n.complete, n.complete = function() {
                    oe.isFunction(n.old) && n.old.call(this), n.queue && oe.dequeue(this, n.queue)
                }, n
            }, oe.fn.extend({
                fadeTo: function(e, t, i, n) {
                    return this.filter(Pe).css("opacity", 0).show().end().animate({
                        opacity: t
                    }, e, i, n)
                },
                animate: function(e, t, i, n) {
                    var o = oe.isEmptyObject(e),
                        r = oe.speed(t, i, n),
                        s = function() {
                            var t = z(this, oe.extend({}, e), r);
                            (o || oe._data(this, "finish")) && t.stop(!0)
                        };
                    return s.finish = s, o || r.queue === !1 ? this.each(s) : this.queue(r.queue, s)
                },
                stop: function(e, t, i) {
                    var n = function(e) {
                        var t = e.stop;
                        delete e.stop, t(i)
                    };
                    return "string" != typeof e && (i = t, t = e, e = void 0), t && e !== !1 && this.queue(e || "fx", []), this.each(function() {
                        var t = !0,
                            o = null != e && e + "queueHooks",
                            r = oe.timers,
                            s = oe._data(this);
                        if (o) s[o] && s[o].stop && n(s[o]);
                        else
                            for (o in s) s[o] && s[o].stop && vt.test(o) && n(s[o]);
                        for (o = r.length; o--;) r[o].elem !== this || null != e && r[o].queue !== e || (r[o].anim.stop(i), t = !1, r.splice(o, 1));
                        (t || !i) && oe.dequeue(this, e)
                    })
                },
                finish: function(e) {
                    return e !== !1 && (e = e || "fx"), this.each(function() {
                        var t, i = oe._data(this),
                            n = i[e + "queue"],
                            o = i[e + "queueHooks"],
                            r = oe.timers,
                            s = n ? n.length : 0;
                        for (i.finish = !0, oe.queue(this, e, []), o && o.stop && o.stop.call(this, !0), t = r.length; t--;) r[t].elem === this && r[t].queue === e && (r[t].anim.stop(!0), r.splice(t, 1));
                        for (t = 0; s > t; t++) n[t] && n[t].finish && n[t].finish.call(this);
                        delete i.finish
                    })
                }
            }), oe.each(["toggle", "show", "hide"], function(e, t) {
                var i = oe.fn[t];
                oe.fn[t] = function(e, n, o) {
                    return null == e || "boolean" == typeof e ? i.apply(this, arguments) : this.animate(D(t, !0), e, n, o)
                }
            }), oe.each({
                slideDown: D("show"),
                slideUp: D("hide"),
                slideToggle: D("toggle"),
                fadeIn: {
                    opacity: "show"
                },
                fadeOut: {
                    opacity: "hide"
                },
                fadeToggle: {
                    opacity: "toggle"
                }
            }, function(e, t) {
                oe.fn[e] = function(e, i, n) {
                    return this.animate(t, e, i, n)
                }
            }), oe.timers = [], oe.fx.tick = function() {
                var e, t = oe.timers,
                    i = 0;
                for (ht = oe.now(); i < t.length; i++) e = t[i], e() || t[i] !== e || t.splice(i--, 1);
                t.length || oe.fx.stop(), ht = void 0
            }, oe.fx.timer = function(e) {
                oe.timers.push(e), e() ? oe.fx.start() : oe.timers.pop()
            }, oe.fx.interval = 13, oe.fx.start = function() {
                ft || (ft = setInterval(oe.fx.tick, oe.fx.interval))
            }, oe.fx.stop = function() {
                clearInterval(ft), ft = null
            }, oe.fx.speeds = {
                slow: 600,
                fast: 200,
                _default: 400
            }, oe.fn.delay = function(e, t) {
                return e = oe.fx ? oe.fx.speeds[e] || e : e, t = t || "fx", this.queue(t, function(t, i) {
                    var n = setTimeout(t, e);
                    i.stop = function() {
                        clearTimeout(n)
                    }
                })
            },
            function() {
                var e, t, i, n, o;
                t = fe.createElement("div"), t.setAttribute("className", "t"), t.innerHTML = "  <link/><table></table><a href='/a'>a</a><input type='checkbox'/>", n = t.getElementsByTagName("a")[0], i = fe.createElement("select"), o = i.appendChild(fe.createElement("option")), e = t.getElementsByTagName("input")[0], n.style.cssText = "top:1px", ie.getSetAttribute = "t" !== t.className, ie.style = /top/.test(n.getAttribute("style")), ie.hrefNormalized = "/a" === n.getAttribute("href"), ie.checkOn = !!e.value, ie.optSelected = o.selected, ie.enctype = !!fe.createElement("form").enctype, i.disabled = !0, ie.optDisabled = !o.disabled, e = fe.createElement("input"), e.setAttribute("value", ""), ie.input = "" === e.getAttribute("value"), e.value = "t", e.setAttribute("type", "radio"), ie.radioValue = "t" === e.value
            }();
        var wt = /\r/g;
        oe.fn.extend({
            val: function(e) {
                var t, i, n, o = this[0];
                return arguments.length ? (n = oe.isFunction(e), this.each(function(i) {
                    var o;
                    1 === this.nodeType && (o = n ? e.call(this, i, oe(this).val()) : e, null == o ? o = "" : "number" == typeof o ? o += "" : oe.isArray(o) && (o = oe.map(o, function(e) {
                        return null == e ? "" : e + ""
                    })), t = oe.valHooks[this.type] || oe.valHooks[this.nodeName.toLowerCase()], t && "set" in t && void 0 !== t.set(this, o, "value") || (this.value = o))
                })) : o ? (t = oe.valHooks[o.type] || oe.valHooks[o.nodeName.toLowerCase()], t && "get" in t && void 0 !== (i = t.get(o, "value")) ? i : (i = o.value, "string" == typeof i ? i.replace(wt, "") : null == i ? "" : i)) : void 0
            }
        }), oe.extend({
            valHooks: {
                option: {
                    get: function(e) {
                        var t = oe.find.attr(e, "value");
                        return null != t ? t : oe.trim(oe.text(e))
                    }
                },
                select: {
                    get: function(e) {
                        for (var t, i, n = e.options, o = e.selectedIndex, r = "select-one" === e.type || 0 > o, s = r ? null : [], a = r ? o + 1 : n.length, l = 0 > o ? a : r ? o : 0; a > l; l++)
                            if (i = n[l], !(!i.selected && l !== o || (ie.optDisabled ? i.disabled : null !== i.getAttribute("disabled")) || i.parentNode.disabled && oe.nodeName(i.parentNode, "optgroup"))) {
                                if (t = oe(i).val(), r) return t;
                                s.push(t)
                            }
                        return s
                    },
                    set: function(e, t) {
                        for (var i, n, o = e.options, r = oe.makeArray(t), s = o.length; s--;)
                            if (n = o[s], oe.inArray(oe.valHooks.option.get(n), r) >= 0) try {
                                n.selected = i = !0
                            } catch (a) {
                                n.scrollHeight
                            } else n.selected = !1;
                        return i || (e.selectedIndex = -1), o
                    }
                }
            }
        }), oe.each(["radio", "checkbox"], function() {
            oe.valHooks[this] = {
                set: function(e, t) {
                    return oe.isArray(t) ? e.checked = oe.inArray(oe(e).val(), t) >= 0 : void 0
                }
            }, ie.checkOn || (oe.valHooks[this].get = function(e) {
                return null === e.getAttribute("value") ? "on" : e.value
            })
        });
        var xt, Tt, _t = oe.expr.attrHandle,
            kt = /^(?:checked|selected)$/i,
            St = ie.getSetAttribute,
            Ct = ie.input;
        oe.fn.extend({
            attr: function(e, t) {
                return Ae(this, oe.attr, e, t, arguments.length > 1)
            },
            removeAttr: function(e) {
                return this.each(function() {
                    oe.removeAttr(this, e)
                })
            }
        }), oe.extend({
            attr: function(e, t, i) {
                var n, o, r = e.nodeType;
                if (e && 3 !== r && 8 !== r && 2 !== r) return typeof e.getAttribute === _e ? oe.prop(e, t, i) : (1 === r && oe.isXMLDoc(e) || (t = t.toLowerCase(), n = oe.attrHooks[t] || (oe.expr.match.bool.test(t) ? Tt : xt)), void 0 === i ? n && "get" in n && null !== (o = n.get(e, t)) ? o : (o = oe.find.attr(e, t), null == o ? void 0 : o) : null !== i ? n && "set" in n && void 0 !== (o = n.set(e, i, t)) ? o : (e.setAttribute(t, i + ""), i) : void oe.removeAttr(e, t))
            },
            removeAttr: function(e, t) {
                var i, n, o = 0,
                    r = t && t.match(ye);
                if (r && 1 === e.nodeType)
                    for (; i = r[o++];) n = oe.propFix[i] || i, oe.expr.match.bool.test(i) ? Ct && St || !kt.test(i) ? e[n] = !1 : e[oe.camelCase("default-" + i)] = e[n] = !1 : oe.attr(e, i, ""), e.removeAttribute(St ? i : n)
            },
            attrHooks: {
                type: {
                    set: function(e, t) {
                        if (!ie.radioValue && "radio" === t && oe.nodeName(e, "input")) {
                            var i = e.value;
                            return e.setAttribute("type", t), i && (e.value = i), t
                        }
                    }
                }
            }
        }), Tt = {
            set: function(e, t, i) {
                return t === !1 ? oe.removeAttr(e, i) : Ct && St || !kt.test(i) ? e.setAttribute(!St && oe.propFix[i] || i, i) : e[oe.camelCase("default-" + i)] = e[i] = !0, i
            }
        }, oe.each(oe.expr.match.bool.source.match(/\w+/g), function(e, t) {
            var i = _t[t] || oe.find.attr;
            _t[t] = Ct && St || !kt.test(t) ? function(e, t, n) {
                var o, r;
                return n || (r = _t[t], _t[t] = o, o = null != i(e, t, n) ? t.toLowerCase() : null, _t[t] = r), o
            } : function(e, t, i) {
                return i ? void 0 : e[oe.camelCase("default-" + t)] ? t.toLowerCase() : null
            }
        }), Ct && St || (oe.attrHooks.value = {
            set: function(e, t, i) {
                return oe.nodeName(e, "input") ? void(e.defaultValue = t) : xt && xt.set(e, t, i)
            }
        }), St || (xt = {
            set: function(e, t, i) {
                var n = e.getAttributeNode(i);
                return n || e.setAttributeNode(n = e.ownerDocument.createAttribute(i)), n.value = t += "", "value" === i || t === e.getAttribute(i) ? t : void 0
            }
        }, _t.id = _t.name = _t.coords = function(e, t, i) {
            var n;
            return i ? void 0 : (n = e.getAttributeNode(t)) && "" !== n.value ? n.value : null
        }, oe.valHooks.button = {
            get: function(e, t) {
                var i = e.getAttributeNode(t);
                return i && i.specified ? i.value : void 0
            },
            set: xt.set
        }, oe.attrHooks.contenteditable = {
            set: function(e, t, i) {
                xt.set(e, "" !== t && t, i)
            }
        }, oe.each(["width", "height"], function(e, t) {
            oe.attrHooks[t] = {
                set: function(e, i) {
                    return "" === i ? (e.setAttribute(t, "auto"), i) : void 0
                }
            }
        })), ie.style || (oe.attrHooks.style = {
            get: function(e) {
                return e.style.cssText || void 0
            },
            set: function(e, t) {
                return e.style.cssText = t + ""
            }
        });
        var $t = /^(?:input|select|textarea|button|object)$/i,
            Pt = /^(?:a|area)$/i;
        oe.fn.extend({
            prop: function(e, t) {
                return Ae(this, oe.prop, e, t, arguments.length > 1)
            },
            removeProp: function(e) {
                return e = oe.propFix[e] || e, this.each(function() {
                    try {
                        this[e] = void 0, delete this[e]
                    } catch (t) {}
                })
            }
        }), oe.extend({
            propFix: {
                "for": "htmlFor",
                "class": "className"
            },
            prop: function(e, t, i) {
                var n, o, r, s = e.nodeType;
                if (e && 3 !== s && 8 !== s && 2 !== s) return r = 1 !== s || !oe.isXMLDoc(e), r && (t = oe.propFix[t] || t, o = oe.propHooks[t]), void 0 !== i ? o && "set" in o && void 0 !== (n = o.set(e, i, t)) ? n : e[t] = i : o && "get" in o && null !== (n = o.get(e, t)) ? n : e[t]
            },
            propHooks: {
                tabIndex: {
                    get: function(e) {
                        var t = oe.find.attr(e, "tabindex");
                        return t ? parseInt(t, 10) : $t.test(e.nodeName) || Pt.test(e.nodeName) && e.href ? 0 : -1
                    }
                }
            }
        }), ie.hrefNormalized || oe.each(["href", "src"], function(e, t) {
            oe.propHooks[t] = {
                get: function(e) {
                    return e.getAttribute(t, 4)
                }
            }
        }), ie.optSelected || (oe.propHooks.selected = {
            get: function(e) {
                var t = e.parentNode;
                return t && (t.selectedIndex, t.parentNode && t.parentNode.selectedIndex), null
            }
        }), oe.each(["tabIndex", "readOnly", "maxLength", "cellSpacing", "cellPadding", "rowSpan", "colSpan", "useMap", "frameBorder", "contentEditable"], function() {
            oe.propFix[this.toLowerCase()] = this
        }), ie.enctype || (oe.propFix.enctype = "encoding");
        var At = /[\t\r\n\f]/g;
        oe.fn.extend({
            addClass: function(e) {
                var t, i, n, o, r, s, a = 0,
                    l = this.length,
                    c = "string" == typeof e && e;
                if (oe.isFunction(e)) return this.each(function(t) {
                    oe(this).addClass(e.call(this, t, this.className))
                });
                if (c)
                    for (t = (e || "").match(ye) || []; l > a; a++)
                        if (i = this[a], n = 1 === i.nodeType && (i.className ? (" " + i.className + " ").replace(At, " ") : " ")) {
                            for (r = 0; o = t[r++];) n.indexOf(" " + o + " ") < 0 && (n += o + " ");
                            s = oe.trim(n), i.className !== s && (i.className = s)
                        }
                return this
            },
            removeClass: function(e) {
                var t, i, n, o, r, s, a = 0,
                    l = this.length,
                    c = 0 === arguments.length || "string" == typeof e && e;
                if (oe.isFunction(e)) return this.each(function(t) {
                    oe(this).removeClass(e.call(this, t, this.className))
                });
                if (c)
                    for (t = (e || "").match(ye) || []; l > a; a++)
                        if (i = this[a], n = 1 === i.nodeType && (i.className ? (" " + i.className + " ").replace(At, " ") : "")) {
                            for (r = 0; o = t[r++];)
                                for (; n.indexOf(" " + o + " ") >= 0;) n = n.replace(" " + o + " ", " ");
                            s = e ? oe.trim(n) : "", i.className !== s && (i.className = s)
                        }
                return this
            },
            toggleClass: function(e, t) {
                var i = typeof e;
                return "boolean" == typeof t && "string" === i ? t ? this.addClass(e) : this.removeClass(e) : this.each(oe.isFunction(e) ? function(i) {
                    oe(this).toggleClass(e.call(this, i, this.className, t), t)
                } : function() {
                    if ("string" === i)
                        for (var t, n = 0, o = oe(this), r = e.match(ye) || []; t = r[n++];) o.hasClass(t) ? o.removeClass(t) : o.addClass(t);
                    else(i === _e || "boolean" === i) && (this.className && oe._data(this, "__className__", this.className), this.className = this.className || e === !1 ? "" : oe._data(this, "__className__") || "")
                })
            },
            hasClass: function(e) {
                for (var t = " " + e + " ", i = 0, n = this.length; n > i; i++)
                    if (1 === this[i].nodeType && (" " + this[i].className + " ").replace(At, " ").indexOf(t) >= 0) return !0;
                return !1
            }
        }), oe.each("blur focus focusin focusout load resize scroll unload click dblclick mousedown mouseup mousemove mouseover mouseout mouseenter mouseleave change select submit keydown keypress keyup error contextmenu".split(" "), function(e, t) {
            oe.fn[t] = function(e, i) {
                return arguments.length > 0 ? this.on(t, null, e, i) : this.trigger(t)
            }
        }), oe.fn.extend({
            hover: function(e, t) {
                return this.mouseenter(e).mouseleave(t || e)
            },
            bind: function(e, t, i) {
                return this.on(e, null, t, i)
            },
            unbind: function(e, t) {
                return this.off(e, null, t)
            },
            delegate: function(e, t, i, n) {
                return this.on(t, e, i, n)
            },
            undelegate: function(e, t, i) {
                return 1 === arguments.length ? this.off(e, "**") : this.off(t, e || "**", i)
            }
        });
        var Ot = oe.now(),
            Et = /\?/,
            Mt = /(,)|(\[|{)|(}|])|"(?:[^"\\\r\n]|\\["\\\/bfnrt]|\\u[\da-fA-F]{4})*"\s*:?|true|false|null|-?(?!0\d)\d+(?:\.\d+|)(?:[eE][+-]?\d+|)/g;
        oe.parseJSON = function(t) {
            if (e.JSON && e.JSON.parse) return e.JSON.parse(t + "");
            var i, n = null,
                o = oe.trim(t + "");
            return o && !oe.trim(o.replace(Mt, function(e, t, o, r) {
                return i && t && (n = 0), 0 === n ? e : (i = o || t, n += !r - !o, "")
            })) ? Function("return " + o)() : oe.error("Invalid JSON: " + t)
        }, oe.parseXML = function(t) {
            var i, n;
            if (!t || "string" != typeof t) return null;
            try {
                e.DOMParser ? (n = new DOMParser, i = n.parseFromString(t, "text/xml")) : (i = new ActiveXObject("Microsoft.XMLDOM"), i.async = "false", i.loadXML(t))
            } catch (o) {
                i = void 0
            }
            return i && i.documentElement && !i.getElementsByTagName("parsererror").length || oe.error("Invalid XML: " + t), i
        };
        var It, Dt, jt = /#.*$/,
            Nt = /([?&])_=[^&]*/,
            Lt = /^(.*?):[ \t]*([^\r\n]*)\r?$/gm,
            zt = /^(?:about|app|app-storage|.+-extension|file|res|widget):$/,
            Ft = /^(?:GET|HEAD)$/,
            Rt = /^\/\//,
            Ht = /^([\w.+-]+:)(?:\/\/(?:[^\/?#]*@|)([^\/?#:]*)(?::(\d+)|)|)/,
            Wt = {},
            Bt = {},
            qt = "*/".concat("*");
        try {
            Dt = location.href
        } catch (Xt) {
            Dt = fe.createElement("a"), Dt.href = "", Dt = Dt.href
        }
        It = Ht.exec(Dt.toLowerCase()) || [], oe.extend({
            active: 0,
            lastModified: {},
            etag: {},
            ajaxSettings: {
                url: Dt,
                type: "GET",
                isLocal: zt.test(It[1]),
                global: !0,
                processData: !0,
                async: !0,
                contentType: "application/x-www-form-urlencoded; charset=UTF-8",
                accepts: {
                    "*": qt,
                    text: "text/plain",
                    html: "text/html",
                    xml: "application/xml, text/xml",
                    json: "application/json, text/javascript"
                },
                contents: {
                    xml: /xml/,
                    html: /html/,
                    json: /json/
                },
                responseFields: {
                    xml: "responseXML",
                    text: "responseText",
                    json: "responseJSON"
                },
                converters: {
                    "* text": String,
                    "text html": !0,
                    "text json": oe.parseJSON,
                    "text xml": oe.parseXML
                },
                flatOptions: {
                    url: !0,
                    context: !0
                }
            },
            ajaxSetup: function(e, t) {
                return t ? H(H(e, oe.ajaxSettings), t) : H(oe.ajaxSettings, e)
            },
            ajaxPrefilter: F(Wt),
            ajaxTransport: F(Bt),
            ajax: function(e, t) {
                function i(e, t, i, n) {
                    var o, d, v, b, w, T = t;
                    2 !== y && (y = 2, a && clearTimeout(a), c = void 0, s = n || "", x.readyState = e > 0 ? 4 : 0, o = e >= 200 && 300 > e || 304 === e, i && (b = W(u, x, i)), b = B(u, b, x, o), o ? (u.ifModified && (w = x.getResponseHeader("Last-Modified"), w && (oe.lastModified[r] = w), w = x.getResponseHeader("etag"), w && (oe.etag[r] = w)), 204 === e || "HEAD" === u.type ? T = "nocontent" : 304 === e ? T = "notmodified" : (T = b.state, d = b.data, v = b.error, o = !v)) : (v = T, (e || !T) && (T = "error", 0 > e && (e = 0))), x.status = e, x.statusText = (t || T) + "", o ? f.resolveWith(p, [d, T, x]) : f.rejectWith(p, [x, T, v]), x.statusCode(m), m = void 0, l && h.trigger(o ? "ajaxSuccess" : "ajaxError", [x, u, o ? d : v]), g.fireWith(p, [x, T]), l && (h.trigger("ajaxComplete", [x, u]), --oe.active || oe.event.trigger("ajaxStop")))
                }
                "object" == typeof e && (t = e, e = void 0), t = t || {};
                var n, o, r, s, a, l, c, d, u = oe.ajaxSetup({}, t),
                    p = u.context || u,
                    h = u.context && (p.nodeType || p.jquery) ? oe(p) : oe.event,
                    f = oe.Deferred(),
                    g = oe.Callbacks("once memory"),
                    m = u.statusCode || {},
                    v = {},
                    b = {},
                    y = 0,
                    w = "canceled",
                    x = {
                        readyState: 0,
                        getResponseHeader: function(e) {
                            var t;
                            if (2 === y) {
                                if (!d)
                                    for (d = {}; t = Lt.exec(s);) d[t[1].toLowerCase()] = t[2];
                                t = d[e.toLowerCase()]
                            }
                            return null == t ? null : t
                        },
                        getAllResponseHeaders: function() {
                            return 2 === y ? s : null
                        },
                        setRequestHeader: function(e, t) {
                            var i = e.toLowerCase();
                            return y || (e = b[i] = b[i] || e, v[e] = t), this
                        },
                        overrideMimeType: function(e) {
                            return y || (u.mimeType = e), this
                        },
                        statusCode: function(e) {
                            var t;
                            if (e)
                                if (2 > y)
                                    for (t in e) m[t] = [m[t], e[t]];
                                else x.always(e[x.status]);
                            return this
                        },
                        abort: function(e) {
                            var t = e || w;
                            return c && c.abort(t), i(0, t), this
                        }
                    };
                if (f.promise(x).complete = g.add, x.success = x.done, x.error = x.fail, u.url = ((e || u.url || Dt) + "").replace(jt, "").replace(Rt, It[1] + "//"), u.type = t.method || t.type || u.method || u.type, u.dataTypes = oe.trim(u.dataType || "*").toLowerCase().match(ye) || [""], null == u.crossDomain && (n = Ht.exec(u.url.toLowerCase()), u.crossDomain = !(!n || n[1] === It[1] && n[2] === It[2] && (n[3] || ("http:" === n[1] ? "80" : "443")) === (It[3] || ("http:" === It[1] ? "80" : "443")))), u.data && u.processData && "string" != typeof u.data && (u.data = oe.param(u.data, u.traditional)), R(Wt, u, t, x), 2 === y) return x;
                l = oe.event && u.global, l && 0 === oe.active++ && oe.event.trigger("ajaxStart"), u.type = u.type.toUpperCase(), u.hasContent = !Ft.test(u.type), r = u.url, u.hasContent || (u.data && (r = u.url += (Et.test(r) ? "&" : "?") + u.data, delete u.data), u.cache === !1 && (u.url = Nt.test(r) ? r.replace(Nt, "$1_=" + Ot++) : r + (Et.test(r) ? "&" : "?") + "_=" + Ot++)), u.ifModified && (oe.lastModified[r] && x.setRequestHeader("If-Modified-Since", oe.lastModified[r]), oe.etag[r] && x.setRequestHeader("If-None-Match", oe.etag[r])), (u.data && u.hasContent && u.contentType !== !1 || t.contentType) && x.setRequestHeader("Content-Type", u.contentType), x.setRequestHeader("Accept", u.dataTypes[0] && u.accepts[u.dataTypes[0]] ? u.accepts[u.dataTypes[0]] + ("*" !== u.dataTypes[0] ? ", " + qt + "; q=0.01" : "") : u.accepts["*"]);
                for (o in u.headers) x.setRequestHeader(o, u.headers[o]);
                if (u.beforeSend && (u.beforeSend.call(p, x, u) === !1 || 2 === y)) return x.abort();
                w = "abort";
                for (o in {
                        success: 1,
                        error: 1,
                        complete: 1
                    }) x[o](u[o]);
                if (c = R(Bt, u, t, x)) {
                    x.readyState = 1, l && h.trigger("ajaxSend", [x, u]), u.async && u.timeout > 0 && (a = setTimeout(function() {
                        x.abort("timeout")
                    }, u.timeout));
                    try {
                        y = 1, c.send(v, i)
                    } catch (T) {
                        if (!(2 > y)) throw T;
                        i(-1, T)
                    }
                } else i(-1, "No Transport");
                return x
            },
            getJSON: function(e, t, i) {
                return oe.get(e, t, i, "json")
            },
            getScript: function(e, t) {
                return oe.get(e, void 0, t, "script")
            }
        }), oe.each(["get", "post"], function(e, t) {
            oe[t] = function(e, i, n, o) {
                return oe.isFunction(i) && (o = o || n, n = i, i = void 0), oe.ajax({
                    url: e,
                    type: t,
                    dataType: o,
                    data: i,
                    success: n
                })
            }
        }), oe._evalUrl = function(e) {
            return oe.ajax({
                url: e,
                type: "GET",
                dataType: "script",
                async: !1,
                global: !1,
                "throws": !0
            })
        }, oe.fn.extend({
            wrapAll: function(e) {
                if (oe.isFunction(e)) return this.each(function(t) {
                    oe(this).wrapAll(e.call(this, t))
                });
                if (this[0]) {
                    var t = oe(e, this[0].ownerDocument).eq(0).clone(!0);
                    this[0].parentNode && t.insertBefore(this[0]), t.map(function() {
                        for (var e = this; e.firstChild && 1 === e.firstChild.nodeType;) e = e.firstChild;
                        return e
                    }).append(this)
                }
                return this
            },
            wrapInner: function(e) {
                return this.each(oe.isFunction(e) ? function(t) {
                    oe(this).wrapInner(e.call(this, t))
                } : function() {
                    var t = oe(this),
                        i = t.contents();
                    i.length ? i.wrapAll(e) : t.append(e)
                })
            },
            wrap: function(e) {
                var t = oe.isFunction(e);
                return this.each(function(i) {
                    oe(this).wrapAll(t ? e.call(this, i) : e)
                })
            },
            unwrap: function() {
                return this.parent().each(function() {
                    oe.nodeName(this, "body") || oe(this).replaceWith(this.childNodes)
                }).end()
            }
        }), oe.expr.filters.hidden = function(e) {
            return e.offsetWidth <= 0 && e.offsetHeight <= 0 || !ie.reliableHiddenOffsets() && "none" === (e.style && e.style.display || oe.css(e, "display"))
        }, oe.expr.filters.visible = function(e) {
            return !oe.expr.filters.hidden(e)
        };
        var Gt = /%20/g,
            Vt = /\[\]$/,
            Ut = /\r?\n/g,
            Yt = /^(?:submit|button|image|reset|file)$/i,
            Qt = /^(?:input|select|textarea|keygen)/i;
        oe.param = function(e, t) {
            var i, n = [],
                o = function(e, t) {
                    t = oe.isFunction(t) ? t() : null == t ? "" : t, n[n.length] = encodeURIComponent(e) + "=" + encodeURIComponent(t)
                };
            if (void 0 === t && (t = oe.ajaxSettings && oe.ajaxSettings.traditional), oe.isArray(e) || e.jquery && !oe.isPlainObject(e)) oe.each(e, function() {
                o(this.name, this.value)
            });
            else
                for (i in e) q(i, e[i], t, o);
            return n.join("&").replace(Gt, "+")
        }, oe.fn.extend({
            serialize: function() {
                return oe.param(this.serializeArray())
            },
            serializeArray: function() {
                return this.map(function() {
                    var e = oe.prop(this, "elements");
                    return e ? oe.makeArray(e) : this
                }).filter(function() {
                    var e = this.type;
                    return this.name && !oe(this).is(":disabled") && Qt.test(this.nodeName) && !Yt.test(e) && (this.checked || !Oe.test(e))
                }).map(function(e, t) {
                    var i = oe(this).val();
                    return null == i ? null : oe.isArray(i) ? oe.map(i, function(e) {
                        return {
                            name: t.name,
                            value: e.replace(Ut, "\r\n")
                        }
                    }) : {
                        name: t.name,
                        value: i.replace(Ut, "\r\n")
                    }
                }).get()
            }
        }), oe.ajaxSettings.xhr = void 0 !== e.ActiveXObject ? function() {
            return !this.isLocal && /^(get|post|head|put|delete|options)$/i.test(this.type) && X() || G()
        } : X;
        var Zt = 0,
            Jt = {},
            Kt = oe.ajaxSettings.xhr();
        e.attachEvent && e.attachEvent("onunload", function() {
            for (var e in Jt) Jt[e](void 0, !0)
        }), ie.cors = !!Kt && "withCredentials" in Kt, Kt = ie.ajax = !!Kt, Kt && oe.ajaxTransport(function(e) {
            if (!e.crossDomain || ie.cors) {
                var t;
                return {
                    send: function(i, n) {
                        var o, r = e.xhr(),
                            s = ++Zt;
                        if (r.open(e.type, e.url, e.async, e.username, e.password), e.xhrFields)
                            for (o in e.xhrFields) r[o] = e.xhrFields[o];
                        e.mimeType && r.overrideMimeType && r.overrideMimeType(e.mimeType), e.crossDomain || i["X-Requested-With"] || (i["X-Requested-With"] = "XMLHttpRequest");
                        for (o in i) void 0 !== i[o] && r.setRequestHeader(o, i[o] + "");
                        r.send(e.hasContent && e.data || null), t = function(i, o) {
                            var a, l, c;
                            if (t && (o || 4 === r.readyState))
                                if (delete Jt[s], t = void 0, r.onreadystatechange = oe.noop, o) 4 !== r.readyState && r.abort();
                                else {
                                    c = {}, a = r.status, "string" == typeof r.responseText && (c.text = r.responseText);
                                    try {
                                        l = r.statusText
                                    } catch (d) {
                                        l = ""
                                    }
                                    a || !e.isLocal || e.crossDomain ? 1223 === a && (a = 204) : a = c.text ? 200 : 404
                                }
                            c && n(a, l, c, r.getAllResponseHeaders())
                        }, e.async ? 4 === r.readyState ? setTimeout(t) : r.onreadystatechange = Jt[s] = t : t()
                    },
                    abort: function() {
                        t && t(void 0, !0)
                    }
                }
            }
        }), oe.ajaxSetup({
            accepts: {
                script: "text/javascript, application/javascript, application/ecmascript, application/x-ecmascript"
            },
            contents: {
                script: /(?:java|ecma)script/
            },
            converters: {
                "text script": function(e) {
                    return oe.globalEval(e), e
                }
            }
        }), oe.ajaxPrefilter("script", function(e) {
            void 0 === e.cache && (e.cache = !1), e.crossDomain && (e.type = "GET", e.global = !1)
        }), oe.ajaxTransport("script", function(e) {
            if (e.crossDomain) {
                var t, i = fe.head || oe("head")[0] || fe.documentElement;
                return {
                    send: function(n, o) {
                        t = fe.createElement("script"), t.async = !0, e.scriptCharset && (t.charset = e.scriptCharset), t.src = e.url, t.onload = t.onreadystatechange = function(e, i) {
                            (i || !t.readyState || /loaded|complete/.test(t.readyState)) && (t.onload = t.onreadystatechange = null, t.parentNode && t.parentNode.removeChild(t), t = null, i || o(200, "success"))
                        }, i.insertBefore(t, i.firstChild)
                    },
                    abort: function() {
                        t && t.onload(void 0, !0)
                    }
                }
            }
        });
        var ei = [],
            ti = /(=)\?(?=&|$)|\?\?/;
        oe.ajaxSetup({
            jsonp: "callback",
            jsonpCallback: function() {
                var e = ei.pop() || oe.expando + "_" + Ot++;
                return this[e] = !0, e
            }
        }), oe.ajaxPrefilter("json jsonp", function(t, i, n) {
            var o, r, s, a = t.jsonp !== !1 && (ti.test(t.url) ? "url" : "string" == typeof t.data && !(t.contentType || "").indexOf("application/x-www-form-urlencoded") && ti.test(t.data) && "data");
            return a || "jsonp" === t.dataTypes[0] ? (o = t.jsonpCallback = oe.isFunction(t.jsonpCallback) ? t.jsonpCallback() : t.jsonpCallback, a ? t[a] = t[a].replace(ti, "$1" + o) : t.jsonp !== !1 && (t.url += (Et.test(t.url) ? "&" : "?") + t.jsonp + "=" + o), t.converters["script json"] = function() {
                return s || oe.error(o + " was not called"), s[0]
            }, t.dataTypes[0] = "json", r = e[o], e[o] = function() {
                s = arguments
            }, n.always(function() {
                e[o] = r, t[o] && (t.jsonpCallback = i.jsonpCallback, ei.push(o)), s && oe.isFunction(r) && r(s[0]), s = r = void 0
            }), "script") : void 0
        }), oe.parseHTML = function(e, t, i) {
            if (!e || "string" != typeof e) return null;
            "boolean" == typeof t && (i = t, t = !1), t = t || fe;
            var n = ue.exec(e),
                o = !i && [];
            return n ? [t.createElement(n[1])] : (n = oe.buildFragment([e], t, o), o && o.length && oe(o).remove(), oe.merge([], n.childNodes))
        };
        var ii = oe.fn.load;
        oe.fn.load = function(e, t, i) {
            if ("string" != typeof e && ii) return ii.apply(this, arguments);
            var n, o, r, s = this,
                a = e.indexOf(" ");
            return a >= 0 && (n = oe.trim(e.slice(a, e.length)), e = e.slice(0, a)), oe.isFunction(t) ? (i = t, t = void 0) : t && "object" == typeof t && (r = "POST"), s.length > 0 && oe.ajax({
                url: e,
                type: r,
                dataType: "html",
                data: t
            }).done(function(e) {
                o = arguments, s.html(n ? oe("<div>").append(oe.parseHTML(e)).find(n) : e)
            }).complete(i && function(e, t) {
                s.each(i, o || [e.responseText, t, e])
            }), this
        }, oe.each(["ajaxStart", "ajaxStop", "ajaxComplete", "ajaxError", "ajaxSuccess", "ajaxSend"], function(e, t) {
            oe.fn[t] = function(e) {
                return this.on(t, e)
            }
        }), oe.expr.filters.animated = function(e) {
            return oe.grep(oe.timers, function(t) {
                return e === t.elem
            }).length
        };
        var ni = e.document.documentElement;
        oe.offset = {
            setOffset: function(e, t, i) {
                var n, o, r, s, a, l, c, d = oe.css(e, "position"),
                    u = oe(e),
                    p = {};
                "static" === d && (e.style.position = "relative"), a = u.offset(), r = oe.css(e, "top"), l = oe.css(e, "left"), c = ("absolute" === d || "fixed" === d) && oe.inArray("auto", [r, l]) > -1, c ? (n = u.position(), s = n.top, o = n.left) : (s = parseFloat(r) || 0, o = parseFloat(l) || 0), oe.isFunction(t) && (t = t.call(e, i, a)), null != t.top && (p.top = t.top - a.top + s), null != t.left && (p.left = t.left - a.left + o), "using" in t ? t.using.call(e, p) : u.css(p)
            }
        }, oe.fn.extend({
            offset: function(e) {
                if (arguments.length) return void 0 === e ? this : this.each(function(t) {
                    oe.offset.setOffset(this, e, t)
                });
                var t, i, n = {
                        top: 0,
                        left: 0
                    },
                    o = this[0],
                    r = o && o.ownerDocument;
                return r ? (t = r.documentElement, oe.contains(t, o) ? (typeof o.getBoundingClientRect !== _e && (n = o.getBoundingClientRect()), i = V(r), {
                    top: n.top + (i.pageYOffset || t.scrollTop) - (t.clientTop || 0),
                    left: n.left + (i.pageXOffset || t.scrollLeft) - (t.clientLeft || 0)
                }) : n) : void 0
            },
            position: function() {
                if (this[0]) {
                    var e, t, i = {
                            top: 0,
                            left: 0
                        },
                        n = this[0];
                    return "fixed" === oe.css(n, "position") ? t = n.getBoundingClientRect() : (e = this.offsetParent(), t = this.offset(), oe.nodeName(e[0], "html") || (i = e.offset()), i.top += oe.css(e[0], "borderTopWidth", !0), i.left += oe.css(e[0], "borderLeftWidth", !0)), {
                        top: t.top - i.top - oe.css(n, "marginTop", !0),
                        left: t.left - i.left - oe.css(n, "marginLeft", !0)
                    }
                }
            },
            offsetParent: function() {
                return this.map(function() {
                    for (var e = this.offsetParent || ni; e && !oe.nodeName(e, "html") && "static" === oe.css(e, "position");) e = e.offsetParent;
                    return e || ni
                })
            }
        }), oe.each({
            scrollLeft: "pageXOffset",
            scrollTop: "pageYOffset"
        }, function(e, t) {
            var i = /Y/.test(t);
            oe.fn[e] = function(n) {
                return Ae(this, function(e, n, o) {
                    var r = V(e);
                    return void 0 === o ? r ? t in r ? r[t] : r.document.documentElement[n] : e[n] : void(r ? r.scrollTo(i ? oe(r).scrollLeft() : o, i ? o : oe(r).scrollTop()) : e[n] = o)
                }, e, n, arguments.length, null)
            }
        }), oe.each(["top", "left"], function(e, t) {
            oe.cssHooks[t] = C(ie.pixelPosition, function(e, i) {
                return i ? (i = tt(e, t), nt.test(i) ? oe(e).position()[t] + "px" : i) : void 0
            })
        }), oe.each({
            Height: "height",
            Width: "width"
        }, function(e, t) {
            oe.each({
                padding: "inner" + e,
                content: t,
                "": "outer" + e
            }, function(i, n) {
                oe.fn[n] = function(n, o) {
                    var r = arguments.length && (i || "boolean" != typeof n),
                        s = i || (n === !0 || o === !0 ? "margin" : "border");
                    return Ae(this, function(t, i, n) {
                        var o;
                        return oe.isWindow(t) ? t.document.documentElement["client" + e] : 9 === t.nodeType ? (o = t.documentElement, Math.max(t.body["scroll" + e], o["scroll" + e], t.body["offset" + e], o["offset" + e], o["client" + e])) : void 0 === n ? oe.css(t, i, s) : oe.style(t, i, n, s)
                    }, t, r ? n : void 0, r, null)
                }
            })
        }), oe.fn.size = function() {
            return this.length
        }, oe.fn.andSelf = oe.fn.addBack, "function" == typeof define && define.amd && define("jquery", [], function() {
            return oe
        });
        var oi = e.jQuery,
            ri = e.$;
        return oe.noConflict = function(t) {
            return e.$ === oe && (e.$ = ri), t && e.jQuery === oe && (e.jQuery = oi), oe
        }, typeof t === _e && (e.jQuery = e.$ = oe), oe
    }), "undefined" == typeof jQuery) throw new Error("Bootstrap's JavaScript requires jQuery");
if (+ function(e) {
        "use strict";
        var t = e.fn.jquery.split(" ")[0].split(".");
        if (t[0] < 2 && t[1] < 9 || 1 == t[0] && 9 == t[1] && t[2] < 1) throw new Error("Bootstrap's JavaScript requires jQuery version 1.9.1 or higher")
    }(jQuery), + function(e) {
        "use strict";

        function t(t) {
            return this.each(function() {
                var i = e(this),
                    o = i.data("bs.alert");
                o || i.data("bs.alert", o = new n(this)), "string" == typeof t && o[t].call(i)
            })
        }
        var i = '[data-dismiss="alert"]',
            n = function(t) {
                e(t).on("click", i, this.close)
            };
        n.VERSION = "3.3.5", n.TRANSITION_DURATION = 150, n.prototype.close = function(t) {
            function i() {
                s.detach().trigger("closed.bs.alert").remove()
            }
            var o = e(this),
                r = o.attr("data-target");
            r || (r = o.attr("href"), r = r && r.replace(/.*(?=#[^\s]*$)/, ""));
            var s = e(r);
            t && t.preventDefault(), s.length || (s = o.closest(".alert")), s.trigger(t = e.Event("close.bs.alert")), t.isDefaultPrevented() || (s.removeClass("in"), e.support.transition && s.hasClass("fade") ? s.one("bsTransitionEnd", i).emulateTransitionEnd(n.TRANSITION_DURATION) : i())
        };
        var o = e.fn.alert;
        e.fn.alert = t, e.fn.alert.Constructor = n, e.fn.alert.noConflict = function() {
            return e.fn.alert = o, this
        }, e(document).on("click.bs.alert.data-api", i, n.prototype.close)
    }(jQuery), + function(e) {
        "use strict";

        function t(t) {
            var i = t.attr("data-target");
            i || (i = t.attr("href"), i = i && /#[A-Za-z]/.test(i) && i.replace(/.*(?=#[^\s]*$)/, ""));
            var n = i && e(i);
            return n && n.length ? n : t.parent()
        }

        function i(i) {
            i && 3 === i.which || (e(o).remove(), e(r).each(function() {
                var n = e(this),
                    o = t(n),
                    r = {
                        relatedTarget: this
                    };
                o.hasClass("open") && (i && "click" == i.type && /input|textarea/i.test(i.target.tagName) && e.contains(o[0], i.target) || (o.trigger(i = e.Event("hide.bs.dropdown", r)), i.isDefaultPrevented() || (n.attr("aria-expanded", "false"), o.removeClass("open").trigger("hidden.bs.dropdown", r))))
            }))
        }

        function n(t) {
            return this.each(function() {
                var i = e(this),
                    n = i.data("bs.dropdown");
                n || i.data("bs.dropdown", n = new s(this)), "string" == typeof t && n[t].call(i)
            })
        }
        var o = ".dropdown-backdrop",
            r = '[data-toggle="dropdown"]',
            s = function(t) {
                e(t).on("click.bs.dropdown", this.toggle)
            };
        s.VERSION = "3.3.5", s.prototype.toggle = function(n) {
            var o = e(this);
            if (!o.is(".disabled, :disabled")) {
                var r = t(o),
                    s = r.hasClass("open");
                if (i(), !s) {
                    "ontouchstart" in document.documentElement && !r.closest(".navbar-nav").length && e(document.createElement("div")).addClass("dropdown-backdrop").insertAfter(e(this)).on("click", i);
                    var a = {
                        relatedTarget: this
                    };
                    if (r.trigger(n = e.Event("show.bs.dropdown", a)), n.isDefaultPrevented()) return;
                    o.trigger("focus").attr("aria-expanded", "true"), r.toggleClass("open").trigger("shown.bs.dropdown", a)
                }
                return !1
            }
        }, s.prototype.keydown = function(i) {
            if (/(38|40|27|32)/.test(i.which) && !/input|textarea/i.test(i.target.tagName)) {
                var n = e(this);
                if (i.preventDefault(), i.stopPropagation(), !n.is(".disabled, :disabled")) {
                    var o = t(n),
                        s = o.hasClass("open");
                    if (!s && 27 != i.which || s && 27 == i.which) return 27 == i.which && o.find(r).trigger("focus"), n.trigger("click");
                    var a = " li:not(.disabled):visible a",
                        l = o.find(".dropdown-menu" + a);
                    if (l.length) {
                        var c = l.index(i.target);
                        38 == i.which && c > 0 && c--, 40 == i.which && c < l.length - 1 && c++, ~c || (c = 0), l.eq(c).trigger("focus")
                    }
                }
            }
        };
        var a = e.fn.dropdown;
        e.fn.dropdown = n, e.fn.dropdown.Constructor = s, e.fn.dropdown.noConflict = function() {
            return e.fn.dropdown = a, this
        }, e(document).on("click.bs.dropdown.data-api", i).on("click.bs.dropdown.data-api", ".dropdown form", function(e) {
            e.stopPropagation()
        }).on("click.bs.dropdown.data-api", r, s.prototype.toggle).on("keydown.bs.dropdown.data-api", r, s.prototype.keydown).on("keydown.bs.dropdown.data-api", ".dropdown-menu", s.prototype.keydown)
    }(jQuery), + function(e) {
        "use strict";

        function t(t, n) {
            return this.each(function() {
                var o = e(this),
                    r = o.data("bs.modal"),
                    s = e.extend({}, i.DEFAULTS, o.data(), "object" == typeof t && t);
                r || o.data("bs.modal", r = new i(this, s)), "string" == typeof t ? r[t](n) : s.show && r.show(n)
            })
        }
        var i = function(t, i) {
            this.options = i, this.$body = e(document.body), this.$element = e(t), this.$dialog = this.$element.find(".modal-dialog"), this.$backdrop = null, this.isShown = null, this.originalBodyPad = null, this.scrollbarWidth = 0, this.ignoreBackdropClick = !1, this.options.remote && this.$element.find(".modal-content").load(this.options.remote, e.proxy(function() {
                this.$element.trigger("loaded.bs.modal")
            }, this))
        };
        i.VERSION = "3.3.5", i.TRANSITION_DURATION = 300, i.BACKDROP_TRANSITION_DURATION = 150, i.DEFAULTS = {
            backdrop: !0,
            keyboard: !0,
            show: !0
        }, i.prototype.toggle = function(e) {
            return this.isShown ? this.hide() : this.show(e)
        }, i.prototype.show = function(t) {
            var n = this,
                o = e.Event("show.bs.modal", {
                    relatedTarget: t
                });
            this.$element.trigger(o), this.isShown || o.isDefaultPrevented() || (this.isShown = !0, this.checkScrollbar(), this.setScrollbar(), this.$body.addClass("modal-open"), this.escape(), this.resize(), this.$element.on("click.dismiss.bs.modal", '[data-dismiss="modal"]', e.proxy(this.hide, this)), this.$dialog.on("mousedown.dismiss.bs.modal", function() {
                n.$element.one("mouseup.dismiss.bs.modal", function(t) {
                    e(t.target).is(n.$element) && (n.ignoreBackdropClick = !0)
                })
            }), this.backdrop(function() {
                var o = e.support.transition && n.$element.hasClass("fade");
                n.$element.parent().length || n.$element.appendTo(n.$body), n.$element.show().scrollTop(0), n.adjustDialog(), o && n.$element[0].offsetWidth, n.$element.addClass("in"), n.enforceFocus();
                var r = e.Event("shown.bs.modal", {
                    relatedTarget: t
                });
                o ? n.$dialog.one("bsTransitionEnd", function() {
                    n.$element.trigger("focus").trigger(r)
                }).emulateTransitionEnd(i.TRANSITION_DURATION) : n.$element.trigger("focus").trigger(r)
            }))
        }, i.prototype.hide = function(t) {
            t && t.preventDefault(), t = e.Event("hide.bs.modal"), this.$element.trigger(t), this.isShown && !t.isDefaultPrevented() && (this.isShown = !1, this.escape(), this.resize(), e(document).off("focusin.bs.modal"), this.$element.removeClass("in").off("click.dismiss.bs.modal").off("mouseup.dismiss.bs.modal"), this.$dialog.off("mousedown.dismiss.bs.modal"), e.support.transition && this.$element.hasClass("fade") ? this.$element.one("bsTransitionEnd", e.proxy(this.hideModal, this)).emulateTransitionEnd(i.TRANSITION_DURATION) : this.hideModal())
        }, i.prototype.enforceFocus = function() {
            e(document).off("focusin.bs.modal").on("focusin.bs.modal", e.proxy(function(e) {
                this.$element[0] === e.target || this.$element.has(e.target).length || this.$element.trigger("focus")
            }, this))
        }, i.prototype.escape = function() {
            this.isShown && this.options.keyboard ? this.$element.on("keydown.dismiss.bs.modal", e.proxy(function(e) {
                27 == e.which && this.hide()
            }, this)) : this.isShown || this.$element.off("keydown.dismiss.bs.modal")
        }, i.prototype.resize = function() {
            this.isShown ? e(window).on("resize.bs.modal", e.proxy(this.handleUpdate, this)) : e(window).off("resize.bs.modal")
        }, i.prototype.hideModal = function() {
            var e = this;
            this.$element.hide(), this.backdrop(function() {
                e.$body.removeClass("modal-open"), e.resetAdjustments(), e.resetScrollbar(), e.$element.trigger("hidden.bs.modal")
            })
        }, i.prototype.removeBackdrop = function() {
            this.$backdrop && this.$backdrop.remove(), this.$backdrop = null
        }, i.prototype.backdrop = function(t) {
            var n = this,
                o = this.$element.hasClass("fade") ? "fade" : "";
            if (this.isShown && this.options.backdrop) {
                var r = e.support.transition && o;
                if (this.$backdrop = e(document.createElement("div")).addClass("modal-backdrop " + o).appendTo(this.$body), this.$element.on("click.dismiss.bs.modal", e.proxy(function(e) {
                        return this.ignoreBackdropClick ? void(this.ignoreBackdropClick = !1) : void(e.target === e.currentTarget && ("static" == this.options.backdrop ? this.$element[0].focus() : this.hide()))
                    }, this)), r && this.$backdrop[0].offsetWidth, this.$backdrop.addClass("in"), !t) return;
                r ? this.$backdrop.one("bsTransitionEnd", t).emulateTransitionEnd(i.BACKDROP_TRANSITION_DURATION) : t()
            } else if (!this.isShown && this.$backdrop) {
                this.$backdrop.removeClass("in");
                var s = function() {
                    n.removeBackdrop(), t && t()
                };
                e.support.transition && this.$element.hasClass("fade") ? this.$backdrop.one("bsTransitionEnd", s).emulateTransitionEnd(i.BACKDROP_TRANSITION_DURATION) : s()
            } else t && t()
        }, i.prototype.handleUpdate = function() {
            this.adjustDialog()
        }, i.prototype.adjustDialog = function() {
            var e = this.$element[0].scrollHeight > document.documentElement.clientHeight;
            this.$element.css({
                paddingLeft: !this.bodyIsOverflowing && e ? this.scrollbarWidth : "",
                paddingRight: this.bodyIsOverflowing && !e ? this.scrollbarWidth : ""
            })
        }, i.prototype.resetAdjustments = function() {
            this.$element.css({
                paddingLeft: "",
                paddingRight: ""
            })
        }, i.prototype.checkScrollbar = function() {
            var e = window.innerWidth;
            if (!e) {
                var t = document.documentElement.getBoundingClientRect();
                e = t.right - Math.abs(t.left)
            }
            this.bodyIsOverflowing = document.body.clientWidth < e, this.scrollbarWidth = this.measureScrollbar()
        }, i.prototype.setScrollbar = function() {
            var e = parseInt(this.$body.css("padding-right") || 0, 10);
            this.originalBodyPad = document.body.style.paddingRight || "", this.bodyIsOverflowing && this.$body.css("padding-right", e + this.scrollbarWidth)
        }, i.prototype.resetScrollbar = function() {
            this.$body.css("padding-right", this.originalBodyPad)
        }, i.prototype.measureScrollbar = function() {
            var e = document.createElement("div");
            e.className = "modal-scrollbar-measure", this.$body.append(e);
            var t = e.offsetWidth - e.clientWidth;
            return this.$body[0].removeChild(e), t
        };
        var n = e.fn.modal;
        e.fn.modal = t, e.fn.modal.Constructor = i, e.fn.modal.noConflict = function() {
            return e.fn.modal = n, this
        }, e(document).on("click.bs.modal.data-api", '[data-toggle="modal"]', function(i) {
            var n = e(this),
                o = n.attr("href"),
                r = e(n.attr("data-target") || o && o.replace(/.*(?=#[^\s]+$)/, "")),
                s = r.data("bs.modal") ? "toggle" : e.extend({
                    remote: !/#/.test(o) && o
                }, r.data(), n.data());
            n.is("a") && i.preventDefault(), r.one("show.bs.modal", function(e) {
                e.isDefaultPrevented() || r.one("hidden.bs.modal", function() {
                    n.is(":visible") && n.trigger("focus")
                })
            }), t.call(r, s, this)
        })
    }(jQuery), + function(e) {
        "use strict";

        function t(t) {
            return this.each(function() {
                var n = e(this),
                    o = n.data("bs.affix"),
                    r = "object" == typeof t && t;
                o || n.data("bs.affix", o = new i(this, r)), "string" == typeof t && o[t]()
            })
        }
        var i = function(t, n) {
            this.options = e.extend({}, i.DEFAULTS, n), this.$target = e(this.options.target).on("scroll.bs.affix.data-api", e.proxy(this.checkPosition, this)).on("click.bs.affix.data-api", e.proxy(this.checkPositionWithEventLoop, this)), this.$element = e(t), this.affixed = null, this.unpin = null, this.pinnedOffset = null, this.checkPosition()
        };
        i.VERSION = "3.3.5", i.RESET = "affix affix-top affix-bottom", i.DEFAULTS = {
            offset: 0,
            target: window
        }, i.prototype.getState = function(e, t, i, n) {
            var o = this.$target.scrollTop(),
                r = this.$element.offset(),
                s = this.$target.height();
            if (null != i && "top" == this.affixed) return i > o && "top";
            if ("bottom" == this.affixed) return null != i ? !(o + this.unpin <= r.top) && "bottom" : !(e - n >= o + s) && "bottom";
            var a = null == this.affixed,
                l = a ? o : r.top,
                c = a ? s : t;
            return null != i && i >= o ? "top" : null != n && l + c >= e - n && "bottom"
        }, i.prototype.getPinnedOffset = function() {
            if (this.pinnedOffset) return this.pinnedOffset;
            this.$element.removeClass(i.RESET).addClass("affix");
            var e = this.$target.scrollTop(),
                t = this.$element.offset();
            return this.pinnedOffset = t.top - e
        }, i.prototype.checkPositionWithEventLoop = function() {
            setTimeout(e.proxy(this.checkPosition, this), 1)
        }, i.prototype.checkPosition = function() {
            if (this.$element.is(":visible")) {
                var t = this.$element.height(),
                    n = this.options.offset,
                    o = n.top,
                    r = n.bottom,
                    s = Math.max(e(document).height(), e(document.body).height());
                "object" != typeof n && (r = o = n), "function" == typeof o && (o = n.top(this.$element)), "function" == typeof r && (r = n.bottom(this.$element));
                var a = this.getState(s, t, o, r);
                if (this.affixed != a) {
                    null != this.unpin && this.$element.css("top", "");
                    var l = "affix" + (a ? "-" + a : ""),
                        c = e.Event(l + ".bs.affix");
                    if (this.$element.trigger(c), c.isDefaultPrevented()) return;
                    this.affixed = a, this.unpin = "bottom" == a ? this.getPinnedOffset() : null, this.$element.removeClass(i.RESET).addClass(l).trigger(l.replace("affix", "affixed") + ".bs.affix")
                }
                "bottom" == a && this.$element.offset({
                    top: s - t - r
                })
            }
        };
        var n = e.fn.affix;
        e.fn.affix = t, e.fn.affix.Constructor = i, e.fn.affix.noConflict = function() {
            return e.fn.affix = n, this
        }, e(window).on("load", function() {
            e('[data-spy="affix"]').each(function() {
                var i = e(this),
                    n = i.data();
                n.offset = n.offset || {}, null != n.offsetBottom && (n.offset.bottom = n.offsetBottom), null != n.offsetTop && (n.offset.top = n.offsetTop), t.call(i, n)
            })
        })
    }(jQuery), + function(e) {
        "use strict";

        function t(t) {
            var i, n = t.attr("data-target") || (i = t.attr("href")) && i.replace(/.*(?=#[^\s]+$)/, "");
            return e(n)
        }

        function i(t) {
            return this.each(function() {
                var i = e(this),
                    o = i.data("bs.collapse"),
                    r = e.extend({}, n.DEFAULTS, i.data(), "object" == typeof t && t);
                !o && r.toggle && /show|hide/.test(t) && (r.toggle = !1), o || i.data("bs.collapse", o = new n(this, r)), "string" == typeof t && o[t]()
            })
        }
        var n = function(t, i) {
            this.$element = e(t), this.options = e.extend({}, n.DEFAULTS, i), this.$trigger = e('[data-toggle="collapse"][href="#' + t.id + '"],[data-toggle="collapse"][data-target="#' + t.id + '"]'), this.transitioning = null, this.options.parent ? this.$parent = this.getParent() : this.addAriaAndCollapsedClass(this.$element, this.$trigger), this.options.toggle && this.toggle()
        };
        n.VERSION = "3.3.5", n.TRANSITION_DURATION = 350, n.DEFAULTS = {
            toggle: !0
        }, n.prototype.dimension = function() {
            var e = this.$element.hasClass("width");
            return e ? "width" : "height"
        }, n.prototype.show = function() {
            if (!this.transitioning && !this.$element.hasClass("in")) {
                var t, o = this.$parent && this.$parent.children(".panel").children(".in, .collapsing");
                if (!(o && o.length && (t = o.data("bs.collapse"), t && t.transitioning))) {
                    var r = e.Event("show.bs.collapse");
                    if (this.$element.trigger(r), !r.isDefaultPrevented()) {
                        o && o.length && (i.call(o, "hide"), t || o.data("bs.collapse", null));
                        var s = this.dimension();
                        this.$element.removeClass("collapse").addClass("collapsing")[s](0).attr("aria-expanded", !0), this.$trigger.removeClass("collapsed").attr("aria-expanded", !0), this.transitioning = 1;
                        var a = function() {
                            this.$element.removeClass("collapsing").addClass("collapse in")[s](""), this.transitioning = 0, this.$element.trigger("shown.bs.collapse")
                        };
                        if (!e.support.transition) return a.call(this);
                        var l = e.camelCase(["scroll", s].join("-"));
                        this.$element.one("bsTransitionEnd", e.proxy(a, this)).emulateTransitionEnd(n.TRANSITION_DURATION)[s](this.$element[0][l])
                    }
                }
            }
        }, n.prototype.hide = function() {
            if (!this.transitioning && this.$element.hasClass("in")) {
                var t = e.Event("hide.bs.collapse");
                if (this.$element.trigger(t), !t.isDefaultPrevented()) {
                    var i = this.dimension();
                    this.$element[i](this.$element[i]())[0].offsetHeight, this.$element.addClass("collapsing").removeClass("collapse in").attr("aria-expanded", !1), this.$trigger.addClass("collapsed").attr("aria-expanded", !1), this.transitioning = 1;
                    var o = function() {
                        this.transitioning = 0, this.$element.removeClass("collapsing").addClass("collapse").trigger("hidden.bs.collapse")
                    };
                    return e.support.transition ? void this.$element[i](0).one("bsTransitionEnd", e.proxy(o, this)).emulateTransitionEnd(n.TRANSITION_DURATION) : o.call(this)
                }
            }
        }, n.prototype.toggle = function() {
            this[this.$element.hasClass("in") ? "hide" : "show"]()
        }, n.prototype.getParent = function() {
            return e(this.options.parent).find('[data-toggle="collapse"][data-parent="' + this.options.parent + '"]').each(e.proxy(function(i, n) {
                var o = e(n);
                this.addAriaAndCollapsedClass(t(o), o)
            }, this)).end()
        }, n.prototype.addAriaAndCollapsedClass = function(e, t) {
            var i = e.hasClass("in");
            e.attr("aria-expanded", i), t.toggleClass("collapsed", !i).attr("aria-expanded", i)
        };
        var o = e.fn.collapse;
        e.fn.collapse = i, e.fn.collapse.Constructor = n, e.fn.collapse.noConflict = function() {
            return e.fn.collapse = o, this
        }, e(document).on("click.bs.collapse.data-api", '[data-toggle="collapse"]', function(n) {
            var o = e(this);
            o.attr("data-target") || n.preventDefault();
            var r = t(o),
                s = r.data("bs.collapse"),
                a = s ? "toggle" : o.data();
            i.call(r, a)
        })
    }(jQuery), + function(e) {
        "use strict";

        function t() {
            var e = document.createElement("bootstrap"),
                t = {
                    WebkitTransition: "webkitTransitionEnd",
                    MozTransition: "transitionend",
                    OTransition: "oTransitionEnd otransitionend",
                    transition: "transitionend"
                };
            for (var i in t)
                if (void 0 !== e.style[i]) return {
                    end: t[i]
                };
            return !1
        }
        e.fn.emulateTransitionEnd = function(t) {
            var i = !1,
                n = this;
            e(this).one("bsTransitionEnd", function() {
                i = !0
            });
            var o = function() {
                i || e(n).trigger(e.support.transition.end)
            };
            return setTimeout(o, t), this
        }, e(function() {
            e.support.transition = t(), e.support.transition && (e.event.special.bsTransitionEnd = {
                bindType: e.support.transition.end,
                delegateType: e.support.transition.end,
                handle: function(t) {
                    return e(t.target).is(this) ? t.handleObj.handler.apply(this, arguments) : void 0
                }
            })
        })
    }(jQuery), function(e) {
        "function" == typeof define && define.amd && define.amd.jQuery ? define(["jquery"], e) : e(jQuery)
    }(function(e) {
        function t(t) {
            return !t || void 0 !== t.allowPageScroll || void 0 === t.swipe && void 0 === t.swipeStatus || (t.allowPageScroll = d), void 0 !== t.click && void 0 === t.tap && (t.tap = t.click), t || (t = {}), t = e.extend({}, e.fn.swipetp.defaults, t), this.each(function() {
                var n = e(this),
                    o = n.data(P);
                o || (o = new i(this, t), n.data(P, o))
            })
        }

        function i(t, i) {
            function n(t) {
                if (!(ce() || e(t.target).closest(i.excludedElements, qe).length > 0)) {
                    var n, o = t.originalEvent ? t.originalEvent : t,
                        r = o.touches,
                        s = r ? r[0] : o;
                    return Xe = x, r ? Ge = r.length : t.preventDefault(), je = 0, Ne = null, We = null, Le = 0, ze = 0, Fe = 0, Re = 1, He = 0, Ve = fe(), Be = ve(), ae(), !r || Ge === i.fingers || i.fingers === y || B() ? (ue(0, s), Ue = Ce(), 2 == Ge && (ue(1, r[1]), ze = Fe = we(Ve[0].start, Ve[1].start)), (i.swipeStatus || i.pinchStatus) && (n = j(o, Xe))) : n = !1, n === !1 ? (Xe = k, j(o, Xe), n) : (i.hold && (et = setTimeout(e.proxy(function() {
                        qe.trigger("hold", [o.target]), i.hold && (n = i.hold.call(qe, o, o.target))
                    }, this), i.longTapThreshold)), de(!0), null)
                }
            }

            function A(e) {
                var t = e.originalEvent ? e.originalEvent : e;
                if (Xe !== _ && Xe !== k && !le()) {
                    var n, o = t.touches,
                        r = o ? o[0] : t,
                        s = pe(r);
                    if (Ye = Ce(), o && (Ge = o.length), i.hold && clearTimeout(et), Xe = T, 2 == Ge && (0 == ze ? (ue(1, o[1]), ze = Fe = we(Ve[0].start, Ve[1].start)) : (pe(o[1]), Fe = we(Ve[0].end, Ve[1].end), We = Te(Ve[0].end, Ve[1].end)), Re = xe(ze, Fe), He = Math.abs(ze - Fe)), Ge === i.fingers || i.fingers === y || !o || B()) {
                        if (Ne = Se(s.start, s.end), H(e, Ne), je = _e(s.start, s.end), Le = ye(), ge(Ne, je), (i.swipeStatus || i.pinchStatus) && (n = j(t, Xe)), !i.triggerOnTouchEnd || i.triggerOnTouchLeave) {
                            var a = !0;
                            if (i.triggerOnTouchLeave) {
                                var l = $e(this);
                                a = Pe(s.end, l)
                            }!i.triggerOnTouchEnd && a ? Xe = D(T) : i.triggerOnTouchLeave && !a && (Xe = D(_)), Xe != k && Xe != _ || j(t, Xe)
                        }
                    } else Xe = k, j(t, Xe);
                    n === !1 && (Xe = k, j(t, Xe))
                }
            }

            function O(e) {
                var t = e.originalEvent ? e.originalEvent : e,
                    n = t.touches;
                return n && n.length ? (se(), !0) : (le() && (Ge = Ze), Ye = Ce(), Le = ye(), z() || !L() ? (Xe = k, j(t, Xe)) : i.triggerOnTouchEnd || 0 == i.triggerOnTouchEnd && Xe === T ? (e.preventDefault(), Xe = _, j(t, Xe)) : !i.triggerOnTouchEnd && Q() ? (Xe = _, N(t, Xe, f)) : Xe === T && (Xe = k, j(t, Xe)), de(!1), null)
            }

            function E() {
                Ge = 0, Ye = 0, Ue = 0, ze = 0, Fe = 0, Re = 1, ae(), de(!1)
            }

            function M(e) {
                var t = e.originalEvent ? e.originalEvent : e;
                i.triggerOnTouchLeave && (Xe = D(_), j(t, Xe))
            }

            function I() {
                qe.unbind(Oe, n), qe.unbind(De, E), qe.unbind(Ee, A), qe.unbind(Me, O), Ie && qe.unbind(Ie, M), de(!1)
            }

            function D(e) {
                var t = e,
                    n = R(),
                    o = L(),
                    r = z();
                return !n || r ? t = k : !o || e != T || i.triggerOnTouchEnd && !i.triggerOnTouchLeave ? !o && e == _ && i.triggerOnTouchLeave && (t = k) : t = _, t
            }

            function j(e, t) {
                var i, n = e.touches;
                return V() || G() || q() || B() ? ((V() || G()) && (i = N(e, t, p)), (q() || B()) && i !== !1 && (i = N(e, t, h))) : oe() && i !== !1 ? i = N(e, t, g) : re() && i !== !1 ? i = N(e, t, m) : ne() && i !== !1 && (i = N(e, t, f)), t === k && E(e), t === _ && (n ? n.length || E(e) : E(e)), i
            }

            function N(t, n, d) {
                var u;
                if (d == p) {
                    if (qe.trigger("swipeStatus", [n, Ne || null, je || 0, Le || 0, Ge, Ve]), i.swipeStatus && (u = i.swipeStatus.call(qe, t, n, Ne || null, je || 0, Le || 0, Ge, Ve), u === !1)) return !1;
                    if (n == _ && X()) {
                        if (qe.trigger("swipe", [Ne, je, Le, Ge, Ve]), i.swipe && (u = i.swipe.call(qe, t, Ne, je, Le, Ge, Ve), u === !1)) return !1;
                        switch (Ne) {
                            case o:
                                qe.trigger("swipeLeft", [Ne, je, Le, Ge, Ve]), i.swipeLeft && (u = i.swipeLeft.call(qe, t, Ne, je, Le, Ge, Ve));
                                break;
                            case r:
                                qe.trigger("swipeRight", [Ne, je, Le, Ge, Ve]), i.swipeRight && (u = i.swipeRight.call(qe, t, Ne, je, Le, Ge, Ve));
                                break;
                            case s:
                                qe.trigger("swipeUp", [Ne, je, Le, Ge, Ve]), i.swipeUp && (u = i.swipeUp.call(qe, t, Ne, je, Le, Ge, Ve));
                                break;
                            case a:
                                qe.trigger("swipeDown", [Ne, je, Le, Ge, Ve]), i.swipeDown && (u = i.swipeDown.call(qe, t, Ne, je, Le, Ge, Ve))
                        }
                    }
                }
                if (d == h) {
                    if (qe.trigger("pinchStatus", [n, We || null, He || 0, Le || 0, Ge, Re, Ve]), i.pinchStatus && (u = i.pinchStatus.call(qe, t, n, We || null, He || 0, Le || 0, Ge, Re, Ve), u === !1)) return !1;
                    if (n == _ && W()) switch (We) {
                        case l:
                            qe.trigger("pinchIn", [We || null, He || 0, Le || 0, Ge, Re, Ve]), i.pinchIn && (u = i.pinchIn.call(qe, t, We || null, He || 0, Le || 0, Ge, Re, Ve));
                            break;
                        case c:
                            qe.trigger("pinchOut", [We || null, He || 0, Le || 0, Ge, Re, Ve]), i.pinchOut && (u = i.pinchOut.call(qe, t, We || null, He || 0, Le || 0, Ge, Re, Ve))
                    }
                }
                return d == f ? n !== k && n !== _ || (clearTimeout(Ke), clearTimeout(et), Z() && !ee() ? (Je = Ce(), Ke = setTimeout(e.proxy(function() {
                    Je = null, qe.trigger("tap", [t.target]), i.tap && (u = i.tap.call(qe, t, t.target))
                }, this), i.doubleTapThreshold)) : (Je = null, qe.trigger("tap", [t.target]), i.tap && (u = i.tap.call(qe, t, t.target)))) : d == g ? n !== k && n !== _ || (clearTimeout(Ke), Je = null, qe.trigger("doubletap", [t.target]), i.doubleTap && (u = i.doubleTap.call(qe, t, t.target))) : d == m && (n !== k && n !== _ || (clearTimeout(Ke), Je = null, qe.trigger("longtap", [t.target]), i.longTap && (u = i.longTap.call(qe, t, t.target)))), u
            }

            function L() {
                var e = !0;
                return null !== i.threshold && (e = je >= i.threshold), e
            }

            function z() {
                var e = !1;
                return null !== i.cancelThreshold && null !== Ne && (e = me(Ne) - je >= i.cancelThreshold), e
            }

            function F() {
                return null === i.pinchThreshold || He >= i.pinchThreshold
            }

            function R() {
                var e;
                return e = !i.maxTimeThreshold || !(Le >= i.maxTimeThreshold)
            }

            function H(e, t) {
                if (i.preventDefaultEvents !== !1)
                    if (i.allowPageScroll === d) e.preventDefault();
                    else {
                        var n = i.allowPageScroll === u;
                        switch (t) {
                            case o:
                                (i.swipeLeft && n || !n && i.allowPageScroll != v) && e.preventDefault();
                                break;
                            case r:
                                (i.swipeRight && n || !n && i.allowPageScroll != v) && e.preventDefault();
                                break;
                            case s:
                                (i.swipeUp && n || !n && i.allowPageScroll != b) && e.preventDefault();
                                break;
                            case a:
                                (i.swipeDown && n || !n && i.allowPageScroll != b) && e.preventDefault()
                        }
                    }
            }

            function W() {
                var e = U(),
                    t = Y(),
                    i = F();
                return e && t && i
            }

            function B() {
                return !!(i.pinchStatus || i.pinchIn || i.pinchOut)
            }

            function q() {
                return !(!W() || !B())
            }

            function X() {
                var e = R(),
                    t = L(),
                    i = U(),
                    n = Y(),
                    o = z(),
                    r = !o && n && i && t && e;
                return r
            }

            function G() {
                return !!(i.swipe || i.swipeStatus || i.swipeLeft || i.swipeRight || i.swipeUp || i.swipeDown)
            }

            function V() {
                return !(!X() || !G())
            }

            function U() {
                return Ge === i.fingers || i.fingers === y || !S
            }

            function Y() {
                return 0 !== Ve[0].end.x
            }

            function Q() {
                return !!i.tap
            }

            function Z() {
                return !!i.doubleTap
            }

            function J() {
                return !!i.longTap
            }

            function K() {
                if (null == Je) return !1;
                var e = Ce();
                return Z() && e - Je <= i.doubleTapThreshold
            }

            function ee() {
                return K()
            }

            function te() {
                return (1 === Ge || !S) && (isNaN(je) || je < i.threshold)
            }

            function ie() {
                return Le > i.longTapThreshold && je < w
            }

            function ne() {
                return !(!te() || !Q())
            }

            function oe() {
                return !(!K() || !Z())
            }

            function re() {
                return !(!ie() || !J())
            }

            function se() {
                Qe = Ce(), Ze = event.touches.length + 1
            }

            function ae() {
                Qe = 0, Ze = 0
            }

            function le() {
                var e = !1;
                if (Qe) {
                    var t = Ce() - Qe;
                    t <= i.fingerReleaseThreshold && (e = !0)
                }
                return e
            }

            function ce() {
                return !(qe.data(P + "_intouch") !== !0)
            }

            function de(e) {
                e === !0 ? (qe.bind(Ee, A), qe.bind(Me, O), Ie && qe.bind(Ie, M)) : (qe.unbind(Ee, A, !1), qe.unbind(Me, O, !1), Ie && qe.unbind(Ie, M, !1)), qe.data(P + "_intouch", e === !0)
            }

            function ue(e, t) {
                var i = void 0 !== t.identifier ? t.identifier : 0;
                return Ve[e].identifier = i, Ve[e].start.x = Ve[e].end.x = t.pageX || t.clientX, Ve[e].start.y = Ve[e].end.y = t.pageY || t.clientY, Ve[e]
            }

            function pe(e) {
                var t = void 0 !== e.identifier ? e.identifier : 0,
                    i = he(t);
                return i.end.x = e.pageX || e.clientX, i.end.y = e.pageY || e.clientY, i
            }

            function he(e) {
                for (var t = 0; t < Ve.length; t++)
                    if (Ve[t].identifier == e) return Ve[t]
            }

            function fe() {
                for (var e = [], t = 0; t <= 5; t++) e.push({
                    start: {
                        x: 0,
                        y: 0
                    },
                    end: {
                        x: 0,
                        y: 0
                    },
                    identifier: 0
                });
                return e
            }

            function ge(e, t) {
                t = Math.max(t, me(e)), Be[e].distance = t
            }

            function me(e) {
                if (Be[e]) return Be[e].distance
            }

            function ve() {
                var e = {};
                return e[o] = be(o), e[r] = be(r), e[s] = be(s), e[a] = be(a), e
            }

            function be(e) {
                return {
                    direction: e,
                    distance: 0
                }
            }

            function ye() {
                return Ye - Ue
            }

            function we(e, t) {
                var i = Math.abs(e.x - t.x),
                    n = Math.abs(e.y - t.y);
                return Math.round(Math.sqrt(i * i + n * n))
            }

            function xe(e, t) {
                var i = t / e * 1;
                return i.toFixed(2)
            }

            function Te() {
                return Re < 1 ? c : l
            }

            function _e(e, t) {
                return Math.round(Math.sqrt(Math.pow(t.x - e.x, 2) + Math.pow(t.y - e.y, 2)))
            }

            function ke(e, t) {
                var i = e.x - t.x,
                    n = t.y - e.y,
                    o = Math.atan2(n, i),
                    r = Math.round(180 * o / Math.PI);
                return r < 0 && (r = 360 - Math.abs(r)), r
            }

            function Se(e, t) {
                var i = ke(e, t);
                return i <= 45 && i >= 0 ? o : i <= 360 && i >= 315 ? o : i >= 135 && i <= 225 ? r : i > 45 && i < 135 ? a : s
            }

            function Ce() {
                var e = new Date;
                return e.getTime()
            }

            function $e(t) {
                t = e(t);
                var i = t.offset(),
                    n = {
                        left: i.left,
                        right: i.left + t.outerWidth(),
                        top: i.top,
                        bottom: i.top + t.outerHeight()
                    };
                return n
            }

            function Pe(e, t) {
                return e.x > t.left && e.x < t.right && e.y > t.top && e.y < t.bottom
            }
            var Ae = S || $ || !i.fallbackToMouseEvents,
                Oe = Ae ? $ ? C ? "MSPointerDown" : "pointerdown" : "touchstart" : "mousedown",
                Ee = Ae ? $ ? C ? "MSPointerMove" : "pointermove" : "touchmove" : "mousemove",
                Me = Ae ? $ ? C ? "MSPointerUp" : "pointerup" : "touchend" : "mouseup",
                Ie = Ae ? null : "mouseleave",
                De = $ ? C ? "MSPointerCancel" : "pointercancel" : "touchcancel",
                je = 0,
                Ne = null,
                Le = 0,
                ze = 0,
                Fe = 0,
                Re = 1,
                He = 0,
                We = 0,
                Be = null,
                qe = e(t),
                Xe = "start",
                Ge = 0,
                Ve = null,
                Ue = 0,
                Ye = 0,
                Qe = 0,
                Ze = 0,
                Je = 0,
                Ke = null,
                et = null;
            try {
                qe.bind(Oe, n), qe.bind(De, E)
            } catch (tt) {
                e.error("events not supported " + Oe + "," + De + " on jQuery.swipetp")
            }
            this.enable = function() {
                return qe.bind(Oe, n), qe.bind(De, E), qe
            }, this.disable = function() {
                return I(), qe
            }, this.destroy = function() {
                I(), qe.data(P, null), qe = null
            }, this.option = function(t, n) {
                if (void 0 !== i[t]) {
                    if (void 0 === n) return i[t];
                    i[t] = n
                } else e.error("Option " + t + " does not exist on jQuery.swipetp.options");
                return null
            }
        }
        var n = "1.6.9",
            o = "left",
            r = "right",
            s = "up",
            a = "down",
            l = "in",
            c = "out",
            d = "none",
            u = "auto",
            p = "swipe",
            h = "pinch",
            f = "tap",
            g = "doubletap",
            m = "longtap",
            v = "horizontal",
            b = "vertical",
            y = "all",
            w = 10,
            x = "start",
            T = "move",
            _ = "end",
            k = "cancel",
            S = "ontouchstart" in window,
            C = window.navigator.msPointerEnabled && !window.navigator.pointerEnabled,
            $ = window.navigator.pointerEnabled || window.navigator.msPointerEnabled,
            P = "TouchSwipe",
            A = {
                fingers: 1,
                threshold: 75,
                cancelThreshold: null,
                pinchThreshold: 20,
                maxTimeThreshold: null,
                fingerReleaseThreshold: 250,
                longTapThreshold: 500,
                doubleTapThreshold: 200,
                swipe: null,
                swipeLeft: null,
                swipeRight: null,
                swipeUp: null,
                swipeDown: null,
                swipeStatus: null,
                pinchIn: null,
                pinchOut: null,
                pinchStatus: null,
                click: null,
                tap: null,
                doubleTap: null,
                longTap: null,
                hold: null,
                triggerOnTouchEnd: !0,
                triggerOnTouchLeave: !1,
                allowPageScroll: "auto",
                fallbackToMouseEvents: !0,
                excludedElements: "label, button, input, select, textarea, a, .noSwipe",
                preventDefaultEvents: !0
            };
        e.fn.swipetp = function(i) {
            var n = e(this),
                o = n.data(P);
            if (o && "string" == typeof i) {
                if (o[i]) return o[i].apply(this, Array.prototype.slice.call(arguments, 1));
                e.error("Method " + i + " does not exist on jQuery.swipetp")
            } else if (!(o || "object" != typeof i && i)) return t.apply(this, arguments);
            return n
        }, e.fn.swipetp.version = n, e.fn.swipetp.defaults = A, e.fn.swipetp.phases = {
            PHASE_START: x,
            PHASE_MOVE: T,
            PHASE_END: _,
            PHASE_CANCEL: k
        }, e.fn.swipetp.directions = {
            LEFT: o,
            RIGHT: r,
            UP: s,
            DOWN: a,
            IN: l,
            OUT: c
        }, e.fn.swipetp.pageScroll = {
            NONE: d,
            HORIZONTAL: v,
            VERTICAL: b,
            AUTO: u
        }, e.fn.swipetp.fingers = {
            ONE: 1,
            TWO: 2,
            THREE: 3,
            ALL: y
        }
    }), "undefined" == typeof console) {
    var console = {};
    console.log = console.error = console.info = console.debug = console.warn = console.trace = console.dir = console.dirxml = console.group = console.groupEnd = console.time = console.timeEnd = console.assert = console.profile = console.groupCollapsed = function() {}
}
if (1 == window.tplogs) try {
    console.groupCollapsed("ThemePunch GreenSocks Logs")
} catch (e) {}
var oldgs = window.GreenSockGlobals;
oldgs_queue = window._gsQueue;
var punchgs = window.GreenSockGlobals = {};
if (1 == window.tplogs) try {
    console.info("Build GreenSock SandBox for ThemePunch Plugins"), console.info("GreenSock TweenLite Engine Initalised by ThemePunch Plugin")
} catch (e) {}! function(e, t) {
    "use strict";
    var i = e.GreenSockGlobals = e.GreenSockGlobals || e;
    if (!i.TweenLite) {
        var n, o, r, s, a, l = function(e) {
                var t, n = e.split("."),
                    o = i;
                for (t = 0; n.length > t; t++) o[n[t]] = o = o[n[t]] || {};
                return o
            },
            c = l("com.greensock"),
            d = 1e-10,
            u = function(e) {
                var t, i = [],
                    n = e.length;
                for (t = 0; t !== n; i.push(e[t++]));
                return i
            },
            p = function() {},
            h = function() {
                var e = Object.prototype.toString,
                    t = e.call([]);
                return function(i) {
                    return null != i && (i instanceof Array || "object" == typeof i && !!i.push && e.call(i) === t)
                }
            }(),
            f = {},
            g = function(n, o, r, s) {
                this.sc = f[n] ? f[n].sc : [], f[n] = this, this.gsClass = null, this.func = r;
                var a = [];
                this.check = function(c) {
                    for (var d, u, p, h, m, v = o.length, b = v; --v > -1;)(d = f[o[v]] || new g(o[v], [])).gsClass ? (a[v] = d.gsClass, b--) : c && d.sc.push(this);
                    if (0 === b && r)
                        for (u = ("com.greensock." + n).split("."), p = u.pop(), h = l(u.join("."))[p] = this.gsClass = r.apply(r, a), s && (i[p] = h, m = "undefined" != typeof module && module.exports, !m && "function" == typeof define && define.amd ? define((e.GreenSockAMDPath ? e.GreenSockAMDPath + "/" : "") + n.split(".").pop(), [], function() {
                                return h
                            }) : n === t && m && (module.exports = h)), v = 0; this.sc.length > v; v++) this.sc[v].check()
                }, this.check(!0)
            },
            m = e._gsDefine = function(e, t, i, n) {
                return new g(e, t, i, n)
            },
            v = c._class = function(e, t, i) {
                return t = t || function() {}, m(e, [], function() {
                    return t
                }, i), t
            };
        m.globals = i;
        var b = [0, 0, 1, 1],
            y = [],
            w = v("easing.Ease", function(e, t, i, n) {
                this._func = e, this._type = i || 0, this._power = n || 0, this._params = t ? b.concat(t) : b
            }, !0),
            x = w.map = {},
            T = w.register = function(e, t, i, n) {
                for (var o, r, s, a, l = t.split(","), d = l.length, u = (i || "easeIn,easeOut,easeInOut").split(","); --d > -1;)
                    for (r = l[d], o = n ? v("easing." + r, null, !0) : c.easing[r] || {}, s = u.length; --s > -1;) a = u[s], x[r + "." + a] = x[a + r] = o[a] = e.getRatio ? e : e[a] || new e
            };
        for (r = w.prototype, r._calcEnd = !1, r.getRatio = function(e) {
                if (this._func) return this._params[0] = e, this._func.apply(null, this._params);
                var t = this._type,
                    i = this._power,
                    n = 1 === t ? 1 - e : 2 === t ? e : .5 > e ? 2 * e : 2 * (1 - e);
                return 1 === i ? n *= n : 2 === i ? n *= n * n : 3 === i ? n *= n * n * n : 4 === i && (n *= n * n * n * n), 1 === t ? 1 - n : 2 === t ? n : .5 > e ? n / 2 : 1 - n / 2
            }, n = ["Linear", "Quad", "Cubic", "Quart", "Quint,Strong"], o = n.length; --o > -1;) r = n[o] + ",Power" + o, T(new w(null, null, 1, o), r, "easeOut", !0), T(new w(null, null, 2, o), r, "easeIn" + (0 === o ? ",easeNone" : "")), T(new w(null, null, 3, o), r, "easeInOut");
        x.linear = c.easing.Linear.easeIn, x.swing = c.easing.Quad.easeInOut;
        var _ = v("events.EventDispatcher", function(e) {
            this._listeners = {}, this._eventTarget = e || this
        });
        r = _.prototype, r.addEventListener = function(e, t, i, n, o) {
            o = o || 0;
            var r, l, c = this._listeners[e],
                d = 0;
            for (null == c && (this._listeners[e] = c = []), l = c.length; --l > -1;) r = c[l], r.c === t && r.s === i ? c.splice(l, 1) : 0 === d && o > r.pr && (d = l + 1);
            c.splice(d, 0, {
                c: t,
                s: i,
                up: n,
                pr: o
            }), this !== s || a || s.wake()
        }, r.removeEventListener = function(e, t) {
            var i, n = this._listeners[e];
            if (n)
                for (i = n.length; --i > -1;)
                    if (n[i].c === t) return void n.splice(i, 1)
        }, r.dispatchEvent = function(e) {
            var t, i, n, o = this._listeners[e];
            if (o)
                for (t = o.length, i = this._eventTarget; --t > -1;) n = o[t], n && (n.up ? n.c.call(n.s || i, {
                    type: e,
                    target: i
                }) : n.c.call(n.s || i))
        };
        var k = e.requestAnimationFrame,
            S = e.cancelAnimationFrame,
            C = Date.now || function() {
                return (new Date).getTime()
            },
            $ = C();
        for (n = ["ms", "moz", "webkit", "o"], o = n.length; --o > -1 && !k;) k = e[n[o] + "RequestAnimationFrame"], S = e[n[o] + "CancelAnimationFrame"] || e[n[o] + "CancelRequestAnimationFrame"];
        v("Ticker", function(e, t) {
            var i, n, o, r, l, c = this,
                u = C(),
                h = t !== !1 && k,
                f = 500,
                g = 33,
                m = "tick",
                v = function(e) {
                    var t, s, a = C() - $;
                    a > f && (u += a - g), $ += a, c.time = ($ - u) / 1e3, t = c.time - l, (!i || t > 0 || e === !0) && (c.frame++, l += t + (t >= r ? .004 : r - t), s = !0), e !== !0 && (o = n(v)), s && c.dispatchEvent(m)
                };
            _.call(c), c.time = c.frame = 0, c.tick = function() {
                v(!0)
            }, c.lagSmoothing = function(e, t) {
                f = e || 1 / d, g = Math.min(t, f, 0)
            }, c.sleep = function() {
                null != o && (h && S ? S(o) : clearTimeout(o), n = p, o = null, c === s && (a = !1))
            }, c.wake = function() {
                null !== o ? c.sleep() : c.frame > 10 && ($ = C() - f + 5), n = 0 === i ? p : h && k ? k : function(e) {
                    return setTimeout(e, 0 | 1e3 * (l - c.time) + 1)
                }, c === s && (a = !0), v(2)
            }, c.fps = function(e) {
                return arguments.length ? (i = e, r = 1 / (i || 60), l = this.time + r, void c.wake()) : i
            }, c.useRAF = function(e) {
                return arguments.length ? (c.sleep(), h = e, void c.fps(i)) : h
            }, c.fps(e), setTimeout(function() {
                h && 5 > c.frame && c.useRAF(!1)
            }, 1500)
        }), r = c.Ticker.prototype = new c.events.EventDispatcher, r.constructor = c.Ticker;
        var P = v("core.Animation", function(e, t) {
            if (this.vars = t = t || {}, this._duration = this._totalDuration = e || 0, this._delay = Number(t.delay) || 0, this._timeScale = 1, this._active = t.immediateRender === !0, this.data = t.data, this._reversed = t.reversed === !0, V) {
                a || s.wake();
                var i = this.vars.useFrames ? G : V;
                i.add(this, i._time), this.vars.paused && this.paused(!0);
            }
        });
        s = P.ticker = new c.Ticker, r = P.prototype, r._dirty = r._gc = r._initted = r._paused = !1, r._totalTime = r._time = 0, r._rawPrevTime = -1, r._next = r._last = r._onUpdate = r._timeline = r.timeline = null, r._paused = !1;
        var A = function() {
            a && C() - $ > 2e3 && s.wake(), setTimeout(A, 2e3)
        };
        A(), r.play = function(e, t) {
            return null != e && this.seek(e, t), this.reversed(!1).paused(!1)
        }, r.pause = function(e, t) {
            return null != e && this.seek(e, t), this.paused(!0)
        }, r.resume = function(e, t) {
            return null != e && this.seek(e, t), this.paused(!1)
        }, r.seek = function(e, t) {
            return this.totalTime(Number(e), t !== !1)
        }, r.restart = function(e, t) {
            return this.reversed(!1).paused(!1).totalTime(e ? -this._delay : 0, t !== !1, !0)
        }, r.reverse = function(e, t) {
            return null != e && this.seek(e || this.totalDuration(), t), this.reversed(!0).paused(!1)
        }, r.render = function() {}, r.invalidate = function() {
            return this._time = this._totalTime = 0, this._initted = this._gc = !1, this._rawPrevTime = -1, (this._gc || !this.timeline) && this._enabled(!0), this
        }, r.isActive = function() {
            var e, t = this._timeline,
                i = this._startTime;
            return !t || !this._gc && !this._paused && t.isActive() && (e = t.rawTime()) >= i && i + this.totalDuration() / this._timeScale > e
        }, r._enabled = function(e, t) {
            return a || s.wake(), this._gc = !e, this._active = this.isActive(), t !== !0 && (e && !this.timeline ? this._timeline.add(this, this._startTime - this._delay) : !e && this.timeline && this._timeline._remove(this, !0)), !1
        }, r._kill = function() {
            return this._enabled(!1, !1)
        }, r.kill = function(e, t) {
            return this._kill(e, t), this
        }, r._uncache = function(e) {
            for (var t = e ? this : this.timeline; t;) t._dirty = !0, t = t.timeline;
            return this
        }, r._swapSelfInParams = function(e) {
            for (var t = e.length, i = e.concat(); --t > -1;) "{self}" === e[t] && (i[t] = this);
            return i
        }, r._callback = function(e) {
            var t = this.vars;
            t[e].apply(t[e + "Scope"] || t.callbackScope || this, t[e + "Params"] || y)
        }, r.eventCallback = function(e, t, i, n) {
            if ("on" === (e || "").substr(0, 2)) {
                var o = this.vars;
                if (1 === arguments.length) return o[e];
                null == t ? delete o[e] : (o[e] = t, o[e + "Params"] = h(i) && -1 !== i.join("").indexOf("{self}") ? this._swapSelfInParams(i) : i, o[e + "Scope"] = n), "onUpdate" === e && (this._onUpdate = t)
            }
            return this
        }, r.delay = function(e) {
            return arguments.length ? (this._timeline.smoothChildTiming && this.startTime(this._startTime + e - this._delay), this._delay = e, this) : this._delay
        }, r.duration = function(e) {
            return arguments.length ? (this._duration = this._totalDuration = e, this._uncache(!0), this._timeline.smoothChildTiming && this._time > 0 && this._time < this._duration && 0 !== e && this.totalTime(this._totalTime * (e / this._duration), !0), this) : (this._dirty = !1, this._duration)
        }, r.totalDuration = function(e) {
            return this._dirty = !1, arguments.length ? this.duration(e) : this._totalDuration
        }, r.time = function(e, t) {
            return arguments.length ? (this._dirty && this.totalDuration(), this.totalTime(e > this._duration ? this._duration : e, t)) : this._time
        }, r.totalTime = function(e, t, i) {
            if (a || s.wake(), !arguments.length) return this._totalTime;
            if (this._timeline) {
                if (0 > e && !i && (e += this.totalDuration()), this._timeline.smoothChildTiming) {
                    this._dirty && this.totalDuration();
                    var n = this._totalDuration,
                        o = this._timeline;
                    if (e > n && !i && (e = n), this._startTime = (this._paused ? this._pauseTime : o._time) - (this._reversed ? n - e : e) / this._timeScale, o._dirty || this._uncache(!1), o._timeline)
                        for (; o._timeline;) o._timeline._time !== (o._startTime + o._totalTime) / o._timeScale && o.totalTime(o._totalTime, !0), o = o._timeline
                }
                this._gc && this._enabled(!0, !1), (this._totalTime !== e || 0 === this._duration) && (D.length && Y(), this.render(e, t, !1), D.length && Y())
            }
            return this
        }, r.progress = r.totalProgress = function(e, t) {
            var i = this.duration();
            return arguments.length ? this.totalTime(i * e, t) : i ? this._time / i : this.ratio
        }, r.startTime = function(e) {
            return arguments.length ? (e !== this._startTime && (this._startTime = e, this.timeline && this.timeline._sortChildren && this.timeline.add(this, e - this._delay)), this) : this._startTime
        }, r.endTime = function(e) {
            return this._startTime + (0 != e ? this.totalDuration() : this.duration()) / this._timeScale
        }, r.timeScale = function(e) {
            if (!arguments.length) return this._timeScale;
            if (e = e || d, this._timeline && this._timeline.smoothChildTiming) {
                var t = this._pauseTime,
                    i = t || 0 === t ? t : this._timeline.totalTime();
                this._startTime = i - (i - this._startTime) * this._timeScale / e
            }
            return this._timeScale = e, this._uncache(!1)
        }, r.reversed = function(e) {
            return arguments.length ? (e != this._reversed && (this._reversed = e, this.totalTime(this._timeline && !this._timeline.smoothChildTiming ? this.totalDuration() - this._totalTime : this._totalTime, !0)), this) : this._reversed
        }, r.paused = function(e) {
            if (!arguments.length) return this._paused;
            var t, i, n = this._timeline;
            return e != this._paused && n && (a || e || s.wake(), t = n.rawTime(), i = t - this._pauseTime, !e && n.smoothChildTiming && (this._startTime += i, this._uncache(!1)), this._pauseTime = e ? t : null, this._paused = e, this._active = this.isActive(), !e && 0 !== i && this._initted && this.duration() && (t = n.smoothChildTiming ? this._totalTime : (t - this._startTime) / this._timeScale, this.render(t, t === this._totalTime, !0))), this._gc && !e && this._enabled(!0, !1), this
        };
        var O = v("core.SimpleTimeline", function(e) {
            P.call(this, 0, e), this.autoRemoveChildren = this.smoothChildTiming = !0
        });
        r = O.prototype = new P, r.constructor = O, r.kill()._gc = !1, r._first = r._last = r._recent = null, r._sortChildren = !1, r.add = r.insert = function(e, t) {
            var i, n;
            if (e._startTime = Number(t || 0) + e._delay, e._paused && this !== e._timeline && (e._pauseTime = e._startTime + (this.rawTime() - e._startTime) / e._timeScale), e.timeline && e.timeline._remove(e, !0), e.timeline = e._timeline = this, e._gc && e._enabled(!0, !0), i = this._last, this._sortChildren)
                for (n = e._startTime; i && i._startTime > n;) i = i._prev;
            return i ? (e._next = i._next, i._next = e) : (e._next = this._first, this._first = e), e._next ? e._next._prev = e : this._last = e, e._prev = i, this._recent = e, this._timeline && this._uncache(!0), this
        }, r._remove = function(e, t) {
            return e.timeline === this && (t || e._enabled(!1, !0), e._prev ? e._prev._next = e._next : this._first === e && (this._first = e._next), e._next ? e._next._prev = e._prev : this._last === e && (this._last = e._prev), e._next = e._prev = e.timeline = null, e === this._recent && (this._recent = this._last), this._timeline && this._uncache(!0)), this
        }, r.render = function(e, t, i) {
            var n, o = this._first;
            for (this._totalTime = this._time = this._rawPrevTime = e; o;) n = o._next, (o._active || e >= o._startTime && !o._paused) && (o._reversed ? o.render((o._dirty ? o.totalDuration() : o._totalDuration) - (e - o._startTime) * o._timeScale, t, i) : o.render((e - o._startTime) * o._timeScale, t, i)), o = n
        }, r.rawTime = function() {
            return a || s.wake(), this._totalTime
        };
        var E = v("TweenLite", function(t, i, n) {
                if (P.call(this, i, n), this.render = E.prototype.render, null == t) throw "Cannot tween a null target.";
                this.target = t = "string" != typeof t ? t : E.selector(t) || t;
                var o, r, s, a = t.jquery || t.length && t !== e && t[0] && (t[0] === e || t[0].nodeType && t[0].style && !t.nodeType),
                    l = this.vars.overwrite;
                if (this._overwrite = l = null == l ? X[E.defaultOverwrite] : "number" == typeof l ? l >> 0 : X[l], (a || t instanceof Array || t.push && h(t)) && "number" != typeof t[0])
                    for (this._targets = s = u(t), this._propLookup = [], this._siblings = [], o = 0; s.length > o; o++) r = s[o], r ? "string" != typeof r ? r.length && r !== e && r[0] && (r[0] === e || r[0].nodeType && r[0].style && !r.nodeType) ? (s.splice(o--, 1), this._targets = s = s.concat(u(r))) : (this._siblings[o] = Q(r, this, !1), 1 === l && this._siblings[o].length > 1 && J(r, this, null, 1, this._siblings[o])) : (r = s[o--] = E.selector(r), "string" == typeof r && s.splice(o + 1, 1)) : s.splice(o--, 1);
                else this._propLookup = {}, this._siblings = Q(t, this, !1), 1 === l && this._siblings.length > 1 && J(t, this, null, 1, this._siblings);
                (this.vars.immediateRender || 0 === i && 0 === this._delay && this.vars.immediateRender !== !1) && (this._time = -d, this.render(-this._delay))
            }, !0),
            M = function(t) {
                return t && t.length && t !== e && t[0] && (t[0] === e || t[0].nodeType && t[0].style && !t.nodeType)
            },
            I = function(e, t) {
                var i, n = {};
                for (i in e) q[i] || i in t && "transform" !== i && "x" !== i && "y" !== i && "width" !== i && "height" !== i && "className" !== i && "border" !== i || !(!H[i] || H[i] && H[i]._autoCSS) || (n[i] = e[i], delete e[i]);
                e.css = n
            };
        r = E.prototype = new P, r.constructor = E, r.kill()._gc = !1, r.ratio = 0, r._firstPT = r._targets = r._overwrittenProps = r._startAt = null, r._notifyPluginsOfEnabled = r._lazy = !1, E.version = "1.18.0", E.defaultEase = r._ease = new w(null, null, 1, 1), E.defaultOverwrite = "auto", E.ticker = s, E.autoSleep = 120, E.lagSmoothing = function(e, t) {
            s.lagSmoothing(e, t)
        }, E.selector = e.$ || e.jQuery || function(t) {
            var i = e.$ || e.jQuery;
            return i ? (E.selector = i, i(t)) : "undefined" == typeof document ? t : document.querySelectorAll ? document.querySelectorAll(t) : document.getElementById("#" === t.charAt(0) ? t.substr(1) : t)
        };
        var D = [],
            j = {},
            N = /(?:(-|-=|\+=)?\d*\.?\d*(?:e[\-+]?\d+)?)[0-9]/gi,
            L = function(e) {
                for (var t, i = this._firstPT, n = 1e-6; i;) t = i.blob ? e ? this.join("") : this.start : i.c * e + i.s, i.r ? t = Math.round(t) : n > t && t > -n && (t = 0), i.f ? i.fp ? i.t[i.p](i.fp, t) : i.t[i.p](t) : i.t[i.p] = t, i = i._next
            },
            z = function(e, t, i, n) {
                var o, r, s, a, l, c, d, u = [e, t],
                    p = 0,
                    h = "",
                    f = 0;
                for (u.start = e, i && (i(u), e = u[0], t = u[1]), u.length = 0, o = e.match(N) || [], r = t.match(N) || [], n && (n._next = null, n.blob = 1, u._firstPT = n), l = r.length, a = 0; l > a; a++) d = r[a], c = t.substr(p, t.indexOf(d, p) - p), h += c || !a ? c : ",", p += c.length, f ? f = (f + 1) % 5 : "rgba(" === c.substr(-5) && (f = 1), d === o[a] || a >= o.length ? h += d : (h && (u.push(h), h = ""), s = parseFloat(o[a]), u.push(s), u._firstPT = {
                    _next: u._firstPT,
                    t: u,
                    p: u.length - 1,
                    s: s,
                    c: ("=" === d.charAt(1) ? parseInt(d.charAt(0) + "1", 10) * parseFloat(d.substr(2)) : parseFloat(d) - s) || 0,
                    f: 0,
                    r: f && 4 > f
                }), p += d.length;
                return h += t.substr(p), h && u.push(h), u.setRatio = L, u
            },
            F = function(e, t, i, n, o, r, s, a) {
                var l, c, d = "get" === i ? e[t] : i,
                    u = typeof e[t],
                    p = "string" == typeof n && "=" === n.charAt(1),
                    h = {
                        t: e,
                        p: t,
                        s: d,
                        f: "function" === u,
                        pg: 0,
                        n: o || t,
                        r: r,
                        pr: 0,
                        c: p ? parseInt(n.charAt(0) + "1", 10) * parseFloat(n.substr(2)) : parseFloat(n) - d || 0
                    };
                return "number" !== u && ("function" === u && "get" === i && (c = t.indexOf("set") || "function" != typeof e["get" + t.substr(3)] ? t : "get" + t.substr(3), h.s = d = s ? e[c](s) : e[c]()), "string" == typeof d && (s || isNaN(d)) ? (h.fp = s, l = z(d, n, a || E.defaultStringFilter, h), h = {
                    t: l,
                    p: "setRatio",
                    s: 0,
                    c: 1,
                    f: 2,
                    pg: 0,
                    n: o || t,
                    pr: 0
                }) : p || (h.c = parseFloat(n) - parseFloat(d) || 0)), h.c ? ((h._next = this._firstPT) && (h._next._prev = h), this._firstPT = h, h) : void 0
            },
            R = E._internals = {
                isArray: h,
                isSelector: M,
                lazyTweens: D,
                blobDif: z
            },
            H = E._plugins = {},
            W = R.tweenLookup = {},
            B = 0,
            q = R.reservedProps = {
                ease: 1,
                delay: 1,
                overwrite: 1,
                onComplete: 1,
                onCompleteParams: 1,
                onCompleteScope: 1,
                useFrames: 1,
                runBackwards: 1,
                startAt: 1,
                onUpdate: 1,
                onUpdateParams: 1,
                onUpdateScope: 1,
                onStart: 1,
                onStartParams: 1,
                onStartScope: 1,
                onReverseComplete: 1,
                onReverseCompleteParams: 1,
                onReverseCompleteScope: 1,
                onRepeat: 1,
                onRepeatParams: 1,
                onRepeatScope: 1,
                easeParams: 1,
                yoyo: 1,
                immediateRender: 1,
                repeat: 1,
                repeatDelay: 1,
                data: 1,
                paused: 1,
                reversed: 1,
                autoCSS: 1,
                lazy: 1,
                onOverwrite: 1,
                callbackScope: 1,
                stringFilter: 1
            },
            X = {
                none: 0,
                all: 1,
                auto: 2,
                concurrent: 3,
                allOnStart: 4,
                preexisting: 5,
                "true": 1,
                "false": 0
            },
            G = P._rootFramesTimeline = new O,
            V = P._rootTimeline = new O,
            U = 30,
            Y = R.lazyRender = function() {
                var e, t = D.length;
                for (j = {}; --t > -1;) e = D[t], e && e._lazy !== !1 && (e.render(e._lazy[0], e._lazy[1], !0), e._lazy = !1);
                D.length = 0
            };
        V._startTime = s.time, G._startTime = s.frame, V._active = G._active = !0, setTimeout(Y, 1), P._updateRoot = E.render = function() {
            var e, t, i;
            if (D.length && Y(), V.render((s.time - V._startTime) * V._timeScale, !1, !1), G.render((s.frame - G._startTime) * G._timeScale, !1, !1), D.length && Y(), s.frame >= U) {
                U = s.frame + (parseInt(E.autoSleep, 10) || 120);
                for (i in W) {
                    for (t = W[i].tweens, e = t.length; --e > -1;) t[e]._gc && t.splice(e, 1);
                    0 === t.length && delete W[i]
                }
                if (i = V._first, (!i || i._paused) && E.autoSleep && !G._first && 1 === s._listeners.tick.length) {
                    for (; i && i._paused;) i = i._next;
                    i || s.sleep()
                }
            }
        }, s.addEventListener("tick", P._updateRoot);
        var Q = function(e, t, i) {
                var n, o, r = e._gsTweenID;
                if (W[r || (e._gsTweenID = r = "t" + B++)] || (W[r] = {
                        target: e,
                        tweens: []
                    }), t && (n = W[r].tweens, n[o = n.length] = t, i))
                    for (; --o > -1;) n[o] === t && n.splice(o, 1);
                return W[r].tweens
            },
            Z = function(e, t, i, n) {
                var o, r, s = e.vars.onOverwrite;
                return s && (o = s(e, t, i, n)), s = E.onOverwrite, s && (r = s(e, t, i, n)), o !== !1 && r !== !1
            },
            J = function(e, t, i, n, o) {
                var r, s, a, l;
                if (1 === n || n >= 4) {
                    for (l = o.length, r = 0; l > r; r++)
                        if ((a = o[r]) !== t) a._gc || a._kill(null, e, t) && (s = !0);
                        else if (5 === n) break;
                    return s
                }
                var c, u = t._startTime + d,
                    p = [],
                    h = 0,
                    f = 0 === t._duration;
                for (r = o.length; --r > -1;)(a = o[r]) === t || a._gc || a._paused || (a._timeline !== t._timeline ? (c = c || K(t, 0, f), 0 === K(a, c, f) && (p[h++] = a)) : u >= a._startTime && a._startTime + a.totalDuration() / a._timeScale > u && ((f || !a._initted) && 2e-10 >= u - a._startTime || (p[h++] = a)));
                for (r = h; --r > -1;)
                    if (a = p[r], 2 === n && a._kill(i, e, t) && (s = !0), 2 !== n || !a._firstPT && a._initted) {
                        if (2 !== n && !Z(a, t)) continue;
                        a._enabled(!1, !1) && (s = !0)
                    }
                return s
            },
            K = function(e, t, i) {
                for (var n = e._timeline, o = n._timeScale, r = e._startTime; n._timeline;) {
                    if (r += n._startTime, o *= n._timeScale, n._paused) return -100;
                    n = n._timeline
                }
                return r /= o, r > t ? r - t : i && r === t || !e._initted && 2 * d > r - t ? d : (r += e.totalDuration() / e._timeScale / o) > t + d ? 0 : r - t - d
            };
        r._init = function() {
            var e, t, i, n, o, r = this.vars,
                s = this._overwrittenProps,
                a = this._duration,
                l = !!r.immediateRender,
                c = r.ease;
            if (r.startAt) {
                this._startAt && (this._startAt.render(-1, !0), this._startAt.kill()), o = {};
                for (n in r.startAt) o[n] = r.startAt[n];
                if (o.overwrite = !1, o.immediateRender = !0, o.lazy = l && r.lazy !== !1, o.startAt = o.delay = null, this._startAt = E.to(this.target, 0, o), l)
                    if (this._time > 0) this._startAt = null;
                    else if (0 !== a) return
            } else if (r.runBackwards && 0 !== a)
                if (this._startAt) this._startAt.render(-1, !0), this._startAt.kill(), this._startAt = null;
                else {
                    0 !== this._time && (l = !1), i = {};
                    for (n in r) q[n] && "autoCSS" !== n || (i[n] = r[n]);
                    if (i.overwrite = 0, i.data = "isFromStart", i.lazy = l && r.lazy !== !1, i.immediateRender = l, this._startAt = E.to(this.target, 0, i), l) {
                        if (0 === this._time) return
                    } else this._startAt._init(), this._startAt._enabled(!1), this.vars.immediateRender && (this._startAt = null)
                }
            if (this._ease = c = c ? c instanceof w ? c : "function" == typeof c ? new w(c, r.easeParams) : x[c] || E.defaultEase : E.defaultEase, r.easeParams instanceof Array && c.config && (this._ease = c.config.apply(c, r.easeParams)), this._easeType = this._ease._type, this._easePower = this._ease._power, this._firstPT = null, this._targets)
                for (e = this._targets.length; --e > -1;) this._initProps(this._targets[e], this._propLookup[e] = {}, this._siblings[e], s ? s[e] : null) && (t = !0);
            else t = this._initProps(this.target, this._propLookup, this._siblings, s);
            if (t && E._onPluginEvent("_onInitAllProps", this), s && (this._firstPT || "function" != typeof this.target && this._enabled(!1, !1)), r.runBackwards)
                for (i = this._firstPT; i;) i.s += i.c, i.c = -i.c, i = i._next;
            this._onUpdate = r.onUpdate, this._initted = !0
        }, r._initProps = function(t, i, n, o) {
            var r, s, a, l, c, d;
            if (null == t) return !1;
            j[t._gsTweenID] && Y(), this.vars.css || t.style && t !== e && t.nodeType && H.css && this.vars.autoCSS !== !1 && I(this.vars, t);
            for (r in this.vars)
                if (d = this.vars[r], q[r]) d && (d instanceof Array || d.push && h(d)) && -1 !== d.join("").indexOf("{self}") && (this.vars[r] = d = this._swapSelfInParams(d, this));
                else if (H[r] && (l = new H[r])._onInitTween(t, this.vars[r], this)) {
                for (this._firstPT = c = {
                        _next: this._firstPT,
                        t: l,
                        p: "setRatio",
                        s: 0,
                        c: 1,
                        f: 1,
                        n: r,
                        pg: 1,
                        pr: l._priority
                    }, s = l._overwriteProps.length; --s > -1;) i[l._overwriteProps[s]] = this._firstPT;
                (l._priority || l._onInitAllProps) && (a = !0), (l._onDisable || l._onEnable) && (this._notifyPluginsOfEnabled = !0), c._next && (c._next._prev = c)
            } else i[r] = F.call(this, t, r, "get", d, r, 0, null, this.vars.stringFilter);
            return o && this._kill(o, t) ? this._initProps(t, i, n, o) : this._overwrite > 1 && this._firstPT && n.length > 1 && J(t, this, i, this._overwrite, n) ? (this._kill(i, t), this._initProps(t, i, n, o)) : (this._firstPT && (this.vars.lazy !== !1 && this._duration || this.vars.lazy && !this._duration) && (j[t._gsTweenID] = !0), a)
        }, r.render = function(e, t, i) {
            var n, o, r, s, a = this._time,
                l = this._duration,
                c = this._rawPrevTime;
            if (e >= l) this._totalTime = this._time = l, this.ratio = this._ease._calcEnd ? this._ease.getRatio(1) : 1, this._reversed || (n = !0, o = "onComplete", i = i || this._timeline.autoRemoveChildren), 0 === l && (this._initted || !this.vars.lazy || i) && (this._startTime === this._timeline._duration && (e = 0), (0 === e || 0 > c || c === d && "isPause" !== this.data) && c !== e && (i = !0, c > d && (o = "onReverseComplete")), this._rawPrevTime = s = !t || e || c === e ? e : d);
            else if (1e-7 > e) this._totalTime = this._time = 0, this.ratio = this._ease._calcEnd ? this._ease.getRatio(0) : 0, (0 !== a || 0 === l && c > 0) && (o = "onReverseComplete", n = this._reversed), 0 > e && (this._active = !1, 0 === l && (this._initted || !this.vars.lazy || i) && (c >= 0 && (c !== d || "isPause" !== this.data) && (i = !0), this._rawPrevTime = s = !t || e || c === e ? e : d)), this._initted || (i = !0);
            else if (this._totalTime = this._time = e, this._easeType) {
                var u = e / l,
                    p = this._easeType,
                    h = this._easePower;
                (1 === p || 3 === p && u >= .5) && (u = 1 - u), 3 === p && (u *= 2), 1 === h ? u *= u : 2 === h ? u *= u * u : 3 === h ? u *= u * u * u : 4 === h && (u *= u * u * u * u), this.ratio = 1 === p ? 1 - u : 2 === p ? u : .5 > e / l ? u / 2 : 1 - u / 2
            } else this.ratio = this._ease.getRatio(e / l);
            if (this._time !== a || i) {
                if (!this._initted) {
                    if (this._init(), !this._initted || this._gc) return;
                    if (!i && this._firstPT && (this.vars.lazy !== !1 && this._duration || this.vars.lazy && !this._duration)) return this._time = this._totalTime = a, this._rawPrevTime = c, D.push(this), void(this._lazy = [e, t]);
                    this._time && !n ? this.ratio = this._ease.getRatio(this._time / l) : n && this._ease._calcEnd && (this.ratio = this._ease.getRatio(0 === this._time ? 0 : 1))
                }
                for (this._lazy !== !1 && (this._lazy = !1), this._active || !this._paused && this._time !== a && e >= 0 && (this._active = !0), 0 === a && (this._startAt && (e >= 0 ? this._startAt.render(e, t, i) : o || (o = "_dummyGS")), this.vars.onStart && (0 !== this._time || 0 === l) && (t || this._callback("onStart"))), r = this._firstPT; r;) r.f ? r.t[r.p](r.c * this.ratio + r.s) : r.t[r.p] = r.c * this.ratio + r.s, r = r._next;
                this._onUpdate && (0 > e && this._startAt && e !== -1e-4 && this._startAt.render(e, t, i), t || (this._time !== a || n) && this._callback("onUpdate")), o && (!this._gc || i) && (0 > e && this._startAt && !this._onUpdate && e !== -1e-4 && this._startAt.render(e, t, i), n && (this._timeline.autoRemoveChildren && this._enabled(!1, !1), this._active = !1), !t && this.vars[o] && this._callback(o), 0 === l && this._rawPrevTime === d && s !== d && (this._rawPrevTime = 0))
            }
        }, r._kill = function(e, t, i) {
            if ("all" === e && (e = null), null == e && (null == t || t === this.target)) return this._lazy = !1, this._enabled(!1, !1);
            t = "string" != typeof t ? t || this._targets || this.target : E.selector(t) || t;
            var n, o, r, s, a, l, c, d, u, p = i && this._time && i._startTime === this._startTime && this._timeline === i._timeline;
            if ((h(t) || M(t)) && "number" != typeof t[0])
                for (n = t.length; --n > -1;) this._kill(e, t[n], i) && (l = !0);
            else {
                if (this._targets) {
                    for (n = this._targets.length; --n > -1;)
                        if (t === this._targets[n]) {
                            a = this._propLookup[n] || {}, this._overwrittenProps = this._overwrittenProps || [], o = this._overwrittenProps[n] = e ? this._overwrittenProps[n] || {} : "all";
                            break
                        }
                } else {
                    if (t !== this.target) return !1;
                    a = this._propLookup, o = this._overwrittenProps = e ? this._overwrittenProps || {} : "all"
                }
                if (a) {
                    if (c = e || a, d = e !== o && "all" !== o && e !== a && ("object" != typeof e || !e._tempKill), i && (E.onOverwrite || this.vars.onOverwrite)) {
                        for (r in c) a[r] && (u || (u = []), u.push(r));
                        if ((u || !e) && !Z(this, i, t, u)) return !1
                    }
                    for (r in c)(s = a[r]) && (p && (s.f ? s.t[s.p](s.s) : s.t[s.p] = s.s, l = !0), s.pg && s.t._kill(c) && (l = !0), s.pg && 0 !== s.t._overwriteProps.length || (s._prev ? s._prev._next = s._next : s === this._firstPT && (this._firstPT = s._next), s._next && (s._next._prev = s._prev), s._next = s._prev = null), delete a[r]), d && (o[r] = 1);
                    !this._firstPT && this._initted && this._enabled(!1, !1)
                }
            }
            return l
        }, r.invalidate = function() {
            return this._notifyPluginsOfEnabled && E._onPluginEvent("_onDisable", this), this._firstPT = this._overwrittenProps = this._startAt = this._onUpdate = null, this._notifyPluginsOfEnabled = this._active = this._lazy = !1, this._propLookup = this._targets ? {} : [], P.prototype.invalidate.call(this), this.vars.immediateRender && (this._time = -d, this.render(-this._delay)), this
        }, r._enabled = function(e, t) {
            if (a || s.wake(), e && this._gc) {
                var i, n = this._targets;
                if (n)
                    for (i = n.length; --i > -1;) this._siblings[i] = Q(n[i], this, !0);
                else this._siblings = Q(this.target, this, !0)
            }
            return P.prototype._enabled.call(this, e, t), !(!this._notifyPluginsOfEnabled || !this._firstPT) && E._onPluginEvent(e ? "_onEnable" : "_onDisable", this)
        }, E.to = function(e, t, i) {
            return new E(e, t, i)
        }, E.from = function(e, t, i) {
            return i.runBackwards = !0, i.immediateRender = 0 != i.immediateRender, new E(e, t, i)
        }, E.fromTo = function(e, t, i, n) {
            return n.startAt = i, n.immediateRender = 0 != n.immediateRender && 0 != i.immediateRender, new E(e, t, n)
        }, E.delayedCall = function(e, t, i, n, o) {
            return new E(t, 0, {
                delay: e,
                onComplete: t,
                onCompleteParams: i,
                callbackScope: n,
                onReverseComplete: t,
                onReverseCompleteParams: i,
                immediateRender: !1,
                lazy: !1,
                useFrames: o,
                overwrite: 0
            })
        }, E.set = function(e, t) {
            return new E(e, 0, t)
        }, E.getTweensOf = function(e, t) {
            if (null == e) return [];
            e = "string" != typeof e ? e : E.selector(e) || e;
            var i, n, o, r;
            if ((h(e) || M(e)) && "number" != typeof e[0]) {
                for (i = e.length, n = []; --i > -1;) n = n.concat(E.getTweensOf(e[i], t));
                for (i = n.length; --i > -1;)
                    for (r = n[i], o = i; --o > -1;) r === n[o] && n.splice(i, 1)
            } else
                for (n = Q(e).concat(), i = n.length; --i > -1;)(n[i]._gc || t && !n[i].isActive()) && n.splice(i, 1);
            return n
        }, E.killTweensOf = E.killDelayedCallsTo = function(e, t, i) {
            "object" == typeof t && (i = t, t = !1);
            for (var n = E.getTweensOf(e, t), o = n.length; --o > -1;) n[o]._kill(i, e)
        };
        var ee = v("plugins.TweenPlugin", function(e, t) {
            this._overwriteProps = (e || "").split(","), this._propName = this._overwriteProps[0], this._priority = t || 0, this._super = ee.prototype
        }, !0);
        if (r = ee.prototype, ee.version = "1.18.0", ee.API = 2, r._firstPT = null, r._addTween = F, r.setRatio = L, r._kill = function(e) {
                var t, i = this._overwriteProps,
                    n = this._firstPT;
                if (null != e[this._propName]) this._overwriteProps = [];
                else
                    for (t = i.length; --t > -1;) null != e[i[t]] && i.splice(t, 1);
                for (; n;) null != e[n.n] && (n._next && (n._next._prev = n._prev), n._prev ? (n._prev._next = n._next, n._prev = null) : this._firstPT === n && (this._firstPT = n._next)), n = n._next;
                return !1
            }, r._roundProps = function(e, t) {
                for (var i = this._firstPT; i;)(e[this._propName] || null != i.n && e[i.n.split(this._propName + "_").join("")]) && (i.r = t), i = i._next
            }, E._onPluginEvent = function(e, t) {
                var i, n, o, r, s, a = t._firstPT;
                if ("_onInitAllProps" === e) {
                    for (; a;) {
                        for (s = a._next, n = o; n && n.pr > a.pr;) n = n._next;
                        (a._prev = n ? n._prev : r) ? a._prev._next = a: o = a, (a._next = n) ? n._prev = a : r = a, a = s
                    }
                    a = t._firstPT = o
                }
                for (; a;) a.pg && "function" == typeof a.t[e] && a.t[e]() && (i = !0), a = a._next;
                return i
            }, ee.activate = function(e) {
                for (var t = e.length; --t > -1;) e[t].API === ee.API && (H[(new e[t])._propName] = e[t]);
                return !0
            }, m.plugin = function(e) {
                if (!(e && e.propName && e.init && e.API)) throw "illegal plugin definition.";
                var t, i = e.propName,
                    n = e.priority || 0,
                    o = e.overwriteProps,
                    r = {
                        init: "_onInitTween",
                        set: "setRatio",
                        kill: "_kill",
                        round: "_roundProps",
                        initAll: "_onInitAllProps"
                    },
                    s = v("plugins." + i.charAt(0).toUpperCase() + i.substr(1) + "Plugin", function() {
                        ee.call(this, i, n), this._overwriteProps = o || []
                    }, e.global === !0),
                    a = s.prototype = new ee(i);
                a.constructor = s, s.API = e.API;
                for (t in r) "function" == typeof e[t] && (a[r[t]] = e[t]);
                return s.version = e.version, ee.activate([s]), s
            }, n = e._gsQueue) {
            for (o = 0; n.length > o; o++) n[o]();
            for (r in f) f[r].func || e.console.log("GSAP encountered missing dependency: com.greensock." + r)
        }
        a = !1
    }
}("undefined" != typeof module && module.exports && "undefined" != typeof global ? global : this || window, "TweenLite");
var _gsScope = "undefined" != typeof module && module.exports && "undefined" != typeof global ? global : this || window;
(_gsScope._gsQueue || (_gsScope._gsQueue = [])).push(function() {
        "use strict";
        _gsScope._gsDefine("TimelineLite", ["core.Animation", "core.SimpleTimeline", "TweenLite"], function(e, t, i) {
            var n = function(e) {
                    t.call(this, e), this._labels = {}, this.autoRemoveChildren = this.vars.autoRemoveChildren === !0, this.smoothChildTiming = this.vars.smoothChildTiming === !0, this._sortChildren = !0, this._onUpdate = this.vars.onUpdate;
                    var i, n, o = this.vars;
                    for (n in o) i = o[n], l(i) && -1 !== i.join("").indexOf("{self}") && (o[n] = this._swapSelfInParams(i));
                    l(o.tweens) && this.add(o.tweens, 0, o.align, o.stagger)
                },
                o = 1e-10,
                r = i._internals,
                s = n._internals = {},
                a = r.isSelector,
                l = r.isArray,
                c = r.lazyTweens,
                d = r.lazyRender,
                u = _gsScope._gsDefine.globals,
                p = function(e) {
                    var t, i = {};
                    for (t in e) i[t] = e[t];
                    return i
                },
                h = function(e, t, i) {
                    var n, o, r = e.cycle;
                    for (n in r) o = r[n], e[n] = "function" == typeof o ? o.call(t[i], i) : o[i % o.length];
                    delete e.cycle
                },
                f = s.pauseCallback = function() {},
                g = function(e) {
                    var t, i = [],
                        n = e.length;
                    for (t = 0; t !== n; i.push(e[t++]));
                    return i
                },
                m = n.prototype = new t;
            return n.version = "1.18.0", m.constructor = n, m.kill()._gc = m._forcingPlayhead = m._hasPause = !1, m.to = function(e, t, n, o) {
                var r = n.repeat && u.TweenMax || i;
                return t ? this.add(new r(e, t, n), o) : this.set(e, n, o)
            }, m.from = function(e, t, n, o) {
                return this.add((n.repeat && u.TweenMax || i).from(e, t, n), o)
            }, m.fromTo = function(e, t, n, o, r) {
                var s = o.repeat && u.TweenMax || i;
                return t ? this.add(s.fromTo(e, t, n, o), r) : this.set(e, o, r)
            }, m.staggerTo = function(e, t, o, r, s, l, c, d) {
                var u, f, m = new n({
                        onComplete: l,
                        onCompleteParams: c,
                        callbackScope: d,
                        smoothChildTiming: this.smoothChildTiming
                    }),
                    v = o.cycle;
                for ("string" == typeof e && (e = i.selector(e) || e), e = e || [], a(e) && (e = g(e)), r = r || 0, 0 > r && (e = g(e), e.reverse(), r *= -1), f = 0; e.length > f; f++) u = p(o), u.startAt && (u.startAt = p(u.startAt), u.startAt.cycle && h(u.startAt, e, f)), v && h(u, e, f), m.to(e[f], t, u, f * r);
                return this.add(m, s)
            }, m.staggerFrom = function(e, t, i, n, o, r, s, a) {
                return i.immediateRender = 0 != i.immediateRender, i.runBackwards = !0, this.staggerTo(e, t, i, n, o, r, s, a)
            }, m.staggerFromTo = function(e, t, i, n, o, r, s, a, l) {
                return n.startAt = i, n.immediateRender = 0 != n.immediateRender && 0 != i.immediateRender, this.staggerTo(e, t, n, o, r, s, a, l)
            }, m.call = function(e, t, n, o) {
                return this.add(i.delayedCall(0, e, t, n), o)
            }, m.set = function(e, t, n) {
                return n = this._parseTimeOrLabel(n, 0, !0), null == t.immediateRender && (t.immediateRender = n === this._time && !this._paused), this.add(new i(e, 0, t), n)
            }, n.exportRoot = function(e, t) {
                e = e || {}, null == e.smoothChildTiming && (e.smoothChildTiming = !0);
                var o, r, s = new n(e),
                    a = s._timeline;
                for (null == t && (t = !0), a._remove(s, !0), s._startTime = 0, s._rawPrevTime = s._time = s._totalTime = a._time, o = a._first; o;) r = o._next, t && o instanceof i && o.target === o.vars.onComplete || s.add(o, o._startTime - o._delay), o = r;
                return a.add(s, 0), s
            }, m.add = function(o, r, s, a) {
                var c, d, u, p, h, f;
                if ("number" != typeof r && (r = this._parseTimeOrLabel(r, 0, !0, o)), !(o instanceof e)) {
                    if (o instanceof Array || o && o.push && l(o)) {
                        for (s = s || "normal", a = a || 0, c = r, d = o.length, u = 0; d > u; u++) l(p = o[u]) && (p = new n({
                            tweens: p
                        })), this.add(p, c), "string" != typeof p && "function" != typeof p && ("sequence" === s ? c = p._startTime + p.totalDuration() / p._timeScale : "start" === s && (p._startTime -= p.delay())), c += a;
                        return this._uncache(!0)
                    }
                    if ("string" == typeof o) return this.addLabel(o, r);
                    if ("function" != typeof o) throw "Cannot add " + o + " into the timeline; it is not a tween, timeline, function, or string.";
                    o = i.delayedCall(0, o)
                }
                if (t.prototype.add.call(this, o, r), (this._gc || this._time === this._duration) && !this._paused && this._duration < this.duration())
                    for (h = this, f = h.rawTime() > o._startTime; h._timeline;) f && h._timeline.smoothChildTiming ? h.totalTime(h._totalTime, !0) : h._gc && h._enabled(!0, !1), h = h._timeline;
                return this
            }, m.remove = function(t) {
                if (t instanceof e) {
                    this._remove(t, !1);
                    var i = t._timeline = t.vars.useFrames ? e._rootFramesTimeline : e._rootTimeline;
                    return t._startTime = (t._paused ? t._pauseTime : i._time) - (t._reversed ? t.totalDuration() - t._totalTime : t._totalTime) / t._timeScale, this
                }
                if (t instanceof Array || t && t.push && l(t)) {
                    for (var n = t.length; --n > -1;) this.remove(t[n]);
                    return this
                }
                return "string" == typeof t ? this.removeLabel(t) : this.kill(null, t)
            }, m._remove = function(e, i) {
                t.prototype._remove.call(this, e, i);
                var n = this._last;
                return n ? this._time > n._startTime + n._totalDuration / n._timeScale && (this._time = this.duration(), this._totalTime = this._totalDuration) : this._time = this._totalTime = this._duration = this._totalDuration = 0, this
            }, m.append = function(e, t) {
                return this.add(e, this._parseTimeOrLabel(null, t, !0, e))
            }, m.insert = m.insertMultiple = function(e, t, i, n) {
                return this.add(e, t || 0, i, n)
            }, m.appendMultiple = function(e, t, i, n) {
                return this.add(e, this._parseTimeOrLabel(null, t, !0, e), i, n)
            }, m.addLabel = function(e, t) {
                return this._labels[e] = this._parseTimeOrLabel(t), this
            }, m.addPause = function(e, t, n, o) {
                var r = i.delayedCall(0, f, n, o || this);
                return r.vars.onComplete = r.vars.onReverseComplete = t, r.data = "isPause", this._hasPause = !0, this.add(r, e)
            }, m.removeLabel = function(e) {
                return delete this._labels[e], this
            }, m.getLabelTime = function(e) {
                return null != this._labels[e] ? this._labels[e] : -1
            }, m._parseTimeOrLabel = function(t, i, n, o) {
                var r;
                if (o instanceof e && o.timeline === this) this.remove(o);
                else if (o && (o instanceof Array || o.push && l(o)))
                    for (r = o.length; --r > -1;) o[r] instanceof e && o[r].timeline === this && this.remove(o[r]);
                if ("string" == typeof i) return this._parseTimeOrLabel(i, n && "number" == typeof t && null == this._labels[i] ? t - this.duration() : 0, n);
                if (i = i || 0, "string" != typeof t || !isNaN(t) && null == this._labels[t]) null == t && (t = this.duration());
                else {
                    if (r = t.indexOf("="), -1 === r) return null == this._labels[t] ? n ? this._labels[t] = this.duration() + i : i : this._labels[t] + i;
                    i = parseInt(t.charAt(r - 1) + "1", 10) * Number(t.substr(r + 1)), t = r > 1 ? this._parseTimeOrLabel(t.substr(0, r - 1), 0, n) : this.duration()
                }
                return Number(t) + i
            }, m.seek = function(e, t) {
                return this.totalTime("number" == typeof e ? e : this._parseTimeOrLabel(e), t !== !1)
            }, m.stop = function() {
                return this.paused(!0)
            }, m.gotoAndPlay = function(e, t) {
                return this.play(e, t)
            }, m.gotoAndStop = function(e, t) {
                return this.pause(e, t)
            }, m.render = function(e, t, i) {
                this._gc && this._enabled(!0, !1);
                var n, r, s, a, l, u, p = this._dirty ? this.totalDuration() : this._totalDuration,
                    h = this._time,
                    f = this._startTime,
                    g = this._timeScale,
                    m = this._paused;
                if (e >= p) this._totalTime = this._time = p, this._reversed || this._hasPausedChild() || (r = !0, a = "onComplete", l = !!this._timeline.autoRemoveChildren, 0 === this._duration && (0 === e || 0 > this._rawPrevTime || this._rawPrevTime === o) && this._rawPrevTime !== e && this._first && (l = !0, this._rawPrevTime > o && (a = "onReverseComplete"))), this._rawPrevTime = this._duration || !t || e || this._rawPrevTime === e ? e : o, e = p + 1e-4;
                else if (1e-7 > e)
                    if (this._totalTime = this._time = 0, (0 !== h || 0 === this._duration && this._rawPrevTime !== o && (this._rawPrevTime > 0 || 0 > e && this._rawPrevTime >= 0)) && (a = "onReverseComplete", r = this._reversed), 0 > e) this._active = !1, this._timeline.autoRemoveChildren && this._reversed ? (l = r = !0, a = "onReverseComplete") : this._rawPrevTime >= 0 && this._first && (l = !0), this._rawPrevTime = e;
                    else {
                        if (this._rawPrevTime = this._duration || !t || e || this._rawPrevTime === e ? e : o, 0 === e && r)
                            for (n = this._first; n && 0 === n._startTime;) n._duration || (r = !1), n = n._next;
                        e = 0, this._initted || (l = !0)
                    } else {
                    if (this._hasPause && !this._forcingPlayhead && !t) {
                        if (e >= h)
                            for (n = this._first; n && e >= n._startTime && !u;) n._duration || "isPause" !== n.data || n.ratio || 0 === n._startTime && 0 === this._rawPrevTime || (u = n), n = n._next;
                        else
                            for (n = this._last; n && n._startTime >= e && !u;) n._duration || "isPause" === n.data && n._rawPrevTime > 0 && (u = n), n = n._prev;
                        u && (this._time = e = u._startTime, this._totalTime = e + this._cycle * (this._totalDuration + this._repeatDelay))
                    }
                    this._totalTime = this._time = this._rawPrevTime = e
                }
                if (this._time !== h && this._first || i || l || u) {
                    if (this._initted || (this._initted = !0), this._active || !this._paused && this._time !== h && e > 0 && (this._active = !0), 0 === h && this.vars.onStart && 0 !== this._time && (t || this._callback("onStart")), this._time >= h)
                        for (n = this._first; n && (s = n._next, !this._paused || m);)(n._active || n._startTime <= this._time && !n._paused && !n._gc) && (u === n && this.pause(), n._reversed ? n.render((n._dirty ? n.totalDuration() : n._totalDuration) - (e - n._startTime) * n._timeScale, t, i) : n.render((e - n._startTime) * n._timeScale, t, i)), n = s;
                    else
                        for (n = this._last; n && (s = n._prev, !this._paused || m);) {
                            if (n._active || h >= n._startTime && !n._paused && !n._gc) {
                                if (u === n) {
                                    for (u = n._prev; u && u.endTime() > this._time;) u.render(u._reversed ? u.totalDuration() - (e - u._startTime) * u._timeScale : (e - u._startTime) * u._timeScale, t, i), u = u._prev;
                                    u = null, this.pause()
                                }
                                n._reversed ? n.render((n._dirty ? n.totalDuration() : n._totalDuration) - (e - n._startTime) * n._timeScale, t, i) : n.render((e - n._startTime) * n._timeScale, t, i)
                            }
                            n = s
                        }
                    this._onUpdate && (t || (c.length && d(), this._callback("onUpdate"))), a && (this._gc || (f === this._startTime || g !== this._timeScale) && (0 === this._time || p >= this.totalDuration()) && (r && (c.length && d(), this._timeline.autoRemoveChildren && this._enabled(!1, !1), this._active = !1), !t && this.vars[a] && this._callback(a)))
                }
            }, m._hasPausedChild = function() {
                for (var e = this._first; e;) {
                    if (e._paused || e instanceof n && e._hasPausedChild()) return !0;
                    e = e._next
                }
                return !1
            }, m.getChildren = function(e, t, n, o) {
                o = o || -9999999999;
                for (var r = [], s = this._first, a = 0; s;) o > s._startTime || (s instanceof i ? t !== !1 && (r[a++] = s) : (n !== !1 && (r[a++] = s), e !== !1 && (r = r.concat(s.getChildren(!0, t, n)), a = r.length))), s = s._next;
                return r
            }, m.getTweensOf = function(e, t) {
                var n, o, r = this._gc,
                    s = [],
                    a = 0;
                for (r && this._enabled(!0, !0), n = i.getTweensOf(e), o = n.length; --o > -1;)(n[o].timeline === this || t && this._contains(n[o])) && (s[a++] = n[o]);
                return r && this._enabled(!1, !0), s
            }, m.recent = function() {
                return this._recent
            }, m._contains = function(e) {
                for (var t = e.timeline; t;) {
                    if (t === this) return !0;
                    t = t.timeline
                }
                return !1
            }, m.shiftChildren = function(e, t, i) {
                i = i || 0;
                for (var n, o = this._first, r = this._labels; o;) o._startTime >= i && (o._startTime += e), o = o._next;
                if (t)
                    for (n in r) r[n] >= i && (r[n] += e);
                return this._uncache(!0)
            }, m._kill = function(e, t) {
                if (!e && !t) return this._enabled(!1, !1);
                for (var i = t ? this.getTweensOf(t) : this.getChildren(!0, !0, !1), n = i.length, o = !1; --n > -1;) i[n]._kill(e, t) && (o = !0);
                return o
            }, m.clear = function(e) {
                var t = this.getChildren(!1, !0, !0),
                    i = t.length;
                for (this._time = this._totalTime = 0; --i > -1;) t[i]._enabled(!1, !1);
                return e !== !1 && (this._labels = {}), this._uncache(!0)
            }, m.invalidate = function() {
                for (var t = this._first; t;) t.invalidate(), t = t._next;
                return e.prototype.invalidate.call(this)
            }, m._enabled = function(e, i) {
                if (e === this._gc)
                    for (var n = this._first; n;) n._enabled(e, !0), n = n._next;
                return t.prototype._enabled.call(this, e, i)
            }, m.totalTime = function() {
                this._forcingPlayhead = !0;
                var t = e.prototype.totalTime.apply(this, arguments);
                return this._forcingPlayhead = !1, t
            }, m.duration = function(e) {
                return arguments.length ? (0 !== this.duration() && 0 !== e && this.timeScale(this._duration / e), this) : (this._dirty && this.totalDuration(), this._duration)
            }, m.totalDuration = function(e) {
                if (!arguments.length) {
                    if (this._dirty) {
                        for (var t, i, n = 0, o = this._last, r = 999999999999; o;) t = o._prev, o._dirty && o.totalDuration(), o._startTime > r && this._sortChildren && !o._paused ? this.add(o, o._startTime - o._delay) : r = o._startTime, 0 > o._startTime && !o._paused && (n -= o._startTime, this._timeline.smoothChildTiming && (this._startTime += o._startTime / this._timeScale), this.shiftChildren(-o._startTime, !1, -9999999999), r = 0), i = o._startTime + o._totalDuration / o._timeScale, i > n && (n = i), o = t;
                        this._duration = this._totalDuration = n, this._dirty = !1
                    }
                    return this._totalDuration
                }
                return 0 !== this.totalDuration() && 0 !== e && this.timeScale(this._totalDuration / e), this
            }, m.paused = function(t) {
                if (!t)
                    for (var i = this._first, n = this._time; i;) i._startTime === n && "isPause" === i.data && (i._rawPrevTime = 0), i = i._next;
                return e.prototype.paused.apply(this, arguments)
            }, m.usesFrames = function() {
                for (var t = this._timeline; t._timeline;) t = t._timeline;
                return t === e._rootFramesTimeline
            }, m.rawTime = function() {
                return this._paused ? this._totalTime : (this._timeline.rawTime() - this._startTime) * this._timeScale
            }, n
        }, !0)
    }), _gsScope._gsDefine && _gsScope._gsQueue.pop()(),
    function(e) {
        "use strict";
        var t = function() {
            return (_gsScope.GreenSockGlobals || _gsScope)[e]
        };
        "function" == typeof define && define.amd ? define(["TweenLite"], t) : "undefined" != typeof module && module.exports && (require("./TweenLite.js"), module.exports = t())
    }("TimelineLite");
var _gsScope = "undefined" != typeof module && module.exports && "undefined" != typeof global ? global : this || window;
(_gsScope._gsQueue || (_gsScope._gsQueue = [])).push(function() {
    "use strict";
    _gsScope._gsDefine("easing.Back", ["easing.Ease"], function(e) {
        var t, i, n, o = _gsScope.GreenSockGlobals || _gsScope,
            r = o.com.greensock,
            s = 2 * Math.PI,
            a = Math.PI / 2,
            l = r._class,
            c = function(t, i) {
                var n = l("easing." + t, function() {}, !0),
                    o = n.prototype = new e;
                return o.constructor = n, o.getRatio = i, n
            },
            d = e.register || function() {},
            u = function(e, t, i, n) {
                var o = l("easing." + e, {
                    easeOut: new t,
                    easeIn: new i,
                    easeInOut: new n
                }, !0);
                return d(o, e), o
            },
            p = function(e, t, i) {
                this.t = e, this.v = t, i && (this.next = i, i.prev = this, this.c = i.v - t, this.gap = i.t - e)
            },
            h = function(t, i) {
                var n = l("easing." + t, function(e) {
                        this._p1 = e || 0 === e ? e : 1.70158, this._p2 = 1.525 * this._p1
                    }, !0),
                    o = n.prototype = new e;
                return o.constructor = n, o.getRatio = i, o.config = function(e) {
                    return new n(e)
                }, n
            },
            f = u("Back", h("BackOut", function(e) {
                return (e -= 1) * e * ((this._p1 + 1) * e + this._p1) + 1
            }), h("BackIn", function(e) {
                return e * e * ((this._p1 + 1) * e - this._p1)
            }), h("BackInOut", function(e) {
                return 1 > (e *= 2) ? .5 * e * e * ((this._p2 + 1) * e - this._p2) : .5 * ((e -= 2) * e * ((this._p2 + 1) * e + this._p2) + 2)
            })),
            g = l("easing.SlowMo", function(e, t, i) {
                t = t || 0 === t ? t : .7, null == e ? e = .7 : e > 1 && (e = 1), this._p = 1 !== e ? t : 0, this._p1 = (1 - e) / 2, this._p2 = e, this._p3 = this._p1 + this._p2, this._calcEnd = i === !0
            }, !0),
            m = g.prototype = new e;
        return m.constructor = g, m.getRatio = function(e) {
            var t = e + (.5 - e) * this._p;
            return this._p1 > e ? this._calcEnd ? 1 - (e = 1 - e / this._p1) * e : t - (e = 1 - e / this._p1) * e * e * e * t : e > this._p3 ? this._calcEnd ? 1 - (e = (e - this._p3) / this._p1) * e : t + (e - t) * (e = (e - this._p3) / this._p1) * e * e * e : this._calcEnd ? 1 : t
        }, g.ease = new g(.7, .7), m.config = g.config = function(e, t, i) {
            return new g(e, t, i)
        }, t = l("easing.SteppedEase", function(e) {
            e = e || 1, this._p1 = 1 / e, this._p2 = e + 1
        }, !0), m = t.prototype = new e, m.constructor = t, m.getRatio = function(e) {
            return 0 > e ? e = 0 : e >= 1 && (e = .999999999), (this._p2 * e >> 0) * this._p1
        }, m.config = t.config = function(e) {
            return new t(e)
        }, i = l("easing.RoughEase", function(t) {
            t = t || {};
            for (var i, n, o, r, s, a, l = t.taper || "none", c = [], d = 0, u = 0 | (t.points || 20), h = u, f = t.randomize !== !1, g = t.clamp === !0, m = t.template instanceof e ? t.template : null, v = "number" == typeof t.strength ? .4 * t.strength : .4; --h > -1;) i = f ? Math.random() : 1 / u * h, n = m ? m.getRatio(i) : i, "none" === l ? o = v : "out" === l ? (r = 1 - i, o = r * r * v) : "in" === l ? o = i * i * v : .5 > i ? (r = 2 * i, o = .5 * r * r * v) : (r = 2 * (1 - i), o = .5 * r * r * v), f ? n += Math.random() * o - .5 * o : h % 2 ? n += .5 * o : n -= .5 * o, g && (n > 1 ? n = 1 : 0 > n && (n = 0)), c[d++] = {
                x: i,
                y: n
            };
            for (c.sort(function(e, t) {
                    return e.x - t.x
                }), a = new p(1, 1, null), h = u; --h > -1;) s = c[h], a = new p(s.x, s.y, a);
            this._prev = new p(0, 0, 0 !== a.t ? a : a.next)
        }, !0), m = i.prototype = new e, m.constructor = i, m.getRatio = function(e) {
            var t = this._prev;
            if (e > t.t) {
                for (; t.next && e >= t.t;) t = t.next;
                t = t.prev
            } else
                for (; t.prev && t.t >= e;) t = t.prev;
            return this._prev = t, t.v + (e - t.t) / t.gap * t.c
        }, m.config = function(e) {
            return new i(e)
        }, i.ease = new i, u("Bounce", c("BounceOut", function(e) {
            return 1 / 2.75 > e ? 7.5625 * e * e : 2 / 2.75 > e ? 7.5625 * (e -= 1.5 / 2.75) * e + .75 : 2.5 / 2.75 > e ? 7.5625 * (e -= 2.25 / 2.75) * e + .9375 : 7.5625 * (e -= 2.625 / 2.75) * e + .984375
        }), c("BounceIn", function(e) {
            return 1 / 2.75 > (e = 1 - e) ? 1 - 7.5625 * e * e : 2 / 2.75 > e ? 1 - (7.5625 * (e -= 1.5 / 2.75) * e + .75) : 2.5 / 2.75 > e ? 1 - (7.5625 * (e -= 2.25 / 2.75) * e + .9375) : 1 - (7.5625 * (e -= 2.625 / 2.75) * e + .984375)
        }), c("BounceInOut", function(e) {
            var t = .5 > e;
            return e = t ? 1 - 2 * e : 2 * e - 1, e = 1 / 2.75 > e ? 7.5625 * e * e : 2 / 2.75 > e ? 7.5625 * (e -= 1.5 / 2.75) * e + .75 : 2.5 / 2.75 > e ? 7.5625 * (e -= 2.25 / 2.75) * e + .9375 : 7.5625 * (e -= 2.625 / 2.75) * e + .984375, t ? .5 * (1 - e) : .5 * e + .5
        })), u("Circ", c("CircOut", function(e) {
            return Math.sqrt(1 - (e -= 1) * e)
        }), c("CircIn", function(e) {
            return -(Math.sqrt(1 - e * e) - 1)
        }), c("CircInOut", function(e) {
            return 1 > (e *= 2) ? -.5 * (Math.sqrt(1 - e * e) - 1) : .5 * (Math.sqrt(1 - (e -= 2) * e) + 1)
        })), n = function(t, i, n) {
            var o = l("easing." + t, function(e, t) {
                    this._p1 = e >= 1 ? e : 1, this._p2 = (t || n) / (1 > e ? e : 1), this._p3 = this._p2 / s * (Math.asin(1 / this._p1) || 0), this._p2 = s / this._p2
                }, !0),
                r = o.prototype = new e;
            return r.constructor = o, r.getRatio = i, r.config = function(e, t) {
                return new o(e, t)
            }, o
        }, u("Elastic", n("ElasticOut", function(e) {
            return this._p1 * Math.pow(2, -10 * e) * Math.sin((e - this._p3) * this._p2) + 1
        }, .3), n("ElasticIn", function(e) {
            return -(this._p1 * Math.pow(2, 10 * (e -= 1)) * Math.sin((e - this._p3) * this._p2))
        }, .3), n("ElasticInOut", function(e) {
            return 1 > (e *= 2) ? -.5 * this._p1 * Math.pow(2, 10 * (e -= 1)) * Math.sin((e - this._p3) * this._p2) : .5 * this._p1 * Math.pow(2, -10 * (e -= 1)) * Math.sin((e - this._p3) * this._p2) + 1
        }, .45)), u("Expo", c("ExpoOut", function(e) {
            return 1 - Math.pow(2, -10 * e)
        }), c("ExpoIn", function(e) {
            return Math.pow(2, 10 * (e - 1)) - .001
        }), c("ExpoInOut", function(e) {
            return 1 > (e *= 2) ? .5 * Math.pow(2, 10 * (e - 1)) : .5 * (2 - Math.pow(2, -10 * (e - 1)))
        })), u("Sine", c("SineOut", function(e) {
            return Math.sin(e * a)
        }), c("SineIn", function(e) {
            return -Math.cos(e * a) + 1
        }), c("SineInOut", function(e) {
            return -.5 * (Math.cos(Math.PI * e) - 1)
        })), l("easing.EaseLookup", {
            find: function(t) {
                return e.map[t]
            }
        }, !0), d(o.SlowMo, "SlowMo", "ease,"), d(i, "RoughEase", "ease,"), d(t, "SteppedEase", "ease,"), f
    }, !0)
}), _gsScope._gsDefine && _gsScope._gsQueue.pop()();
var _gsScope = "undefined" != typeof module && module.exports && "undefined" != typeof global ? global : this || window;
(_gsScope._gsQueue || (_gsScope._gsQueue = [])).push(function() {
        "use strict";
        _gsScope._gsDefine("plugins.CSSPlugin", ["plugins.TweenPlugin", "TweenLite"], function(e, t) {
            var i, n, o, r, s = function() {
                    e.call(this, "css"), this._overwriteProps.length = 0, this.setRatio = s.prototype.setRatio
                },
                a = _gsScope._gsDefine.globals,
                l = {},
                c = s.prototype = new e("css");
            c.constructor = s, s.version = "1.18.0", s.API = 2, s.defaultTransformPerspective = 0, s.defaultSkewType = "compensated", s.defaultSmoothOrigin = !0, c = "px", s.suffixMap = {
                top: c,
                right: c,
                bottom: c,
                left: c,
                width: c,
                height: c,
                fontSize: c,
                padding: c,
                margin: c,
                perspective: c,
                lineHeight: ""
            };
            var d, u, p, h, f, g, m = /(?:\d|\-\d|\.\d|\-\.\d)+/g,
                v = /(?:\d|\-\d|\.\d|\-\.\d|\+=\d|\-=\d|\+=.\d|\-=\.\d)+/g,
                b = /(?:\+=|\-=|\-|\b)[\d\-\.]+[a-zA-Z0-9]*(?:%|\b)/gi,
                y = /(?![+-]?\d*\.?\d+|[+-]|e[+-]\d+)[^0-9]/g,
                w = /(?:\d|\-|\+|=|#|\.)*/g,
                x = /opacity *= *([^)]*)/i,
                T = /opacity:([^;]*)/i,
                _ = /alpha\(opacity *=.+?\)/i,
                k = /^(rgb|hsl)/,
                S = /([A-Z])/g,
                C = /-([a-z])/gi,
                $ = /(^(?:url\(\"|url\())|(?:(\"\))$|\)$)/gi,
                P = function(e, t) {
                    return t.toUpperCase()
                },
                A = /(?:Left|Right|Width)/i,
                O = /(M11|M12|M21|M22)=[\d\-\.e]+/gi,
                E = /progid\:DXImageTransform\.Microsoft\.Matrix\(.+?\)/i,
                M = /,(?=[^\)]*(?:\(|$))/gi,
                I = Math.PI / 180,
                D = 180 / Math.PI,
                j = {},
                N = document,
                L = function(e) {
                    return N.createElementNS ? N.createElementNS("http://www.w3.org/1999/xhtml", e) : N.createElement(e)
                },
                z = L("div"),
                F = L("img"),
                R = s._internals = {
                    _specialProps: l
                },
                H = navigator.userAgent,
                W = function() {
                    var e = H.indexOf("Android"),
                        t = L("a");
                    return p = -1 !== H.indexOf("Safari") && -1 === H.indexOf("Chrome") && (-1 === e || Number(H.substr(e + 8, 1)) > 3), f = p && 6 > Number(H.substr(H.indexOf("Version/") + 8, 1)), h = -1 !== H.indexOf("Firefox"), (/MSIE ([0-9]{1,}[\.0-9]{0,})/.exec(H) || /Trident\/.*rv:([0-9]{1,}[\.0-9]{0,})/.exec(H)) && (g = parseFloat(RegExp.$1)), !!t && (t.style.cssText = "top:1px;opacity:.55;", /^0.55/.test(t.style.opacity))
                }(),
                B = function(e) {
                    return x.test("string" == typeof e ? e : (e.currentStyle ? e.currentStyle.filter : e.style.filter) || "") ? parseFloat(RegExp.$1) / 100 : 1
                },
                q = function(e) {
                    window.console && console.log(e)
                },
                X = "",
                G = "",
                V = function(e, t) {
                    t = t || z;
                    var i, n, o = t.style;
                    if (void 0 !== o[e]) return e;
                    for (e = e.charAt(0).toUpperCase() + e.substr(1), i = ["O", "Moz", "ms", "Ms", "Webkit"], n = 5; --n > -1 && void 0 === o[i[n] + e];);
                    return n >= 0 ? (G = 3 === n ? "ms" : i[n], X = "-" + G.toLowerCase() + "-", G + e) : null
                },
                U = N.defaultView ? N.defaultView.getComputedStyle : function() {},
                Y = s.getStyle = function(e, t, i, n, o) {
                    var r;
                    return W || "opacity" !== t ? (!n && e.style[t] ? r = e.style[t] : (i = i || U(e)) ? r = i[t] || i.getPropertyValue(t) || i.getPropertyValue(t.replace(S, "-$1").toLowerCase()) : e.currentStyle && (r = e.currentStyle[t]), null == o || r && "none" !== r && "auto" !== r && "auto auto" !== r ? r : o) : B(e)
                },
                Q = R.convertToPixels = function(e, i, n, o, r) {
                    if ("px" === o || !o) return n;
                    if ("auto" === o || !n) return 0;
                    var a, l, c, d = A.test(i),
                        u = e,
                        p = z.style,
                        h = 0 > n;
                    if (h && (n = -n), "%" === o && -1 !== i.indexOf("border")) a = n / 100 * (d ? e.clientWidth : e.clientHeight);
                    else {
                        if (p.cssText = "border:0 solid red;position:" + Y(e, "position") + ";line-height:0;", "%" !== o && u.appendChild && "v" !== o.charAt(0) && "rem" !== o) p[d ? "borderLeftWidth" : "borderTopWidth"] = n + o;
                        else {
                            if (u = e.parentNode || N.body, l = u._gsCache, c = t.ticker.frame, l && d && l.time === c) return l.width * n / 100;
                            p[d ? "width" : "height"] = n + o
                        }
                        u.appendChild(z), a = parseFloat(z[d ? "offsetWidth" : "offsetHeight"]), u.removeChild(z), d && "%" === o && s.cacheWidths !== !1 && (l = u._gsCache = u._gsCache || {}, l.time = c, l.width = 100 * (a / n)), 0 !== a || r || (a = Q(e, i, n, o, !0))
                    }
                    return h ? -a : a
                },
                Z = R.calculateOffset = function(e, t, i) {
                    if ("absolute" !== Y(e, "position", i)) return 0;
                    var n = "left" === t ? "Left" : "Top",
                        o = Y(e, "margin" + n, i);
                    return e["offset" + n] - (Q(e, t, parseFloat(o), o.replace(w, "")) || 0)
                },
                J = function(e, t) {
                    var i, n, o, r = {};
                    if (t = t || U(e, null))
                        if (i = t.length)
                            for (; --i > -1;) o = t[i], (-1 === o.indexOf("-transform") || Se === o) && (r[o.replace(C, P)] = t.getPropertyValue(o));
                        else
                            for (i in t)(-1 === i.indexOf("Transform") || ke === i) && (r[i] = t[i]);
                    else if (t = e.currentStyle || e.style)
                        for (i in t) "string" == typeof i && void 0 === r[i] && (r[i.replace(C, P)] = t[i]);
                    return W || (r.opacity = B(e)), n = Le(e, t, !1), r.rotation = n.rotation, r.skewX = n.skewX, r.scaleX = n.scaleX, r.scaleY = n.scaleY, r.x = n.x, r.y = n.y, $e && (r.z = n.z, r.rotationX = n.rotationX, r.rotationY = n.rotationY, r.scaleZ = n.scaleZ), r.filters && delete r.filters, r
                },
                K = function(e, t, i, n, o) {
                    var r, s, a, l = {},
                        c = e.style;
                    for (s in i) "cssText" !== s && "length" !== s && isNaN(s) && (t[s] !== (r = i[s]) || o && o[s]) && -1 === s.indexOf("Origin") && ("number" == typeof r || "string" == typeof r) && (l[s] = "auto" !== r || "left" !== s && "top" !== s ? "" !== r && "auto" !== r && "none" !== r || "string" != typeof t[s] || "" === t[s].replace(y, "") ? r : 0 : Z(e, s), void 0 !== c[s] && (a = new fe(c, s, c[s], a)));
                    if (n)
                        for (s in n) "className" !== s && (l[s] = n[s]);
                    return {
                        difs: l,
                        firstMPT: a
                    }
                },
                ee = {
                    width: ["Left", "Right"],
                    height: ["Top", "Bottom"]
                },
                te = ["marginLeft", "marginRight", "marginTop", "marginBottom"],
                ie = function(e, t, i) {
                    var n = parseFloat("width" === t ? e.offsetWidth : e.offsetHeight),
                        o = ee[t],
                        r = o.length;
                    for (i = i || U(e, null); --r > -1;) n -= parseFloat(Y(e, "padding" + o[r], i, !0)) || 0, n -= parseFloat(Y(e, "border" + o[r] + "Width", i, !0)) || 0;
                    return n
                },
                ne = function(e, t) {
                    if ("contain" === e || "auto" === e || "auto auto" === e) return e + " ";
                    (null == e || "" === e) && (e = "0 0");
                    var i = e.split(" "),
                        n = -1 !== e.indexOf("left") ? "0%" : -1 !== e.indexOf("right") ? "100%" : i[0],
                        o = -1 !== e.indexOf("top") ? "0%" : -1 !== e.indexOf("bottom") ? "100%" : i[1];
                    return null == o ? o = "center" === n ? "50%" : "0" : "center" === o && (o = "50%"), ("center" === n || isNaN(parseFloat(n)) && -1 === (n + "").indexOf("=")) && (n = "50%"), e = n + " " + o + (i.length > 2 ? " " + i[2] : ""), t && (t.oxp = -1 !== n.indexOf("%"), t.oyp = -1 !== o.indexOf("%"), t.oxr = "=" === n.charAt(1), t.oyr = "=" === o.charAt(1), t.ox = parseFloat(n.replace(y, "")), t.oy = parseFloat(o.replace(y, "")), t.v = e), t || e
                },
                oe = function(e, t) {
                    return "string" == typeof e && "=" === e.charAt(1) ? parseInt(e.charAt(0) + "1", 10) * parseFloat(e.substr(2)) : parseFloat(e) - parseFloat(t)
                },
                re = function(e, t) {
                    return null == e ? t : "string" == typeof e && "=" === e.charAt(1) ? parseInt(e.charAt(0) + "1", 10) * parseFloat(e.substr(2)) + t : parseFloat(e)
                },
                se = function(e, t, i, n) {
                    var o, r, s, a, l, c = 1e-6;
                    return null == e ? a = t : "number" == typeof e ? a = e : (o = 360, r = e.split("_"), l = "=" === e.charAt(1), s = (l ? parseInt(e.charAt(0) + "1", 10) * parseFloat(r[0].substr(2)) : parseFloat(r[0])) * (-1 === e.indexOf("rad") ? 1 : D) - (l ? 0 : t), r.length && (n && (n[i] = t + s), -1 !== e.indexOf("short") && (s %= o, s !== s % (o / 2) && (s = 0 > s ? s + o : s - o)), -1 !== e.indexOf("_cw") && 0 > s ? s = (s + 9999999999 * o) % o - (0 | s / o) * o : -1 !== e.indexOf("ccw") && s > 0 && (s = (s - 9999999999 * o) % o - (0 | s / o) * o)), a = t + s), c > a && a > -c && (a = 0), a
                },
                ae = {
                    aqua: [0, 255, 255],
                    lime: [0, 255, 0],
                    silver: [192, 192, 192],
                    black: [0, 0, 0],
                    maroon: [128, 0, 0],
                    teal: [0, 128, 128],
                    blue: [0, 0, 255],
                    navy: [0, 0, 128],
                    white: [255, 255, 255],
                    fuchsia: [255, 0, 255],
                    olive: [128, 128, 0],
                    yellow: [255, 255, 0],
                    orange: [255, 165, 0],
                    gray: [128, 128, 128],
                    purple: [128, 0, 128],
                    green: [0, 128, 0],
                    red: [255, 0, 0],
                    pink: [255, 192, 203],
                    cyan: [0, 255, 255],
                    transparent: [255, 255, 255, 0]
                },
                le = function(e, t, i) {
                    return e = 0 > e ? e + 1 : e > 1 ? e - 1 : e, 0 | 255 * (1 > 6 * e ? t + 6 * (i - t) * e : .5 > e ? i : 2 > 3 * e ? t + 6 * (i - t) * (2 / 3 - e) : t) + .5
                },
                ce = s.parseColor = function(e, t) {
                    var i, n, o, r, s, a, l, c, d, u, p;
                    if (e)
                        if ("number" == typeof e) i = [e >> 16, 255 & e >> 8, 255 & e];
                        else {
                            if ("," === e.charAt(e.length - 1) && (e = e.substr(0, e.length - 1)), ae[e]) i = ae[e];
                            else if ("#" === e.charAt(0)) 4 === e.length && (n = e.charAt(1), o = e.charAt(2), r = e.charAt(3), e = "#" + n + n + o + o + r + r), e = parseInt(e.substr(1), 16), i = [e >> 16, 255 & e >> 8, 255 & e];
                            else if ("hsl" === e.substr(0, 3))
                                if (i = p = e.match(m), t) {
                                    if (-1 !== e.indexOf("=")) return e.match(v)
                                } else s = Number(i[0]) % 360 / 360, a = Number(i[1]) / 100, l = Number(i[2]) / 100, o = .5 >= l ? l * (a + 1) : l + a - l * a, n = 2 * l - o, i.length > 3 && (i[3] = Number(e[3])), i[0] = le(s + 1 / 3, n, o), i[1] = le(s, n, o), i[2] = le(s - 1 / 3, n, o);
                            else i = e.match(m) || ae.transparent;
                            i[0] = Number(i[0]), i[1] = Number(i[1]), i[2] = Number(i[2]), i.length > 3 && (i[3] = Number(i[3]))
                        } else i = ae.black;
                    return t && !p && (n = i[0] / 255, o = i[1] / 255, r = i[2] / 255, c = Math.max(n, o, r), d = Math.min(n, o, r), l = (c + d) / 2, c === d ? s = a = 0 : (u = c - d, a = l > .5 ? u / (2 - c - d) : u / (c + d), s = c === n ? (o - r) / u + (r > o ? 6 : 0) : c === o ? (r - n) / u + 2 : (n - o) / u + 4, s *= 60), i[0] = 0 | s + .5, i[1] = 0 | 100 * a + .5, i[2] = 0 | 100 * l + .5), i
                },
                de = function(e, t) {
                    var i, n, o, r = e.match(ue) || [],
                        s = 0,
                        a = r.length ? "" : e;
                    for (i = 0; r.length > i; i++) n = r[i], o = e.substr(s, e.indexOf(n, s) - s), s += o.length + n.length, n = ce(n, t), 3 === n.length && n.push(1), a += o + (t ? "hsla(" + n[0] + "," + n[1] + "%," + n[2] + "%," + n[3] : "rgba(" + n.join(",")) + ")";
                    return a
                },
                ue = "(?:\\b(?:(?:rgb|rgba|hsl|hsla)\\(.+?\\))|\\B#.+?\\b";
            for (c in ae) ue += "|" + c + "\\b";
            ue = RegExp(ue + ")", "gi"), s.colorStringFilter = function(e) {
                var t, i = e[0] + e[1];
                ue.lastIndex = 0, ue.test(i) && (t = -1 !== i.indexOf("hsl(") || -1 !== i.indexOf("hsla("), e[0] = de(e[0], t), e[1] = de(e[1], t))
            }, t.defaultStringFilter || (t.defaultStringFilter = s.colorStringFilter);
            var pe = function(e, t, i, n) {
                    if (null == e) return function(e) {
                        return e
                    };
                    var o, r = t ? (e.match(ue) || [""])[0] : "",
                        s = e.split(r).join("").match(b) || [],
                        a = e.substr(0, e.indexOf(s[0])),
                        l = ")" === e.charAt(e.length - 1) ? ")" : "",
                        c = -1 !== e.indexOf(" ") ? " " : ",",
                        d = s.length,
                        u = d > 0 ? s[0].replace(m, "") : "";
                    return d ? o = t ? function(e) {
                        var t, p, h, f;
                        if ("number" == typeof e) e += u;
                        else if (n && M.test(e)) {
                            for (f = e.replace(M, "|").split("|"), h = 0; f.length > h; h++) f[h] = o(f[h]);
                            return f.join(",")
                        }
                        if (t = (e.match(ue) || [r])[0], p = e.split(t).join("").match(b) || [], h = p.length, d > h--)
                            for (; d > ++h;) p[h] = i ? p[0 | (h - 1) / 2] : s[h];
                        return a + p.join(c) + c + t + l + (-1 !== e.indexOf("inset") ? " inset" : "")
                    } : function(e) {
                        var t, r, p;
                        if ("number" == typeof e) e += u;
                        else if (n && M.test(e)) {
                            for (r = e.replace(M, "|").split("|"), p = 0; r.length > p; p++) r[p] = o(r[p]);
                            return r.join(",")
                        }
                        if (t = e.match(b) || [], p = t.length, d > p--)
                            for (; d > ++p;) t[p] = i ? t[0 | (p - 1) / 2] : s[p];
                        return a + t.join(c) + l
                    } : function(e) {
                        return e
                    }
                },
                he = function(e) {
                    return e = e.split(","),
                        function(t, i, n, o, r, s, a) {
                            var l, c = (i + "").split(" ");
                            for (a = {}, l = 0; 4 > l; l++) a[e[l]] = c[l] = c[l] || c[(l - 1) / 2 >> 0];
                            return o.parse(t, a, r, s)
                        }
                },
                fe = (R._setPluginRatio = function(e) {
                    this.plugin.setRatio(e);
                    for (var t, i, n, o, r = this.data, s = r.proxy, a = r.firstMPT, l = 1e-6; a;) t = s[a.v], a.r ? t = Math.round(t) : l > t && t > -l && (t = 0), a.t[a.p] = t, a = a._next;
                    if (r.autoRotate && (r.autoRotate.rotation = s.rotation), 1 === e)
                        for (a = r.firstMPT; a;) {
                            if (i = a.t, i.type) {
                                if (1 === i.type) {
                                    for (o = i.xs0 + i.s + i.xs1, n = 1; i.l > n; n++) o += i["xn" + n] + i["xs" + (n + 1)];
                                    i.e = o
                                }
                            } else i.e = i.s + i.xs0;
                            a = a._next
                        }
                }, function(e, t, i, n, o) {
                    this.t = e, this.p = t, this.v = i, this.r = o, n && (n._prev = this, this._next = n)
                }),
                ge = (R._parseToProxy = function(e, t, i, n, o, r) {
                    var s, a, l, c, d, u = n,
                        p = {},
                        h = {},
                        f = i._transform,
                        g = j;
                    for (i._transform = null, j = t, n = d = i.parse(e, t, n, o), j = g, r && (i._transform = f, u && (u._prev = null, u._prev && (u._prev._next = null))); n && n !== u;) {
                        if (1 >= n.type && (a = n.p, h[a] = n.s + n.c, p[a] = n.s, r || (c = new fe(n, "s", a, c, n.r), n.c = 0), 1 === n.type))
                            for (s = n.l; --s > 0;) l = "xn" + s, a = n.p + "_" + l, h[a] = n.data[l], p[a] = n[l], r || (c = new fe(n, l, a, c, n.rxp[l]));
                        n = n._next
                    }
                    return {
                        proxy: p,
                        end: h,
                        firstMPT: c,
                        pt: d
                    }
                }, R.CSSPropTween = function(e, t, n, o, s, a, l, c, d, u, p) {
                    this.t = e, this.p = t, this.s = n, this.c = o, this.n = l || t, e instanceof ge || r.push(this.n), this.r = c, this.type = a || 0, d && (this.pr = d, i = !0), this.b = void 0 === u ? n : u, this.e = void 0 === p ? n + o : p, s && (this._next = s, s._prev = this)
                }),
                me = function(e, t, i, n, o, r) {
                    var s = new ge(e, t, i, n - i, o, (-1), r);
                    return s.b = i, s.e = s.xs0 = n, s
                },
                ve = s.parseComplex = function(e, t, i, n, o, r, s, a, l, c) {
                    i = i || r || "", s = new ge(e, t, 0, 0, s, c ? 2 : 1, null, (!1), a, i, n), n += "";
                    var u, p, h, f, g, b, y, w, x, T, _, k, S, C = i.split(", ").join(",").split(" "),
                        $ = n.split(", ").join(",").split(" "),
                        P = C.length,
                        A = d !== !1;
                    for ((-1 !== n.indexOf(",") || -1 !== i.indexOf(",")) && (C = C.join(" ").replace(M, ", ").split(" "), $ = $.join(" ").replace(M, ", ").split(" "), P = C.length), P !== $.length && (C = (r || "").split(" "), P = C.length), s.plugin = l, s.setRatio = c, ue.lastIndex = 0, u = 0; P > u; u++)
                        if (f = C[u], g = $[u], w = parseFloat(f), w || 0 === w) s.appendXtra("", w, oe(g, w), g.replace(v, ""), A && -1 !== g.indexOf("px"), !0);
                        else if (o && ue.test(f)) k = "," === g.charAt(g.length - 1) ? ")," : ")", S = -1 !== g.indexOf("hsl") && W, f = ce(f, S), g = ce(g, S), x = f.length + g.length > 6, x && !W && 0 === g[3] ? (s["xs" + s.l] += s.l ? " transparent" : "transparent", s.e = s.e.split($[u]).join("transparent")) : (W || (x = !1), S ? s.appendXtra(x ? "hsla(" : "hsl(", f[0], oe(g[0], f[0]), ",", !1, !0).appendXtra("", f[1], oe(g[1], f[1]), "%,", !1).appendXtra("", f[2], oe(g[2], f[2]), x ? "%," : "%" + k, !1) : s.appendXtra(x ? "rgba(" : "rgb(", f[0], g[0] - f[0], ",", !0, !0).appendXtra("", f[1], g[1] - f[1], ",", !0).appendXtra("", f[2], g[2] - f[2], x ? "," : k, !0), x && (f = 4 > f.length ? 1 : f[3], s.appendXtra("", f, (4 > g.length ? 1 : g[3]) - f, k, !1))), ue.lastIndex = 0;
                    else if (b = f.match(m)) {
                        if (y = g.match(v), !y || y.length !== b.length) return s;
                        for (h = 0, p = 0; b.length > p; p++) _ = b[p], T = f.indexOf(_, h), s.appendXtra(f.substr(h, T - h), Number(_), oe(y[p], _), "", A && "px" === f.substr(T + _.length, 2), 0 === p), h = T + _.length;
                        s["xs" + s.l] += f.substr(h)
                    } else s["xs" + s.l] += s.l ? " " + f : f;
                    if (-1 !== n.indexOf("=") && s.data) {
                        for (k = s.xs0 + s.data.s, u = 1; s.l > u; u++) k += s["xs" + u] + s.data["xn" + u];
                        s.e = k + s["xs" + u]
                    }
                    return s.l || (s.type = -1, s.xs0 = s.e), s.xfirst || s
                },
                be = 9;
            for (c = ge.prototype, c.l = c.pr = 0; --be > 0;) c["xn" + be] = 0, c["xs" + be] = "";
            c.xs0 = "", c._next = c._prev = c.xfirst = c.data = c.plugin = c.setRatio = c.rxp = null, c.appendXtra = function(e, t, i, n, o, r) {
                var s = this,
                    a = s.l;
                return s["xs" + a] += r && a ? " " + e : e || "", i || 0 === a || s.plugin ? (s.l++, s.type = s.setRatio ? 2 : 1, s["xs" + s.l] = n || "", a > 0 ? (s.data["xn" + a] = t + i, s.rxp["xn" + a] = o, s["xn" + a] = t, s.plugin || (s.xfirst = new ge(s, "xn" + a, t, i, s.xfirst || s, 0, s.n, o, s.pr), s.xfirst.xs0 = 0), s) : (s.data = {
                    s: t + i
                }, s.rxp = {}, s.s = t, s.c = i, s.r = o, s)) : (s["xs" + a] += t + (n || ""), s)
            };
            var ye = function(e, t) {
                    t = t || {}, this.p = t.prefix ? V(e) || e : e, l[e] = l[this.p] = this, this.format = t.formatter || pe(t.defaultValue, t.color, t.collapsible, t.multi), t.parser && (this.parse = t.parser), this.clrs = t.color, this.multi = t.multi, this.keyword = t.keyword, this.dflt = t.defaultValue, this.pr = t.priority || 0
                },
                we = R._registerComplexSpecialProp = function(e, t, i) {
                    "object" != typeof t && (t = {
                        parser: i
                    });
                    var n, o, r = e.split(","),
                        s = t.defaultValue;
                    for (i = i || [s], n = 0; r.length > n; n++) t.prefix = 0 === n && t.prefix, t.defaultValue = i[n] || s, o = new ye(r[n], t)
                },
                xe = function(e) {
                    if (!l[e]) {
                        var t = e.charAt(0).toUpperCase() + e.substr(1) + "Plugin";
                        we(e, {
                            parser: function(e, i, n, o, r, s, c) {
                                var d = a.com.greensock.plugins[t];
                                return d ? (d._cssRegister(), l[n].parse(e, i, n, o, r, s, c)) : (q("Error: " + t + " js file not loaded."), r)
                            }
                        })
                    }
                };
            c = ye.prototype, c.parseComplex = function(e, t, i, n, o, r) {
                var s, a, l, c, d, u, p = this.keyword;
                if (this.multi && (M.test(i) || M.test(t) ? (a = t.replace(M, "|").split("|"), l = i.replace(M, "|").split("|")) : p && (a = [t], l = [i])), l) {
                    for (c = l.length > a.length ? l.length : a.length, s = 0; c > s; s++) t = a[s] = a[s] || this.dflt, i = l[s] = l[s] || this.dflt, p && (d = t.indexOf(p), u = i.indexOf(p), d !== u && (-1 === u ? a[s] = a[s].split(p).join("") : -1 === d && (a[s] += " " + p)));
                    t = a.join(", "), i = l.join(", ")
                }
                return ve(e, this.p, t, i, this.clrs, this.dflt, n, this.pr, o, r)
            }, c.parse = function(e, t, i, n, r, s) {
                return this.parseComplex(e.style, this.format(Y(e, this.p, o, !1, this.dflt)), this.format(t), r, s)
            }, s.registerSpecialProp = function(e, t, i) {
                we(e, {
                    parser: function(e, n, o, r, s, a) {
                        var l = new ge(e, o, 0, 0, s, 2, o, (!1), i);
                        return l.plugin = a, l.setRatio = t(e, n, r._tween, o), l
                    },
                    priority: i
                })
            }, s.useSVGTransformAttr = p || h;
            var Te, _e = "scaleX,scaleY,scaleZ,x,y,z,skewX,skewY,rotation,rotationX,rotationY,perspective,xPercent,yPercent".split(","),
                ke = V("transform"),
                Se = X + "transform",
                Ce = V("transformOrigin"),
                $e = null !== V("perspective"),
                Pe = R.Transform = function() {
                    this.perspective = parseFloat(s.defaultTransformPerspective) || 0, this.force3D = !(s.defaultForce3D === !1 || !$e) && (s.defaultForce3D || "auto")
                },
                Ae = window.SVGElement,
                Oe = function(e, t, i) {
                    var n, o = N.createElementNS("http://www.w3.org/2000/svg", e),
                        r = /([a-z])([A-Z])/g;
                    for (n in i) o.setAttributeNS(null, n.replace(r, "$1-$2").toLowerCase(), i[n]);
                    return t.appendChild(o), o
                },
                Ee = N.documentElement,
                Me = function() {
                    var e, t, i, n = g || /Android/i.test(H) && !window.chrome;
                    return N.createElementNS && !n && (e = Oe("svg", Ee), t = Oe("rect", e, {
                        width: 100,
                        height: 50,
                        x: 100
                    }), i = t.getBoundingClientRect().width, t.style[Ce] = "50% 50%", t.style[ke] = "scaleX(0.5)", n = i === t.getBoundingClientRect().width && !(h && $e), Ee.removeChild(e)), n
                }(),
                Ie = function(e, t, i, n, o) {
                    var r, a, l, c, d, u, p, h, f, g, m, v, b, y, w = e._gsTransform,
                        x = Ne(e, !0);
                    w && (b = w.xOrigin, y = w.yOrigin), (!n || 2 > (r = n.split(" ")).length) && (p = e.getBBox(), t = ne(t).split(" "), r = [(-1 !== t[0].indexOf("%") ? parseFloat(t[0]) / 100 * p.width : parseFloat(t[0])) + p.x, (-1 !== t[1].indexOf("%") ? parseFloat(t[1]) / 100 * p.height : parseFloat(t[1])) + p.y]), i.xOrigin = c = parseFloat(r[0]), i.yOrigin = d = parseFloat(r[1]), n && x !== je && (u = x[0], p = x[1], h = x[2], f = x[3], g = x[4], m = x[5], v = u * f - p * h, a = c * (f / v) + d * (-h / v) + (h * m - f * g) / v, l = c * (-p / v) + d * (u / v) - (u * m - p * g) / v, c = i.xOrigin = r[0] = a, d = i.yOrigin = r[1] = l), w && (o || o !== !1 && s.defaultSmoothOrigin !== !1 ? (a = c - b, l = d - y, w.xOffset += a * x[0] + l * x[2] - a, w.yOffset += a * x[1] + l * x[3] - l) : w.xOffset = w.yOffset = 0), e.setAttribute("data-svg-origin", r.join(" "))
                },
                De = function(e) {
                    return !!(Ae && "function" == typeof e.getBBox && e.getCTM && (!e.parentNode || e.parentNode.getBBox && e.parentNode.getCTM))
                },
                je = [1, 0, 0, 1, 0, 0],
                Ne = function(e, t) {
                    var i, n, o, r, s, a = e._gsTransform || new Pe,
                        l = 1e5;
                    if (ke ? n = Y(e, Se, null, !0) : e.currentStyle && (n = e.currentStyle.filter.match(O), n = n && 4 === n.length ? [n[0].substr(4), Number(n[2].substr(4)), Number(n[1].substr(4)), n[3].substr(4), a.x || 0, a.y || 0].join(",") : ""), i = !n || "none" === n || "matrix(1, 0, 0, 1, 0, 0)" === n, (a.svg || e.getBBox && De(e)) && (i && -1 !== (e.style[ke] + "").indexOf("matrix") && (n = e.style[ke], i = 0), o = e.getAttribute("transform"), i && o && (-1 !== o.indexOf("matrix") ? (n = o, i = 0) : -1 !== o.indexOf("translate") && (n = "matrix(1,0,0,1," + o.match(/(?:\-|\b)[\d\-\.e]+\b/gi).join(",") + ")", i = 0))), i) return je;
                    for (o = (n || "").match(/(?:\-|\b)[\d\-\.e]+\b/gi) || [], be = o.length; --be > -1;) r = Number(o[be]), o[be] = (s = r - (r |= 0)) ? (0 | s * l + (0 > s ? -.5 : .5)) / l + r : r;
                    return t && o.length > 6 ? [o[0], o[1], o[4], o[5], o[12], o[13]] : o
                },
                Le = R.getTransform = function(e, i, n, r) {
                    if (e._gsTransform && n && !r) return e._gsTransform;
                    var a, l, c, d, u, p, h = n ? e._gsTransform || new Pe : new Pe,
                        f = 0 > h.scaleX,
                        g = 2e-5,
                        m = 1e5,
                        v = $e ? parseFloat(Y(e, Ce, i, !1, "0 0 0").split(" ")[2]) || h.zOrigin || 0 : 0,
                        b = parseFloat(s.defaultTransformPerspective) || 0;
                    if (h.svg = !(!e.getBBox || !De(e)), h.svg && (Ie(e, Y(e, Ce, o, !1, "50% 50%") + "", h, e.getAttribute("data-svg-origin")), Te = s.useSVGTransformAttr || Me), a = Ne(e), a !== je) {
                        if (16 === a.length) {
                            var y, w, x, T, _, k = a[0],
                                S = a[1],
                                C = a[2],
                                $ = a[3],
                                P = a[4],
                                A = a[5],
                                O = a[6],
                                E = a[7],
                                M = a[8],
                                I = a[9],
                                j = a[10],
                                N = a[12],
                                L = a[13],
                                z = a[14],
                                F = a[11],
                                R = Math.atan2(O, j);
                            h.zOrigin && (z = -h.zOrigin, N = M * z - a[12], L = I * z - a[13], z = j * z + h.zOrigin - a[14]), h.rotationX = R * D, R && (T = Math.cos(-R), _ = Math.sin(-R), y = P * T + M * _, w = A * T + I * _, x = O * T + j * _, M = P * -_ + M * T, I = A * -_ + I * T, j = O * -_ + j * T, F = E * -_ + F * T, P = y, A = w, O = x), R = Math.atan2(M, j), h.rotationY = R * D, R && (T = Math.cos(-R), _ = Math.sin(-R), y = k * T - M * _, w = S * T - I * _, x = C * T - j * _, I = S * _ + I * T, j = C * _ + j * T, F = $ * _ + F * T, k = y, S = w, C = x), R = Math.atan2(S, k), h.rotation = R * D, R && (T = Math.cos(-R), _ = Math.sin(-R), k = k * T + P * _, w = S * T + A * _, A = S * -_ + A * T, O = C * -_ + O * T, S = w), h.rotationX && Math.abs(h.rotationX) + Math.abs(h.rotation) > 359.9 && (h.rotationX = h.rotation = 0, h.rotationY += 180), h.scaleX = (0 | Math.sqrt(k * k + S * S) * m + .5) / m, h.scaleY = (0 | Math.sqrt(A * A + I * I) * m + .5) / m, h.scaleZ = (0 | Math.sqrt(O * O + j * j) * m + .5) / m, h.skewX = 0, h.perspective = F ? 1 / (0 > F ? -F : F) : 0, h.x = N, h.y = L, h.z = z, h.svg && (h.x -= h.xOrigin - (h.xOrigin * k - h.yOrigin * P), h.y -= h.yOrigin - (h.yOrigin * S - h.xOrigin * A))
                        } else if (!($e && !r && a.length && h.x === a[4] && h.y === a[5] && (h.rotationX || h.rotationY) || void 0 !== h.x && "none" === Y(e, "display", i))) {
                            var H = a.length >= 6,
                                W = H ? a[0] : 1,
                                B = a[1] || 0,
                                q = a[2] || 0,
                                X = H ? a[3] : 1;
                            h.x = a[4] || 0, h.y = a[5] || 0, c = Math.sqrt(W * W + B * B), d = Math.sqrt(X * X + q * q), u = W || B ? Math.atan2(B, W) * D : h.rotation || 0, p = q || X ? Math.atan2(q, X) * D + u : h.skewX || 0, Math.abs(p) > 90 && 270 > Math.abs(p) && (f ? (c *= -1, p += 0 >= u ? 180 : -180, u += 0 >= u ? 180 : -180) : (d *= -1, p += 0 >= p ? 180 : -180)), h.scaleX = c, h.scaleY = d, h.rotation = u, h.skewX = p, $e && (h.rotationX = h.rotationY = h.z = 0, h.perspective = b, h.scaleZ = 1), h.svg && (h.x -= h.xOrigin - (h.xOrigin * W + h.yOrigin * q), h.y -= h.yOrigin - (h.xOrigin * B + h.yOrigin * X))
                        }
                        h.zOrigin = v;
                        for (l in h) g > h[l] && h[l] > -g && (h[l] = 0)
                    }
                    return n && (e._gsTransform = h, h.svg && (Te && e.style[ke] ? t.delayedCall(.001, function() {
                        He(e.style, ke)
                    }) : !Te && e.getAttribute("transform") && t.delayedCall(.001, function() {
                        e.removeAttribute("transform")
                    }))), h
                },
                ze = function(e) {
                    var t, i, n = this.data,
                        o = -n.rotation * I,
                        r = o + n.skewX * I,
                        s = 1e5,
                        a = (0 | Math.cos(o) * n.scaleX * s) / s,
                        l = (0 | Math.sin(o) * n.scaleX * s) / s,
                        c = (0 | Math.sin(r) * -n.scaleY * s) / s,
                        d = (0 | Math.cos(r) * n.scaleY * s) / s,
                        u = this.t.style,
                        p = this.t.currentStyle;
                    if (p) {
                        i = l, l = -c, c = -i, t = p.filter, u.filter = "";
                        var h, f, m = this.t.offsetWidth,
                            v = this.t.offsetHeight,
                            b = "absolute" !== p.position,
                            y = "progid:DXImageTransform.Microsoft.Matrix(M11=" + a + ", M12=" + l + ", M21=" + c + ", M22=" + d,
                            T = n.x + m * n.xPercent / 100,
                            _ = n.y + v * n.yPercent / 100;
                        if (null != n.ox && (h = (n.oxp ? .01 * m * n.ox : n.ox) - m / 2, f = (n.oyp ? .01 * v * n.oy : n.oy) - v / 2, T += h - (h * a + f * l), _ += f - (h * c + f * d)), b ? (h = m / 2, f = v / 2, y += ", Dx=" + (h - (h * a + f * l) + T) + ", Dy=" + (f - (h * c + f * d) + _) + ")") : y += ", sizingMethod='auto expand')", u.filter = -1 !== t.indexOf("DXImageTransform.Microsoft.Matrix(") ? t.replace(E, y) : y + " " + t, (0 === e || 1 === e) && 1 === a && 0 === l && 0 === c && 1 === d && (b && -1 === y.indexOf("Dx=0, Dy=0") || x.test(t) && 100 !== parseFloat(RegExp.$1) || -1 === t.indexOf(t.indexOf("Alpha")) && u.removeAttribute("filter")), !b) {
                            var k, S, C, $ = 8 > g ? 1 : -1;
                            for (h = n.ieOffsetX || 0, f = n.ieOffsetY || 0, n.ieOffsetX = Math.round((m - ((0 > a ? -a : a) * m + (0 > l ? -l : l) * v)) / 2 + T), n.ieOffsetY = Math.round((v - ((0 > d ? -d : d) * v + (0 > c ? -c : c) * m)) / 2 + _), be = 0; 4 > be; be++) S = te[be], k = p[S], i = -1 !== k.indexOf("px") ? parseFloat(k) : Q(this.t, S, parseFloat(k), k.replace(w, "")) || 0, C = i !== n[S] ? 2 > be ? -n.ieOffsetX : -n.ieOffsetY : 2 > be ? h - n.ieOffsetX : f - n.ieOffsetY, u[S] = (n[S] = Math.round(i - C * (0 === be || 2 === be ? 1 : $))) + "px"
                        }
                    }
                },
                Fe = R.set3DTransformRatio = R.setTransformRatio = function(e) {
                    var t, i, n, o, r, s, a, l, c, d, u, p, f, g, m, v, b, y, w, x, T, _, k, S = this.data,
                        C = this.t.style,
                        $ = S.rotation,
                        P = S.rotationX,
                        A = S.rotationY,
                        O = S.scaleX,
                        E = S.scaleY,
                        M = S.scaleZ,
                        D = S.x,
                        j = S.y,
                        N = S.z,
                        L = S.svg,
                        z = S.perspective,
                        F = S.force3D;
                    if (!((1 !== e && 0 !== e || "auto" !== F || this.tween._totalTime !== this.tween._totalDuration && this.tween._totalTime) && F || N || z || A || P) || Te && L || !$e) return void($ || S.skewX || L ? ($ *= I, _ = S.skewX * I, k = 1e5, t = Math.cos($) * O, o = Math.sin($) * O, i = Math.sin($ - _) * -E, r = Math.cos($ - _) * E, _ && "simple" === S.skewType && (b = Math.tan(_), b = Math.sqrt(1 + b * b), i *= b, r *= b, S.skewY && (t *= b, o *= b)), L && (D += S.xOrigin - (S.xOrigin * t + S.yOrigin * i) + S.xOffset, j += S.yOrigin - (S.xOrigin * o + S.yOrigin * r) + S.yOffset, Te && (S.xPercent || S.yPercent) && (g = this.t.getBBox(), D += .01 * S.xPercent * g.width, j += .01 * S.yPercent * g.height), g = 1e-6, g > D && D > -g && (D = 0), g > j && j > -g && (j = 0)), w = (0 | t * k) / k + "," + (0 | o * k) / k + "," + (0 | i * k) / k + "," + (0 | r * k) / k + "," + D + "," + j + ")", L && Te ? this.t.setAttribute("transform", "matrix(" + w) : C[ke] = (S.xPercent || S.yPercent ? "translate(" + S.xPercent + "%," + S.yPercent + "%) matrix(" : "matrix(") + w) : C[ke] = (S.xPercent || S.yPercent ? "translate(" + S.xPercent + "%," + S.yPercent + "%) matrix(" : "matrix(") + O + ",0,0," + E + "," + D + "," + j + ")");
                    if (h && (g = 1e-4, g > O && O > -g && (O = M = 2e-5), g > E && E > -g && (E = M = 2e-5), !z || S.z || S.rotationX || S.rotationY || (z = 0)), $ || S.skewX) $ *= I, m = t = Math.cos($), v = o = Math.sin($), S.skewX && ($ -= S.skewX * I, m = Math.cos($), v = Math.sin($), "simple" === S.skewType && (b = Math.tan(S.skewX * I), b = Math.sqrt(1 + b * b), m *= b, v *= b, S.skewY && (t *= b, o *= b))), i = -v, r = m;
                    else {
                        if (!(A || P || 1 !== M || z || L)) return void(C[ke] = (S.xPercent || S.yPercent ? "translate(" + S.xPercent + "%," + S.yPercent + "%) translate3d(" : "translate3d(") + D + "px," + j + "px," + N + "px)" + (1 !== O || 1 !== E ? " scale(" + O + "," + E + ")" : ""));
                        t = r = 1, i = o = 0
                    }
                    c = 1, n = s = a = l = d = u = 0, p = z ? -1 / z : 0, f = S.zOrigin, g = 1e-6, x = ",", T = "0", $ = A * I, $ && (m = Math.cos($), v = Math.sin($), a = -v, d = p * -v, n = t * v, s = o * v, c = m, p *= m, t *= m, o *= m), $ = P * I, $ && (m = Math.cos($), v = Math.sin($), b = i * m + n * v, y = r * m + s * v, l = c * v, u = p * v, n = i * -v + n * m, s = r * -v + s * m, c *= m, p *= m, i = b, r = y), 1 !== M && (n *= M, s *= M, c *= M, p *= M), 1 !== E && (i *= E, r *= E, l *= E, u *= E), 1 !== O && (t *= O, o *= O, a *= O, d *= O), (f || L) && (f && (D += n * -f, j += s * -f, N += c * -f + f), L && (D += S.xOrigin - (S.xOrigin * t + S.yOrigin * i) + S.xOffset, j += S.yOrigin - (S.xOrigin * o + S.yOrigin * r) + S.yOffset), g > D && D > -g && (D = T), g > j && j > -g && (j = T), g > N && N > -g && (N = 0)), w = S.xPercent || S.yPercent ? "translate(" + S.xPercent + "%," + S.yPercent + "%) matrix3d(" : "matrix3d(", w += (g > t && t > -g ? T : t) + x + (g > o && o > -g ? T : o) + x + (g > a && a > -g ? T : a), w += x + (g > d && d > -g ? T : d) + x + (g > i && i > -g ? T : i) + x + (g > r && r > -g ? T : r), P || A ? (w += x + (g > l && l > -g ? T : l) + x + (g > u && u > -g ? T : u) + x + (g > n && n > -g ? T : n), w += x + (g > s && s > -g ? T : s) + x + (g > c && c > -g ? T : c) + x + (g > p && p > -g ? T : p) + x) : w += ",0,0,0,0,1,0,", w += D + x + j + x + N + x + (z ? 1 + -N / z : 1) + ")", C[ke] = w
                };
            c = Pe.prototype, c.x = c.y = c.z = c.skewX = c.skewY = c.rotation = c.rotationX = c.rotationY = c.zOrigin = c.xPercent = c.yPercent = c.xOffset = c.yOffset = 0, c.scaleX = c.scaleY = c.scaleZ = 1, we("transform,scale,scaleX,scaleY,scaleZ,x,y,z,rotation,rotationX,rotationY,rotationZ,skewX,skewY,shortRotation,shortRotationX,shortRotationY,shortRotationZ,transformOrigin,svgOrigin,transformPerspective,directionalRotation,parseTransform,force3D,skewType,xPercent,yPercent,smoothOrigin", {
                parser: function(e, t, i, n, r, a, l) {
                    if (n._lastParsedTransform === l) return r;
                    n._lastParsedTransform = l;
                    var c, d, u, p, h, f, g, m, v, b, y = e._gsTransform,
                        w = e.style,
                        x = 1e-6,
                        T = _e.length,
                        _ = l,
                        k = {},
                        S = "transformOrigin";
                    if (l.display ? (p = Y(e, "display"), w.display = "block", c = Le(e, o, !0, l.parseTransform), w.display = p) : c = Le(e, o, !0, l.parseTransform), n._transform = c, "string" == typeof _.transform && ke) p = z.style, p[ke] = _.transform, p.display = "block", p.position = "absolute", N.body.appendChild(z), d = Le(z, null, !1), N.body.removeChild(z), d.perspective || (d.perspective = c.perspective), null != _.xPercent && (d.xPercent = re(_.xPercent, c.xPercent)), null != _.yPercent && (d.yPercent = re(_.yPercent, c.yPercent));
                    else if ("object" == typeof _) {
                        if (d = {
                                scaleX: re(null != _.scaleX ? _.scaleX : _.scale, c.scaleX),
                                scaleY: re(null != _.scaleY ? _.scaleY : _.scale, c.scaleY),
                                scaleZ: re(_.scaleZ, c.scaleZ),
                                x: re(_.x, c.x),
                                y: re(_.y, c.y),
                                z: re(_.z, c.z),
                                xPercent: re(_.xPercent, c.xPercent),
                                yPercent: re(_.yPercent, c.yPercent),
                                perspective: re(_.transformPerspective, c.perspective)
                            }, m = _.directionalRotation, null != m)
                            if ("object" == typeof m)
                                for (p in m) _[p] = m[p];
                            else _.rotation = m;
                            "string" == typeof _.x && -1 !== _.x.indexOf("%") && (d.x = 0, d.xPercent = re(_.x, c.xPercent)), "string" == typeof _.y && -1 !== _.y.indexOf("%") && (d.y = 0,
                            d.yPercent = re(_.y, c.yPercent)), d.rotation = se("rotation" in _ ? _.rotation : "shortRotation" in _ ? _.shortRotation + "_short" : "rotationZ" in _ ? _.rotationZ : c.rotation, c.rotation, "rotation", k), $e && (d.rotationX = se("rotationX" in _ ? _.rotationX : "shortRotationX" in _ ? _.shortRotationX + "_short" : c.rotationX || 0, c.rotationX, "rotationX", k), d.rotationY = se("rotationY" in _ ? _.rotationY : "shortRotationY" in _ ? _.shortRotationY + "_short" : c.rotationY || 0, c.rotationY, "rotationY", k)), d.skewX = null == _.skewX ? c.skewX : se(_.skewX, c.skewX), d.skewY = null == _.skewY ? c.skewY : se(_.skewY, c.skewY), (u = d.skewY - c.skewY) && (d.skewX += u, d.rotation += u)
                    }
                    for ($e && null != _.force3D && (c.force3D = _.force3D, g = !0), c.skewType = _.skewType || c.skewType || s.defaultSkewType, f = c.force3D || c.z || c.rotationX || c.rotationY || d.z || d.rotationX || d.rotationY || d.perspective, f || null == _.scale || (d.scaleZ = 1); --T > -1;) i = _e[T], h = d[i] - c[i], (h > x || -x > h || null != _[i] || null != j[i]) && (g = !0, r = new ge(c, i, c[i], h, r), i in k && (r.e = k[i]), r.xs0 = 0, r.plugin = a, n._overwriteProps.push(r.n));
                    return h = _.transformOrigin, c.svg && (h || _.svgOrigin) && (v = c.xOffset, b = c.yOffset, Ie(e, ne(h), d, _.svgOrigin, _.smoothOrigin), r = me(c, "xOrigin", (y ? c : d).xOrigin, d.xOrigin, r, S), r = me(c, "yOrigin", (y ? c : d).yOrigin, d.yOrigin, r, S), (v !== c.xOffset || b !== c.yOffset) && (r = me(c, "xOffset", y ? v : c.xOffset, c.xOffset, r, S), r = me(c, "yOffset", y ? b : c.yOffset, c.yOffset, r, S)), h = Te ? null : "0px 0px"), (h || $e && f && c.zOrigin) && (ke ? (g = !0, i = Ce, h = (h || Y(e, i, o, !1, "50% 50%")) + "", r = new ge(w, i, 0, 0, r, (-1), S), r.b = w[i], r.plugin = a, $e ? (p = c.zOrigin, h = h.split(" "), c.zOrigin = (h.length > 2 && (0 === p || "0px" !== h[2]) ? parseFloat(h[2]) : p) || 0, r.xs0 = r.e = h[0] + " " + (h[1] || "50%") + " 0px", r = new ge(c, "zOrigin", 0, 0, r, (-1), r.n), r.b = p, r.xs0 = r.e = c.zOrigin) : r.xs0 = r.e = h) : ne(h + "", c)), g && (n._transformType = c.svg && Te || !f && 3 !== this._transformType ? 2 : 3), r
                },
                prefix: !0
            }), we("boxShadow", {
                defaultValue: "0px 0px 0px 0px #999",
                prefix: !0,
                color: !0,
                multi: !0,
                keyword: "inset"
            }), we("borderRadius", {
                defaultValue: "0px",
                parser: function(e, t, i, r, s) {
                    t = this.format(t);
                    var a, l, c, d, u, p, h, f, g, m, v, b, y, w, x, T, _ = ["borderTopLeftRadius", "borderTopRightRadius", "borderBottomRightRadius", "borderBottomLeftRadius"],
                        k = e.style;
                    for (g = parseFloat(e.offsetWidth), m = parseFloat(e.offsetHeight), a = t.split(" "), l = 0; _.length > l; l++) this.p.indexOf("border") && (_[l] = V(_[l])), u = d = Y(e, _[l], o, !1, "0px"), -1 !== u.indexOf(" ") && (d = u.split(" "), u = d[0], d = d[1]), p = c = a[l], h = parseFloat(u), b = u.substr((h + "").length), y = "=" === p.charAt(1), y ? (f = parseInt(p.charAt(0) + "1", 10), p = p.substr(2), f *= parseFloat(p), v = p.substr((f + "").length - (0 > f ? 1 : 0)) || "") : (f = parseFloat(p), v = p.substr((f + "").length)), "" === v && (v = n[i] || b), v !== b && (w = Q(e, "borderLeft", h, b), x = Q(e, "borderTop", h, b), "%" === v ? (u = 100 * (w / g) + "%", d = 100 * (x / m) + "%") : "em" === v ? (T = Q(e, "borderLeft", 1, "em"), u = w / T + "em", d = x / T + "em") : (u = w + "px", d = x + "px"), y && (p = parseFloat(u) + f + v, c = parseFloat(d) + f + v)), s = ve(k, _[l], u + " " + d, p + " " + c, !1, "0px", s);
                    return s
                },
                prefix: !0,
                formatter: pe("0px 0px 0px 0px", !1, !0)
            }), we("backgroundPosition", {
                defaultValue: "0 0",
                parser: function(e, t, i, n, r, s) {
                    var a, l, c, d, u, p, h = "background-position",
                        f = o || U(e, null),
                        m = this.format((f ? g ? f.getPropertyValue(h + "-x") + " " + f.getPropertyValue(h + "-y") : f.getPropertyValue(h) : e.currentStyle.backgroundPositionX + " " + e.currentStyle.backgroundPositionY) || "0 0"),
                        v = this.format(t);
                    if (-1 !== m.indexOf("%") != (-1 !== v.indexOf("%")) && (p = Y(e, "backgroundImage").replace($, ""), p && "none" !== p)) {
                        for (a = m.split(" "), l = v.split(" "), F.setAttribute("src", p), c = 2; --c > -1;) m = a[c], d = -1 !== m.indexOf("%"), d !== (-1 !== l[c].indexOf("%")) && (u = 0 === c ? e.offsetWidth - F.width : e.offsetHeight - F.height, a[c] = d ? parseFloat(m) / 100 * u + "px" : 100 * (parseFloat(m) / u) + "%");
                        m = a.join(" ")
                    }
                    return this.parseComplex(e.style, m, v, r, s)
                },
                formatter: ne
            }), we("backgroundSize", {
                defaultValue: "0 0",
                formatter: ne
            }), we("perspective", {
                defaultValue: "0px",
                prefix: !0
            }), we("perspectiveOrigin", {
                defaultValue: "50% 50%",
                prefix: !0
            }), we("transformStyle", {
                prefix: !0
            }), we("backfaceVisibility", {
                prefix: !0
            }), we("userSelect", {
                prefix: !0
            }), we("margin", {
                parser: he("marginTop,marginRight,marginBottom,marginLeft")
            }), we("padding", {
                parser: he("paddingTop,paddingRight,paddingBottom,paddingLeft")
            }), we("clip", {
                defaultValue: "rect(0px,0px,0px,0px)",
                parser: function(e, t, i, n, r, s) {
                    var a, l, c;
                    return 9 > g ? (l = e.currentStyle, c = 8 > g ? " " : ",", a = "rect(" + l.clipTop + c + l.clipRight + c + l.clipBottom + c + l.clipLeft + ")", t = this.format(t).split(",").join(c)) : (a = this.format(Y(e, this.p, o, !1, this.dflt)), t = this.format(t)), this.parseComplex(e.style, a, t, r, s)
                }
            }), we("textShadow", {
                defaultValue: "0px 0px 0px #999",
                color: !0,
                multi: !0
            }), we("autoRound,strictUnits", {
                parser: function(e, t, i, n, o) {
                    return o
                }
            }), we("border", {
                defaultValue: "0px solid #000",
                parser: function(e, t, i, n, r, s) {
                    return this.parseComplex(e.style, this.format(Y(e, "borderTopWidth", o, !1, "0px") + " " + Y(e, "borderTopStyle", o, !1, "solid") + " " + Y(e, "borderTopColor", o, !1, "#000")), this.format(t), r, s)
                },
                color: !0,
                formatter: function(e) {
                    var t = e.split(" ");
                    return t[0] + " " + (t[1] || "solid") + " " + (e.match(ue) || ["#000"])[0]
                }
            }), we("borderWidth", {
                parser: he("borderTopWidth,borderRightWidth,borderBottomWidth,borderLeftWidth")
            }), we("float,cssFloat,styleFloat", {
                parser: function(e, t, i, n, o) {
                    var r = e.style,
                        s = "cssFloat" in r ? "cssFloat" : "styleFloat";
                    return new ge(r, s, 0, 0, o, (-1), i, (!1), 0, r[s], t)
                }
            });
            var Re = function(e) {
                var t, i = this.t,
                    n = i.filter || Y(this.data, "filter") || "",
                    o = 0 | this.s + this.c * e;
                100 === o && (-1 === n.indexOf("atrix(") && -1 === n.indexOf("radient(") && -1 === n.indexOf("oader(") ? (i.removeAttribute("filter"), t = !Y(this.data, "filter")) : (i.filter = n.replace(_, ""), t = !0)), t || (this.xn1 && (i.filter = n = n || "alpha(opacity=" + o + ")"), -1 === n.indexOf("pacity") ? 0 === o && this.xn1 || (i.filter = n + " alpha(opacity=" + o + ")") : i.filter = n.replace(x, "opacity=" + o))
            };
            we("opacity,alpha,autoAlpha", {
                defaultValue: "1",
                parser: function(e, t, i, n, r, s) {
                    var a = parseFloat(Y(e, "opacity", o, !1, "1")),
                        l = e.style,
                        c = "autoAlpha" === i;
                    return "string" == typeof t && "=" === t.charAt(1) && (t = ("-" === t.charAt(0) ? -1 : 1) * parseFloat(t.substr(2)) + a), c && 1 === a && "hidden" === Y(e, "visibility", o) && 0 !== t && (a = 0), W ? r = new ge(l, "opacity", a, t - a, r) : (r = new ge(l, "opacity", 100 * a, 100 * (t - a), r), r.xn1 = c ? 1 : 0, l.zoom = 1, r.type = 2, r.b = "alpha(opacity=" + r.s + ")", r.e = "alpha(opacity=" + (r.s + r.c) + ")", r.data = e, r.plugin = s, r.setRatio = Re), c && (r = new ge(l, "visibility", 0, 0, r, (-1), null, (!1), 0, 0 !== a ? "inherit" : "hidden", 0 === t ? "hidden" : "inherit"), r.xs0 = "inherit", n._overwriteProps.push(r.n), n._overwriteProps.push(i)), r
                }
            });
            var He = function(e, t) {
                    t && (e.removeProperty ? (("ms" === t.substr(0, 2) || "webkit" === t.substr(0, 6)) && (t = "-" + t), e.removeProperty(t.replace(S, "-$1").toLowerCase())) : e.removeAttribute(t))
                },
                We = function(e) {
                    if (this.t._gsClassPT = this, 1 === e || 0 === e) {
                        this.t.setAttribute("class", 0 === e ? this.b : this.e);
                        for (var t = this.data, i = this.t.style; t;) t.v ? i[t.p] = t.v : He(i, t.p), t = t._next;
                        1 === e && this.t._gsClassPT === this && (this.t._gsClassPT = null)
                    } else this.t.getAttribute("class") !== this.e && this.t.setAttribute("class", this.e)
                };
            we("className", {
                parser: function(e, t, n, r, s, a, l) {
                    var c, d, u, p, h, f = e.getAttribute("class") || "",
                        g = e.style.cssText;
                    if (s = r._classNamePT = new ge(e, n, 0, 0, s, 2), s.setRatio = We, s.pr = -11, i = !0, s.b = f, d = J(e, o), u = e._gsClassPT) {
                        for (p = {}, h = u.data; h;) p[h.p] = 1, h = h._next;
                        u.setRatio(1)
                    }
                    return e._gsClassPT = s, s.e = "=" !== t.charAt(1) ? t : f.replace(RegExp("\\s*\\b" + t.substr(2) + "\\b"), "") + ("+" === t.charAt(0) ? " " + t.substr(2) : ""), e.setAttribute("class", s.e), c = K(e, d, J(e), l, p), e.setAttribute("class", f), s.data = c.firstMPT, e.style.cssText = g, s = s.xfirst = r.parse(e, c.difs, s, a)
                }
            });
            var Be = function(e) {
                if ((1 === e || 0 === e) && this.data._totalTime === this.data._totalDuration && "isFromStart" !== this.data.data) {
                    var t, i, n, o, r, s = this.t.style,
                        a = l.transform.parse;
                    if ("all" === this.e) s.cssText = "", o = !0;
                    else
                        for (t = this.e.split(" ").join("").split(","), n = t.length; --n > -1;) i = t[n], l[i] && (l[i].parse === a ? o = !0 : i = "transformOrigin" === i ? Ce : l[i].p), He(s, i);
                    o && (He(s, ke), r = this.t._gsTransform, r && (r.svg && this.t.removeAttribute("data-svg-origin"), delete this.t._gsTransform))
                }
            };
            for (we("clearProps", {
                    parser: function(e, t, n, o, r) {
                        return r = new ge(e, n, 0, 0, r, 2), r.setRatio = Be, r.e = t, r.pr = -10, r.data = o._tween, i = !0, r
                    }
                }), c = "bezier,throwProps,physicsProps,physics2D".split(","), be = c.length; be--;) xe(c[be]);
            c = s.prototype, c._firstPT = c._lastParsedTransform = c._transform = null, c._onInitTween = function(e, t, a) {
                if (!e.nodeType) return !1;
                this._target = e, this._tween = a, this._vars = t, d = t.autoRound, i = !1, n = t.suffixMap || s.suffixMap, o = U(e, ""), r = this._overwriteProps;
                var c, h, g, m, v, b, y, w, x, _ = e.style;
                if (u && "" === _.zIndex && (c = Y(e, "zIndex", o), ("auto" === c || "" === c) && this._addLazySet(_, "zIndex", 0)), "string" == typeof t && (m = _.cssText, c = J(e, o), _.cssText = m + ";" + t, c = K(e, c, J(e)).difs, !W && T.test(t) && (c.opacity = parseFloat(RegExp.$1)), t = c, _.cssText = m), this._firstPT = h = t.className ? l.className.parse(e, t.className, "className", this, null, null, t) : this.parse(e, t, null), this._transformType) {
                    for (x = 3 === this._transformType, ke ? p && (u = !0, "" === _.zIndex && (y = Y(e, "zIndex", o), ("auto" === y || "" === y) && this._addLazySet(_, "zIndex", 0)), f && this._addLazySet(_, "WebkitBackfaceVisibility", this._vars.WebkitBackfaceVisibility || (x ? "visible" : "hidden"))) : _.zoom = 1, g = h; g && g._next;) g = g._next;
                    w = new ge(e, "transform", 0, 0, null, 2), this._linkCSSP(w, null, g), w.setRatio = ke ? Fe : ze, w.data = this._transform || Le(e, o, !0), w.tween = a, w.pr = -1, r.pop()
                }
                if (i) {
                    for (; h;) {
                        for (b = h._next, g = m; g && g.pr > h.pr;) g = g._next;
                        (h._prev = g ? g._prev : v) ? h._prev._next = h: m = h, (h._next = g) ? g._prev = h : v = h, h = b
                    }
                    this._firstPT = m
                }
                return !0
            }, c.parse = function(e, t, i, r) {
                var s, a, c, u, p, h, f, g, m, v, b = e.style;
                for (s in t) h = t[s], a = l[s], a ? i = a.parse(e, h, s, this, i, r, t) : (p = Y(e, s, o) + "", m = "string" == typeof h, "color" === s || "fill" === s || "stroke" === s || -1 !== s.indexOf("Color") || m && k.test(h) ? (m || (h = ce(h), h = (h.length > 3 ? "rgba(" : "rgb(") + h.join(",") + ")"), i = ve(b, s, p, h, !0, "transparent", i, 0, r)) : !m || -1 === h.indexOf(" ") && -1 === h.indexOf(",") ? (c = parseFloat(p), f = c || 0 === c ? p.substr((c + "").length) : "", ("" === p || "auto" === p) && ("width" === s || "height" === s ? (c = ie(e, s, o), f = "px") : "left" === s || "top" === s ? (c = Z(e, s, o), f = "px") : (c = "opacity" !== s ? 0 : 1, f = "")), v = m && "=" === h.charAt(1), v ? (u = parseInt(h.charAt(0) + "1", 10), h = h.substr(2), u *= parseFloat(h), g = h.replace(w, "")) : (u = parseFloat(h), g = m ? h.replace(w, "") : ""), "" === g && (g = s in n ? n[s] : f), h = u || 0 === u ? (v ? u + c : u) + g : t[s], f !== g && "" !== g && (u || 0 === u) && c && (c = Q(e, s, c, f), "%" === g ? (c /= Q(e, s, 100, "%") / 100, t.strictUnits !== !0 && (p = c + "%")) : "em" === g || "rem" === g ? c /= Q(e, s, 1, g) : "px" !== g && (u = Q(e, s, u, g), g = "px"), v && (u || 0 === u) && (h = u + c + g)), v && (u += c), !c && 0 !== c || !u && 0 !== u ? void 0 !== b[s] && (h || "NaN" != h + "" && null != h) ? (i = new ge(b, s, u || c || 0, 0, i, (-1), s, (!1), 0, p, h), i.xs0 = "none" !== h || "display" !== s && -1 === s.indexOf("Style") ? h : p) : q("invalid " + s + " tween value: " + t[s]) : (i = new ge(b, s, c, u - c, i, 0, s, d !== !1 && ("px" === g || "zIndex" === s), 0, p, h), i.xs0 = g)) : i = ve(b, s, p, h, !0, null, i, 0, r)), r && i && !i.plugin && (i.plugin = r);
                return i
            }, c.setRatio = function(e) {
                var t, i, n, o = this._firstPT,
                    r = 1e-6;
                if (1 !== e || this._tween._time !== this._tween._duration && 0 !== this._tween._time)
                    if (e || this._tween._time !== this._tween._duration && 0 !== this._tween._time || this._tween._rawPrevTime === -1e-6)
                        for (; o;) {
                            if (t = o.c * e + o.s, o.r ? t = Math.round(t) : r > t && t > -r && (t = 0), o.type)
                                if (1 === o.type)
                                    if (n = o.l, 2 === n) o.t[o.p] = o.xs0 + t + o.xs1 + o.xn1 + o.xs2;
                                    else if (3 === n) o.t[o.p] = o.xs0 + t + o.xs1 + o.xn1 + o.xs2 + o.xn2 + o.xs3;
                            else if (4 === n) o.t[o.p] = o.xs0 + t + o.xs1 + o.xn1 + o.xs2 + o.xn2 + o.xs3 + o.xn3 + o.xs4;
                            else if (5 === n) o.t[o.p] = o.xs0 + t + o.xs1 + o.xn1 + o.xs2 + o.xn2 + o.xs3 + o.xn3 + o.xs4 + o.xn4 + o.xs5;
                            else {
                                for (i = o.xs0 + t + o.xs1, n = 1; o.l > n; n++) i += o["xn" + n] + o["xs" + (n + 1)];
                                o.t[o.p] = i
                            } else -1 === o.type ? o.t[o.p] = o.xs0 : o.setRatio && o.setRatio(e);
                            else o.t[o.p] = t + o.xs0;
                            o = o._next
                        } else
                            for (; o;) 2 !== o.type ? o.t[o.p] = o.b : o.setRatio(e), o = o._next;
                    else
                        for (; o;) {
                            if (2 !== o.type)
                                if (o.r && -1 !== o.type)
                                    if (t = Math.round(o.s + o.c), o.type) {
                                        if (1 === o.type) {
                                            for (n = o.l, i = o.xs0 + t + o.xs1, n = 1; o.l > n; n++) i += o["xn" + n] + o["xs" + (n + 1)];
                                            o.t[o.p] = i
                                        }
                                    } else o.t[o.p] = t + o.xs0;
                            else o.t[o.p] = o.e;
                            else o.setRatio(e);
                            o = o._next
                        }
            }, c._enableTransforms = function(e) {
                this._transform = this._transform || Le(this._target, o, !0), this._transformType = this._transform.svg && Te || !e && 3 !== this._transformType ? 2 : 3
            };
            var qe = function() {
                this.t[this.p] = this.e, this.data._linkCSSP(this, this._next, null, !0)
            };
            c._addLazySet = function(e, t, i) {
                var n = this._firstPT = new ge(e, t, 0, 0, this._firstPT, 2);
                n.e = i, n.setRatio = qe, n.data = this
            }, c._linkCSSP = function(e, t, i, n) {
                return e && (t && (t._prev = e), e._next && (e._next._prev = e._prev), e._prev ? e._prev._next = e._next : this._firstPT === e && (this._firstPT = e._next, n = !0), i ? i._next = e : n || null !== this._firstPT || (this._firstPT = e), e._next = t, e._prev = i), e
            }, c._kill = function(t) {
                var i, n, o, r = t;
                if (t.autoAlpha || t.alpha) {
                    r = {};
                    for (n in t) r[n] = t[n];
                    r.opacity = 1, r.autoAlpha && (r.visibility = 1)
                }
                return t.className && (i = this._classNamePT) && (o = i.xfirst, o && o._prev ? this._linkCSSP(o._prev, i._next, o._prev._prev) : o === this._firstPT && (this._firstPT = i._next), i._next && this._linkCSSP(i._next, i._next._next, o._prev), this._classNamePT = null), e.prototype._kill.call(this, r)
            };
            var Xe = function(e, t, i) {
                var n, o, r, s;
                if (e.slice)
                    for (o = e.length; --o > -1;) Xe(e[o], t, i);
                else
                    for (n = e.childNodes, o = n.length; --o > -1;) r = n[o], s = r.type, r.style && (t.push(J(r)), i && i.push(r)), 1 !== s && 9 !== s && 11 !== s || !r.childNodes.length || Xe(r, t, i)
            };
            return s.cascadeTo = function(e, i, n) {
                var o, r, s, a, l = t.to(e, i, n),
                    c = [l],
                    d = [],
                    u = [],
                    p = [],
                    h = t._internals.reservedProps;
                for (e = l._targets || l.target, Xe(e, d, p), l.render(i, !0, !0), Xe(e, u), l.render(0, !0, !0), l._enabled(!0), o = p.length; --o > -1;)
                    if (r = K(p[o], d[o], u[o]), r.firstMPT) {
                        r = r.difs;
                        for (s in n) h[s] && (r[s] = n[s]);
                        a = {};
                        for (s in r) a[s] = d[o][s];
                        c.push(t.fromTo(p[o], i, a, r))
                    }
                return c
            }, e.activate([s]), s
        }, !0)
    }), _gsScope._gsDefine && _gsScope._gsQueue.pop()(),
    function(e) {
        "use strict";
        var t = function() {
            return (_gsScope.GreenSockGlobals || _gsScope)[e]
        };
        "function" == typeof define && define.amd ? define(["TweenLite"], t) : "undefined" != typeof module && module.exports && (require("../TweenLite.js"), module.exports = t())
    }("CSSPlugin");
var _gsScope = "undefined" != typeof module && module.exports && "undefined" != typeof global ? global : this || window;
! function(e) {
    "use strict";
    var t = e.GreenSockGlobals || e,
        i = function(e) {
            var i, n = e.split("."),
                o = t;
            for (i = 0; n.length > i; i++) o[n[i]] = o = o[n[i]] || {};
            return o
        },
        n = i("com.greensock.utils"),
        o = function(e) {
            var t = e.nodeType,
                i = "";
            if (1 === t || 9 === t || 11 === t) {
                if ("string" == typeof e.textContent) return e.textContent;
                for (e = e.firstChild; e; e = e.nextSibling) i += o(e)
            } else if (3 === t || 4 === t) return e.nodeValue;
            return i
        },
        r = document,
        s = r.defaultView ? r.defaultView.getComputedStyle : function() {},
        a = /([A-Z])/g,
        l = function(e, t, i, n) {
            var o;
            return (i = i || s(e, null)) ? (e = i.getPropertyValue(t.replace(a, "-$1").toLowerCase()), o = e || i.length ? e : i[t]) : e.currentStyle && (i = e.currentStyle, o = i[t]), n ? o : parseInt(o, 10) || 0
        },
        c = function(e) {
            return !!(e.length && e[0] && (e[0].nodeType && e[0].style && !e.nodeType || e[0].length && e[0][0]))
        },
        d = function(e) {
            var t, i, n, o = [],
                r = e.length;
            for (t = 0; r > t; t++)
                if (i = e[t], c(i))
                    for (n = i.length, n = 0; i.length > n; n++) o.push(i[n]);
                else o.push(i);
            return o
        },
        u = ")eefec303079ad17405c",
        p = /(?:<br>|<br\/>|<br \/>)/gi,
        h = r.all && !r.addEventListener,
        f = "<div style='position:relative;display:inline-block;" + (h ? "*display:inline;*zoom:1;'" : "'"),
        g = function(e) {
            e = e || "";
            var t = -1 !== e.indexOf("++"),
                i = 1;
            return t && (e = e.split("++").join("")),
                function() {
                    return f + (e ? " class='" + e + (t ? i++ : "") + "'>" : ">")
                }
        },
        m = n.SplitText = t.SplitText = function(e, t) {
            if ("string" == typeof e && (e = m.selector(e)), !e) throw "cannot split a null element.";
            this.elements = c(e) ? d(e) : [e], this.chars = [], this.words = [], this.lines = [], this._originals = [], this.vars = t || {}, this.split(t)
        },
        v = function(e, t, i) {
            var n = e.nodeType;
            if (1 === n || 9 === n || 11 === n)
                for (e = e.firstChild; e; e = e.nextSibling) v(e, t, i);
            else(3 === n || 4 === n) && (e.nodeValue = e.nodeValue.split(t).join(i))
        },
        b = function(e, t) {
            for (var i = t.length; --i > -1;) e.push(t[i])
        },
        y = function(e, t, i, n, a) {
            p.test(e.innerHTML) && (e.innerHTML = e.innerHTML.replace(p, u));
            var c, d, h, f, m, y, w, x, T, _, k, S, C, $, P = o(e),
                A = t.type || t.split || "chars,words,lines",
                O = -1 !== A.indexOf("lines") ? [] : null,
                E = -1 !== A.indexOf("words"),
                M = -1 !== A.indexOf("chars"),
                I = "absolute" === t.position || t.absolute === !0,
                D = I ? "&#173; " : " ",
                j = -999,
                N = s(e),
                L = l(e, "paddingLeft", N),
                z = l(e, "borderBottomWidth", N) + l(e, "borderTopWidth", N),
                F = l(e, "borderLeftWidth", N) + l(e, "borderRightWidth", N),
                R = l(e, "paddingTop", N) + l(e, "paddingBottom", N),
                H = l(e, "paddingLeft", N) + l(e, "paddingRight", N),
                W = l(e, "textAlign", N, !0),
                B = e.clientHeight,
                q = e.clientWidth,
                X = "</div>",
                G = g(t.wordsClass),
                V = g(t.charsClass),
                U = -1 !== (t.linesClass || "").indexOf("++"),
                Y = t.linesClass,
                Q = -1 !== P.indexOf("<"),
                Z = !0,
                J = [],
                K = [],
                ee = [];
            for (U && (Y = Y.split("++").join("")), Q && (P = P.split("<").join("{{LT}}")), c = P.length, f = G(), m = 0; c > m; m++)
                if (w = P.charAt(m), ")" === w && P.substr(m, 20) === u) f += (Z ? X : "") + "<BR/>", Z = !1, m !== c - 20 && P.substr(m + 20, 20) !== u && (f += " " + G(), Z = !0), m += 19;
                else if (" " === w && " " !== P.charAt(m - 1) && m !== c - 1 && P.substr(m - 20, 20) !== u) {
                for (f += Z ? X : "", Z = !1;
                    " " === P.charAt(m + 1);) f += D, m++;
                (")" !== P.charAt(m + 1) || P.substr(m + 1, 20) !== u) && (f += D + G(), Z = !0)
            } else "{" === w && "{{LT}}" === P.substr(m, 6) ? (f += M ? V() + "{{LT}}</div>" : "{{LT}}", m += 5) : f += M && " " !== w ? V() + w + "</div>" : w;
            for (e.innerHTML = f + (Z ? X : ""), Q && v(e, "{{LT}}", "<"), y = e.getElementsByTagName("*"), c = y.length, x = [], m = 0; c > m; m++) x[m] = y[m];
            if (O || I)
                for (m = 0; c > m; m++) T = x[m], h = T.parentNode === e, (h || I || M && !E) && (_ = T.offsetTop, O && h && _ !== j && "BR" !== T.nodeName && (d = [], O.push(d), j = _), I && (T._x = T.offsetLeft, T._y = _, T._w = T.offsetWidth, T._h = T.offsetHeight), O && (E !== h && M || (d.push(T), T._x -= L), h && m && (x[m - 1]._wordEnd = !0), "BR" === T.nodeName && T.nextSibling && "BR" === T.nextSibling.nodeName && O.push([])));
            for (m = 0; c > m; m++) T = x[m], h = T.parentNode === e, "BR" !== T.nodeName ? (I && (S = T.style, E || h || (T._x += T.parentNode._x, T._y += T.parentNode._y), S.left = T._x + "px", S.top = T._y + "px", S.position = "absolute", S.display = "block", S.width = T._w + 1 + "px", S.height = T._h + "px"), E ? h && "" !== T.innerHTML ? K.push(T) : M && J.push(T) : h ? (e.removeChild(T), x.splice(m--, 1), c--) : !h && M && (_ = !O && !I && T.nextSibling, e.appendChild(T), _ || e.appendChild(r.createTextNode(" ")), J.push(T))) : O || I ? (e.removeChild(T), x.splice(m--, 1), c--) : E || e.appendChild(T);
            if (O) {
                for (I && (k = r.createElement("div"), e.appendChild(k), C = k.offsetWidth + "px", _ = k.offsetParent === e ? 0 : e.offsetLeft, e.removeChild(k)), S = e.style.cssText, e.style.cssText = "display:none;"; e.firstChild;) e.removeChild(e.firstChild);
                for ($ = !I || !E && !M, m = 0; O.length > m; m++) {
                    for (d = O[m], k = r.createElement("div"), k.style.cssText = "display:block;text-align:" + W + ";position:" + (I ? "absolute;" : "relative;"), Y && (k.className = Y + (U ? m + 1 : "")), ee.push(k), c = d.length, y = 0; c > y; y++) "BR" !== d[y].nodeName && (T = d[y], k.appendChild(T), $ && (T._wordEnd || E) && k.appendChild(r.createTextNode(" ")), I && (0 === y && (k.style.top = T._y + "px", k.style.left = L + _ + "px"), T.style.top = "0px", _ && (T.style.left = T._x - _ + "px")));
                    0 === c && (k.innerHTML = "&nbsp;"), E || M || (k.innerHTML = o(k).split(String.fromCharCode(160)).join(" ")), I && (k.style.width = C, k.style.height = T._h + "px"), e.appendChild(k)
                }
                e.style.cssText = S
            }
            I && (B > e.clientHeight && (e.style.height = B - R + "px", B > e.clientHeight && (e.style.height = B + z + "px")), q > e.clientWidth && (e.style.width = q - H + "px", q > e.clientWidth && (e.style.width = q + F + "px"))), b(i, J), b(n, K), b(a, ee)
        },
        w = m.prototype;
    w.split = function(e) {
        this.isSplit && this.revert(), this.vars = e || this.vars, this._originals.length = this.chars.length = this.words.length = this.lines.length = 0;
        for (var t = this.elements.length; --t > -1;) this._originals[t] = this.elements[t].innerHTML, y(this.elements[t], this.vars, this.chars, this.words, this.lines);
        return this.chars.reverse(), this.words.reverse(), this.lines.reverse(), this.isSplit = !0, this
    }, w.revert = function() {
        if (!this._originals) throw "revert() call wasn't scoped properly.";
        for (var e = this._originals.length; --e > -1;) this.elements[e].innerHTML = this._originals[e];
        return this.chars = [], this.words = [], this.lines = [], this.isSplit = !1, this
    }, m.selector = e.$ || e.jQuery || function(t) {
        var i = e.$ || e.jQuery;
        return i ? (m.selector = i, i(t)) : "undefined" == typeof document ? t : document.querySelectorAll ? document.querySelectorAll(t) : document.getElementById("#" === t.charAt(0) ? t.substr(1) : t)
    }, m.version = "0.3.4"
}(_gsScope),
function(e) {
    "use strict";
    var t = function() {
        return (_gsScope.GreenSockGlobals || _gsScope)[e]
    };
    "function" == typeof define && define.amd ? define(["TweenLite"], t) : "undefined" != typeof module && module.exports && (module.exports = t())
}("SplitText");
try {
    window.GreenSockGlobals = null, window._gsQueue = null, window._gsDefine = null, delete window.GreenSockGlobals, delete window._gsQueue, delete window._gsDefine
} catch (e) {}
try {
    window.GreenSockGlobals = oldgs, window._gsQueue = oldgs_queue
} catch (e) {}
if (1 == window.tplogs) try {
        console.groupEnd()
    } catch (e) {}! function(e, t) {
        e.waitForImages = {
            hasImageProperties: ["backgroundImage", "listStyleImage", "borderImage", "borderCornerImage"]
        }, e.expr[":"].uncached = function(t) {
            var i = document.createElement("img");
            return i.src = t.src, e(t).is('img[src!=""]') && !i.complete
        }, e.fn.waitForImages = function(t, i, n) {
            if (e.isPlainObject(arguments[0]) && (i = t.each, n = t.waitForAll, t = t.finished), t = t || e.noop, i = i || e.noop, n = !!n, !e.isFunction(t) || !e.isFunction(i)) throw new TypeError("An invalid callback was supplied.");
            return this.each(function() {
                var o = e(this),
                    r = [];
                if (n) {
                    var s = e.waitForImages.hasImageProperties || [],
                        a = /url\((['"]?)(.*?)\1\)/g;
                    o.find("*").each(function() {
                        var t = e(this);
                        t.is("img:uncached") && r.push({
                            src: t.attr("src"),
                            element: t[0]
                        }), e.each(s, function(e, i) {
                            var n = t.css(i);
                            if (!n) return !0;
                            for (var o; o = a.exec(n);) r.push({
                                src: o[2],
                                element: t[0]
                            })
                        })
                    })
                } else o.find("img:uncached").each(function() {
                    r.push({
                        src: this.src,
                        element: this
                    })
                });
                var l = r.length,
                    c = 0;
                0 == l && t.call(o[0]), e.each(r, function(n, r) {
                    var s = new Image;
                    e(s).bind("load error", function(e) {
                        if (c++, i.call(r.element, c, l, "load" == e.type), c == l) return t.call(o[0]), !1
                    }), s.src = r.src
                })
            })
        }
    }(jQuery), ! function(e, t) {
        "use strict";
        e.fn.extend({
            revolution: function(n) {
                var o = {
                    delay: 9e3,
                    responsiveLevels: 4064,
                    visibilityLevels: [2048, 1024, 778, 480],
                    gridwidth: 960,
                    gridheight: 500,
                    minHeight: 0,
                    autoHeight: "off",
                    sliderType: "standard",
                    sliderLayout: "auto",
                    fullScreenAutoWidth: "off",
                    fullScreenAlignForce: "off",
                    fullScreenOffsetContainer: "",
                    fullScreenOffset: "0",
                    hideCaptionAtLimit: 0,
                    hideAllCaptionAtLimit: 0,
                    hideSliderAtLimit: 0,
                    disableProgressBar: "off",
                    stopAtSlide: -1,
                    stopAfterLoops: -1,
                    shadow: 0,
                    dottedOverlay: "none",
                    startDelay: 0,
                    lazyType: "smart",
                    spinner: "spinner0",
                    shuffle: "off",
                    viewPort: {
                        enable: !1,
                        outof: "wait",
                        visible_area: "60%"
                    },
                    fallbacks: {
                        isJoomla: !1,
                        panZoomDisableOnMobile: "off",
                        simplifyAll: "on",
                        nextSlideOnWindowFocus: "off",
                        disableFocusListener: !0
                    },
                    parallax: {
                        type: "off",
                        levels: [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85],
                        origo: "enterpoint",
                        speed: 400,
                        bgparallax: "on",
                        opacity: "on",
                        disable_onmobile: "off",
                        ddd_shadow: "on",
                        ddd_bgfreeze: "off",
                        ddd_overflow: "visible",
                        ddd_layer_overflow: "visible",
                        ddd_z_correction: 65,
                        ddd_path: "mouse"
                    },
                    carousel: {
                        horizontal_align: "center",
                        vertical_align: "center",
                        infinity: "on",
                        space: 0,
                        maxVisibleItems: 3,
                        stretch: "off",
                        fadeout: "on",
                        maxRotation: 0,
                        minScale: 0,
                        vary_fade: "off",
                        vary_rotation: "on",
                        vary_scale: "off",
                        border_radius: "0px",
                        padding_top: 0,
                        padding_bottom: 0
                    },
                    navigation: {
                        keyboardNavigation: "on",
                        keyboard_direction: "horizontal",
                        mouseScrollNavigation: "off",
                        onHoverStop: "on",
                        touch: {
                            touchenabled: "off",
                            swipe_treshold: 75,
                            swipe_min_touches: 1,
                            drag_block_vertical: !1,
                            swipe_direction: "horizontal"
                        },
                        arrows: {
                            style: "",
                            enable: !1,
                            hide_onmobile: !1,
                            hide_onleave: !0,
                            hide_delay: 200,
                            hide_delay_mobile: 1200,
                            hide_under: 0,
                            hide_over: 9999,
                            tmp: "",
                            left: {
                                h_align: "left",
                                v_align: "center",
                                h_offset: 20,
                                v_offset: 0
                            },
                            right: {
                                h_align: "right",
                                v_align: "center",
                                h_offset: 20,
                                v_offset: 0
                            }
                        },
                        bullets: {
                            style: "",
                            enable: !1,
                            hide_onmobile: !1,
                            hide_onleave: !0,
                            hide_delay: 200,
                            hide_delay_mobile: 1200,
                            hide_under: 0,
                            hide_over: 9999,
                            direction: "horizontal",
                            h_align: "left",
                            v_align: "center",
                            space: 0,
                            h_offset: 20,
                            v_offset: 0,
                            tmp: '<span class="tp-bullet-image"></span><span class="tp-bullet-title"></span>'
                        },
                        thumbnails: {
                            style: "",
                            enable: !1,
                            width: 100,
                            height: 50,
                            min_width: 100,
                            wrapper_padding: 2,
                            wrapper_color: "#f5f5f5",
                            wrapper_opacity: 1,
                            tmp: '<span class="tp-thumb-image"></span><span class="tp-thumb-title"></span>',
                            visibleAmount: 5,
                            hide_onmobile: !1,
                            hide_onleave: !0,
                            hide_delay: 200,
                            hide_delay_mobile: 1200,
                            hide_under: 0,
                            hide_over: 9999,
                            direction: "horizontal",
                            span: !1,
                            position: "inner",
                            space: 2,
                            h_align: "left",
                            v_align: "center",
                            h_offset: 20,
                            v_offset: 0
                        },
                        tabs: {
                            style: "",
                            enable: !1,
                            width: 100,
                            min_width: 100,
                            height: 50,
                            wrapper_padding: 10,
                            wrapper_color: "#f5f5f5",
                            wrapper_opacity: 1,
                            tmp: '<span class="tp-tab-image"></span>',
                            visibleAmount: 5,
                            hide_onmobile: !1,
                            hide_onleave: !0,
                            hide_delay: 200,
                            hide_delay_mobile: 1200,
                            hide_under: 0,
                            hide_over: 9999,
                            direction: "horizontal",
                            span: !1,
                            space: 0,
                            position: "inner",
                            h_align: "left",
                            v_align: "center",
                            h_offset: 20,
                            v_offset: 0
                        }
                    },
                    extensions: "../js/revolutionslider5/extensions/",
                    extensions_suffix: ".min.js",
                    debugMode: !1
                };
                return n = e.extend(!0, {}, o, n), this.each(function() {
                    var o = e(this);
                    "hero" == n.sliderType && o.find(">ul>li").each(function(t) {
                        t > 0 && e(this).remove()
                    }), n.jsFileLocation = n.jsFileLocation || c("themepunch.revolution.min.js"), n.jsFileLocation = n.jsFileLocation + n.extensions, n.scriptsneeded = a(n, o), n.curWinRange = 0, n.navigation != t && n.navigation.touch != t && (n.navigation.touch.swipe_min_touches = n.navigation.touch.swipe_min_touches > 5 ? 1 : n.navigation.touch.swipe_min_touches), e(this).on("scriptsloaded", function() {
                        return n.modulesfailing ? (o.html('<div style="margin:auto;line-height:40px;font-size:14px;color:#fff;padding:15px;background:#e74c3c;margin:20px 0px;">!! Error at loading Slider Revolution 5.0 Extrensions.' + n.errorm + "</div>").show(), !1) : (i.migration != t && (n = i.migration(o, n)), punchgs.force3D = !0, "on" !== n.simplifyAll && punchgs.TweenLite.lagSmoothing(1e3, 16), u(o, n), void h(o, n))
                    }), l(o, n.scriptsneeded)
                })
            },
            revremoveslide: function(n) {
                return this.each(function() {
                    var s = e(this);
                    if (s != t && s.length > 0 && e("body").find("#" + s.attr("id")).length > 0) {
                        var a = s.parent().find(".tp-bannertimer"),
                            l = a.data("opt");
                        if (l && l.li.length > 0 && (n > 0 || n <= l.li.length)) {
                            var c = e(l.li[n]),
                                d = c.data("index"),
                                u = !1;
                            l.slideamount = l.slideamount - 1, r(".tp-bullet", d, l), r(".tp-tab", d, l), r(".tp-thumb", d, l), c.hasClass("active-revslide") && (u = !0), c.remove(), l.li = o(l.li, n), l.carousel && l.carousel.slides && (l.carousel.slides = o(l.carousel.slides, n)), l.thumbs = o(l.thumbs, n), i.updateNavIndexes && i.updateNavIndexes(l), u && s.revnext()
                        }
                    }
                })
            },
            revaddcallback: function(i) {
                return this.each(function() {
                    var n = e(this);
                    if (n != t && n.length > 0 && e("body").find("#" + n.attr("id")).length > 0) {
                        var o = n.parent().find(".tp-bannertimer"),
                            r = o.data("opt");
                        r.callBackArray === t && (r.callBackArray = new Array), r.callBackArray.push(i)
                    }
                })
            },
            revgetparallaxproc: function() {
                var i = e(this);
                if (i != t && i.length > 0 && e("body").find("#" + i.attr("id")).length > 0) {
                    var n = i.parent().find(".tp-bannertimer"),
                        o = n.data("opt");
                    return o.scrollproc
                }
            },
            revdebugmode: function() {
                return this.each(function() {
                    var i = e(this);
                    if (i != t && i.length > 0 && e("body").find("#" + i.attr("id")).length > 0) {
                        var n = i.parent().find(".tp-bannertimer"),
                            o = n.data("opt");
                        o.debugMode = !0, b(i, o)
                    }
                })
            },
            revscroll: function(i) {
                return this.each(function() {
                    var n = e(this);
                    n != t && n.length > 0 && e("body").find("#" + n.attr("id")).length > 0 && e("body,html").animate({
                        scrollTop: n.offset().top + n.height() - i + "px"
                    }, {
                        duration: 400
                    })
                })
            },
            revredraw: function(i) {
                return this.each(function() {
                    var i = e(this);
                    if (i != t && i.length > 0 && e("body").find("#" + i.attr("id")).length > 0) {
                        var n = i.parent().find(".tp-bannertimer"),
                            o = n.data("opt");
                        b(i, o)
                    }
                })
            },
            revkill: function(n) {
                var o = this,
                    r = e(this);
                if (punchgs.TweenLite.killDelayedCallsTo(i.showHideNavElements), i.endMoveCaption && punchgs.TweenLite.killDelayedCallsTo(i.endMoveCaption), r != t && r.length > 0 && e("body").find("#" + r.attr("id")).length > 0) {
                    r.data("conthover", 1), r.data("conthover-changed", 1), r.trigger("revolution.slide.onpause");
                    var s = r.parent().find(".tp-bannertimer"),
                        a = s.data("opt");
                    a.tonpause = !0, r.trigger("stoptimer"), punchgs.TweenLite.killTweensOf(r.find("*"), !1), punchgs.TweenLite.killTweensOf(r, !1), r.unbind("hover, mouseover, mouseenter,mouseleave, resize");
                    var l = "resize.revslider-" + r.attr("id");
                    e(window).off(l), r.find("*").each(function() {
                        var i = e(this);
                        i.unbind("on, hover, mouseenter,mouseleave,mouseover, resize,restarttimer, stoptimer"), i.off("on, hover, mouseenter,mouseleave,mouseover, resize"), i.data("mySplitText", null), i.data("ctl", null), i.data("tween") != t && i.data("tween").kill(), i.data("kenburn") != t && i.data("kenburn").kill(), i.data("timeline_out") != t && i.data("timeline_out").kill(), i.data("timeline") != t && i.data("timeline").kill(), i.remove(), i.empty(), i = null
                    }), punchgs.TweenLite.killTweensOf(r.find("*"), !1), punchgs.TweenLite.killTweensOf(r, !1), s.remove();
                    try {
                        r.closest(".forcefullwidth_wrapper_tp_banner").remove()
                    } catch (c) {}
                    try {
                        r.closest(".rev_slider_wrapper").remove()
                    } catch (c) {}
                    try {
                        r.remove()
                    } catch (c) {}
                    return r.empty(), r.html(), r = null, a = null, delete o.c, delete o.opt, !0
                }
                return !1
            },
            revpause: function() {
                return this.each(function() {
                    var i = e(this);
                    if (i != t && i.length > 0 && e("body").find("#" + i.attr("id")).length > 0) {
                        i.data("conthover", 1), i.data("conthover-changed", 1), i.trigger("revolution.slide.onpause");
                        var n = i.parent().find(".tp-bannertimer"),
                            o = n.data("opt");
                        o.tonpause = !0, i.trigger("stoptimer")
                    }
                })
            },
            revresume: function() {
                return this.each(function() {
                    var i = e(this);
                    if (i != t && i.length > 0 && e("body").find("#" + i.attr("id")).length > 0) {
                        i.data("conthover", 0), i.data("conthover-changed", 1), i.trigger("revolution.slide.onresume");
                        var n = i.parent().find(".tp-bannertimer"),
                            o = n.data("opt");
                        o.tonpause = !1, i.trigger("starttimer")
                    }
                })
            },
            revnext: function() {
                return this.each(function() {
                    var n = e(this);
                    if (n != t && n.length > 0 && e("body").find("#" + n.attr("id")).length > 0) {
                        var o = n.parent().find(".tp-bannertimer"),
                            r = o.data("opt");
                        i.callingNewSlide(r, n, 1)
                    }
                })
            },
            revprev: function() {
                return this.each(function() {
                    var n = e(this);
                    if (n != t && n.length > 0 && e("body").find("#" + n.attr("id")).length > 0) {
                        var o = n.parent().find(".tp-bannertimer"),
                            r = o.data("opt");
                        i.callingNewSlide(r, n, -1)
                    }
                })
            },
            revmaxslide: function() {
                return e(this).find(".tp-revslider-mainul >li").length
            },
            revcurrentslide: function() {
                var i = e(this);
                if (i != t && i.length > 0 && e("body").find("#" + i.attr("id")).length > 0) {
                    var n = i.parent().find(".tp-bannertimer"),
                        o = n.data("opt");
                    return parseInt(o.act, 0) + 1
                }
            },
            revlastslide: function() {
                return e(this).find(".tp-revslider-mainul >li").length
            },
            revshowslide: function(n) {
                return this.each(function() {
                    var o = e(this);
                    if (o != t && o.length > 0 && e("body").find("#" + o.attr("id")).length > 0) {
                        var r = o.parent().find(".tp-bannertimer"),
                            s = r.data("opt");
                        i.callingNewSlide(s, o, "to" + (n - 1))
                    }
                })
            },
            revcallslidewithid: function(n) {
                return this.each(function() {
                    var o = e(this);
                    if (o != t && o.length > 0 && e("body").find("#" + o.attr("id")).length > 0) {
                        var r = o.parent().find(".tp-bannertimer"),
                            s = r.data("opt");
                        i.callingNewSlide(s, o, n)
                    }
                })
            }
        });
        var i = e.fn.revolution;
        e.extend(!0, i, {
            simp: function(e, t, i) {
                var n = Math.abs(e) - Math.floor(Math.abs(e / t)) * t;
                return i ? n : 0 > e ? -1 * n : n
            },
            iOSVersion: function() {
                var e = !1;
                return navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/iPad/i) ? navigator.userAgent.match(/OS 4_\d like Mac OS X/i) && (e = !0) : e = !1, e
            },
            isIE: function(t, i) {
                var n = e('<div style="display:none;"/>').appendTo(e("body"));
                n.html("<!--[if " + (i || "") + " IE " + (t || "") + "]><a>&nbsp;</a><![endif]-->");
                var o = n.find("a").length;
                return n.remove(), o
            },
            is_mobile: function() {
                var e = ["android", "webos", "iphone", "ipad", "blackberry", "Android", "webos", , "iPod", "iPhone", "iPad", "Blackberry", "BlackBerry"],
                    t = !1;
                for (var i in e) navigator.userAgent.split(e[i]).length > 1 && (t = !0);
                return t
            },
            callBackHandling: function(t, i, n) {
                try {
                    t.callBackArray && e.each(t.callBackArray, function(e, t) {
                        t && t.inmodule && t.inmodule === i && t.atposition && t.atposition === n && t.callback && t.callback.call()
                    })
                } catch (o) {
                    console.log("Call Back Failed")
                }
            },
            get_browser: function() {
                var e, t = navigator.appName,
                    i = navigator.userAgent,
                    n = i.match(/(opera|chrome|safari|firefox|msie)\/?\s*(\.?\d+(\.\d+)*)/i);
                return n && null != (e = i.match(/version\/([\.\d]+)/i)) && (n[2] = e[1]), n = n ? [n[1], n[2]] : [t, navigator.appVersion, "-?"], n[0]
            },
            get_browser_version: function() {
                var e, t = navigator.appName,
                    i = navigator.userAgent,
                    n = i.match(/(opera|chrome|safari|firefox|msie)\/?\s*(\.?\d+(\.\d+)*)/i);
                return n && null != (e = i.match(/version\/([\.\d]+)/i)) && (n[2] = e[1]), n = n ? [n[1], n[2]] : [t, navigator.appVersion, "-?"], n[1]
            },
            getHorizontalOffset: function(e, t) {
                var i = p(e, ".outer-left"),
                    n = p(e, ".outer-right");
                switch (t) {
                    case "left":
                        return i;
                    case "right":
                        return n;
                    case "both":
                        return i + n
                }
            },
            callingNewSlide: function(t, i, n) {
                var o = i.find(".next-revslide").length > 0 ? i.find(".next-revslide").index() : i.find(".processing-revslide").length > 0 ? i.find(".processing-revslide").index() : i.find(".active-revslide").index(),
                    r = 0;
                i.find(".next-revslide").removeClass("next-revslide"), n && e.isNumeric(n) || n.match(/to/g) ? (1 === n || -1 === n ? (r = o + n, r = 0 > r ? t.slideamount - 1 : r >= t.slideamount ? 0 : r) : (n = e.isNumeric(n) ? n : parseInt(n.split("to")[1], 0), r = 0 > n ? 0 : n > t.slideamount - 1 ? t.slideamount - 1 : n),
                    i.find(".tp-revslider-slidesli:eq(" + r + ")").addClass("next-revslide")) : n && i.find(".tp-revslider-slidesli").each(function() {
                    var t = e(this);
                    t.data("index") === n && t.addClass("next-revslide")
                }), r = i.find(".next-revslide").index(), i.trigger("revolution.nextslide.waiting"), r !== o && -1 != r ? O(i, t) : i.find(".next-revslide").removeClass("next-revslide")
            },
            slotSize: function(i, n) {
                n.slotw = Math.ceil(n.width / n.slots), "fullscreen" == n.sliderLayout ? n.sloth = Math.ceil(e(window).height() / n.slots) : n.sloth = Math.ceil(n.height / n.slots), "on" == n.autoHeight && i !== t && "" !== i && (n.sloth = Math.ceil(i.height() / n.slots))
            },
            setSize: function(i) {
                var n = (i.top_outer || 0) + (i.bottom_outer || 0),
                    o = parseInt(i.carousel.padding_top || 0, 0),
                    r = parseInt(i.carousel.padding_bottom || 0, 0),
                    s = i.gridheight[i.curWinRange];
                if (s = s < i.minHeight ? i.minHeight : s, "fullwidth" == i.sliderLayout && "off" == i.autoHeight && punchgs.TweenLite.set(i.c, {
                        maxHeight: s + "px"
                    }), i.c.css({
                        marginTop: o,
                        marginBottom: r
                    }), i.width = i.ul.width(), i.height = i.ul.height(), y(i), i.height = Math.round(i.gridheight[i.curWinRange] * (i.width / i.gridwidth[i.curWinRange])), i.height > i.gridheight[i.curWinRange] && "on" != i.autoHeight && (i.height = i.gridheight[i.curWinRange]), "fullscreen" == i.sliderLayout || i.infullscreenmode) {
                    i.height = i.bw * i.gridheight[i.curWinRange];
                    var a = (i.c.parent().width(), e(window).height());
                    if (i.fullScreenOffsetContainer != t) {
                        try {
                            var l = i.fullScreenOffsetContainer.split(",");
                            l && e.each(l, function(t, i) {
                                a = e(i).length > 0 ? a - e(i).outerHeight(!0) : a
                            })
                        } catch (c) {}
                        try {
                            i.fullScreenOffset.split("%").length > 1 && i.fullScreenOffset != t && i.fullScreenOffset.length > 0 ? a -= e(window).height() * parseInt(i.fullScreenOffset, 0) / 100 : i.fullScreenOffset != t && i.fullScreenOffset.length > 0 && (a -= parseInt(i.fullScreenOffset, 0))
                        } catch (c) {}
                    }
                    a = a < i.minHeight ? i.minHeight : a, a -= n, i.c.parent().height(a), i.c.closest(".rev_slider_wrapper").height(a), i.c.css({
                        height: "100%"
                    }), i.height = a, i.minHeight != t && i.height < i.minHeight && (i.height = i.minHeight)
                } else i.minHeight != t && i.height < i.minHeight && (i.height = i.minHeight), i.c.height(i.height);
                var d = {
                    height: o + r + n + i.height
                };
                i.c.closest(".forcefullwidth_wrapper_tp_banner").find(".tp-fullwidth-forcer").css(d), i.c.closest(".rev_slider_wrapper").css(d), y(i)
            },
            enterInViewPort: function(n) {
                n.waitForCountDown && (D(n.c, n), n.waitForCountDown = !1), n.waitForFirstSlide && (O(n.c, n), n.waitForFirstSlide = !1), ("playing" == n.sliderlaststatus || n.sliderlaststatus == t) && n.c.trigger("starttimer"), n.lastplayedvideos != t && n.lastplayedvideos.length > 0 && e.each(n.lastplayedvideos, function(e, t) {
                    i.playVideo(t, n)
                })
            },
            leaveViewPort: function(n) {
                n.sliderlaststatus = n.sliderstatus, n.c.trigger("stoptimer"), n.playingvideos != t && n.playingvideos.length > 0 && (n.lastplayedvideos = e.extend(!0, [], n.playingvideos), n.playingvideos && e.each(n.playingvideos, function(e, t) {
                    i.stopVideo && i.stopVideo(t, n)
                }))
            },
            unToggleState: function(i) {
                i != t && i.length > 0 && e.each(i, function(e, t) {
                    t.removeClass("rs-toggle-content-active")
                })
            },
            toggleState: function(i) {
                i != t && i.length > 0 && e.each(i, function(e, t) {
                    t.addClass("rs-toggle-content-active")
                })
            },
            lastToggleState: function(i) {
                var n = 0;
                return i != t && i.length > 0 && e.each(i, function(e, t) {
                    n = t.hasClass("rs-toggle-content-active")
                }), n
            }
        });
        var n = i.is_mobile(),
            o = function(t, i) {
                var n = [];
                return e.each(t, function(e, t) {
                    e != i && n.push(t)
                }), n
            },
            r = function(t, i, n) {
                n.c.find(t).each(function() {
                    var t = e(this);
                    t.data("liref") === i && t.remove()
                })
            },
            s = function(i, n) {
                return !e("body").data(i) && (n.filesystem ? (n.errorm === t && (n.errorm = "<br>Local Filesystem Detected !<br>Put this to your header:"), console.warn("Local Filesystem detected !"), n.errorm = n.errorm + '<br>&lt;script type="text/javascript" src="' + n.jsFileLocation + i + n.extensions_suffix + '"&gt;&lt;/script&gt;', console.warn(n.jsFileLocation + i + n.extensions_suffix + " could not be loaded !"), console.warn("Please use a local Server or work online or make sure that you load all needed Libraries manually in your Document."), console.log(" "), n.modulesfailing = !0, !1) : (e.ajax({
                    url: n.jsFileLocation + i + n.extensions_suffix,
                    dataType: "script",
                    cache: !0,
                    error: function(e) {
                        console.warn("Slider Revolution 5.0 Error !"), console.error("Failure at Loading:" + i + n.extensions_suffix + " on Path:" + n.jsFileLocation), console.info(e)
                    }
                }), void e("body").data(i, !0)))
            },
            a = function(n, o) {
                var r = new Object,
                    a = n.navigation;
                return r.kenburns = !1, r.parallax = !1, r.carousel = !1, r.navigation = !1, r.videos = !1, r.actions = !1, r.layeranim = !1, r.migration = !1, o.data("version") && o.data("version").toString().match(/5./gi) ? (o.find("img").each(function() {
                    "on" == e(this).data("kenburns") && (r.kenburns = !0)
                }), ("carousel" == n.sliderType || "on" == a.keyboardNavigation || "on" == a.mouseScrollNavigation || "on" == a.touch.touchenabled || a.arrows.enable || a.bullets.enable || a.thumbnails.enable || a.tabs.enable) && (r.navigation = !0), o.find(".tp-caption, .tp-static-layer, .rs-background-video-layer").each(function() {
                    var i = e(this);
                    (i.data("ytid") != t || i.find("iframe").length > 0 && i.find("iframe").attr("src").toLowerCase().indexOf("youtube") > 0) && (r.videos = !0), (i.data("vimeoid") != t || i.find("iframe").length > 0 && i.find("iframe").attr("src").toLowerCase().indexOf("vimeo") > 0) && (r.videos = !0), i.data("actions") !== t && (r.actions = !0), r.layeranim = !0
                }), o.find("li").each(function() {
                    e(this).data("link") && e(this).data("link") != t && (r.layeranim = !0, r.actions = !0)
                }), !r.videos && (o.find(".rs-background-video-layer").length > 0 || o.find(".tp-videolayer").length > 0 || o.find("iframe").length > 0 || o.find("video").length > 0) && (r.videos = !0), "carousel" == n.sliderType && (r.carousel = !0), ("off" !== n.parallax.type || n.viewPort.enable || "true" == n.viewPort.enable) && (r.parallax = !0)) : (r.kenburns = !0, r.parallax = !0, r.carousel = !1, r.navigation = !0, r.videos = !0, r.actions = !0, r.layeranim = !0, r.migration = !0), "hero" == n.sliderType && (r.carousel = !1, r.navigation = !1), window.location.href.match(/file:/gi) && (r.filesystem = !0, n.filesystem = !0), r.videos && "undefined" == typeof i.isVideoPlaying && s("revolution.extension.video", n), r.carousel && "undefined" == typeof i.prepareCarousel && s("revolution.extension.carousel", n), r.carousel || "undefined" != typeof i.animateSlide || s("revolution.extension.slideanims", n), r.actions && "undefined" == typeof i.checkActions && s("revolution.extension.actions", n), r.layeranim && "undefined" == typeof i.handleStaticLayers && s("revolution.extension.layeranimation", n), r.kenburns && "undefined" == typeof i.stopKenBurn && s("revolution.extension.kenburn", n), r.navigation && "undefined" == typeof i.createNavigation && s("revolution.extension.navigation", n), r.migration && "undefined" == typeof i.migration && s("revolution.extension.migration", n), r.parallax && "undefined" == typeof i.checkForParallax && s("revolution.extension.parallax", n), r
            },
            l = function(e, t) {
                t.filesystem || "undefined" != typeof punchgs && (!t.kenburns || t.kenburns && "undefined" != typeof i.stopKenBurn) && (!t.navigation || t.navigation && "undefined" != typeof i.createNavigation) && (!t.carousel || t.carousel && "undefined" != typeof i.prepareCarousel) && (!t.videos || t.videos && "undefined" != typeof i.resetVideo) && (!t.actions || t.actions && "undefined" != typeof i.checkActions) && (!t.layeranim || t.layeranim && "undefined" != typeof i.handleStaticLayers) && (!t.migration || t.migration && "undefined" != typeof i.migration) && (!t.parallax || t.parallax && "undefined" != typeof i.checkForParallax) && (t.carousel || !t.carousel && "undefined" != typeof i.animateSlide) ? e.trigger("scriptsloaded") : setTimeout(function() {
                    l(e, t)
                }, 50)
            },
            c = function(t) {
                var i = new RegExp("themepunch.revolution.min.js", "gi"),
                    n = "";
                return e("script").each(function() {
                    var t = e(this).attr("src");
                    t && t.match(i) && (n = t)
                }), n = n.replace("jquery.themepunch.revolution.min.js", ""), n = n.replace("jquery.themepunch.revolution.js", ""), n = n.split("?")[0]
            },
            d = function(t, i) {
                var n = 9999,
                    o = 0,
                    r = 0,
                    s = 0,
                    a = e(window).width(),
                    l = i && 9999 == t.responsiveLevels ? t.visibilityLevels : t.responsiveLevels;
                l && l.length && e.each(l, function(e, t) {
                    t > a && (0 == o || o > t) && (n = t, s = e, o = t), a > t && t > o && (o = t, r = e)
                }), n > o && (s = r), i ? t.forcedWinRange = s : t.curWinRange = s
            },
            u = function(e, t) {
                t.carousel.maxVisibleItems = t.carousel.maxVisibleItems < 1 ? 999 : t.carousel.maxVisibleItems, t.carousel.vertical_align = "top" === t.carousel.vertical_align ? "0%" : "bottom" === t.carousel.vertical_align ? "100%" : "50%"
            },
            p = function(t, i) {
                var n = 0;
                return t.find(i).each(function() {
                    var t = e(this);
                    !t.hasClass("tp-forcenotvisible") && n < t.outerWidth() && (n = t.outerWidth())
                }), n
            },
            h = function(o, r) {
                if (o == t) return !1;
                if (o.data("aimg") != t && ("enabled" == o.data("aie8") && i.isIE(8) || "enabled" == o.data("amobile") && n) && o.html('<img class="tp-slider-alternative-image" src="' + o.data("aimg") + '">'), o.find(">ul").addClass("tp-revslider-mainul"), r.c = o, r.ul = o.find(".tp-revslider-mainul"), r.ul.find(">li").each(function(t) {
                        var i = e(this);
                        "on" == i.data("hideslideonmobile") && n && i.remove()
                    }), r.cid = o.attr("id"), r.ul.css({
                        visibility: "visible"
                    }), r.slideamount = r.ul.find(">li").length, r.slayers = o.find(".tp-static-layers"), r.ul.find(">li").each(function(t) {
                        e(this).data("originalindex", t)
                    }), "on" == r.shuffle) {
                    var s = new Object,
                        a = r.ul.find(">li:first-child");
                    s.fstransition = a.data("fstransition"), s.fsmasterspeed = a.data("fsmasterspeed"), s.fsslotamount = a.data("fsslotamount");
                    for (var l = 0; l < r.slideamount; l++) {
                        var c = Math.round(Math.random() * r.slideamount);
                        r.ul.find(">li:eq(" + c + ")").prependTo(r.ul)
                    }
                    var u = r.ul.find(">li:first-child");
                    u.data("fstransition", s.fstransition), u.data("fsmasterspeed", s.fsmasterspeed), u.data("fsslotamount", s.fsslotamount), r.li = r.ul.find(">li")
                }
                if (r.li = r.ul.find(">li"), r.thumbs = new Array, r.slots = 4, r.act = -1, r.firststart = 1, r.loadqueue = new Array, r.syncload = 0, r.conw = o.width(), r.conh = o.height(), r.responsiveLevels.length > 1 ? r.responsiveLevels[0] = 9999 : r.responsiveLevels = 9999, e.each(r.li, function(i, n) {
                        var n = e(n),
                            o = n.find(".rev-slidebg") || n.find("img").first(),
                            s = 0;
                        n.addClass("tp-revslider-slidesli"), n.data("index") === t && n.data("index", "rs-" + Math.round(999999 * Math.random()));
                        var a = new Object;
                        a.params = new Array, a.id = n.data("index"), a.src = n.data("thumb") !== t ? n.data("thumb") : o.data("lazyload") !== t ? o.data("lazyload") : o.attr("src"), n.data("title") !== t && a.params.push({
                            from: RegExp("\\{\\{title\\}\\}", "g"),
                            to: n.data("title")
                        }), n.data("description") !== t && a.params.push({
                            from: RegExp("\\{\\{description\\}\\}", "g"),
                            to: n.data("description")
                        });
                        for (var s = 1; 10 >= s; s++) n.data("param" + s) !== t && a.params.push({
                            from: RegExp("\\{\\{param" + s + "\\}\\}", "g"),
                            to: n.data("param" + s)
                        });
                        if (r.thumbs.push(a), n.data("origindex", n.index()), n.data("link") != t) {
                            var l = n.data("link"),
                                c = n.data("target") || "_self",
                                d = "back" === n.data("slideindex") ? 0 : 60,
                                u = n.data("linktoslide"),
                                p = u;
                            u != t && "next" != u && "prev" != u && r.li.each(function() {
                                var t = e(this);
                                t.data("origindex") + 1 == p && (u = t.data("index"))
                            }), "slide" != l && (u = "no");
                            var h = '<div class="tp-caption sft slidelink" style="cursor:pointer;width:100%;height:100%;z-index:' + d + ';" data-x="center" data-y="center" ',
                                f = "scroll_under" === u ? '[{"event":"click","action":"scrollbelow","offset":"100px","delay":"0"}]' : "prev" === u ? '[{"event":"click","action":"jumptoslide","slide":"prev","delay":"0.2"}]' : "next" === u ? '[{"event":"click","action":"jumptoslide","slide":"next","delay":"0.2"}]' : '[{"event":"click","action":"jumptoslide","slide":"' + u + '","delay":"0.2"}]';
                            h = "no" == u ? h + ' data-start="0">' : h + "data-actions='" + f + '\' data-start="0">', h += '<a style="width:100%;height:100%;display:block"', h = "slide" != l ? h + ' target="' + c + '" href="' + l + '"' : h, h += '><span style="width:100%;height:100%;display:block"></span></a></div>', n.append(h)
                        }
                    }), r.rle = r.responsiveLevels.length || 1, r.gridwidth = f(r.gridwidth, r.rle), r.gridheight = f(r.gridheight, r.rle), "on" == r.simplifyAll && (i.isIE(8) || i.iOSVersion()) && (o.find(".tp-caption").each(function() {
                        var t = e(this);
                        t.removeClass("customin customout").addClass("fadein fadeout"), t.data("splitin", ""), t.data("speed", 400)
                    }), r.li.each(function() {
                        var t = e(this);
                        t.data("transition", "fade"), t.data("masterspeed", 500), t.data("slotamount", 1);
                        var i = t.find(".rev-slidebg") || t.find(">img").first();
                        i.data("kenburns", "off")
                    })), r.desktop = !navigator.userAgent.match(/(iPhone|iPod|iPad|Android|BlackBerry|BB10|mobi|tablet|opera mini|nexus 7)/i), r.autoHeight = "fullscreen" == r.sliderLayout ? "on" : r.autoHeight, "fullwidth" == r.sliderLayout && "off" == r.autoHeight && o.css({
                        maxHeight: r.gridheight[r.curWinRange] + "px"
                    }), "auto" != r.sliderLayout && 0 == o.closest(".forcefullwidth_wrapper_tp_banner").length && ("fullscreen" !== r.sliderLayout || "on" != r.fullScreenAutoWidth)) {
                    var p = o.parent(),
                        h = p.css("marginBottom"),
                        y = p.css("marginTop");
                    h = h === t ? 0 : h, y = y === t ? 0 : y, p.wrap('<div class="forcefullwidth_wrapper_tp_banner" style="position:relative;width:100%;height:auto;margin-top:' + y + ";margin-bottom:" + h + '"></div>'), o.closest(".forcefullwidth_wrapper_tp_banner").append('<div class="tp-fullwidth-forcer" style="width:100%;height:' + o.height() + 'px"></div>'), o.parent().css({
                        marginTop: "0px",
                        marginBottom: "0px"
                    }), o.parent().css({
                        position: "absolute"
                    })
                }
                if (r.shadow !== t && r.shadow > 0 && (o.parent().addClass("tp-shadow" + r.shadow), o.parent().append('<div class="tp-shadowcover"></div>'), o.parent().find(".tp-shadowcover").css({
                        backgroundColor: o.parent().css("backgroundColor"),
                        backgroundImage: o.parent().css("backgroundImage")
                    })), d(r), d(r, !0), !o.hasClass("revslider-initialised")) {
                    o.addClass("revslider-initialised"), o.addClass("tp-simpleresponsive"), o.attr("id") == t && o.attr("id", "revslider-" + Math.round(1e3 * Math.random() + 5)), r.firefox13 = !1, r.ie = !e.support.opacity, r.ie9 = 9 == document.documentMode, r.origcd = r.delay;
                    var x = e.fn.jquery.split("."),
                        T = parseFloat(x[0]),
                        _ = parseFloat(x[1]);
                    parseFloat(x[2] || "0"), 1 == T && 7 > _ && o.html('<div style="text-align:center; padding:40px 0px; font-size:20px; color:#992222;"> The Current Version of jQuery:' + x + " <br>Please update your jQuery Version to min. 1.7 in Case you wish to use the Revolution Slider Plugin</div>"), T > 1 && (r.ie = !1);
                    var k = new Object;
                    k.addedyt = 0, k.addedvim = 0, k.addedvid = 0, o.find(".tp-caption, .rs-background-video-layer").each(function(o) {
                        var s = e(this),
                            a = s.data("autoplayonlyfirsttime"),
                            l = s.data("autoplay");
                        s.hasClass("tp-static-layer") && i.handleStaticLayers && i.handleStaticLayers(s, r);
                        var c = s.data("noposteronmobile") || s.data("noPosterOnMobile") || s.data("posteronmobile") || s.data("posterOnMobile") || s.data("posterOnMObile");
                        s.data("noposteronmobile", c);
                        var d = 0;
                        if (s.find("iframe").each(function() {
                                punchgs.TweenLite.set(e(this), {
                                    autoAlpha: 0
                                }), d++
                            }), d > 0 && s.data("iframes", !0), s.hasClass("tp-caption")) {
                            var u = s.hasClass("slidelink") ? "width:100% !important;height:100% !important;" : "";
                            s.wrap('<div class="tp-parallax-wrap" style="' + u + 'position:absolute;visibility:hidden"><div class="tp-loop-wrap" style="' + u + 'position:absolute;"><div class="tp-mask-wrap" style="' + u + 'position:absolute" ></div></div></div>');
                            var p = ["pendulum", "rotate", "slideloop", "pulse", "wave"],
                                h = s.closest(".tp-loop-wrap");
                            e.each(p, function(e, t) {
                                var i = s.find(".rs-" + t),
                                    n = i.data() || "";
                                "" != n && (h.data(n), h.addClass("rs-" + t), i.children(0).unwrap(), s.data("loopanimation", "on"))
                            }), punchgs.TweenLite.set(s, {
                                visibility: "hidden"
                            })
                        }
                        var f = s.data("actions");
                        f !== t && i.checkActions(s, r, f), g(s, r), i.checkVideoApis && (k = i.checkVideoApis(s, r, k)), n && ((1 == a || "true" == a) && (s.data("autoplayonlyfirsttime", "false"), a = !1), (1 == l || "true" == l || "on" == l || "1sttime" == l) && (s.data("autoplay", "off"), l = "off")), (1 == a || "true" == a || "1sttime" == l) && s.closest("li.tp-revslider-slidesli").addClass("rs-pause-timer-once"), (1 == l || "true" == l || "on" == l || "no1sttime" == l) && s.closest("li.tp-revslider-slidesli").addClass("rs-pause-timer-always")
                    }), o.hover(function() {
                        o.trigger("tp-mouseenter"), r.overcontainer = !0
                    }, function() {
                        o.trigger("tp-mouseleft"), r.overcontainer = !1
                    }), o.on("mouseover", function() {
                        o.trigger("tp-mouseover"), r.overcontainer = !0
                    }), o.find(".tp-caption video").each(function(t) {
                        var i = e(this);
                        i.removeClass("video-js vjs-default-skin"), i.attr("preload", ""), i.css({
                            display: "none"
                        })
                    }), "standard" !== r.sliderType && (r.lazyType = "all"), $(o.find(".tp-static-layers"), r, 0), A(o.find(".tp-static-layers img"), r, function() {
                        o.find(".tp-static-layers img").each(function() {
                            var i = e(this),
                                n = i.data("lazyload") != t ? i.data("lazyload") : i.attr("src"),
                                o = P(r, n);
                            i.attr("src", o.src)
                        })
                    }), r.li.each(function(t) {
                        var i = e(this);
                        ("all" == r.lazyType || "smart" == r.lazyType && (0 == t || 1 == t || t == r.slideamount || t == r.slideamount - 1)) && ($(i, r, t), A(i, r, function() {
                            "carousel" == r.sliderType && punchgs.TweenLite.to(i, 1, {
                                autoAlpha: 1,
                                ease: punchgs.Power3.easeInOut
                            })
                        }))
                    });
                    var S = z("#")[0];
                    if (S.length < 9 && S.split("slide").length > 1) {
                        var C = parseInt(S.split("slide")[1], 0);
                        1 > C && (C = 1), C > r.slideamount && (C = r.slideamount), r.startWithSlide = C - 1
                    }
                    o.append('<div class="tp-loader ' + r.spinner + '"><div class="dot1"></div><div class="dot2"></div><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div>'), 0 === o.find(".tp-bannertimer").length && o.append('<div class="tp-bannertimer" style="visibility:hidden"></div>'), o.find(".tp-bannertimer").css({
                        width: "0%"
                    }), o.find(".tp-bannertimer").data("opt", r), r.ul.css({
                        display: "block"
                    }), w(o, r), "off" !== r.parallax.type && i.checkForParallax(o, r), i.setSize(r), "hero" !== r.sliderType && i.createNavigation(o, r), i.resizeThumbsTabs && i.resizeThumbsTabs(r), m(r);
                    var E = r.viewPort;
                    r.inviewport = !1, E != t && E.enable && (e.isNumeric(E.visible_area) || -1 !== E.visible_area.indexOf("%") && (E.visible_area = parseInt(E.visible_area) / 100), i.scrollTicker && i.scrollTicker(r, o)), setTimeout(function() {
                        "carousel" == r.sliderType && i.prepareCarousel(r), !E.enable || E.enable && r.inviewport || E.enable && !r.inviewport && "wait" == !E.outof ? O(o, r) : r.waitForFirstSlide = !0, i.manageNavigation && i.manageNavigation(r), r.slideamount > 1 && (!E.enable || E.enable && r.inviewport ? D(o, r) : r.waitForCountDown = !0), setTimeout(function() {
                            o.trigger("revolution.slide.onloaded")
                        }, 100)
                    }, r.startDelay), r.startDelay = 0, e("body").data("rs-fullScreenMode", !1), e(window).on("mozfullscreenchange webkitfullscreenchange fullscreenchange", function() {
                        e("body").data("rs-fullScreenMode", !e("body").data("rs-fullScreenMode")), e("body").data("rs-fullScreenMode") && setTimeout(function() {
                            e(window).trigger("resize")
                        }, 200)
                    });
                    var M = "resize.revslider-" + o.attr("id");
                    e(window).on(M, function() {
                        return o != t && (0 != e("body").find(o) && m(r), void((o.outerWidth(!0) != r.width || o.is(":hidden") || "fullscreen" == r.sliderLayout && e(window).height() != r.lastwindowheight) && (r.lastwindowheight = e(window).height(), b(o, r))))
                    }), v(o, r), m(r), r.fallbacks.disableFocusListener || "true" == r.fallbacks.disableFocusListener || r.fallbacks.disableFocusListener === !0 || L(o, r)
                }
            },
            f = function(t, i) {
                if (!e.isArray(t)) {
                    var n = t;
                    t = new Array, t.push(n)
                }
                if (t.length < i)
                    for (var n = t[t.length - 1], o = 0; o < i - t.length + 2; o++) t.push(n);
                return t
            },
            g = function(n, o) {
                "sliderenter" === n.data("start") && (o.layersonhover === t && (o.c.on("tp-mouseenter", function() {
                    o.layersonhover && e.each(o.layersonhover, function(e, n) {
                        n.data("animdirection", "in");
                        var r = n.data("timeline_out"),
                            s = "carousel" === o.sliderType ? 0 : o.width / 2 - o.gridwidth[o.curWinRange] * o.bw / 2,
                            a = 0,
                            l = n.closest(".tp-revslider-slidesli");
                        if (l.hasClass("active-revslide") || l.hasClass("processing-revslide")) {
                            r != t && (r.pause(0), r.kill()), i.animateSingleCaption(n, o, s, a, 0, !1, !0);
                            var c = n.data("timeline");
                            n.data("triggerstate", "on"), c.play(0)
                        }
                    })
                }), o.c.on("tp-mouseleft", function() {
                    o.layersonhover && e.each(o.layersonhover, function(e, t) {
                        t.data("animdirection", "out"), t.data("triggered", !0), t.data("triggerstate", "off"), i.stopVideo && i.stopVideo(t, o), i.endMoveCaption && i.endMoveCaption(t, null, null, o)
                    })
                }), o.layersonhover = new Array), o.layersonhover.push(n))
            },
            m = function(t) {
                var n = i.getHorizontalOffset(t.c, "left");
                if ("auto" == t.sliderLayout || "fullscreen" === t.sliderLayout && "on" == t.fullScreenAutoWidth) "fullscreen" == t.sliderLayout && "on" == t.fullScreenAutoWidth ? punchgs.TweenLite.set(t.ul, {
                    left: 0,
                    width: t.c.width()
                }) : punchgs.TweenLite.set(t.ul, {
                    left: n,
                    width: t.c.width() - i.getHorizontalOffset(t.c, "both")
                });
                else {
                    var o = Math.ceil(t.c.closest(".forcefullwidth_wrapper_tp_banner").offset().left - n);
                    punchgs.TweenLite.set(t.c.parent(), {
                        left: 0 - o + "px",
                        width: e(window).width() - i.getHorizontalOffset(t.c, "both")
                    })
                }
                t.slayers && "fullwidth" != t.sliderLayout && "fullscreen" != t.sliderLayout && punchgs.TweenLite.set(t.slayers, {
                    left: n
                })
            },
            v = function(n, o, r) {
                var s = n.parent();
                e(window).width() < o.hideSliderAtLimit ? (n.trigger("stoptimer"), "none" != s.css("display") && s.data("olddisplay", s.css("display")), s.css({
                    display: "none"
                })) : n.is(":hidden") && r && (s.data("olddisplay") != t && "undefined" != s.data("olddisplay") && "none" != s.data("olddisplay") ? s.css({
                    display: s.data("olddisplay")
                }) : s.css({
                    display: "block"
                }), n.trigger("restarttimer"), setTimeout(function() {
                    b(n, o)
                }, 150)), i.hideUnHideNav && i.hideUnHideNav(o)
            },
            b = function(n, o) {
                if (1 == o.infullscreenmode && (o.minHeight = e(window).height()), d(o), d(o, !0), !i.resizeThumbsTabs || i.resizeThumbsTabs(o) === !0) {
                    if (v(n, o, !0), m(o), "carousel" == o.sliderType && i.prepareCarousel(o, !0), n === t) return !1;
                    i.setSize(o), o.conw = o.c.width(), o.conh = o.infullscreenmode ? o.minHeight : o.c.height();
                    var r = n.find(".active-revslide .slotholder"),
                        s = n.find(".processing-revslide .slotholder");
                    x(n, o, n, 2), "standard" === o.sliderType && (punchgs.TweenLite.set(s.find(".defaultimg"), {
                        opacity: 0
                    }), r.find(".defaultimg").css({
                        opacity: 1
                    })), "carousel" == o.sliderType && o.lastconw != o.conw && (clearTimeout(o.pcartimer), o.pcartimer = setTimeout(function() {
                        i.prepareCarousel(o, !0)
                    }, 100), o.lastconw = o.conw), i.manageNavigation && i.manageNavigation(o), i.animateTheCaptions && i.animateTheCaptions(n.find(".active-revslide"), o, !0), "on" == s.data("kenburns") && i.startKenBurn(s, o, s.data("kbtl").progress()), "on" == r.data("kenburns") && i.startKenBurn(r, o, r.data("kbtl").progress()), i.animateTheCaptions && i.animateTheCaptions(s.closest("li"), o, !0), i.manageNavigation && i.manageNavigation(o)
                }
            },
            y = function(e) {
                e.bw = e.width / e.gridwidth[e.curWinRange], e.bh = e.height / e.gridheight[e.curWinRange], e.bh > e.bw ? e.bh = e.bw : e.bw = e.bh, (e.bh > 1 || e.bw > 1) && (e.bw = 1, e.bh = 1)
            },
            w = function(o, r) {
                if (o.find(".tp-caption").each(function() {
                        var i = e(this);
                        i.data("transition") !== t && i.addClass(i.data("transition"))
                    }), r.ul.css({
                        overflow: "hidden",
                        width: "100%",
                        height: "100%",
                        maxHeight: o.parent().css("maxHeight")
                    }), "on" == r.autoHeight && (r.ul.css({
                        overflow: "hidden",
                        width: "100%",
                        height: "100%",
                        maxHeight: "none"
                    }), o.css({
                        maxHeight: "none"
                    }), o.parent().css({
                        maxHeight: "none"
                    })), r.li.each(function(i) {
                        var n = e(this),
                            o = n.data("originalindex");
                        (r.startWithSlide != t && o == r.startWithSlide || r.startWithSlide === t && 0 == i) && n.addClass("next-revslide"), n.css({
                            width: "100%",
                            height: "100%",
                            overflow: "hidden"
                        })
                    }), "carousel" === r.sliderType) {
                    r.ul.css({
                        overflow: "visible"
                    }).wrap('<div class="tp-carousel-wrapper" style="width:100%;height:100%;position:absolute;top:0px;left:0px;overflow:hidden;"></div>');
                    var s = '<div style="clear:both;display:block;width:100%;height:1px;position:relative;margin-bottom:-1px"></div>';
                    r.c.parent().prepend(s), r.c.parent().append(s), i.prepareCarousel(r)
                }
                o.parent().css({
                    overflow: "visible"
                }), r.li.find(">img").each(function(i) {
                    var o = e(this),
                        s = o.closest("li").find(".rs-background-video-layer");
                    s.addClass("defaultvid").css({
                        zIndex: 30
                    }), o.addClass("defaultimg"), "on" == r.fallbacks.panZoomDisableOnMobile && n && (o.data("kenburns", "off"), o.data("bgfit", "cover")), o.wrap('<div class="slotholder" style="width:100%;height:100%;"></div>'), s.appendTo(o.closest("li").find(".slotholder"));
                    var a = o.data();
                    o.closest(".slotholder").data(a), s.length > 0 && a.bgparallax != t && s.data("bgparallax", a.bgparallax), "none" != r.dottedOverlay && r.dottedOverlay != t && o.closest(".slotholder").append('<div class="tp-dottedoverlay ' + r.dottedOverlay + '"></div>');
                    var l = o.attr("src");
                    a.src = l, a.bgfit = a.bgfit || "cover", a.bgrepeat = a.bgrepeat || "no-repeat", a.bgposition = a.bgposition || "center center";
                    var c = o.closest(".slotholder");
                    o.parent().append('<div class="tp-bgimg defaultimg" style="background-color:' + o.css("backgroundColor") + ";background-repeat:" + a.bgrepeat + ";background-image:url(" + l + ");background-size:" + a.bgfit + ";background-position:" + a.bgposition + ';width:100%;height:100%;"></div>');
                    var d = document.createComment("Runtime Modification - Img tag is Still Available for SEO Goals in Source - " + o.get(0).outerHTML);
                    o.replaceWith(d), o = c.find(".tp-bgimg"), o.data(a), o.attr("src", l), ("standard" === r.sliderType || "undefined" === r.sliderType) && o.css({
                        opacity: 0
                    })
                })
            },
            x = function(t, i, n, o) {
                i.removePrepare = i.removePrepare + o, n.find(".slot, .slot-circle-wrapper").each(function() {
                    e(this).remove()
                }), i.transition = 0, i.removePrepare = 0
            },
            T = function(e) {
                var i = e;
                return e != t && e.length > 0 && (i = e.split("?")[0]), i
            },
            _ = function(e, t) {
                var i = e.split("/"),
                    n = t.split("/");
                i.pop();
                for (var o = 0; o < n.length; o++) "." != n[o] && (".." == n[o] ? i.pop() : i.push(n[o]));
                return i.join("/")
            },
            k = function(t, i, n) {
                i.syncload--, i.loadqueue && e.each(i.loadqueue, function(e, i) {
                    var o = i.src.replace(/\.\.\/\.\.\//gi, ""),
                        r = self.location.href,
                        s = document.location.origin,
                        a = r.substring(0, r.length - 1) + "/" + o,
                        l = s + "/" + o,
                        c = _(self.location.href, i.src);
                    r = r.substring(0, r.length - 1) + o, s += o, (T(s) === T(decodeURIComponent(t.src)) || T(r) === T(decodeURIComponent(t.src)) || T(c) === T(decodeURIComponent(t.src)) || T(l) === T(decodeURIComponent(t.src)) || T(a) === T(decodeURIComponent(t.src)) || T(i.src) === T(decodeURIComponent(t.src)) || T(i.src).replace(/^.*\/\/[^\/]+/, "") === T(decodeURIComponent(t.src)).replace(/^.*\/\/[^\/]+/, "") || "file://" === window.location.origin && T(t.src).match(new RegExp(o))) && (i.progress = n, i.width = t.width, i.height = t.height)
                }), S(i)
            },
            S = function(t) {
                3 != t.syncload && t.loadqueue && e.each(t.loadqueue, function(e, i) {
                    if (i.progress.match(/prepared/g) && t.syncload <= 3) {
                        t.syncload++;
                        var n = new Image;
                        n.onload = function() {
                            k(this, t, "loaded")
                        }, n.onerror = function() {
                            k(this, t, "failed")
                        }, n.src = i.src, i.progress = "inload"
                    }
                })
            },
            C = function(t, i, n) {
                var o = !1;
                if (i.loadqueue && e.each(i.loadqueue, function(e, i) {
                        i.src === t && (o = !0)
                    }), !o) {
                    var r = new Object;
                    r.src = t, r.prio = n, r.progress = "prepared", i.loadqueue.push(r)
                }
            },
            $ = function(i, n, o) {
                i.find("img,.defaultimg").each(function() {
                    var i = e(this),
                        r = i.data("lazyload") !== t && "undefined" !== i.data("lazyload") ? i.data("lazyload") : i.attr("src");
                    i.data("start-to-load", e.now()), C(r, n, o)
                }), S(n)
            },
            P = function(t, i) {
                var n = new Object;
                return t.loadqueue && e.each(t.loadqueue, function(e, t) {
                    t.src == i && (n = t)
                }), n
            },
            A = function(n, o, r) {
                var s = !1;
                n.find("img,.defaultimg").each(function() {
                    var r = e(this),
                        a = r.data("lazyload") != t ? r.data("lazyload") : r.attr("src"),
                        l = P(o, a);
                    if (r.data("loaded") === t && l !== t && l.progress && l.progress.match(/loaded/g)) {
                        if (r.attr("src", l.src), r.hasClass("defaultimg")) i.isIE(8) ? defimg.attr("src", l.src) : r.css({
                            backgroundImage: 'url("' + l.src + '")'
                        }), n.data("owidth", l.width), n.data("oheight", l.height), n.find(".slotholder").data("owidth", l.width), n.find(".slotholder").data("oheight", l.height);
                        else {
                            var c = r.data("ww"),
                                d = r.data("hh");
                            r.data("owidth", l.width), r.data("oheight", l.height), c = c == t || "auto" == c || "" == c ? l.width : c, d = d == t || "auto" == d || "" == d ? l.height : d, r.data("ww", c), r.data("hh", d)
                        }
                        r.data("loaded", !0)
                    }
                    if (l && l.progress && l.progress.match(/inprogress|inload|prepared/g) && (e.now() - r.data("start-to-load") < 5e3 ? s = !0 : console.error(a + "  Could not be loaded !")), 1 == o.youtubeapineeded && (!window.YT || YT.Player == t) && (s = !0, e.now() - o.youtubestarttime > 5e3 && 1 != o.youtubewarning)) {
                        o.youtubewarning = !0;
                        var u = "YouTube Api Could not be loaded !";
                        "https:" === location.protocol && (u += " Please Check and Renew SSL Certificate !"), console.error(u), o.c.append('<div style="position:absolute;top:50%;width:100%;color:#e74c3c;  font-size:16px; text-align:center; padding:15px;background:#000; display:block;"><strong>' + u + "</strong></div>")
                    }
                    if (1 == o.vimeoapineeded && !window.Froogaloop && (s = !0, e.now() - o.vimeostarttime > 5e3 && 1 != o.vimeowarning)) {
                        o.vimeowarning = !0;
                        var u = "Vimeo Froogaloop Api Could not be loaded !";
                        "https:" === location.protocol && (u += " Please Check and Renew SSL Certificate !"), console.error(u), o.c.append('<div style="position:absolute;top:50%;width:100%;color:#e74c3c;  font-size:16px; text-align:center; padding:15px;background:#000; display:block;"><strong>' + u + "</strong></div>")
                    }
                }), s ? setTimeout(function() {
                    A(n, o, r)
                }, 19) : r()
            },
            O = function(t, n) {
                if (clearTimeout(n.waitWithSwapSlide), t.find(".processing-revslide").length > 0) return n.waitWithSwapSlide = setTimeout(function() {
                    O(t, n)
                }, 150), !1;
                var o = t.find(".active-revslide"),
                    r = t.find(".next-revslide"),
                    s = r.find(".defaultimg");
                return r.index() === o.index() ? (r.removeClass("next-revslide"), !1) : (r.removeClass("next-revslide").addClass("processing-revslide"), r.data("slide_on_focus_amount", r.data("slide_on_focus_amount") + 1 || 1), "on" == n.stopLoop && r.index() == n.lastslidetoshow - 1 && (t.find(".tp-bannertimer").css({
                    visibility: "hidden"
                }), t.trigger("revolution.slide.onstop"), n.noloopanymore = 1), r.index() === n.slideamount - 1 && (n.looptogo = n.looptogo - 1, n.looptogo <= 0 && (n.stopLoop = "on")), n.tonpause = !0, t.trigger("stoptimer"), n.cd = 0, "off" === n.spinner ? t.find(".tp-loader").css({
                    display: "none"
                }) : t.find(".tp-loader").css({
                    display: "block"
                }), $(r, n, 1), void A(r, n, function() {
                    r.find(".rs-background-video-layer").each(function() {
                        var t = e(this);
                        t.hasClass("HasListener") || (t.data("bgvideo", 1), i.manageVideoLayer(t, n)), 0 == t.find(".rs-fullvideo-cover").length && t.append('<div class="rs-fullvideo-cover"></div>')
                    }), E(n, s, t)
                }))
            },
            E = function(e, n, o) {
                var r = o.find(".active-revslide"),
                    s = o.find(".processing-revslide"),
                    a = r.find(".slotholder"),
                    l = s.find(".slotholder");
                e.tonpause = !1, e.cd = 0, o.trigger("nulltimer"), o.find(".tp-loader").css({
                    display: "none"
                }), i.setSize(e), i.slotSize(n, e), i.manageNavigation && i.manageNavigation(e);
                var c = {};
                c.nextslide = s, c.currentslide = r, o.trigger("revolution.slide.onbeforeswap", c), e.transition = 1, e.videoplaying = !1, s.data("delay") != t ? (e.cd = 0, e.delay = s.data("delay")) : e.delay = e.origcd;
                var d = r.index(),
                    u = s.index();
                e.sdir = d > u ? 1 : 0, "arrow" == e.sc_indicator && (0 == d && u == e.slideamount - 1 && (e.sdir = 1), d == e.slideamount - 1 && 0 == u && (e.sdir = 0)), e.lsdir = e.lsdir === t ? e.sdir : e.lsdir, e.dirc = e.lsdir != e.sdir, e.lsdir = e.sdir, r.index() != s.index() && 1 != e.firststart && i.removeTheCaptions && i.removeTheCaptions(r, e), s.hasClass("rs-pause-timer-once") || s.hasClass("rs-pause-timer-always") ? e.videoplaying = !0 : o.trigger("restarttimer"), s.removeClass("rs-pause-timer-once");
                var p, h;
                if ("carousel" == e.sliderType) h = new punchgs.TimelineLite, i.prepareCarousel(e, h), M(o, e, l, a, s, r, h), e.transition = 0, e.firststart = 0;
                else {
                    h = new punchgs.TimelineLite({
                        onComplete: function() {
                            M(o, e, l, a, s, r, h)
                        }
                    }), h.add(punchgs.TweenLite.set(l.find(".defaultimg"), {
                        opacity: 0
                    })), h.pause(), 1 == e.firststart && (punchgs.TweenLite.set(r, {
                        autoAlpha: 0
                    }), e.firststart = 0), punchgs.TweenLite.set(r, {
                        zIndex: 18
                    }), punchgs.TweenLite.set(s, {
                        autoAlpha: 0,
                        zIndex: 20
                    }), "prepared" == s.data("differentissplayed") && (s.data("differentissplayed", "done"), s.data("transition", s.data("savedtransition")), s.data("slotamount", s.data("savedslotamount")), s.data("masterspeed", s.data("savedmasterspeed"))), s.data("fstransition") != t && "done" != s.data("differentissplayed") && (s.data("savedtransition", s.data("transition")), s.data("savedslotamount", s.data("slotamount")), s.data("savedmasterspeed", s.data("masterspeed")), s.data("transition", s.data("fstransition")), s.data("slotamount", s.data("fsslotamount")), s.data("masterspeed", s.data("fsmasterspeed")), s.data("differentissplayed", "prepared")), s.data("transition") == t && s.data("transition", "random"), p = 0;
                    var f = s.data("transition") !== t ? s.data("transition").split(",") : "fade",
                        g = s.data("nexttransid") == t ? -1 : s.data("nexttransid");
                    "on" == s.data("randomtransition") ? g = Math.round(Math.random() * f.length) : g += 1, g == f.length && (g = 0), s.data("nexttransid", g);
                    var m = f[g];
                    e.ie && ("boxfade" == m && (m = "boxslide"), "slotfade-vertical" == m && (m = "slotzoom-vertical"), "slotfade-horizontal" == m && (m = "slotzoom-horizontal")), i.isIE(8) && (m = 11), h = i.animateSlide(p, m, o, e, s, r, l, a, h), "on" == l.data("kenburns") && (i.startKenBurn(l, e), h.add(punchgs.TweenLite.set(l, {
                        autoAlpha: 0
                    }))), h.pause()
                }
                i.scrollHandling && (i.scrollHandling(e, !0), h.eventCallback("onUpdate", function() {
                    i.scrollHandling(e, !0)
                })), "off" != e.parallax.type && e.parallax.firstgo == t && i.scrollHandling && (e.parallax.firstgo = !0, e.lastscrolltop = -999, i.scrollHandling(e, !0), setTimeout(function() {
                    e.lastscrolltop = -999, i.scrollHandling(e, !0)
                }, 210), setTimeout(function() {
                    e.lastscrolltop = -999, i.scrollHandling(e, !0)
                }, 420)), i.animateTheCaptions ? i.animateTheCaptions(s, e, null, h) : h != t && setTimeout(function() {
                    h.resume()
                }, 30), punchgs.TweenLite.to(s, .001, {
                    autoAlpha: 1
                })
            },
            M = function(o, r, s, a, l, c, d) {
                "carousel" === r.sliderType || (r.removePrepare = 0, punchgs.TweenLite.to(s.find(".defaultimg"), .001, {
                        zIndex: 20,
                        autoAlpha: 1,
                        onComplete: function() {
                            x(o, r, l, 1)
                        }
                    }), l.index() != c.index() && punchgs.TweenLite.to(c, .2, {
                        zIndex: 18,
                        autoAlpha: 0,
                        onComplete: function() {
                            x(o, r, c, 1)
                        }
                    })), o.find(".active-revslide").removeClass("active-revslide"), o.find(".processing-revslide").removeClass("processing-revslide").addClass("active-revslide"),
                    r.act = l.index(), ("scroll" == r.parallax.type || "scroll+mouse" == r.parallax.type || "mouse+scroll" == r.parallax.type) && (r.lastscrolltop = -999, i.scrollHandling(r)), d.clear(), a.data("kbtl") != t && (a.data("kbtl").reverse(), a.data("kbtl").timeScale(25)), "on" == s.data("kenburns") && (s.data("kbtl") != t ? (s.data("kbtl").timeScale(1), s.data("kbtl").play()) : i.startKenBurn(s, r)), l.find(".rs-background-video-layer").each(function(t) {
                        if (n) return !1;
                        var o = e(this);
                        i.resetVideo(o, r), punchgs.TweenLite.fromTo(o, 1, {
                            autoAlpha: 0
                        }, {
                            autoAlpha: 1,
                            ease: punchgs.Power3.easeInOut,
                            delay: .2,
                            onComplete: function() {
                                i.animcompleted && i.animcompleted(o, r)
                            }
                        })
                    }), c.find(".rs-background-video-layer").each(function(t) {
                        if (n) return !1;
                        var o = e(this);
                        i.stopVideo && (i.resetVideo(o, r), i.stopVideo(o, r)), punchgs.TweenLite.to(o, 1, {
                            autoAlpha: 0,
                            ease: punchgs.Power3.easeInOut,
                            delay: .2
                        })
                    });
                var u = {};
                u.slideIndex = l.index() + 1, u.slideLIIndex = l.index(), u.slide = l, u.currentslide = l, u.prevslide = c, o.trigger("revolution.slide.onchange", u), o.trigger("revolution.slide.onafterswap", u), r.duringslidechange = !1;
                var p = c.data("slide_on_focus_amount"),
                    h = c.data("hideafterloop");
                0 != h && p >= h && r.c.revremoveslide(c.index())
            },
            I = function(t, i) {
                t.children().each(function() {
                    try {
                        e(this).die("click")
                    } catch (t) {}
                    try {
                        e(this).die("mouseenter")
                    } catch (t) {}
                    try {
                        e(this).die("mouseleave")
                    } catch (t) {}
                    try {
                        e(this).unbind("hover")
                    } catch (t) {}
                });
                try {
                    t.die("click", "mouseenter", "mouseleave")
                } catch (n) {}
                clearInterval(i.cdint), t = null
            },
            D = function(o, r) {
                r.cd = 0, r.loop = 0, r.stopAfterLoops != t && r.stopAfterLoops > -1 ? r.looptogo = r.stopAfterLoops : r.looptogo = 9999999, r.stopAtSlide != t && r.stopAtSlide > -1 ? r.lastslidetoshow = r.stopAtSlide : r.lastslidetoshow = 999, r.stopLoop = "off", 0 == r.looptogo && (r.stopLoop = "on");
                var s = o.find(".tp-bannertimer");
                o.on("stoptimer", function() {
                    var t = e(this).find(".tp-bannertimer");
                    t.data("tween").pause(), "on" == r.disableProgressBar && t.css({
                        visibility: "hidden"
                    }), r.sliderstatus = "paused", i.unToggleState(r.slidertoggledby)
                }), o.on("starttimer", function() {
                    1 != r.conthover && 1 != r.videoplaying && r.width > r.hideSliderAtLimit && 1 != r.tonpause && 1 != r.overnav && (1 === r.noloopanymore || r.viewPort.enable && !r.inviewport || (s.css({
                        visibility: "visible"
                    }), s.data("tween").resume(), r.sliderstatus = "playing")), "on" == r.disableProgressBar && s.css({
                        visibility: "hidden"
                    }), i.toggleState(r.slidertoggledby)
                }), o.on("restarttimer", function() {
                    var t = e(this).find(".tp-bannertimer");
                    return !(r.mouseoncontainer && "on" == r.navigation.onHoverStop && !n) && (1 === r.noloopanymore || r.viewPort.enable && !r.inviewport || (t.css({
                        visibility: "visible"
                    }), t.data("tween").kill(), t.data("tween", punchgs.TweenLite.fromTo(t, r.delay / 1e3, {
                        width: "0%"
                    }, {
                        force3D: "auto",
                        width: "100%",
                        ease: punchgs.Linear.easeNone,
                        onComplete: a,
                        delay: 1
                    })), r.sliderstatus = "playing"), "on" == r.disableProgressBar && t.css({
                        visibility: "hidden"
                    }), void i.toggleState(r.slidertoggledby))
                }), o.on("nulltimer", function() {
                    s.data("tween").pause(0), "on" == r.disableProgressBar && s.css({
                        visibility: "hidden"
                    }), r.sliderstatus = "paused"
                });
                var a = function() {
                    0 == e("body").find(o).length && (I(o, r), clearInterval(r.cdint)), o.trigger("revolution.slide.slideatend"), 1 == o.data("conthover-changed") && (r.conthover = o.data("conthover"), o.data("conthover-changed", 0)), i.callingNewSlide(r, o, 1)
                };
                s.data("tween", punchgs.TweenLite.fromTo(s, r.delay / 1e3, {
                    width: "0%"
                }, {
                    force3D: "auto",
                    width: "100%",
                    ease: punchgs.Linear.easeNone,
                    onComplete: a,
                    delay: 1
                })), s.data("opt", r), r.slideamount > 1 && (0 != r.stopAfterLoops || 1 != r.stopAtSlide) ? o.trigger("starttimer") : (r.noloopanymore = 1, o.trigger("nulltimer")), o.on("tp-mouseenter", function() {
                    r.mouseoncontainer = !0, "on" != r.navigation.onHoverStop || n || (o.trigger("stoptimer"), o.trigger("revolution.slide.onpause"))
                }), o.on("tp-mouseleft", function() {
                    r.mouseoncontainer = !1, 1 != o.data("conthover") && "on" == r.navigation.onHoverStop && (1 == r.viewPort.enable && r.inviewport || 0 == r.viewPort.enable) && (o.trigger("revolution.slide.onresume"), o.trigger("starttimer"))
                })
            },
            j = (function() {
                var e, t, i = {
                    hidden: "visibilitychange",
                    webkitHidden: "webkitvisibilitychange",
                    mozHidden: "mozvisibilitychange",
                    msHidden: "msvisibilitychange"
                };
                for (e in i)
                    if (e in document) {
                        t = i[e];
                        break
                    }
                return function(i) {
                    return i && document.addEventListener(t, i), !document[e]
                }
            }(), function(e) {
                return e != t && e.c != t && void(1 != e.windowfocused && (e.windowfocused = !0, punchgs.TweenLite.delayedCall(.3, function() {
                    "on" == e.fallbacks.nextSlideOnWindowFocus && e.c.revnext(), e.c.revredraw(), "playing" == e.lastsliderstatus && e.c.revresume()
                })))
            }),
            N = function(e) {
                e.windowfocused = !1, e.lastsliderstatus = e.sliderstatus, e.c.revpause();
                var t = e.c.find(".active-revslide .slotholder"),
                    n = e.c.find(".processing-revslide .slotholder");
                "on" == n.data("kenburns") && i.stopKenBurn(n, e), "on" == t.data("kenburns") && i.stopKenBurn(t, e)
            },
            L = function(i, n) {
                var o = document.documentMode === t,
                    r = window.chrome;
                o && !r ? e(window).on("focusin", function() {
                    j(n)
                }).on("focusout", function() {
                    N(n)
                }) : window.addEventListener ? (window.addEventListener("focus", function(e) {
                    j(n)
                }, !1), window.addEventListener("blur", function(e) {
                    N(n)
                }, !1)) : (window.attachEvent("focus", function(e) {
                    j(n)
                }), window.attachEvent("blur", function(e) {
                    N(n)
                }))
            },
            z = function(e) {
                for (var t, i = [], n = window.location.href.slice(window.location.href.indexOf(e) + 1).split("_"), o = 0; o < n.length; o++) n[o] = n[o].replace("%3D", "="), t = n[o].split("="), i.push(t[0]), i[t[0]] = t[1];
                return i
            }
    }(jQuery), ! function(e, t, i, n) {
        "use strict";

        function o(t, i, n) {
            var r, s = this,
                a = "cbp";
            if (e.data(t, "cubeportfolio")) throw new Error("cubeportfolio is already initialized. Destroy it before initialize again!");
            e.data(t, "cubeportfolio", s), s.options = e.extend({}, e.fn.cubeportfolio.options, i), s.isAnimating = !0, s.defaultFilter = s.options.defaultFilter, s.registeredEvents = [], s.queue = [], s.addedWrapp = !1, e.isFunction(n) && s.registerEvent("initFinish", n, !0), s.obj = t, s.$obj = e(t), r = s.$obj.children(), s.options.caption && ("expand" === s.options.caption || o["private"].modernBrowser || (s.options.caption = "minimal"), a += " cbp-caption-active cbp-caption-" + s.options.caption), s.$obj.addClass(a), (0 === r.length || r.first().hasClass("cbp-item")) && (s.wrapInner(s.obj, "cbp-wrapper"), s.addedWrapp = !0), s.$ul = s.$obj.children().addClass("cbp-wrapper"), s.wrapInner(s.obj, "cbp-wrapper-outer"), s.wrapper = s.$obj.children(".cbp-wrapper-outer"), s.blocks = s.$ul.children(".cbp-item"), s.blocksOn = s.blocks, s.wrapInner(s.blocks, "cbp-item-wrapper"), s.plugins = e.map(o.plugins, function(e) {
                return e(s)
            }), s.triggerEvent("afterPlugins"), s.loadImages(s.$obj, s.display)
        }
        e.extend(o.prototype, {
            storeData: function(t, i) {
                var n = this;
                i = i || 0, t.each(function(t, o) {
                    var r = e(o),
                        s = r.width(),
                        a = r.height();
                    r.data("cbp", {
                        index: i + t,
                        wrapper: r.children(".cbp-item-wrapper"),
                        widthInitial: s,
                        heightInitial: a,
                        width: s,
                        height: a,
                        widthAndGap: s + n.options.gapVertical,
                        heightAndGap: a + n.options.gapHorizontal,
                        left: null,
                        leftNew: null,
                        top: null,
                        topNew: null,
                        pack: !1
                    })
                })
            },
            wrapInner: function(e, t) {
                var o, r, s;
                if (t = t || "", !(e.length && e.length < 1))
                    for (e.length === n && (e = [e]), r = e.length - 1; r >= 0; r--) {
                        for (o = e[r], s = i.createElement("div"), s.setAttribute("class", t); o.childNodes.length;) s.appendChild(o.childNodes[0]);
                        o.appendChild(s)
                    }
            },
            removeAttrImage: function(e) {
                e.removeAttribute("width"), e.removeAttribute("height"), e.removeAttribute("style")
            },
            loadImages: function(t, i) {
                var n = this;
                requestAnimationFrame(function() {
                    var o = t.find("img").map(function(t, i) {
                            return i.hasAttribute("width") && i.hasAttribute("height") ? (i.style.width = i.getAttribute("width") + "px", i.style.height = i.getAttribute("height") + "px", i.hasAttribute("data-cbp-src") ? null : (null === n.checkSrc(i.src) ? n.removeAttrImage(i) : e("<img>").on("load.cbp error.cbp", function() {
                                n.removeAttrImage(i)
                            }).attr("src", i.src), null)) : n.checkSrc(i.src)
                        }),
                        r = o.length;
                    return 0 === r ? void i.call(n) : void e.each(o, function(t, o) {
                        e("<img>").on("load.cbp error.cbp", function() {
                            r--, 0 === r && i.call(n)
                        }).attr("src", o)
                    })
                })
            },
            checkSrc: function(e) {
                if ("" === e) return null;
                var t = new Image;
                return t.src = e, t.complete && t.naturalWidth !== n && 0 !== t.naturalWidth ? null : e
            },
            display: function() {
                var e = this;
                e.width = e.$obj.outerWidth(), e.storeData(e.blocks), e.triggerEvent("initStartRead"), e.triggerEvent("initStartWrite"), e.layoutAndAdjustment(), e.triggerEvent("initEndRead"), e.triggerEvent("initEndWrite"), e.$obj.addClass("cbp-ready"), e.runQueue("delayFrame", e.delayFrame)
            },
            delayFrame: function() {
                var e = this;
                requestAnimationFrame(function() {
                    e.resizeEvent(), e.triggerEvent("initFinish"), e.isAnimating = !1, e.$obj.trigger("initComplete.cbp")
                })
            },
            resizeEvent: function() {
                var e, t = this;
                o["private"].initResizeEvent({
                    instance: t,
                    fn: function() {
                        var t = this;
                        t.triggerEvent("beforeResizeGrid"), e = t.$obj.outerWidth(), t.width !== e && ("alignCenter" === t.options.gridAdjustment && (t.wrapper[0].style.maxWidth = ""), t.width = e, t.layoutAndAdjustment(), t.triggerEvent("resizeGrid")), t.triggerEvent("resizeWindow")
                    }
                })
            },
            gridAdjust: function() {
                var t = this;
                "responsive" === t.options.gridAdjustment ? t.responsiveLayout() : (t.blocks.removeAttr("style"), t.blocks.each(function(i, n) {
                    var o = e(n).data("cbp"),
                        r = n.getBoundingClientRect(),
                        s = t.columnWidthTruncate(r.right - r.left),
                        a = Math.round(r.bottom - r.top);
                    o.height = a, o.heightAndGap = a + t.options.gapHorizontal, o.width = s, o.widthAndGap = s + t.options.gapVertical
                }), t.widthAvailable = t.width + t.options.gapVertical), t.triggerEvent("gridAdjust")
            },
            layoutAndAdjustment: function() {
                var e = this;
                e.gridAdjust(), e.layout()
            },
            layout: function() {
                var e = this;
                e.computeBlocks(e.filterConcat(e.defaultFilter)), "slider" === e.options.layoutMode ? (e.sliderLayoutReset(), e.sliderLayout()) : (e.mosaicLayoutReset(), e.mosaicLayout()), e.positionateItems(), e.resizeMainContainer()
            },
            computeFilter: function(e) {
                var t = this;
                t.computeBlocks(e), t.mosaicLayoutReset(), t.mosaicLayout(), t.filterLayout()
            },
            filterLayout: function() {
                var t = this;
                t.blocksOff.addClass("cbp-item-off"), t.blocksOn.removeClass("cbp-item-off").each(function(t, i) {
                    var n = e(i).data("cbp");
                    n.left = n.leftNew, n.top = n.topNew, i.style.left = n.left + "px", i.style.top = n.top + "px"
                }), t.resizeMainContainer(), t.filterFinish()
            },
            filterFinish: function() {
                var e = this;
                e.blocksAreSorted && e.sortBlocks(e.blocks, "index"), e.isAnimating = !1, e.$obj.trigger("filterComplete.cbp"), e.triggerEvent("filterFinish")
            },
            computeBlocks: function(e) {
                var t = this;
                t.blocksOnInitial = t.blocksOn, t.blocksOn = t.blocks.filter(e), t.blocksOff = t.blocks.not(e), t.triggerEvent("computeBlocksFinish", e)
            },
            responsiveLayout: function() {
                var t = this;
                t.cols = t[e.isArray(t.options.mediaQueries) ? "getColumnsBreakpoints" : "getColumnsAuto"](), t.columnWidth = t.columnWidthTruncate((t.width + t.options.gapVertical) / t.cols), t.widthAvailable = t.columnWidth * t.cols, "mosaic" === t.options.layoutMode && t.getMosaicWidthReference(), t.blocks.each(function(i, n) {
                    var o, r = e(n).data("cbp"),
                        s = 1;
                    "mosaic" === t.options.layoutMode && (s = t.getColsMosaic(r.widthInitial)), o = t.columnWidth * s - t.options.gapVertical, n.style.width = o + "px", r.width = o, r.widthAndGap = o + t.options.gapVertical, n.style.height = ""
                });
                var i = [];
                t.blocks.each(function(t, n) {
                    var o = e(n),
                        r = o.data("cbp").width;
                    e.each(o.find("img").filter("[width][height]"), function(e, t) {
                        var n = r / parseInt(t.getAttribute("width"), 10);
                        i.push({
                            el: t,
                            width: r,
                            height: Math.floor(parseInt(t.getAttribute("height"), 10) * n)
                        })
                    })
                }), e.each(i, function(e, t) {
                    t.el.width = t.width, t.el.height = t.height, t.el.style.width = t.width + "px", t.el.style.height = t.height + "px"
                }), t.blocks.each(function(i, n) {
                    var o = e(n).data("cbp"),
                        r = n.getBoundingClientRect(),
                        s = Math.round(r.bottom - r.top);
                    o.height = s, o.heightAndGap = s + t.options.gapHorizontal
                })
            },
            getMosaicWidthReference: function() {
                var t = this,
                    i = [];
                t.blocks.each(function(t, n) {
                    var o = e(n).data("cbp");
                    i.push(o.widthInitial)
                }), i.sort(function(e, t) {
                    return e - t
                }), i[0] ? t.mosaicWidthReference = i[0] : t.mosaicWidthReference = t.columnWidth
            },
            getColsMosaic: function(e) {
                var t = this;
                if (e === t.width) return t.cols;
                var i = e / t.mosaicWidthReference;
                return i = i % 1 >= .79 ? Math.ceil(i) : Math.floor(i), Math.min(Math.max(i, 1), t.cols)
            },
            getColumnsAuto: function() {
                var e = this;
                if (0 === e.blocks.length) return 1;
                var t = e.blocks.first().data("cbp").widthInitial + e.options.gapVertical;
                return Math.max(Math.round(e.width / t), 1)
            },
            getColumnsBreakpoints: function() {
                var t, i = this,
                    o = i.width;
                return e.each(i.options.mediaQueries, function(e, i) {
                    return o >= i.width ? (t = i.cols, !1) : void 0
                }), t === n && (t = i.options.mediaQueries[i.options.mediaQueries.length - 1].cols), t
            },
            columnWidthTruncate: function(e) {
                return Math.floor(e)
            },
            positionateItems: function() {
                var t, i = this;
                i.blocksOn.removeClass("cbp-item-off").each(function(i, n) {
                    t = e(n).data("cbp"), t.left = t.leftNew, t.top = t.topNew, n.style.left = t.left + "px", n.style.top = t.top + "px"
                }), i.blocksOff.addClass("cbp-item-off"), i.blocksAreSorted && i.sortBlocks(i.blocks, "index")
            },
            resizeMainContainer: function() {
                var t, i = this,
                    r = Math.max(i.freeSpaces.slice(-1)[0].topStart - i.options.gapHorizontal, 0);
                return "alignCenter" === i.options.gridAdjustment && (t = 0, i.blocksOn.each(function(i, n) {
                    var o = e(n).data("cbp"),
                        r = o.left + o.width;
                    r > t && (t = r)
                }), i.wrapper[0].style.maxWidth = t + "px"), r === i.height ? void i.triggerEvent("resizeMainContainer") : (i.obj.style.height = r + "px", i.height !== n && (o["private"].modernBrowser ? i.$obj.one(o["private"].transitionend, function() {
                    i.$obj.trigger("pluginResize.cbp")
                }) : i.$obj.trigger("pluginResize.cbp")), i.height = r, void i.triggerEvent("resizeMainContainer"))
            },
            filterConcat: function(e) {
                return e.replace(/\|/gi, "")
            },
            pushQueue: function(e, t) {
                var i = this;
                i.queue[e] = i.queue[e] || [], i.queue[e].push(t)
            },
            runQueue: function(t, i) {
                var n = this,
                    o = n.queue[t] || [];
                e.when.apply(e, o).then(e.proxy(i, n))
            },
            clearQueue: function(e) {
                var t = this;
                t.queue[e] = []
            },
            registerEvent: function(e, t, i) {
                var n = this;
                n.registeredEvents[e] || (n.registeredEvents[e] = []), n.registeredEvents[e].push({
                    func: t,
                    oneTime: i || !1
                })
            },
            triggerEvent: function(e, t) {
                var i, n, o = this;
                if (o.registeredEvents[e])
                    for (i = 0, n = o.registeredEvents[e].length; n > i; i++) o.registeredEvents[e][i].func.call(o, t), o.registeredEvents[e][i].oneTime && (o.registeredEvents[e].splice(i, 1), i--, n--)
            },
            addItems: function(t, i) {
                var n = this;
                n.wrapInner(t, "cbp-item-wrapper"), t.addClass("cbp-item-loading").css({
                    top: "100%",
                    left: 0
                }).appendTo(n.$ul), o["private"].modernBrowser ? t.last().one(o["private"].animationend, function() {
                    n.addItemsFinish(t, i)
                }) : n.addItemsFinish(t, i), n.loadImages(t, function() {
                    n.$obj.addClass("cbp-addItems"), n.storeData(t, n.blocks.length), e.merge(n.blocks, t), n.triggerEvent("addItemsToDOM", t), n.layoutAndAdjustment(), n.elems && o["public"].showCounter.call(n.obj, n.elems), n.triggerEvent("appendItemsFinish")
                })
            },
            addItemsFinish: function(t, i) {
                var n = this;
                n.isAnimating = !1, n.$obj.removeClass("cbp-addItems"), t.removeClass("cbp-item-loading"), e.isFunction(i) && i.call(n, t)
            }
        }), e.fn.cubeportfolio = function(e, t, i) {
            return this.each(function() {
                if ("object" == typeof e || !e) return o["public"].init.call(this, e, t);
                if (o["public"][e]) return o["public"][e].call(this, t, i);
                throw new Error("Method " + e + " does not exist on jquery.cubeportfolio.js")
            })
        }, o.plugins = {}, e.fn.cubeportfolio.constructor = o
    }(jQuery, window, document),
    function(e, t, i, n) {
        "use strict";
        var o = e.fn.cubeportfolio.constructor;
        e.extend(o.prototype, {
            mosaicLayoutReset: function() {
                var t = this;
                t.blocksAreSorted = !1, t.blocksOn.each(function(t, i) {
                    e(i).data("cbp").pack = !1
                })
            },
            mosaicLayout: function() {
                var e, t = this,
                    i = t.blocksOn.length,
                    n = {};
                for (t.freeSpaces = [{
                        leftStart: 0,
                        leftEnd: t.widthAvailable,
                        topStart: 0,
                        topEnd: Math.pow(2, 18)
                    }], e = 0; i > e; e++) {
                    if (n = t.getSpaceIndexAndBlock(), null === n) return t.sortBlocksToPreventGaps(), void t.mosaicLayout();
                    t.generateF1F2(n.spaceIndex, n.dataBlock), t.generateG1G2G3G4(n.dataBlock), t.cleanFreeSpaces(), t.addHeightToBlocks()
                }
                t.blocksAreSorted && t.sortBlocks(t.blocksOn, "topNew")
            },
            getSpaceIndexAndBlock: function() {
                var t = this,
                    i = null;
                return e.each(t.freeSpaces, function(n, o) {
                    var r = o.leftEnd - o.leftStart,
                        s = o.topEnd - o.topStart;
                    return t.blocksOn.each(function(t, a) {
                        var l = e(a).data("cbp");
                        if (l.pack !== !0) return l.widthAndGap <= r && l.heightAndGap <= s ? (l.pack = !0, i = {
                            spaceIndex: n,
                            dataBlock: l
                        }, l.leftNew = o.leftStart, l.topNew = o.topStart, !1) : void 0
                    }), !t.blocksAreSorted && t.options.sortToPreventGaps && n > 0 ? (i = null, !1) : null === i && void 0
                }), i
            },
            generateF1F2: function(e, t) {
                var i = this,
                    n = i.freeSpaces[e],
                    o = {
                        leftStart: n.leftStart + t.widthAndGap,
                        leftEnd: n.leftEnd,
                        topStart: n.topStart,
                        topEnd: n.topEnd
                    },
                    r = {
                        leftStart: n.leftStart,
                        leftEnd: n.leftEnd,
                        topStart: n.topStart + t.heightAndGap,
                        topEnd: n.topEnd
                    };
                i.freeSpaces.splice(e, 1), o.leftEnd > o.leftStart && o.topEnd > o.topStart && (i.freeSpaces.splice(e, 0, o), e++), r.leftEnd > r.leftStart && r.topEnd > r.topStart && i.freeSpaces.splice(e, 0, r)
            },
            generateG1G2G3G4: function(t) {
                var i = this,
                    n = [];
                e.each(i.freeSpaces, function(e, o) {
                    var r = i.intersectSpaces(o, t);
                    return null === r ? void n.push(o) : (i.generateG1(o, r, n), i.generateG2(o, r, n), i.generateG3(o, r, n), void i.generateG4(o, r, n))
                }), i.freeSpaces = n
            },
            intersectSpaces: function(e, t) {
                var i = {
                    leftStart: t.leftNew,
                    leftEnd: t.leftNew + t.widthAndGap,
                    topStart: t.topNew,
                    topEnd: t.topNew + t.heightAndGap
                };
                if (e.leftStart === i.leftStart && e.leftEnd === i.leftEnd && e.topStart === i.topStart && e.topEnd === i.topEnd) return null;
                var n = Math.max(e.leftStart, i.leftStart),
                    o = Math.min(e.leftEnd, i.leftEnd),
                    r = Math.max(e.topStart, i.topStart),
                    s = Math.min(e.topEnd, i.topEnd);
                return n >= o || r >= s ? null : {
                    leftStart: n,
                    leftEnd: o,
                    topStart: r,
                    topEnd: s
                }
            },
            generateG1: function(e, t, i) {
                e.topStart !== t.topStart && i.push({
                    leftStart: e.leftStart,
                    leftEnd: e.leftEnd,
                    topStart: e.topStart,
                    topEnd: t.topStart
                })
            },
            generateG2: function(e, t, i) {
                e.leftEnd !== t.leftEnd && i.push({
                    leftStart: t.leftEnd,
                    leftEnd: e.leftEnd,
                    topStart: e.topStart,
                    topEnd: e.topEnd
                })
            },
            generateG3: function(e, t, i) {
                e.topEnd !== t.topEnd && i.push({
                    leftStart: e.leftStart,
                    leftEnd: e.leftEnd,
                    topStart: t.topEnd,
                    topEnd: e.topEnd
                })
            },
            generateG4: function(e, t, i) {
                e.leftStart !== t.leftStart && i.push({
                    leftStart: e.leftStart,
                    leftEnd: t.leftStart,
                    topStart: e.topStart,
                    topEnd: e.topEnd
                })
            },
            cleanFreeSpaces: function() {
                var e = this;
                e.freeSpaces.sort(function(e, t) {
                    return e.topStart > t.topStart ? 1 : e.topStart < t.topStart ? -1 : e.leftStart > t.leftStart ? 1 : e.leftStart < t.leftStart ? -1 : 0
                }), e.correctSubPixelValues(), e.removeNonMaximalFreeSpaces()
            },
            correctSubPixelValues: function() {
                var e, t, i, n, o = this;
                for (e = 0, t = o.freeSpaces.length - 1; t > e; e++) i = o.freeSpaces[e], n = o.freeSpaces[e + 1], n.topStart - i.topStart <= 1 && (n.topStart = i.topStart)
            },
            removeNonMaximalFreeSpaces: function() {
                var t = this;
                t.uniqueFreeSpaces(), t.freeSpaces = e.map(t.freeSpaces, function(i, n) {
                    return e.each(t.freeSpaces, function(e, t) {
                        return n !== e && t.leftStart <= i.leftStart && t.leftEnd >= i.leftEnd && t.topStart <= i.topStart && t.topEnd >= i.topEnd ? (i = null, !1) : void 0
                    }), i
                })
            },
            uniqueFreeSpaces: function() {
                var t = this,
                    i = [];
                e.each(t.freeSpaces, function(t, n) {
                    e.each(i, function(e, t) {
                        return t.leftStart === n.leftStart && t.leftEnd === n.leftEnd && t.topStart === n.topStart && t.topEnd === n.topEnd ? (n = null, !1) : void 0
                    }), null !== n && i.push(n)
                }), t.freeSpaces = i
            },
            addHeightToBlocks: function() {
                var t = this;
                if (!(t.freeSpaces.length > 1)) {
                    var i = t.freeSpaces[0].topStart;
                    t.blocksOn.each(function(t, n) {
                        var o = e(n).data("cbp");
                        if (o.pack === !0) {
                            var r = i - o.topNew - o.heightAndGap;
                            0 > r && (n.style.height = o.height + r + "px")
                        }
                    })
                }
            },
            sortBlocksToPreventGaps: function() {
                var t = this;
                t.blocksAreSorted = !0, t.blocksOn.sort(function(t, i) {
                    var n = e(t).data("cbp"),
                        o = e(i).data("cbp");
                    return n.widthAndGap < o.widthAndGap ? 1 : n.widthAndGap > o.widthAndGap ? -1 : n.heightAndGap < o.heightAndGap ? 1 : n.heightAndGap > o.heightAndGap ? -1 : n.index > o.index ? 1 : n.index < o.index ? -1 : void 0
                }), t.blocksOn.each(function(t, i) {
                    e(i).data("cbp").pack = !1, i.style.height = ""
                })
            },
            sortBlocks: function(t, i) {
                t.sort(function(t, n) {
                    var o = e(t).data("cbp"),
                        r = e(n).data("cbp");
                    return o[i] > r[i] ? 1 : o[i] < r[i] ? -1 : o.leftNew > r.leftNew ? 1 : o.leftNew < r.leftNew ? -1 : 0
                })
            }
        })
    }(jQuery, window, document), jQuery.fn.cubeportfolio.options = {
        filters: "",
        loadMore: "",
        loadMoreAction: "click",
        search: "",
        layoutMode: "grid",
        sortToPreventGaps: !1,
        drag: !0,
        auto: !1,
        autoTimeout: 5e3,
        autoPauseOnHover: !0,
        showNavigation: !0,
        showPagination: !0,
        rewindNav: !0,
        scrollByPage: !1,
        defaultFilter: "*",
        filterDeeplinking: !1,
        animationType: "fadeOut",
        gridAdjustment: "responsive",
        mediaQueries: !1,
        gapHorizontal: 10,
        gapVertical: 10,
        caption: "pushTop",
        displayType: "fadeIn",
        displayTypeSpeed: 400,
        lightboxDelegate: ".cbp-lightbox",
        lightboxGallery: !0,
        lightboxTitleSrc: "data-title",
        lightboxCounter: '<div class="cbp-popup-lightbox-counter">{{current}} of {{total}}</div>',
        singlePageDelegate: ".cbp-singlePage",
        singlePageDeeplinking: !0,
        singlePageStickyNavigation: !0,
        singlePageCounter: '<div class="cbp-popup-singlePage-counter">{{current}} of {{total}}</div>',
        singlePageAnimation: "left",
        singlePageCallback: function(e, t) {},
        singlePageInlineDelegate: ".cbp-singlePageInline",
        singlePageInlineDeeplinking: !1,
        singlePageInlinePosition: "top",
        singlePageInlineInFocus: !0,
        singlePageInlineCallback: function(e, t) {},
        plugins: {}
    },
    function(e, t, i, n) {
        "use strict";
        var o = e.fn.cubeportfolio.constructor;
        o["private"] = {
            resizeEventArray: [],
            initResizeEvent: function(e) {
                var t = o["private"];
                0 === t.resizeEventArray.length && t.resizeEvent(), t.resizeEventArray.push(e)
            },
            destroyResizeEvent: function(i) {
                var n = o["private"],
                    r = e.map(n.resizeEventArray, function(e, t) {
                        return e.instance !== i ? e : void 0
                    });
                n.resizeEventArray = r, 0 === n.resizeEventArray.length && e(t).off("resize.cbp")
            },
            resizeEvent: function() {
                var i, n = o["private"];
                e(t).on("resize.cbp", function() {
                    clearTimeout(i), i = setTimeout(function() {
                        t.innerHeight != screen.height && e.each(n.resizeEventArray, function(e, t) {
                            t.fn.call(t.instance)
                        })
                    }, 50)
                })
            },
            checkInstance: function(t) {
                var i = e.data(this, "cubeportfolio");
                if (!i) throw new Error("cubeportfolio is not initialized. Initialize it before calling " + t + " method!");
                return i.triggerEvent("publicMethod"), i
            },
            browserInfo: function() {
                var e, i, r, s = o["private"],
                    a = navigator.appVersion; - 1 !== a.indexOf("MSIE 8.") ? s.browser = "ie8" : -1 !== a.indexOf("MSIE 9.") ? s.browser = "ie9" : -1 !== a.indexOf("MSIE 10.") ? s.browser = "ie10" : t.ActiveXObject || "ActiveXObject" in t ? s.browser = "ie11" : /android/gi.test(a) ? s.browser = "android" : /iphone|ipad|ipod/gi.test(a) ? s.browser = "ios" : /chrome/gi.test(a) ? s.browser = "chrome" : s.browser = "", r = s.styleSupport("perspective"), typeof r !== n && (e = s.styleSupport("transition"), s.transitionend = {
                    WebkitTransition: "webkitTransitionEnd",
                    transition: "transitionend"
                }[e], i = s.styleSupport("animation"), s.animationend = {
                    WebkitAnimation: "webkitAnimationEnd",
                    animation: "animationend"
                }[i], s.animationDuration = {
                    WebkitAnimation: "webkitAnimationDuration",
                    animation: "animationDuration"
                }[i], s.animationDelay = {
                    WebkitAnimation: "webkitAnimationDelay",
                    animation: "animationDelay"
                }[i], s.transform = s.styleSupport("transform"), e && i && s.transform && (s.modernBrowser = !0))
            },
            styleSupport: function(e) {
                var t, n = "Webkit" + e.charAt(0).toUpperCase() + e.slice(1),
                    o = i.createElement("div");
                return e in o.style ? t = e : n in o.style && (t = n), o = null, t
            }
        }, o["private"].browserInfo()
    }(jQuery, window, document),
    function(e, t, i, n) {
        "use strict";
        var o = e.fn.cubeportfolio.constructor;
        o["public"] = {
            init: function(e, t) {
                new o(this, e, t)
            },
            destroy: function(t) {
                var i = o["private"].checkInstance.call(this, "destroy");
                i.triggerEvent("beforeDestroy"), e.removeData(this, "cubeportfolio"), i.blocks.removeData("cbp"), i.$obj.removeClass("cbp-ready").removeAttr("style"), i.$ul.removeClass("cbp-wrapper"), o["private"].destroyResizeEvent(i), i.$obj.off(".cbp"), i.blocks.removeClass("cbp-item-off").removeAttr("style"), i.blocks.find(".cbp-item-wrapper").children().unwrap(), i.options.caption && i.$obj.removeClass("cbp-caption-active cbp-caption-" + i.options.caption), i.destroySlider(), i.$ul.unwrap(), i.addedWrapp && i.blocks.unwrap(), e.each(i.plugins, function(e, t) {
                    "function" == typeof t.destroy && t.destroy()
                }), e.isFunction(t) && t.call(i), i.triggerEvent("afterDestroy")
            },
            filter: function(t, i) {
                var r, s = o["private"].checkInstance.call(this, "filter");
                if (!s.isAnimating) {
                    if (s.isAnimating = !0, e.isFunction(i) && s.registerEvent("filterFinish", i, !0), e.isFunction(t)) {
                        if (r = t.call(s, s.blocks), r === n) throw new Error("When you call cubeportfolio API `filter` method with a param of type function you must return the blocks that will be visible.")
                    } else {
                        if (s.options.filterDeeplinking) {
                            var a = location.href.replace(/#cbpf=(.*?)([#\?&]|$)/gi, "");
                            location.href = a + "#cbpf=" + encodeURIComponent(t), s.singlePage && s.singlePage.url && (s.singlePage.url = location.href)
                        }
                        s.defaultFilter = t, r = s.filterConcat(s.defaultFilter)
                    }
                    s.singlePageInline && s.singlePageInline.isOpen ? s.singlePageInline.close("promise", {
                        callback: function() {
                            s.computeFilter(r)
                        }
                    }) : s.computeFilter(r)
                }
            },
            showCounter: function(t, i) {
                var n = o["private"].checkInstance.call(this, "showCounter");
                e.isFunction(i) && n.registerEvent("showCounterFinish", i, !0), n.elems = t, t.each(function() {
                    var t = e(this),
                        i = n.blocks.filter(t.data("filter")).length;
                    t.find(".cbp-filter-counter").text(i)
                }), n.triggerEvent("showCounterFinish", t)
            },
            appendItems: function(t, i) {
                var n = o["private"].checkInstance.call(this, "appendItems"),
                    r = e(t).filter(".cbp-item");
                return n.isAnimating || r.length < 1 ? void(e.isFunction(i) && i.call(n, r)) : (n.isAnimating = !0, void(n.singlePageInline && n.singlePageInline.isOpen ? n.singlePageInline.close("promise", {
                    callback: function() {
                        n.addItems(r, i)
                    }
                }) : n.addItems(r, i)))
            }
        }
    }(jQuery, window, document),
    function(e, t, i, n) {
        "use strict";
        var o = e.fn.cubeportfolio.constructor;
        e.extend(o.prototype, {
            updateSliderPagination: function() {
                var t, i, n = this;
                if (n.options.showPagination) {
                    for (t = Math.ceil(n.blocksOn.length / n.cols), n.navPagination.empty(), i = t - 1; i >= 0; i--) e("<div/>", {
                        "class": "cbp-nav-pagination-item",
                        "data-slider-action": "jumpTo"
                    }).appendTo(n.navPagination);
                    n.navPaginationItems = n.navPagination.children()
                }
                n.enableDisableNavSlider()
            },
            destroySlider: function() {
                var t = this;
                "slider" === t.options.layoutMode && (t.$obj.removeClass("cbp-mode-slider"), t.$ul.removeAttr("style"), t.$ul.off(".cbp"), e(i).off(".cbp"), t.options.auto && t.stopSliderAuto())
            },
            nextSlider: function(e) {
                var t = this;
                if (t.isEndSlider()) {
                    if (!t.isRewindNav()) return;
                    t.sliderActive = 0
                } else t.options.scrollByPage ? t.sliderActive = Math.min(t.sliderActive + t.cols, t.blocksOn.length - t.cols) : t.sliderActive += 1;
                t.goToSlider()
            },
            prevSlider: function(e) {
                var t = this;
                if (t.isStartSlider()) {
                    if (!t.isRewindNav()) return;
                    t.sliderActive = t.blocksOn.length - t.cols
                } else t.options.scrollByPage ? t.sliderActive = Math.max(0, t.sliderActive - t.cols) : t.sliderActive -= 1;
                t.goToSlider()
            },
            jumpToSlider: function(e) {
                var t = this,
                    i = Math.min(e.index() * t.cols, t.blocksOn.length - t.cols);
                i !== t.sliderActive && (t.sliderActive = i, t.goToSlider())
            },
            jumpDragToSlider: function(e) {
                var t, i, n, o = this,
                    r = e > 0;
                o.options.scrollByPage ? (t = o.cols * o.columnWidth, i = o.cols) : (t = o.columnWidth, i = 1), e = Math.abs(e), n = Math.floor(e / t) * i, e % t > 20 && (n += i), r ? o.sliderActive = Math.min(o.sliderActive + n, o.blocksOn.length - o.cols) : o.sliderActive = Math.max(0, o.sliderActive - n), o.goToSlider()
            },
            isStartSlider: function() {
                return 0 === this.sliderActive
            },
            isEndSlider: function() {
                var e = this;
                return e.sliderActive + e.cols > e.blocksOn.length - 1
            },
            goToSlider: function() {
                var e = this;
                e.enableDisableNavSlider(), e.updateSliderPosition()
            },
            startSliderAuto: function() {
                var e = this;
                return e.isDrag ? void e.stopSliderAuto() : void(e.timeout = setTimeout(function() {
                    e.nextSlider(), e.startSliderAuto()
                }, e.options.autoTimeout))
            },
            stopSliderAuto: function() {
                clearTimeout(this.timeout)
            },
            enableDisableNavSlider: function() {
                var e, t, i = this;
                i.isRewindNav() || (t = i.isStartSlider() ? "addClass" : "removeClass", i.navPrev[t]("cbp-nav-stop"), t = i.isEndSlider() ? "addClass" : "removeClass", i.navNext[t]("cbp-nav-stop")), i.options.showPagination && (e = i.options.scrollByPage ? Math.ceil(i.sliderActive / i.cols) : i.isEndSlider() ? i.navPaginationItems.length - 1 : Math.floor(i.sliderActive / i.cols), i.navPaginationItems.removeClass("cbp-nav-pagination-active").eq(e).addClass("cbp-nav-pagination-active")), i.customPagination && (e = i.options.scrollByPage ? Math.ceil(i.sliderActive / i.cols) : i.isEndSlider() ? i.customPaginationItems.length - 1 : Math.floor(i.sliderActive / i.cols), i.customPaginationItems.removeClass(i.customPaginationClass).eq(e).addClass(i.customPaginationClass))
            },
            isRewindNav: function() {
                var e = this;
                return !e.options.showNavigation || !(e.blocksOn.length <= e.cols) && !!e.options.rewindNav
            },
            sliderItemsLength: function() {
                return this.blocksOn.length <= this.cols
            },
            sliderLayout: function() {
                var t = this;
                t.blocksOn.each(function(i, n) {
                    var o = e(n).data("cbp");
                    o.leftNew = t.columnWidth * i, o.topNew = 0, t.sliderFreeSpaces.push({
                        topStart: o.heightAndGap
                    })
                }), t.getFreeSpacesForSlider(), t.$ul.width(t.columnWidth * t.blocksOn.length - t.options.gapVertical)
            },
            getFreeSpacesForSlider: function() {
                var e = this;
                e.freeSpaces = e.sliderFreeSpaces.slice(e.sliderActive, e.sliderActive + e.cols), e.freeSpaces.sort(function(e, t) {
                    return e.topStart > t.topStart ? 1 : e.topStart < t.topStart ? -1 : void 0
                })
            },
            updateSliderPosition: function() {
                var e = this,
                    t = -e.sliderActive * e.columnWidth;
                o["private"].modernBrowser ? e.$ul[0].style[o["private"].transform] = "translate3d(" + t + "px, 0px, 0)" : e.$ul[0].style.left = t + "px", e.getFreeSpacesForSlider(), e.resizeMainContainer()
            },
            dragSlider: function() {
                function r(t) {
                    if (!m.sliderItemsLength()) {
                        if (w ? g = t : t.preventDefault(), m.options.auto && m.stopSliderAuto(), b) return void e(p).one("click.cbp", function() {
                            return !1
                        });
                        p = e(t.target), d = c(t).x, u = 0, h = -m.sliderActive * m.columnWidth, f = m.columnWidth * (m.blocksOn.length - m.cols), v.on(y.move, a), v.on(y.end, s), m.$obj.addClass("cbp-mode-slider-dragStart")
                    }
                }

                function s(e) {
                    m.$obj.removeClass("cbp-mode-slider-dragStart"), b = !0, 0 !== u ? (p.one("click.cbp", function(e) {
                        return !1
                    }), requestAnimationFrame(function() {
                        m.jumpDragToSlider(u), m.$ul.one(o["private"].transitionend, l)
                    })) : l.call(m), v.off(y.move), v.off(y.end)
                }

                function a(e) {
                    u = d - c(e).x, (u > 8 || -8 > u) && e.preventDefault(), m.isDrag = !0;
                    var t = h - u;
                    0 > u && h > u ? t = (h - u) / 5 : u > 0 && -f > h - u && (t = -f + (f + h - u) / 5), o["private"].modernBrowser ? m.$ul[0].style[o["private"].transform] = "translate3d(" + t + "px, 0px, 0)" : m.$ul[0].style.left = t + "px"
                }

                function l() {
                    if (b = !1, m.isDrag = !1, m.options.auto) {
                        if (m.mouseIsEntered) return;
                        m.startSliderAuto()
                    }
                }

                function c(e) {
                    return e.originalEvent !== n && e.originalEvent.touches !== n && (e = e.originalEvent.touches[0]), {
                        x: e.pageX,
                        y: e.pageY
                    }
                }
                var d, u, p, h, f, g, m = this,
                    v = e(i),
                    b = !1,
                    y = {},
                    w = !1;
                m.isDrag = !1, "ontouchstart" in t || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0 ? (y = {
                    start: "touchstart.cbp",
                    move: "touchmove.cbp",
                    end: "touchend.cbp"
                }, w = !0) : y = {
                    start: "mousedown.cbp",
                    move: "mousemove.cbp",
                    end: "mouseup.cbp"
                }, m.$ul.on(y.start, r)
            },
            sliderLayoutReset: function() {
                var e = this;
                e.freeSpaces = [], e.sliderFreeSpaces = []
            }
        })
    }(jQuery, window, document), "function" != typeof Object.create && (Object.create = function(e) {
        function t() {}
        return t.prototype = e, new t
    }),
    function() {
        for (var e = 0, t = ["moz", "webkit"], i = 0; i < t.length && !window.requestAnimationFrame; i++) window.requestAnimationFrame = window[t[i] + "RequestAnimationFrame"], window.cancelAnimationFrame = window[t[i] + "CancelAnimationFrame"] || window[t[i] + "CancelRequestAnimationFrame"];
        window.requestAnimationFrame || (window.requestAnimationFrame = function(t, i) {
            var n = (new Date).getTime(),
                o = Math.max(0, 16 - (n - e)),
                r = window.setTimeout(function() {
                    t(n + o)
                }, o);
            return e = n + o, r
        }), window.cancelAnimationFrame || (window.cancelAnimationFrame = function(e) {
            clearTimeout(e)
        })
    }(),
    function(e, t, i, n) {
        "use strict";

        function o(e) {
            var t = this;
            t.parent = e, e.filterLayout = t.filterLayout, e.registerEvent("computeBlocksFinish", function(t) {
                e.blocksOn2On = e.blocksOnInitial.filter(t), e.blocksOn2Off = e.blocksOnInitial.not(t)
            })
        }
        var r = e.fn.cubeportfolio.constructor;
        o.prototype.filterLayout = function() {
            function t() {
                i.blocks.removeClass("cbp-item-on2off cbp-item-off2on cbp-item-on2on").each(function(t, i) {
                    var n = e(i).data("cbp");
                    n.left = n.leftNew, n.top = n.topNew, i.style.left = n.left + "px", i.style.top = n.top + "px", i.style[r["private"].transform] = ""
                }), i.blocksOff.addClass("cbp-item-off"), i.$obj.removeClass("cbp-animation-" + i.options.animationType), i.filterFinish()
            }
            var i = this;
            i.$obj.addClass("cbp-animation-" + i.options.animationType), i.blocksOn2On.addClass("cbp-item-on2on").each(function(t, i) {
                var n = e(i).data("cbp");
                i.style[r["private"].transform] = "translate3d(" + (n.leftNew - n.left) + "px, " + (n.topNew - n.top) + "px, 0)"
            }), i.blocksOn2Off.addClass("cbp-item-on2off"), i.blocksOff2On = i.blocksOn.filter(".cbp-item-off").removeClass("cbp-item-off").addClass("cbp-item-off2on").each(function(t, i) {
                var n = e(i).data("cbp");
                i.style.left = n.leftNew + "px", i.style.top = n.topNew + "px"
            }), i.blocksOn2Off.length ? i.blocksOn2Off.last().data("cbp").wrapper.one(r["private"].animationend, t) : i.blocksOff2On.length ? i.blocksOff2On.last().data("cbp").wrapper.one(r["private"].animationend, t) : t(), i.resizeMainContainer()
        }, o.prototype.destroy = function() {
            var e = this.parent;
            e.$obj.removeClass("cbp-animation-" + e.options.animationType)
        }, r.plugins.animationClassic = function(t) {
            return !r["private"].modernBrowser || e.inArray(t.options.animationType, ["boxShadow", "fadeOut", "flipBottom", "flipOut", "quicksand", "scaleSides", "skew"]) < 0 ? null : new o(t)
        }
    }(jQuery, window, document),
    function(e, t, i, n) {
        "use strict";

        function o(e) {
            var t = this;
            t.parent = e, e.filterLayout = t.filterLayout
        }
        var r = e.fn.cubeportfolio.constructor;
        o.prototype.filterLayout = function() {
            function t() {
                i.wrapper[0].removeChild(n), "sequentially" === i.options.animationType && i.blocksOn.each(function(t, i) {
                    e(i).data("cbp").wrapper[0].style[r["private"].animationDelay] = ""
                }), i.$obj.removeClass("cbp-animation-" + i.options.animationType), i.filterFinish()
            }
            var i = this,
                n = i.$ul[0].cloneNode(!0);
            n.setAttribute("class", "cbp-wrapper-helper"), i.wrapper[0].insertBefore(n, i.$ul[0]), requestAnimationFrame(function() {
                i.$obj.addClass("cbp-animation-" + i.options.animationType), i.blocksOff.addClass("cbp-item-off"), i.blocksOn.removeClass("cbp-item-off").each(function(t, n) {
                    var o = e(n).data("cbp");
                    o.left = o.leftNew, o.top = o.topNew, n.style.left = o.left + "px", n.style.top = o.top + "px", "sequentially" === i.options.animationType && (o.wrapper[0].style[r["private"].animationDelay] = 60 * t + "ms")
                }), i.blocksOn.length ? i.blocksOn.last().data("cbp").wrapper.one(r["private"].animationend, t) : i.blocksOnInitial.length ? i.blocksOnInitial.last().data("cbp").wrapper.one(r["private"].animationend, t) : t(), i.resizeMainContainer()
            })
        }, o.prototype.destroy = function() {
            var e = this.parent;
            e.$obj.removeClass("cbp-animation-" + e.options.animationType)
        }, r.plugins.animationClone = function(t) {
            return !r["private"].modernBrowser || e.inArray(t.options.animationType, ["fadeOutTop", "slideLeft", "sequentially"]) < 0 ? null : new o(t)
        }
    }(jQuery, window, document),
    function(e, t, i, n) {
        "use strict";

        function o(e) {
            var t = this;
            t.parent = e, e.filterLayout = t.filterLayout
        }
        var r = e.fn.cubeportfolio.constructor;
        o.prototype.filterLayout = function() {
            function t() {
                i.wrapper[0].removeChild(n[0]), i.$obj.removeClass("cbp-animation-" + i.options.animationType), i.blocks.each(function(t, i) {
                    e(i).data("cbp").wrapper[0].style[r["private"].animationDelay] = ""
                }), i.filterFinish()
            }
            var i = this,
                n = i.$ul.clone(!0, !0);
            n[0].setAttribute("class", "cbp-wrapper-helper"), i.wrapper[0].insertBefore(n[0], i.$ul[0]);
            var o = n.find(".cbp-item").not(".cbp-item-off");
            i.sortBlocks(o, "top"), o.children(".cbp-item-wrapper").each(function(e, t) {
                t.style[r["private"].animationDelay] = 50 * e + "ms"
            }), requestAnimationFrame(function() {
                i.$obj.addClass("cbp-animation-" + i.options.animationType), i.blocksOff.addClass("cbp-item-off"), i.blocksOn.removeClass("cbp-item-off").each(function(t, i) {
                    var n = e(i).data("cbp");
                    n.left = n.leftNew, n.top = n.topNew, i.style.left = n.left + "px", i.style.top = n.top + "px", n.wrapper[0].style[r["private"].animationDelay] = 50 * t + "ms"
                });
                var n = i.blocksOn.length,
                    s = o.length;
                0 === n && 0 === s ? t() : s > n ? o.last().children(".cbp-item-wrapper").one(r["private"].animationend, t) : i.blocksOn.last().data("cbp").wrapper.one(r["private"].animationend, t), i.resizeMainContainer()
            })
        }, o.prototype.destroy = function() {
            var e = this.parent;
            e.$obj.removeClass("cbp-animation-" + e.options.animationType)
        }, r.plugins.animationCloneDelay = function(t) {
            return !r["private"].modernBrowser || e.inArray(t.options.animationType, ["3dflip", "flipOutDelay", "foldLeft", "frontRow", "rotateRoom", "rotateSides", "scaleDown", "slideDelay", "unfold"]) < 0 ? null : new o(t)
        }
    }(jQuery, window, document),
    function(e, t, i, n) {
        "use strict";

        function o(e) {
            var t = this;
            t.parent = e, e.filterLayout = t.filterLayout
        }
        var r = e.fn.cubeportfolio.constructor;
        o.prototype.filterLayout = function() {
            function t() {
                i.wrapper[0].removeChild(n), i.$obj.removeClass("cbp-animation-" + i.options.animationType), i.filterFinish()
            }
            var i = this,
                n = i.$ul[0].cloneNode(!0);
            n.setAttribute("class", "cbp-wrapper-helper"), i.wrapper[0].insertBefore(n, i.$ul[0]), requestAnimationFrame(function() {
                i.$obj.addClass("cbp-animation-" + i.options.animationType), i.blocksOff.addClass("cbp-item-off"), i.blocksOn.removeClass("cbp-item-off").each(function(t, i) {
                    var n = e(i).data("cbp");
                    n.left = n.leftNew, n.top = n.topNew, i.style.left = n.left + "px", i.style.top = n.top + "px"
                }), i.blocksOn.length ? i.$ul.one(r["private"].animationend, t) : i.blocksOnInitial.length ? e(n).one(r["private"].animationend, t) : t(), i.resizeMainContainer()
            })
        }, o.prototype.destroy = function() {
            var e = this.parent;
            e.$obj.removeClass("cbp-animation-" + e.options.animationType)
        }, r.plugins.animationWrapper = function(t) {
            return !r["private"].modernBrowser || e.inArray(t.options.animationType, ["bounceBottom", "bounceLeft", "bounceTop", "moveLeft"]) < 0 ? null : new o(t)
        }
    }(jQuery, window, document),
    function(e, t, i, n) {
        "use strict";

        function o(t) {
            var i = this;
            i.parent = t, t.registerEvent("initFinish", function() {
                t.$obj.on("click.cbp", ".cbp-caption-defaultWrap", function(i) {
                    if (i.preventDefault(), !t.isAnimating) {
                        t.isAnimating = !0;
                        var n = e(this),
                            o = n.next(),
                            r = n.parent(),
                            s = {
                                position: "relative",
                                height: o.outerHeight(!0)
                            },
                            a = {
                                position: "relative",
                                height: 0
                            };
                        if (t.$obj.addClass("cbp-caption-expand-active"), r.hasClass("cbp-caption-expand-open")) {
                            var l = a;
                            a = s, s = l, r.removeClass("cbp-caption-expand-open")
                        }
                        o.css(s), t.$obj.one("pluginResize.cbp", function() {
                            t.isAnimating = !1, t.$obj.removeClass("cbp-caption-expand-active"), 0 === s.height && (r.removeClass("cbp-caption-expand-open"), o.attr("style", ""))
                        }), t.layoutAndAdjustment(), o.css(a), requestAnimationFrame(function() {
                            r.addClass("cbp-caption-expand-open"), o.css(s), t.triggerEvent("gridAdjust"), t.triggerEvent("resizeGrid")
                        })
                    }
                })
            }, !0)
        }
        var r = e.fn.cubeportfolio.constructor;
        o.prototype.destroy = function() {
            this.parent.$obj.find(".cbp-caption-defaultWrap").off("click.cbp").parent().removeClass("cbp-caption-expand-active")
        }, r.plugins.captionExpand = function(e) {
            return "expand" !== e.options.caption ? null : new o(e)
        }
    }(jQuery, window, document),
    function(e, t, i, n) {
        "use strict";

        function o(t) {
            var i = e.Deferred();
            t.pushQueue("delayFrame", i), t.registerEvent("initEndWrite", function() {
                t.blocksOn.each(function(e, i) {
                    i.style[r["private"].animationDelay] = e * t.options.displayTypeSpeed + "ms"
                }), t.$obj.addClass("cbp-displayType-bottomToTop"), t.blocksOn.last().one(r["private"].animationend, function() {
                    t.$obj.removeClass("cbp-displayType-bottomToTop"), t.blocksOn.each(function(e, t) {
                        t.style[r["private"].animationDelay] = ""
                    }), i.resolve()
                })
            }, !0)
        }
        var r = e.fn.cubeportfolio.constructor;
        r.plugins.displayBottomToTop = function(e) {
            return r["private"].modernBrowser && "bottomToTop" === e.options.displayType && 0 !== e.blocksOn.length ? new o(e) : null
        }
    }(jQuery, window, document),
    function(e, t, i, n) {
        "use strict";

        function o(t) {
            var i = e.Deferred();
            t.pushQueue("delayFrame", i), t.registerEvent("initEndWrite", function() {
                t.obj.style[r["private"].animationDuration] = t.options.displayTypeSpeed + "ms", t.$obj.addClass("cbp-displayType-fadeIn"), t.$obj.one(r["private"].animationend, function() {
                    t.$obj.removeClass("cbp-displayType-fadeIn"), t.obj.style[r["private"].animationDuration] = "", i.resolve()
                })
            }, !0)
        }
        var r = e.fn.cubeportfolio.constructor;
        r.plugins.displayFadeIn = function(e) {
            return !r["private"].modernBrowser || "lazyLoading" !== e.options.displayType && "fadeIn" !== e.options.displayType || 0 === e.blocksOn.length ? null : new o(e)
        }
    }(jQuery, window, document),
    function(e, t, i, n) {
        "use strict";

        function o(t) {
            var i = e.Deferred();
            t.pushQueue("delayFrame", i), t.registerEvent("initEndWrite", function() {
                t.obj.style[r["private"].animationDuration] = t.options.displayTypeSpeed + "ms", t.$obj.addClass("cbp-displayType-fadeInToTop"), t.$obj.one(r["private"].animationend, function() {
                    t.$obj.removeClass("cbp-displayType-fadeInToTop"), t.obj.style[r["private"].animationDuration] = "", i.resolve()
                })
            }, !0)
        }
        var r = e.fn.cubeportfolio.constructor;
        r.plugins.displayFadeInToTop = function(e) {
            return r["private"].modernBrowser && "fadeInToTop" === e.options.displayType && 0 !== e.blocksOn.length ? new o(e) : null
        }
    }(jQuery, window, document),
    function(e, t, i, n) {
        "use strict";

        function o(t) {
            var i = e.Deferred();
            t.pushQueue("delayFrame", i), t.registerEvent("initEndWrite", function() {
                t.blocksOn.each(function(e, i) {
                    i.style[r["private"].animationDelay] = e * t.options.displayTypeSpeed + "ms"
                }), t.$obj.addClass("cbp-displayType-sequentially"), t.blocksOn.last().one(r["private"].animationend, function() {
                    t.$obj.removeClass("cbp-displayType-sequentially"), t.blocksOn.each(function(e, t) {
                        t.style[r["private"].animationDelay] = ""
                    }), i.resolve()
                })
            }, !0)
        }
        var r = e.fn.cubeportfolio.constructor;
        r.plugins.displaySequentially = function(e) {
            return r["private"].modernBrowser && "sequentially" === e.options.displayType && 0 !== e.blocksOn.length ? new o(e) : null
        }
    }(jQuery, window, document),
    function(e, t, i, n) {
        "use strict";

        function o(t) {
            var i = this;
            i.parent = t, i.filters = e(t.options.filters), i.filterData = [], t.registerEvent("afterPlugins", function(e) {
                i.filterFromUrl(), i.registerFilter()
            })
        }
        var r = e.fn.cubeportfolio.constructor;
        o.prototype.registerFilter = function() {
            var t = this,
                i = t.parent,
                n = i.defaultFilter.split("|");
            t.wrap = t.filters.find(".cbp-l-filters-dropdownWrap").on({
                "mouseover.cbp": function() {
                    e(this).addClass("cbp-l-filters-dropdownWrap-open")
                },
                "mouseleave.cbp": function() {
                    e(this).removeClass("cbp-l-filters-dropdownWrap-open")
                }
            }), t.filters.each(function(o, r) {
                var s = e(r),
                    a = "*",
                    l = s.find(".cbp-filter-item"),
                    c = {};
                s.hasClass("cbp-l-filters-dropdown") && (c.wrap = s.find(".cbp-l-filters-dropdownWrap"), c.header = s.find(".cbp-l-filters-dropdownHeader"), c.headerText = c.header.text()), i.$obj.cubeportfolio("showCounter", l), e.each(n, function(e, t) {
                    return l.filter('[data-filter="' + t + '"]').length ? (a = t, n.splice(e, 1), !1) : void 0
                }), e.data(r, "filterName", a), t.filterData.push(r), t.filtersCallback(c, l.filter('[data-filter="' + a + '"]')), l.on("click.cbp", function() {
                    var n = e(this);
                    if (!n.hasClass("cbp-filter-item-active") && !i.isAnimating) {
                        t.filtersCallback(c, n), e.data(r, "filterName", n.data("filter"));
                        var o = e.map(t.filterData, function(t, i) {
                            var n = e.data(t, "filterName");
                            return "" !== n && "*" !== n ? n : null
                        });
                        o.length < 1 && (o = ["*"]);
                        var s = o.join("|");
                        i.defaultFilter !== s && i.$obj.cubeportfolio("filter", s)
                    }
                })
            })
        }, o.prototype.filtersCallback = function(t, i) {
            e.isEmptyObject(t) || (t.wrap.trigger("mouseleave.cbp"), t.headerText ? t.headerText = "" : t.header.html(i.html())), i.addClass("cbp-filter-item-active").siblings().removeClass("cbp-filter-item-active")
        }, o.prototype.filterFromUrl = function() {
            var e = /#cbpf=(.*?)([#\?&]|$)/gi.exec(location.href);
            null !== e && (this.parent.defaultFilter = decodeURIComponent(e[1]))
        }, o.prototype.destroy = function() {
            var e = this;
            e.filters.find(".cbp-filter-item").off(".cbp"), e.wrap.off(".cbp")
        }, r.plugins.filters = function(e) {
            return "" === e.options.filters ? null : new o(e)
        }
    }(jQuery, window, document),
    function(e, t, i, n) {
        "use strict";

        function o(t) {
            var i = this;
            i.parent = t, i.options = e.extend({}, s, i.parent.options.plugins.inlineSlider), i.runInit(), t.registerEvent("addItemsToDOM", function() {
                i.runInit()
            })
        }

        function r(e) {
            var t = this;
            e.hasClass("cbp-slider-inline-ready") || (e.addClass("cbp-slider-inline-ready"), t.items = e.find(".cbp-slider-wrapper").children(".cbp-slider-item"), t.active = t.items.filter(".cbp-slider-item--active").index(), t.total = t.items.length - 1, t.updateLeft(), e.find(".cbp-slider-next").on("click.cbp", function(e) {
                e.preventDefault(), t.active < t.total ? (t.active++, t.updateLeft()) : t.active === t.total && (t.active = 0, t.updateLeft())
            }), e.find(".cbp-slider-prev").on("click.cbp", function(e) {
                e.preventDefault(), t.active > 0 ? (t.active--, t.updateLeft()) : 0 === t.active && (t.active = t.total, t.updateLeft())
            }))
        }
        var s = {},
            a = e.fn.cubeportfolio.constructor;
        r.prototype.updateLeft = function() {
            var e = this;
            e.items.removeClass("cbp-slider-item--active"), e.items.eq(e.active).addClass("cbp-slider-item--active"), e.items.each(function(t, i) {
                i.style.left = t - e.active + "00%"
            })
        }, o.prototype.runInit = function() {
            var t = this;
            t.parent.$obj.find(".cbp-slider-inline").not(".cbp-slider-inline-ready").each(function(i, n) {
                var o = e(n),
                    s = o.find(".cbp-slider-item--active").find("img")[0];
                s.hasAttribute("data-cbp-src") ? t.parent.$obj.on("lazyLoad.cbp", function(e, t) {
                    t.src === s.src && new r(o)
                }) : new r(o)
            })
        }, o.prototype.destroy = function() {
            var t = this;
            t.parent.$obj.find(".cbp-slider-next").off("click.cbp"), t.parent.$obj.find(".cbp-slider-prev").off("click.cbp"), t.parent.$obj.off("lazyLoad.cbp"), t.parent.$obj.find(".cbp-slider-inline").each(function(t, i) {
                var n = e(i);
                n.removeClass("cbp-slider-inline-ready");
                var o = n.find(".cbp-slider-item");
                o.removeClass("cbp-slider-item--active"), o.removeAttr("style"), o.eq(0).addClass("cbp-slider-item--active")
            })
        }, a.plugins.inlineSlider = function(e) {
            return new o(e)
        }
    }(jQuery, window, document),
    function(e, t, i, n) {
        "use strict";

        function o(i) {
            var n = this;
            n.window = e(t), n.parent = i, n.options = e.extend({}, r, n.parent.options.plugins.lazyLoad), i.registerEvent("initEndWrite", function() {
                var e;
                n.triggerImg(), i.registerEvent("resizeMainContainer", function() {
                    n.triggerImg()
                }), n.window.on("scroll.cbp", function() {
                    clearTimeout(e), e = setTimeout(function() {
                        n.triggerImg()
                    }, 300)
                })
            }, !0)
        }
        var r = {
                loadingClass: "cbp-lazyload",
                threshold: 0
            },
            s = e.fn.cubeportfolio.constructor;
        o.prototype.triggerImg = function() {
            var t = this,
                i = t.parent.$obj.find("img").filter("[data-cbp-src]");
            0 !== i.length && (t.screenHeight = t.window.height(), i.each(function(i, n) {
                var o = e(n.parentNode);
                if (!t.isElementInScreen(n)) return void o.addClass(t.options.loadingClass);
                var r = n.getAttribute("data-cbp-src");
                null === t.parent.checkSrc(r) ? (t.removeLazy(n, r), o.removeClass(t.options.loadingClass)) : (o.addClass(t.options.loadingClass), e("<img>").on("load.cbp error.cbp", function() {
                    t.removeLazy(n, r, o)
                }).attr("src", r))
            }))
        }, o.prototype.removeLazy = function(t, i, n) {
            var o = this;
            t.src = i, t.removeAttribute("data-cbp-src"), o.parent.removeAttrImage(t), o.parent.$obj.trigger("lazyLoad.cbp", t), n && (s["private"].modernBrowser ? e(t).one(s["private"].transitionend, function() {
                n.removeClass(o.options.loadingClass)
            }) : n.removeClass(o.options.loadingClass))
        }, o.prototype.isElementInScreen = function(e) {
            var t = this,
                i = e.getBoundingClientRect(),
                n = i.bottom + t.options.threshold,
                o = t.screenHeight + n - (i.top - t.options.threshold);
            return n >= 0 && o >= n
        }, o.prototype.destroy = function() {
            var e = this;
            e.window.off("scroll.cbp")
        }, s.plugins.lazyLoad = function(e) {
            return new o(e)
        }
    }(jQuery, window, document),
    function(e, t, i, n) {
        "use strict";

        function o(t) {
            var i = this;
            i.parent = t, i.loadMore = e(t.options.loadMore).find(".cbp-l-loadMore-link"), i.loadMore.length && i[t.options.loadMoreAction]()
        }
        var r = e.fn.cubeportfolio.constructor;
        o.prototype.click = function() {
            var t = this,
                i = 0;
            t.loadMore.on("click.cbp", function(n) {
                var o = e(this);
                n.preventDefault(), t.parent.isAnimating || o.hasClass("cbp-l-loadMore-stop") || (o.addClass("cbp-l-loadMore-loading"), i++, e.ajax({
                    url: t.loadMore.attr("href") + "?block=" + i,
                    type: "GET",
                    dataType: "HTML"
                }).done(function(e) {
                    var n = e.replace(/(\r\n|\n|\r)/gm, ""),
                        r = n.indexOf("cbp-loadMore-block" + i);
                    if (-1 === r) return void o.addClass("cbp-l-loadMore-stop");
                    var s, a = n.indexOf(">", r) + 1,
                        l = n.indexOf("cbp-loadMore-block" + (i + 1));
                    s = -1 === l ? n.lastIndexOf("</") : n.lastIndexOf("</", l), t.parent.$obj.cubeportfolio("appendItems", n.substring(a, s), function() {
                        o.removeClass("cbp-l-loadMore-loading"), -1 === l && o.addClass("cbp-l-loadMore-stop")
                    })
                }).fail(function() {}))
            })
        }, o.prototype.auto = function() {
            var i = this;
            i.parent.$obj.on("initComplete.cbp", function() {
                Object.create({
                    init: function() {
                        var n = this;
                        n.isActive = !1, n.numberOfClicks = 0, i.loadMore.addClass("cbp-l-loadMore-loading"), n.window = e(t), n.addEvents(), n.getNewItems()
                    },
                    addEvents: function() {
                        var e, t = this;
                        i.loadMore.on("click.cbp", function(e) {
                            e.preventDefault()
                        }), t.window.on("scroll.loadMoreObject", function() {
                            clearTimeout(e), e = setTimeout(function() {
                                i.parent.isAnimating || t.getNewItems()
                            }, 80)
                        }), i.parent.$obj.on("filterComplete.cbp", function() {
                            t.getNewItems()
                        })
                    },
                    getNewItems: function() {
                        var t, n, o = this;
                        o.isActive || i.loadMore.hasClass("cbp-l-loadMore-stop") || (t = i.loadMore.offset().top - 200, n = o.window.scrollTop() + o.window.height(), t > n || (o.isActive = !0, o.numberOfClicks++, e.ajax({
                            url: i.loadMore.attr("href") + "?block=" + o.numberOfClicks,
                            type: "GET",
                            dataType: "HTML",
                            cache: !0
                        }).done(function(e) {
                            var t = e.replace(/(\r\n|\n|\r)/gm, ""),
                                n = t.indexOf("cbp-loadMore-block" + o.numberOfClicks);
                            if (-1 === n) return void i.loadMore.addClass("cbp-l-loadMore-stop");
                            var r, s = t.indexOf(">", n) + 1,
                                a = t.indexOf("cbp-loadMore-block" + (o.numberOfClicks + 1));
                            r = -1 === a ? t.lastIndexOf("</") : t.lastIndexOf("</", a), i.parent.$obj.cubeportfolio("appendItems", t.substring(s, r), function() {
                                -1 === a ? (i.loadMore.addClass("cbp-l-loadMore-stop"), o.window.off("scroll.loadMoreObject"), i.parent.$obj.off("filterComplete.cbp")) : (o.isActive = !1, o.window.trigger("scroll.loadMoreObject"))
                            })
                        }).fail(function() {
                            o.isActive = !1
                        })))
                    }
                }).init()
            })
        }, o.prototype.destroy = function() {
            var i = this;
            i.loadMore.off(".cbp"), e(t).off("scroll.loadMoreObject")
        }, r.plugins.loadMore = function(e) {
            return "" === e.options.loadMore ? null : new o(e)
        }
    }(jQuery, window, document),
    function(e, t, i, n) {
        "use strict";

        function o(e) {
            var t = this;
            t.parent = e, e.options.lightboxShowCounter === !1 && (e.options.lightboxCounter = ""), e.options.singlePageShowCounter === !1 && (e.options.singlePageCounter = ""), e.registerEvent("initStartRead", function() {
                t.run()
            }, !0)
        }
        var r = e.fn.cubeportfolio.constructor,
            s = {
                init: function(t, n) {
                    var o, r = this;
                    if (r.cubeportfolio = t, r.type = n, r.isOpen = !1, r.options = r.cubeportfolio.options, "lightbox" === n && r.cubeportfolio.registerEvent("resizeWindow", function() {
                            r.resizeImage()
                        }), "singlePageInline" !== n) {
                        if (r.createMarkup(), "singlePage" === n && (r.cubeportfolio.registerEvent("resizeWindow", function() {
                                if (r.options.singlePageStickyNavigation) {
                                    var e = r.wrap[0].clientWidth;
                                    e > 0 && (r.navigationWrap.width(e), r.navigation.width(e))
                                }
                            }), r.options.singlePageDeeplinking)) {
                            r.url = location.href, "#" === r.url.slice(-1) && (r.url = r.url.slice(0, -1));
                            var s = r.url.split("#cbp="),
                                a = s.shift();
                            if (e.each(s, function(t, i) {
                                    return r.cubeportfolio.blocksOn.each(function(t, n) {
                                        var s = e(n).find(r.options.singlePageDelegate + '[href="' + i + '"]');
                                        return s.length ? (o = s, !1) : void 0
                                    }), !o && void 0
                                }), o) {
                                r.url = a;
                                var l = o,
                                    c = l.attr("data-cbp-singlePage"),
                                    d = [];
                                c ? d = l.closest(e(".cbp-item")).find('[data-cbp-singlePage="' + c + '"]') : r.cubeportfolio.blocksOn.each(function(t, i) {
                                    var n = e(i);
                                    n.not(".cbp-item-off") && n.find(r.options.singlePageDelegate).each(function(t, i) {
                                        e(i).attr("data-cbp-singlePage") || d.push(i)
                                    })
                                }), r.openSinglePage(d, o[0])
                            } else if (s.length) {
                                var u = i.createElement("a");
                                u.setAttribute("href", s[0]), r.openSinglePage([u], u)
                            }
                        }
                    } else if (r.height = 0, r.createMarkupSinglePageInline(), r.cubeportfolio.registerEvent("resizeGrid", function() {
                            r.isOpen && r.close()
                        }), r.options.singlePageInlineDeeplinking) {
                        r.url = location.href, "#" === r.url.slice(-1) && (r.url = r.url.slice(0, -1));
                        var s = r.url.split("#cbpi="),
                            a = s.shift();
                        e.each(s, function(t, i) {
                            return r.cubeportfolio.blocksOn.each(function(t, n) {
                                var s = e(n).find(r.options.singlePageInlineDelegate + '[href="' + i + '"]');
                                return s.length ? (o = s, !1) : void 0
                            }), !o && void 0
                        }), o && r.cubeportfolio.registerEvent("initFinish", function() {
                            r.openSinglePageInline(r.cubeportfolio.blocksOn, o[0])
                        }, !0)
                    }
                },
                createMarkup: function() {
                    var t = this,
                        n = "";
                    "singlePage" === t.type && "left" !== t.options.singlePageAnimation && (n = " cbp-popup-singlePage-" + t.options.singlePageAnimation), t.wrap = e("<div/>", {
                        "class": "cbp-popup-wrap cbp-popup-" + t.type + n,
                        "data-action": "lightbox" === t.type ? "close" : ""
                    }).on("click.cbp", function(i) {
                        if (!t.stopEvents) {
                            var n = e(i.target).attr("data-action");
                            t[n] && (t[n](), i.preventDefault())
                        }
                    }), t.content = e("<div/>", {
                        "class": "cbp-popup-content"
                    }).appendTo(t.wrap), e("<div/>", {
                        "class": "cbp-popup-loadingBox"
                    }).appendTo(t.wrap), "ie8" === r["private"].browser && (t.bg = e("<div/>", {
                        "class": "cbp-popup-ie8bg",
                        "data-action": "lightbox" === t.type ? "close" : ""
                    }).appendTo(t.wrap)), t.navigationWrap = e("<div/>", {
                        "class": "cbp-popup-navigation-wrap"
                    }).appendTo(t.wrap), t.navigation = e("<div/>", {
                        "class": "cbp-popup-navigation"
                    }).appendTo(t.navigationWrap), t.closeButton = e("<div/>", {
                        "class": "cbp-popup-close",
                        title: "Close (Esc arrow key)",
                        "data-action": "close"
                    }).appendTo(t.navigation), t.nextButton = e("<div/>", {
                        "class": "cbp-popup-next",
                        title: "Next (Right arrow key)",
                        "data-action": "next"
                    }).appendTo(t.navigation), t.prevButton = e("<div/>", {
                        "class": "cbp-popup-prev",
                        title: "Previous (Left arrow key)",
                        "data-action": "prev"
                    }).appendTo(t.navigation), "singlePage" === t.type && (t.options.singlePageCounter && (t.counter = e(t.options.singlePageCounter).appendTo(t.navigation), t.counter.text("")), t.content.on("click.cbp", t.options.singlePageDelegate, function(e) {
                        e.preventDefault();
                        var i, n = t.dataArray.length,
                            o = this.getAttribute("href");
                        for (i = 0; n > i && t.dataArray[i].url !== o; i++);
                        t.singlePageJumpTo(i - t.current)
                    }), t.wrap.on("mousewheel.cbp DOMMouseScroll.cbp", function(e) {
                        e.stopImmediatePropagation()
                    })), e(i).on("keydown.cbp", function(e) {
                        t.isOpen && (t.stopEvents || (37 === e.keyCode ? t.prev() : 39 === e.keyCode ? t.next() : 27 === e.keyCode && t.close()))
                    })
                },
                createMarkupSinglePageInline: function() {
                    var t = this;
                    t.wrap = e("<div/>", {
                        "class": "cbp-popup-singlePageInline"
                    }).on("click.cbp", function(i) {
                        if (!t.stopEvents) {
                            var n = e(i.target).attr("data-action");
                            n && t[n] && (t[n](), i.preventDefault())
                        }
                    }), t.content = e("<div/>", {
                        "class": "cbp-popup-content"
                    }).appendTo(t.wrap), t.navigation = e("<div/>", {
                        "class": "cbp-popup-navigation"
                    }).appendTo(t.wrap), t.closeButton = e("<div/>", {
                        "class": "cbp-popup-close",
                        title: "Close (Esc arrow key)",
                        "data-action": "close"
                    }).appendTo(t.navigation)
                },
                destroy: function() {
                    var t = this,
                        n = e("body");
                    e(i).off("keydown.cbp"), n.off("click.cbp", t.options.lightboxDelegate), n.off("click.cbp", t.options.singlePageDelegate), t.content.off("click.cbp", t.options.singlePageDelegate), t.cubeportfolio.$obj.off("click.cbp", t.options.singlePageInlineDelegate), t.cubeportfolio.$obj.off("click.cbp", t.options.lightboxDelegate), t.cubeportfolio.$obj.off("click.cbp", t.options.singlePageDelegate), t.cubeportfolio.$obj.removeClass("cbp-popup-isOpening"), t.cubeportfolio.$obj.find(".cbp-item").removeClass("cbp-singlePageInline-active"), t.wrap.remove()
                },
                openLightbox: function(n, o) {
                    var r, s, a = this,
                        l = 0,
                        c = [];
                    if (!a.isOpen) {
                        if (a.isOpen = !0, a.stopEvents = !1, a.dataArray = [], a.current = null, r = o.getAttribute("href"), null === r) throw new Error("HEI! Your clicked element doesn't have a href attribute.");
                        e.each(n, function(t, i) {
                            var n, o = i.getAttribute("href"),
                                s = o,
                                d = "isImage";
                            if (-1 === e.inArray(o, c)) {
                                if (r === o) a.current = l;
                                else if (!a.options.lightboxGallery) return;
                                /youtube/i.test(o) ? (n = o.substring(o.lastIndexOf("v=") + 2), /autoplay=/i.test(n) || (n += "&autoplay=1"), n = n.replace(/\?|&/, "?"), s = "//www.youtube.com/embed/" + n, d = "isYoutube") : /vimeo\.com/i.test(o) ? (n = o.substring(o.lastIndexOf("/") + 1), /autoplay=/i.test(n) || (n += "&autoplay=1"), n = n.replace(/\?|&/, "?"), s = "//player.vimeo.com/video/" + n, d = "isVimeo") : /www\.ted\.com/i.test(o) ? (s = "http://embed.ted.com/talks/" + o.substring(o.lastIndexOf("/") + 1) + ".html", d = "isTed") : /soundcloud\.com/i.test(o) ? (s = o, d = "isSoundCloud") : /(\.mp4)|(\.ogg)|(\.ogv)|(\.webm)/i.test(o) ? (s = -1 !== o.indexOf("|") ? o.split("|") : o.split("%7C"), d = "isSelfHostedVideo") : /\.mp3$/i.test(o) && (s = o, d = "isSelfHostedAudio"), a.dataArray.push({
                                    src: s,
                                    title: i.getAttribute(a.options.lightboxTitleSrc),
                                    type: d
                                }), l++
                            }
                            c.push(o)
                        }), a.counterTotal = a.dataArray.length, 1 === a.counterTotal ? (a.nextButton.hide(), a.prevButton.hide(), a.dataActionImg = "") : (a.nextButton.show(), a.prevButton.show(), a.dataActionImg = 'data-action="next"'), a.wrap.appendTo(i.body), a.scrollTop = e(t).scrollTop(), a.originalStyle = e("html").attr("style"), e("html").css({
                            overflow: "hidden",
                            marginRight: t.innerWidth - e(i).width()
                        }), a.wrap.addClass("cbp-popup-transitionend"), a.wrap.show(), s = a.dataArray[a.current], a[s.type](s)
                    }
                },
                openSinglePage: function(n, o) {
                    var s, a = this,
                        l = 0,
                        c = [];
                    if (!a.isOpen) {
                        if (a.cubeportfolio.singlePageInline && a.cubeportfolio.singlePageInline.isOpen && a.cubeportfolio.singlePageInline.close(), a.isOpen = !0, a.stopEvents = !1, a.dataArray = [], a.current = null, s = o.getAttribute("href"), null === s) throw new Error("HEI! Your clicked element doesn't have a href attribute.");
                        e.each(n, function(t, i) {
                            var n = i.getAttribute("href"); - 1 === e.inArray(n, c) && (s === n && (a.current = l), a.dataArray.push({
                                url: n,
                                element: i
                            }), l++), c.push(n)
                        }), a.counterTotal = a.dataArray.length, 1 === a.counterTotal ? (a.nextButton.hide(), a.prevButton.hide()) : (a.nextButton.show(), a.prevButton.show()), a.wrap.appendTo(i.body), a.scrollTop = e(t).scrollTop(), a.wrap.scrollTop(0), a.wrap.show(), a.finishOpen = 2, a.navigationMobile = e(), a.wrap.one(r["private"].transitionend, function() {
                            e("html").css({
                                overflow: "hidden",
                                marginRight: t.innerWidth - e(i).width()
                            }), a.wrap.addClass("cbp-popup-transitionend"), a.options.singlePageStickyNavigation && (a.wrap.addClass("cbp-popup-singlePage-sticky"), a.navigationWrap.width(a.wrap[0].clientWidth), ("android" === r["private"].browser || "ios" === r["private"].browser) && (a.navigationMobile = e("<div/>", {
                                "class": "cbp-popup-singlePage cbp-popup-singlePage-sticky",
                                id: a.wrap.attr("id")
                            }).on("click.cbp", function(t) {
                                if (!a.stopEvents) {
                                    var i = e(t.target).attr("data-action");
                                    a[i] && (a[i](), t.preventDefault())
                                }
                            }), a.navigationMobile.appendTo(i.body).append(a.navigationWrap))), a.finishOpen--, a.finishOpen <= 0 && a.updateSinglePageIsOpen.call(a)
                        }), ("ie8" === r["private"].browser || "ie9" === r["private"].browser) && (a.options.singlePageStickyNavigation && (a.navigationWrap.width(a.wrap[0].clientWidth), setTimeout(function() {
                            a.wrap.addClass("cbp-popup-singlePage-sticky")
                        }, 1e3)), a.finishOpen--), a.wrap.addClass("cbp-popup-loading"), a.wrap.offset(), a.wrap.addClass("cbp-popup-singlePage-open"), a.options.singlePageDeeplinking && (a.url = a.url.split("#cbp=")[0], location.href = a.url + "#cbp=" + a.dataArray[a.current].url), e.isFunction(a.options.singlePageCallback) && a.options.singlePageCallback.call(a, a.dataArray[a.current].url, a.dataArray[a.current].element)
                    }
                },
                openSinglePageInline: function(i, n, o) {
                    var r, s, a, l, c = this;
                    if (o = o || !1, c.fromOpen = o, c.storeBlocks = i, c.storeCurrentBlock = n, c.isOpen) return s = e(n).closest(".cbp-item").index(), void(c.dataArray[c.current].url !== n.getAttribute("href") || c.current !== s ? c.cubeportfolio.singlePageInline.close("open", {
                        blocks: i,
                        currentBlock: n,
                        fromOpen: !0
                    }) : c.close());
                    if (c.isOpen = !0, c.stopEvents = !1, c.dataArray = [], c.current = null, r = n.getAttribute("href"), null === r) throw new Error("HEI! Your clicked element doesn't have a href attribute.");
                    if (a = e(n).closest(".cbp-item")[0], i.each(function(e, t) {
                            a === t && (c.current = e)
                        }), c.dataArray[c.current] = {
                            url: r,
                            element: n
                        }, l = e(c.dataArray[c.current].element).parents(".cbp-item").addClass("cbp-singlePageInline-active"), c.counterTotal = i.length, c.wrap.insertBefore(c.cubeportfolio.wrapper), "top" === c.options.singlePageInlinePosition) c.blocksToMove = i, c.top = 0;
                    else if ("bottom" === c.options.singlePageInlinePosition) c.blocksToMove = e(), c.top = c.cubeportfolio.height;
                    else if ("above" === c.options.singlePageInlinePosition) c.top = e(i[c.current]).data("cbp").top, c.blocksToMove = e(), i.each(function(t, i) {
                        var n = e(i).data("cbp");
                        n.top + n.height >= c.top && (c.blocksToMove = c.blocksToMove.add(i))
                    }), c.top = Math.max(c.top - c.options.gapHorizontal, 0);
                    else {
                        var d = e(i[c.current]).data("cbp");
                        c.top = d.top + d.height, c.blocksToMove = e(), i.each(function(t, i) {
                            var n = e(i).data("cbp");
                            n.top + n.height > c.top && (c.blocksToMove = c.blocksToMove.add(i))
                        })
                    }
                    if (c.wrap[0].style.height = c.wrap.outerHeight(!0) + "px", c.deferredInline = e.Deferred(), c.options.singlePageInlineInFocus) {
                        c.scrollTop = e(t).scrollTop();
                        var u = c.cubeportfolio.$obj.offset().top + c.top - 100;
                        c.scrollTop !== u ? e("html,body").animate({
                            scrollTop: u
                        }, 350).promise().then(function() {
                            c.resizeSinglePageInline(), c.deferredInline.resolve()
                        }) : (c.resizeSinglePageInline(), c.deferredInline.resolve())
                    } else c.resizeSinglePageInline(), c.deferredInline.resolve();
                    c.cubeportfolio.$obj.addClass("cbp-popup-singlePageInline-open"), c.wrap.css({
                        top: c.top
                    }), c.options.singlePageInlineDeeplinking && (c.url = c.url.split("#cbpi=")[0], location.href = c.url + "#cbpi=" + c.dataArray[c.current].url), e.isFunction(c.options.singlePageInlineCallback) && c.options.singlePageInlineCallback.call(c, c.dataArray[c.current].url, c.dataArray[c.current].element)
                },
                resizeSinglePageInline: function() {
                    var e = this;
                    e.height = 0 === e.top || e.top === e.cubeportfolio.height ? e.wrap.outerHeight(!0) : e.wrap.outerHeight(!0) - e.options.gapHorizontal, e.storeBlocks.each(function(e, t) {
                        r["private"].modernBrowser ? t.style[r["private"].transform] = "" : t.style.marginTop = ""
                    }), e.blocksToMove.each(function(t, i) {
                        r["private"].modernBrowser ? i.style[r["private"].transform] = "translate3d(0px, " + e.height + "px, 0)" : i.style.marginTop = e.height + "px"
                    }), e.cubeportfolio.obj.style.height = e.cubeportfolio.height + e.height + "px"
                },
                revertResizeSinglePageInline: function() {
                    var t = this;
                    t.deferredInline = e.Deferred(), t.storeBlocks.each(function(e, t) {
                        r["private"].modernBrowser ? t.style[r["private"].transform] = "" : t.style.marginTop = ""
                    }), t.cubeportfolio.obj.style.height = t.cubeportfolio.height + "px"
                },
                appendScriptsToWrap: function(e) {
                    var t = this,
                        n = 0,
                        o = function(r) {
                            var s = i.createElement("script"),
                                a = r.src;
                            s.type = "text/javascript", s.readyState ? s.onreadystatechange = function() {
                                ("loaded" == s.readyState || "complete" == s.readyState) && (s.onreadystatechange = null, n++, e[n] && o(e[n]))
                            } : s.onload = function() {
                                n++, e[n] && o(e[n])
                            }, a ? s.src = a : s.text = r.text, t.content[0].appendChild(s)
                        };
                    o(e[0])
                },
                updateSinglePage: function(t, i, n) {
                    var o, r = this;
                    r.content.addClass("cbp-popup-content").removeClass("cbp-popup-content-basic"), n === !1 && r.content.removeClass("cbp-popup-content").addClass("cbp-popup-content-basic"), r.counter && (o = e(r.getCounterMarkup(r.options.singlePageCounter, r.current + 1, r.counterTotal)), r.counter.text(o.text())), r.fromAJAX = {
                        html: t,
                        scripts: i
                    }, r.cubeportfolio.$obj.trigger("updateSinglePageStart.cbp"), r.finishOpen--, r.finishOpen <= 0 && r.updateSinglePageIsOpen.call(r)
                },
                updateSinglePageIsOpen: function() {
                    var t, i = this;
                    i.wrap.addClass("cbp-popup-ready"), i.wrap.removeClass("cbp-popup-loading"), i.content.html(i.fromAJAX.html), i.fromAJAX.scripts && i.appendScriptsToWrap(i.fromAJAX.scripts), i.fromAJAX = {}, t = i.content.find(".cbp-slider"), t ? (t.find(".cbp-slider-item").addClass("cbp-item"), i.slider = t.cubeportfolio({
                        layoutMode: "slider",
                        mediaQueries: [{
                            width: 1,
                            cols: 1
                        }],
                        gapHorizontal: 0,
                        gapVertical: 0,
                        caption: "",
                        coverRatio: ""
                    })) : i.slider = null, ("android" === r["private"].browser || "ios" === r["private"].browser) && e("html").css({
                        position: "fixed"
                    }), i.cubeportfolio.$obj.trigger("updateSinglePageComplete.cbp")
                },
                updateSinglePageInline: function(e, t) {
                    var i = this;
                    i.content.html(e), t && i.appendScriptsToWrap(t), i.cubeportfolio.$obj.trigger("updateSinglePageInlineStart.cbp"), i.singlePageInlineIsOpen.call(i)
                },
                singlePageInlineIsOpen: function() {
                    function e() {
                        t.wrap.addClass("cbp-popup-singlePageInline-ready"), t.wrap[0].style.height = "", t.resizeSinglePageInline(), t.cubeportfolio.$obj.trigger("updateSinglePageInlineComplete.cbp")
                    }
                    var t = this;
                    t.cubeportfolio.loadImages(t.wrap, function() {
                        var i = t.content.find(".cbp-slider");
                        i.length ? (i.find(".cbp-slider-item").addClass("cbp-item"), i.one("initComplete.cbp", function() {
                            t.deferredInline.done(e)
                        }), i.on("pluginResize.cbp", function() {
                            t.deferredInline.done(e)
                        }), t.slider = i.cubeportfolio({
                            layoutMode: "slider",
                            displayType: "default",
                            mediaQueries: [{
                                width: 1,
                                cols: 1
                            }],
                            gapHorizontal: 0,
                            gapVertical: 0,
                            caption: "",
                            coverRatio: ""
                        })) : (t.slider = null, t.deferredInline.done(e))
                    })
                },
                isImage: function(t) {
                    var i = this;
                    new Image, i.tooggleLoading(!0), i.cubeportfolio.loadImages(e('<div><img src="' + t.src + '"></div>'), function() {
                        i.updateImagesMarkup(t.src, t.title, i.getCounterMarkup(i.options.lightboxCounter, i.current + 1, i.counterTotal)), i.tooggleLoading(!1)
                    })
                },
                isVimeo: function(e) {
                    var t = this;
                    t.updateVideoMarkup(e.src, e.title, t.getCounterMarkup(t.options.lightboxCounter, t.current + 1, t.counterTotal))
                },
                isYoutube: function(e) {
                    var t = this;
                    t.updateVideoMarkup(e.src, e.title, t.getCounterMarkup(t.options.lightboxCounter, t.current + 1, t.counterTotal))
                },
                isTed: function(e) {
                    var t = this;
                    t.updateVideoMarkup(e.src, e.title, t.getCounterMarkup(t.options.lightboxCounter, t.current + 1, t.counterTotal))
                },
                isSoundCloud: function(e) {
                    var t = this;
                    t.updateVideoMarkup(e.src, e.title, t.getCounterMarkup(t.options.lightboxCounter, t.current + 1, t.counterTotal))
                },
                isSelfHostedVideo: function(e) {
                    var t = this;
                    t.updateSelfHostedVideo(e.src, e.title, t.getCounterMarkup(t.options.lightboxCounter, t.current + 1, t.counterTotal))
                },
                isSelfHostedAudio: function(e) {
                    var t = this;
                    t.updateSelfHostedAudio(e.src, e.title, t.getCounterMarkup(t.options.lightboxCounter, t.current + 1, t.counterTotal))
                },
                getCounterMarkup: function(e, t, i) {
                    if (!e.length) return "";
                    var n = {
                        current: t,
                        total: i
                    };
                    return e.replace(/\{\{current}}|\{\{total}}/gi, function(e) {
                        return n[e.slice(2, -2)]
                    })
                },
                updateSelfHostedVideo: function(e, t, i) {
                    var n, o = this;
                    o.wrap.addClass("cbp-popup-lightbox-isIframe");
                    var r = '<div class="cbp-popup-lightbox-iframe"><video controls="controls" height="auto" style="width: 100%">';
                    for (n = 0; n < e.length; n++) /(\.mp4)/i.test(e[n]) ? r += '<source src="' + e[n] + '" type="video/mp4">' : /(\.ogg)|(\.ogv)/i.test(e[n]) ? r += '<source src="' + e[n] + '" type="video/ogg">' : /(\.webm)/i.test(e[n]) && (r += '<source src="' + e[n] + '" type="video/webm">');
                    r += 'Your browser does not support the video tag.</video><div class="cbp-popup-lightbox-bottom">' + (t ? '<div class="cbp-popup-lightbox-title">' + t + "</div>" : "") + i + "</div></div>", o.content.html(r), o.wrap.addClass("cbp-popup-ready"), o.preloadNearbyImages()
                },
                updateSelfHostedAudio: function(e, t, i) {
                    var n = this;
                    n.wrap.addClass("cbp-popup-lightbox-isIframe");
                    var o = '<div class="cbp-popup-lightbox-iframe"><div class="cbp-misc-video"><audio controls="controls" height="auto" style="width: 75%"><source src="' + e + '" type="audio/mpeg">Your browser does not support the audio tag.</audio></div><div class="cbp-popup-lightbox-bottom">' + (t ? '<div class="cbp-popup-lightbox-title">' + t + "</div>" : "") + i + "</div></div>";
                    n.content.html(o), n.wrap.addClass("cbp-popup-ready"), n.preloadNearbyImages()
                },
                updateVideoMarkup: function(e, t, i) {
                    var n = this;
                    n.wrap.addClass("cbp-popup-lightbox-isIframe");
                    var o = '<div class="cbp-popup-lightbox-iframe"><iframe src="' + e + '" frameborder="0" allowfullscreen scrolling="no"></iframe><div class="cbp-popup-lightbox-bottom">' + (t ? '<div class="cbp-popup-lightbox-title">' + t + "</div>" : "") + i + "</div></div>";
                    n.content.html(o), n.wrap.addClass("cbp-popup-ready"), n.preloadNearbyImages()
                },
                updateImagesMarkup: function(e, t, i) {
                    var n = this;
                    n.wrap.removeClass("cbp-popup-lightbox-isIframe");
                    var o = '<div class="cbp-popup-lightbox-figure"><img src="' + e + '" class="cbp-popup-lightbox-img" ' + n.dataActionImg + ' /><div class="cbp-popup-lightbox-bottom">' + (t ? '<div class="cbp-popup-lightbox-title">' + t + "</div>" : "") + i + "</div></div>";
                    n.content.html(o), n.wrap.addClass("cbp-popup-ready"), n.resizeImage(), n.preloadNearbyImages()
                },
                next: function() {
                    var e = this;
                    e[e.type + "JumpTo"](1)
                },
                prev: function() {
                    var e = this;
                    e[e.type + "JumpTo"](-1)
                },
                lightboxJumpTo: function(e) {
                    var t, i = this;
                    i.current = i.getIndex(i.current + e), t = i.dataArray[i.current], i[t.type](t)
                },
                singlePageJumpTo: function(t) {
                    var i = this;
                    i.current = i.getIndex(i.current + t), e.isFunction(i.options.singlePageCallback) && (i.resetWrap(), i.wrap.scrollTop(0), i.wrap.addClass("cbp-popup-loading"), i.options.singlePageCallback.call(i, i.dataArray[i.current].url, i.dataArray[i.current].element), i.options.singlePageDeeplinking && (location.href = i.url + "#cbp=" + i.dataArray[i.current].url))
                },
                resetWrap: function() {
                    var e = this;
                    "singlePage" === e.type && e.options.singlePageDeeplinking && (location.href = e.url + "#"), "singlePageInline" === e.type && e.options.singlePageInlineDeeplinking && (location.href = e.url + "#")
                },
                getIndex: function(e) {
                    var t = this;
                    return e %= t.counterTotal, 0 > e && (e = t.counterTotal + e), e
                },
                close: function(i, n) {
                    function o() {
                        a.content.html(""), a.wrap.detach(), a.cubeportfolio.$obj.removeClass("cbp-popup-singlePageInline-open cbp-popup-singlePageInline-close"), "promise" === i && e.isFunction(n.callback) && n.callback.call(a.cubeportfolio), a.resetWrap()
                    }

                    function s() {
                        a.options.singlePageInlineInFocus && "promise" !== i ? e("html,body").animate({
                            scrollTop: a.scrollTop
                        }, 350).promise().then(function() {
                            o()
                        }) : o()
                    }
                    var a = this;
                    a.isOpen = !1, "singlePageInline" === a.type ? "open" === i ? (a.wrap.removeClass("cbp-popup-singlePageInline-ready"), e(a.dataArray[a.current].element).closest(".cbp-item").removeClass("cbp-singlePageInline-active"), a.openSinglePageInline(n.blocks, n.currentBlock, n.fromOpen)) : (a.height = 0, a.revertResizeSinglePageInline(), a.wrap.removeClass("cbp-popup-singlePageInline-ready"), a.cubeportfolio.$obj.addClass("cbp-popup-singlePageInline-close"), a.cubeportfolio.$obj.find(".cbp-item").removeClass("cbp-singlePageInline-active"), r["private"].modernBrowser ? a.wrap.one(r["private"].transitionend, function() {
                        s()
                    }) : s()) : "singlePage" === a.type ? (a.resetWrap(), a.wrap.removeClass("cbp-popup-ready cbp-popup-transitionend"), ("android" === r["private"].browser || "ios" === r["private"].browser) && (e("html").css({
                        position: ""
                    }), a.navigationWrap.appendTo(a.wrap), a.navigationMobile.remove()), e(t).scrollTop(a.scrollTop), setTimeout(function() {
                        a.stopScroll = !0, a.navigationWrap.css({
                            top: a.wrap.scrollTop()
                        }), a.wrap.removeClass("cbp-popup-singlePage-open cbp-popup-singlePage-sticky"), ("ie8" === r["private"].browser || "ie9" === r["private"].browser) && (a.content.html(""), a.wrap.detach(), e("html").css({
                            overflow: "",
                            marginRight: "",
                            position: ""
                        }), a.navigationWrap.removeAttr("style"))
                    }, 0), e("html").css({
                        overflow: "",
                        marginRight: "",
                        position: ""
                    }), a.wrap.one(r["private"].transitionend, function() {
                        a.content.html(""), a.wrap.detach(), a.navigationWrap.removeAttr("style")
                    })) : (a.originalStyle ? e("html").attr("style", a.originalStyle) : e("html").css({
                        overflow: "",
                        marginRight: ""
                    }), e(t).scrollTop(a.scrollTop), a.content.html(""), a.wrap.detach())
                },
                tooggleLoading: function(e) {
                    var t = this;
                    t.stopEvents = e, t.wrap[e ? "addClass" : "removeClass"]("cbp-popup-loading")
                },
                resizeImage: function() {
                    if (this.isOpen) {
                        var i = e(t).height(),
                            n = this.content.find("img"),
                            o = parseInt(n.css("margin-top"), 10) + parseInt(n.css("margin-bottom"), 10);
                        n.css("max-height", i - o + "px")
                    }
                },
                preloadNearbyImages: function() {
                    var e = [],
                        t = this;
                    e.push(t.getIndex(t.current + 1)), e.push(t.getIndex(t.current + 2)), e.push(t.getIndex(t.current + 3)), e.push(t.getIndex(t.current - 1)), e.push(t.getIndex(t.current - 2)), e.push(t.getIndex(t.current - 3));
                    for (var i = e.length - 1; i >= 0; i--) "isImage" === t.dataArray[e[i]].type && t.cubeportfolio.checkSrc(t.dataArray[e[i]].src)
                }
            },
            a = !1,
            l = !1;
        o.prototype.run = function() {
            var t = this,
                n = t.parent,
                o = e(i.body);
            n.lightbox = null, n.options.lightboxDelegate && !a && (a = !0, n.lightbox = Object.create(s), n.lightbox.init(n, "lightbox"), o.on("click.cbp", n.options.lightboxDelegate, function(i) {
                i.preventDefault();
                var o = e(this),
                    r = o.attr("data-cbp-lightbox"),
                    s = t.detectScope(o),
                    a = s.data("cubeportfolio"),
                    l = [];
                a ? a.blocksOn.each(function(t, i) {
                    var o = e(i);
                    o.not(".cbp-item-off") && o.find(n.options.lightboxDelegate).each(function(t, i) {
                        r ? e(i).attr("data-cbp-lightbox") === r && l.push(i) : l.push(i)
                    })
                }) : l = r ? s.find(n.options.lightboxDelegate + "[data-cbp-lightbox=" + r + "]") : s.find(n.options.lightboxDelegate), n.lightbox.openLightbox(l, o[0])
            })), n.singlePage = null, n.options.singlePageDelegate && !l && (l = !0, n.singlePage = Object.create(s), n.singlePage.init(n, "singlePage"), o.on("click.cbp", n.options.singlePageDelegate, function(i) {
                i.preventDefault();
                var o = e(this),
                    r = o.attr("data-cbp-singlePage"),
                    s = t.detectScope(o),
                    a = s.data("cubeportfolio"),
                    l = [];
                a ? a.blocksOn.each(function(t, i) {
                    var o = e(i);
                    o.not(".cbp-item-off") && o.find(n.options.singlePageDelegate).each(function(t, i) {
                        r ? e(i).attr("data-cbp-singlePage") === r && l.push(i) : l.push(i)
                    })
                }) : l = r ? s.find(n.options.singlePageDelegate + "[data-cbp-singlePage=" + r + "]") : s.find(n.options.singlePageDelegate), n.singlePage.openSinglePage(l, o[0])
            })), n.singlePageInline = null, n.options.singlePageDelegate && (n.singlePageInline = Object.create(s), n.singlePageInline.init(n, "singlePageInline"), n.$obj.on("click.cbp", n.options.singlePageInlineDelegate, function(e) {
                e.preventDefault(), n.singlePageInline.openSinglePageInline(n.blocksOn, this)
            }))
        }, o.prototype.detectScope = function(t) {
            var n, o, r;
            return n = t.closest(".cbp-popup-singlePageInline"), n.length ? (r = t.closest(".cbp", n[0]), r.length ? r : n) : (o = t.closest(".cbp-popup-singlePage"), o.length ? (r = t.closest(".cbp", o[0]), r.length ? r : o) : (r = t.closest(".cbp"), r.length ? r : e(i.body)))
        }, o.prototype.destroy = function() {
            var t = this.parent;
            e(i.body).off("click.cbp"), a = !1, l = !1, t.lightbox && t.lightbox.destroy(), t.singlePage && t.singlePage.destroy(), t.singlePageInline && t.singlePageInline.destroy()
        }, r.plugins.popUp = function(e) {
            return new o(e)
        }
    }(jQuery, window, document),
    function(e, t, i, n) {
        "use strict";

        function o(t) {
            var i = this;
            i.parent = t, i.searchInput = e(t.options.search), i.searchInput.each(function(t, i) {
                var n = i.getAttribute("data-search");
                n || (n = "*"), e.data(i, "searchData", {
                    value: i.value,
                    el: n
                })
            });
            var n = null;
            i.searchInput.on("keyup.cbp paste.cbp", function(t) {
                t.preventDefault();
                var o = e(this);
                clearTimeout(n), n = setTimeout(function() {
                    i.runEvent.call(i, o)
                }, 300)
            }), i.searchNothing = i.searchInput.siblings(".cbp-search-nothing").detach(), i.searchNothingHeight = null, i.searchNothingHTML = i.searchNothing.html(), i.searchInput.siblings(".cbp-search-icon").on("click.cbp", function(t) {
                t.preventDefault(), i.runEvent.call(i, e(this).prev().val(""))
            })
        }
        var r = e.fn.cubeportfolio.constructor;
        o.prototype.runEvent = function(t) {
            var i = this,
                n = t.val(),
                o = t.data("searchData"),
                r = new RegExp(n, "i");
            o.value === n || i.parent.isAnimating || (o.value = n, n.length > 0 ? t.attr("value", n) : t.removeAttr("value"), i.parent.$obj.cubeportfolio("filter", function(t) {
                var s = t.filter(function(t, i) {
                    var n = e(i).find(o.el).text();
                    return n.search(r) > -1 || void 0
                });
                if (0 === s.length && i.searchNothing.length) {
                    var a = i.searchNothingHTML.replace("{{query}}", n);
                    i.searchNothing.html(a), i.searchNothing.appendTo(i.parent.$obj), null === i.searchNothingHeight && (i.searchNothingHeight = i.searchNothing.outerHeight(!0)), i.parent.registerEvent("resizeMainContainer", function() {
                        i.parent.height = i.parent.height + i.searchNothingHeight, i.parent.obj.style.height = i.parent.height + "px"
                    }, !0)
                } else i.searchNothing.detach();
                return s
            }, function() {
                t.trigger("keyup.cbp")
            }))
        }, o.prototype.destroy = function() {
            var t = this;
            t.searchInput.off(".cbp"), t.searchInput.next(".cbp-search-icon").off(".cbp"), t.searchInput.each(function(t, i) {
                e.removeData(i)
            })
        }, r.plugins.search = function(e) {
            return "" === e.options.search ? null : new o(e)
        }
    }(jQuery, window, document),
    function(e, t, i, n) {
        "use strict";

        function o(t) {
            var i = this;
            i.parent = t, i.options = e.extend({}, r, i.parent.options.plugins.slider);
            var n = e(i.options.pagination);
            n.length > 0 && (i.parent.customPagination = n, i.parent.customPaginationItems = n.children(), i.parent.customPaginationClass = i.options.paginationClass, i.parent.customPaginationItems.on("click.cbp", function(t) {
                t.preventDefault(), t.stopImmediatePropagation(), t.stopPropagation(), i.parent.sliderStopEvents || i.parent.jumpToSlider(e(this))
            })), i.parent.registerEvent("gridAdjust", function() {
                i.sliderMarkup.call(i.parent), i.parent.registerEvent("gridAdjust", function() {
                    i.updateSlider.call(i.parent)
                })
            }, !0)
        }
        var r = {
                pagination: "",
                paginationClass: "cbp-pagination-active"
            },
            s = e.fn.cubeportfolio.constructor;
        o.prototype.sliderMarkup = function() {
            var t = this;
            t.sliderStopEvents = !1, t.sliderActive = 0, t.$obj.one("initComplete.cbp", function() {
                t.$obj.addClass("cbp-mode-slider")
            }), t.nav = e("<div/>", {
                "class": "cbp-nav"
            }), t.nav.on("click.cbp", "[data-slider-action]", function(i) {
                if (i.preventDefault(), i.stopImmediatePropagation(), i.stopPropagation(), !t.sliderStopEvents) {
                    var n = e(this),
                        o = n.attr("data-slider-action");
                    t[o + "Slider"] && t[o + "Slider"](n)
                }
            }), t.options.showNavigation && (t.controls = e("<div/>", {
                "class": "cbp-nav-controls"
            }), t.navPrev = e("<div/>", {
                "class": "cbp-nav-prev",
                "data-slider-action": "prev"
            }).appendTo(t.controls), t.navNext = e("<div/>", {
                "class": "cbp-nav-next",
                "data-slider-action": "next"
            }).appendTo(t.controls), t.controls.appendTo(t.nav)), t.options.showPagination && (t.navPagination = e("<div/>", {
                "class": "cbp-nav-pagination"
            }).appendTo(t.nav)), (t.controls || t.navPagination) && t.nav.appendTo(t.$obj), t.updateSliderPagination(), t.options.auto && (t.options.autoPauseOnHover && (t.mouseIsEntered = !1, t.$obj.on("mouseenter.cbp", function(e) {
                t.mouseIsEntered = !0, t.stopSliderAuto()
            }).on("mouseleave.cbp", function(e) {
                t.mouseIsEntered = !1, t.startSliderAuto()
            })), t.startSliderAuto()), t.options.drag && s["private"].modernBrowser && t.dragSlider()
        }, o.prototype.updateSlider = function() {
            var e = this;
            e.updateSliderPosition(), e.updateSliderPagination()
        }, o.prototype.destroy = function() {
            var e = this;
            e.parent.customPaginationItems && e.parent.customPaginationItems.off(".cbp"), (e.parent.controls || e.parent.navPagination) && (e.parent.nav.off(".cbp"), e.parent.nav.remove())
        }, s.plugins.slider = function(e) {
            return "slider" !== e.options.layoutMode ? null : new o(e)
        }
    }(jQuery, window, document), ! function(e) {
        "use strict";
        "function" == typeof define && define.amd ? define(["jquery"], e) : "undefined" != typeof exports ? module.exports = e(require("jquery")) : e(jQuery)
    }(function(e) {
        "use strict";
        var t = window.Slick || {};
        t = function() {
            function t(t, n) {
                var o, r, s, a = this;
                if (a.defaults = {
                        accessibility: !0,
                        adaptiveHeight: !1,
                        appendArrows: e(t),
                        appendDots: e(t),
                        arrows: !0,
                        asNavFor: null,
                        prevArrow: '<button type="button" data-role="none" class="slick-prev" aria-label="previous">Previous</button>',
                        nextArrow: '<button type="button" data-role="none" class="slick-next" aria-label="next">Next</button>',
                        autoplay: !1,
                        autoplaySpeed: 3e3,
                        centerMode: !1,
                        centerPadding: "50px",
                        cssEase: "ease",
                        customPaging: function(e, t) {
                            return '<button type="button" data-role="none">' + (t + 1) + "</button>"
                        },
                        dots: !1,
                        dotsClass: "slick-dots",
                        draggable: !0,
                        easing: "linear",
                        edgeFriction: .35,
                        fade: !1,
                        focusOnSelect: !1,
                        infinite: !0,
                        initialSlide: 0,
                        lazyLoad: "ondemand",
                        mobileFirst: !1,
                        pauseOnHover: !0,
                        pauseOnDotsHover: !1,
                        respondTo: "window",
                        responsive: null,
                        rows: 1,
                        rtl: !1,
                        slide: "",
                        slidesPerRow: 1,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        speed: 500,
                        swipe: !0,
                        swipeToSlide: !1,
                        touchMove: !0,
                        touchThreshold: 5,
                        useCSS: !0,
                        variableWidth: !1,
                        vertical: !1,
                        verticalSwiping: !1,
                        waitForAnimate: !0
                    }, a.initials = {
                        animating: !1,
                        dragging: !1,
                        autoPlayTimer: null,
                        currentDirection: 0,
                        currentLeft: null,
                        currentSlide: 0,
                        direction: 1,
                        $dots: null,
                        listWidth: null,
                        listHeight: null,
                        loadIndex: 0,
                        $nextArrow: null,
                        $prevArrow: null,
                        slideCount: null,
                        slideWidth: null,
                        $slideTrack: null,
                        $slides: null,
                        sliding: !1,
                        slideOffset: 0,
                        swipeLeft: null,
                        $list: null,
                        touchObject: {},
                        transformsEnabled: !1
                    }, e.extend(a, a.initials), a.activeBreakpoint = null, a.animType = null, a.animProp = null, a.breakpoints = [], a.breakpointSettings = [], a.cssTransitions = !1, a.hidden = "hidden", a.paused = !1, a.positionProp = null, a.respondTo = null, a.rowCount = 1, a.shouldClick = !0, a.$slider = e(t), a.$slidesCache = null, a.transformType = null, a.transitionType = null, a.visibilityChange = "visibilitychange", a.windowWidth = 0, a.windowTimer = null, o = e(t).data("slick") || {}, a.options = e.extend({}, a.defaults, o, n), a.currentSlide = a.options.initialSlide, a.originalSettings = a.options, r = a.options.responsive || null, r && r.length > -1) {
                    a.respondTo = a.options.respondTo || "window";
                    for (s in r) r.hasOwnProperty(s) && (a.breakpoints.push(r[s].breakpoint), a.breakpointSettings[r[s].breakpoint] = r[s].settings);
                    a.breakpoints.sort(function(e, t) {
                        return a.options.mobileFirst === !0 ? e - t : t - e
                    })
                }
                "undefined" != typeof document.mozHidden ? (a.hidden = "mozHidden", a.visibilityChange = "mozvisibilitychange") : "undefined" != typeof document.msHidden ? (a.hidden = "msHidden", a.visibilityChange = "msvisibilitychange") : "undefined" != typeof document.webkitHidden && (a.hidden = "webkitHidden", a.visibilityChange = "webkitvisibilitychange"), a.autoPlay = e.proxy(a.autoPlay, a), a.autoPlayClear = e.proxy(a.autoPlayClear, a), a.changeSlide = e.proxy(a.changeSlide, a), a.clickHandler = e.proxy(a.clickHandler, a), a.selectHandler = e.proxy(a.selectHandler, a), a.setPosition = e.proxy(a.setPosition, a), a.swipeHandler = e.proxy(a.swipeHandler, a), a.dragHandler = e.proxy(a.dragHandler, a), a.keyHandler = e.proxy(a.keyHandler, a), a.autoPlayIterator = e.proxy(a.autoPlayIterator, a), a.instanceUid = i++, a.htmlExpr = /^(?:\s*(<[\w\W]+>)[^>]*)$/, a.init(), a.checkResponsive(!0)
            }
            var i = 0;
            return t
        }(), t.prototype.addSlide = t.prototype.slickAdd = function(t, i, n) {
            var o = this;
            if ("boolean" == typeof i) n = i, i = null;
            else if (0 > i || i >= o.slideCount) return !1;
            o.unload(), "number" == typeof i ? 0 === i && 0 === o.$slides.length ? e(t).appendTo(o.$slideTrack) : n ? e(t).insertBefore(o.$slides.eq(i)) : e(t).insertAfter(o.$slides.eq(i)) : n === !0 ? e(t).prependTo(o.$slideTrack) : e(t).appendTo(o.$slideTrack), o.$slides = o.$slideTrack.children(this.options.slide), o.$slideTrack.children(this.options.slide).detach(), o.$slideTrack.append(o.$slides), o.$slides.each(function(t, i) {
                e(i).attr("data-slick-index", t)
            }), o.$slidesCache = o.$slides, o.reinit()
        }, t.prototype.animateHeight = function() {
            var e = this;
            if (1 === e.options.slidesToShow && e.options.adaptiveHeight === !0 && e.options.vertical === !1) {
                var t = e.$slides.eq(e.currentSlide).outerHeight(!0);
                e.$list.animate({
                    height: t
                }, e.options.speed)
            }
        }, t.prototype.animateSlide = function(t, i) {
            var n = {},
                o = this;
            o.animateHeight(), o.options.rtl === !0 && o.options.vertical === !1 && (t = -t), o.transformsEnabled === !1 ? o.options.vertical === !1 ? o.$slideTrack.animate({
                left: t
            }, o.options.speed, o.options.easing, i) : o.$slideTrack.animate({
                top: t
            }, o.options.speed, o.options.easing, i) : o.cssTransitions === !1 ? (o.options.rtl === !0 && (o.currentLeft = -o.currentLeft), e({
                animStart: o.currentLeft
            }).animate({
                animStart: t
            }, {
                duration: o.options.speed,
                easing: o.options.easing,
                step: function(e) {
                    e = Math.ceil(e), o.options.vertical === !1 ? (n[o.animType] = "translate(" + e + "px, 0px)", o.$slideTrack.css(n)) : (n[o.animType] = "translate(0px," + e + "px)", o.$slideTrack.css(n))
                },
                complete: function() {
                    i && i.call()
                }
            })) : (o.applyTransition(), t = Math.ceil(t), n[o.animType] = o.options.vertical === !1 ? "translate3d(" + t + "px, 0px, 0px)" : "translate3d(0px," + t + "px, 0px)", o.$slideTrack.css(n), i && setTimeout(function() {
                o.disableTransition(), i.call()
            }, o.options.speed))
        }, t.prototype.asNavFor = function(t) {
            var i = this,
                n = null !== i.options.asNavFor ? e(i.options.asNavFor).slick("getSlick") : null;
            null !== n && n.slideHandler(t, !0)
        }, t.prototype.applyTransition = function(e) {
            var t = this,
                i = {};
            i[t.transitionType] = t.options.fade === !1 ? t.transformType + " " + t.options.speed + "ms " + t.options.cssEase : "opacity " + t.options.speed + "ms " + t.options.cssEase, t.options.fade === !1 ? t.$slideTrack.css(i) : t.$slides.eq(e).css(i)
        }, t.prototype.autoPlay = function() {
            var e = this;
            e.autoPlayTimer && clearInterval(e.autoPlayTimer), e.slideCount > e.options.slidesToShow && e.paused !== !0 && (e.autoPlayTimer = setInterval(e.autoPlayIterator, e.options.autoplaySpeed))
        }, t.prototype.autoPlayClear = function() {
            var e = this;
            e.autoPlayTimer && clearInterval(e.autoPlayTimer)
        }, t.prototype.autoPlayIterator = function() {
            var e = this;
            e.options.infinite === !1 ? 1 === e.direction ? (e.currentSlide + 1 === e.slideCount - 1 && (e.direction = 0), e.slideHandler(e.currentSlide + e.options.slidesToScroll)) : (0 === e.currentSlide - 1 && (e.direction = 1), e.slideHandler(e.currentSlide - e.options.slidesToScroll)) : e.slideHandler(e.currentSlide + e.options.slidesToScroll)
        }, t.prototype.buildArrows = function() {
            var t = this;
            t.options.arrows === !0 && t.slideCount > t.options.slidesToShow && (t.$prevArrow = e(t.options.prevArrow), t.$nextArrow = e(t.options.nextArrow), t.htmlExpr.test(t.options.prevArrow) && t.$prevArrow.appendTo(t.options.appendArrows), t.htmlExpr.test(t.options.nextArrow) && t.$nextArrow.appendTo(t.options.appendArrows), t.options.infinite !== !0 && t.$prevArrow.addClass("slick-disabled"))
        }, t.prototype.buildDots = function() {
            var t, i, n = this;
            if (n.options.dots === !0 && n.slideCount > n.options.slidesToShow) {
                for (i = '<ul class="' + n.options.dotsClass + '">', t = 0; t <= n.getDotCount(); t += 1) i += "<li>" + n.options.customPaging.call(this, n, t) + "</li>";
                i += "</ul>", n.$dots = e(i).appendTo(n.options.appendDots), n.$dots.find("li").first().addClass("slick-active").attr("aria-hidden", "false")
            }
        }, t.prototype.buildOut = function() {
            var t = this;
            t.$slides = t.$slider.children(":not(.slick-cloned)").addClass("slick-slide"), t.slideCount = t.$slides.length, t.$slides.each(function(t, i) {
                e(i).attr("data-slick-index", t)
            }), t.$slidesCache = t.$slides, t.$slider.addClass("slick-slider"), t.$slideTrack = 0 === t.slideCount ? e('<div class="slick-track"/>').appendTo(t.$slider) : t.$slides.wrapAll('<div class="slick-track"/>').parent(), t.$list = t.$slideTrack.wrap('<div aria-live="polite" class="slick-list"/>').parent(), t.$slideTrack.css("opacity", 0), (t.options.centerMode === !0 || t.options.swipeToSlide === !0) && (t.options.slidesToScroll = 1), e("img[data-lazy]", t.$slider).not("[src]").addClass("slick-loading"), t.setupInfinite(), t.buildArrows(), t.buildDots(), t.updateDots(), t.options.accessibility === !0 && t.$list.prop("tabIndex", 0), t.setSlideClasses("number" == typeof this.currentSlide ? this.currentSlide : 0), t.options.draggable === !0 && t.$list.addClass("draggable")
        }, t.prototype.buildRows = function() {
            var e, t, i, n, o, r, s, a = this;
            if (n = document.createDocumentFragment(), r = a.$slider.children(), a.options.rows > 1) {
                for (s = a.options.slidesPerRow * a.options.rows, o = Math.ceil(r.length / s), e = 0; o > e; e++) {
                    var l = document.createElement("div");
                    for (t = 0; t < a.options.rows; t++) {
                        var c = document.createElement("div");
                        for (i = 0; i < a.options.slidesPerRow; i++) {
                            var d = e * s + (t * a.options.slidesPerRow + i);
                            r.get(d) && c.appendChild(r.get(d))
                        }
                        l.appendChild(c)
                    }
                    n.appendChild(l)
                }
                a.$slider.html(n), a.$slider.children().children().children().width(100 / a.options.slidesPerRow + "%").css({
                    display: "inline-block"
                })
            }
        }, t.prototype.checkResponsive = function(t) {
            var i, n, o, r = this,
                s = r.$slider.width(),
                a = window.innerWidth || e(window).width();
            if ("window" === r.respondTo ? o = a : "slider" === r.respondTo ? o = s : "min" === r.respondTo && (o = Math.min(a, s)), r.originalSettings.responsive && r.originalSettings.responsive.length > -1 && null !== r.originalSettings.responsive) {
                n = null;
                for (i in r.breakpoints) r.breakpoints.hasOwnProperty(i) && (r.originalSettings.mobileFirst === !1 ? o < r.breakpoints[i] && (n = r.breakpoints[i]) : o > r.breakpoints[i] && (n = r.breakpoints[i]));
                null !== n ? null !== r.activeBreakpoint ? n !== r.activeBreakpoint && (r.activeBreakpoint = n, "unslick" === r.breakpointSettings[n] ? r.unslick() : (r.options = e.extend({}, r.originalSettings, r.breakpointSettings[n]), t === !0 && (r.currentSlide = r.options.initialSlide), r.refresh())) : (r.activeBreakpoint = n, "unslick" === r.breakpointSettings[n] ? r.unslick() : (r.options = e.extend({}, r.originalSettings, r.breakpointSettings[n]), t === !0 && (r.currentSlide = r.options.initialSlide), r.refresh())) : null !== r.activeBreakpoint && (r.activeBreakpoint = null, r.options = r.originalSettings, t === !0 && (r.currentSlide = r.options.initialSlide), r.refresh())
            }
        }, t.prototype.changeSlide = function(t, i) {
            var n, o, r, s = this,
                a = e(t.target);
            switch (a.is("a") && t.preventDefault(), r = 0 !== s.slideCount % s.options.slidesToScroll, n = r ? 0 : (s.slideCount - s.currentSlide) % s.options.slidesToScroll, t.data.message) {
                case "previous":
                    o = 0 === n ? s.options.slidesToScroll : s.options.slidesToShow - n, s.slideCount > s.options.slidesToShow && s.slideHandler(s.currentSlide - o, !1, i);
                    break;
                case "next":
                    o = 0 === n ? s.options.slidesToScroll : n, s.slideCount > s.options.slidesToShow && s.slideHandler(s.currentSlide + o, !1, i);
                    break;
                case "index":
                    var l = 0 === t.data.index ? 0 : t.data.index || e(t.target).parent().index() * s.options.slidesToScroll;
                    s.slideHandler(s.checkNavigable(l), !1, i);
                    break;
                default:
                    return
            }
        }, t.prototype.checkNavigable = function(e) {
            var t, i, n = this;
            if (t = n.getNavigableIndexes(), i = 0, e > t[t.length - 1]) e = t[t.length - 1];
            else
                for (var o in t) {
                    if (e < t[o]) {
                        e = i;
                        break
                    }
                    i = t[o]
                }
            return e
        }, t.prototype.cleanUpEvents = function() {
            var t = this;
            t.options.dots === !0 && t.slideCount > t.options.slidesToShow && e("li", t.$dots).off("click.slick", t.changeSlide), t.options.dots === !0 && t.options.pauseOnDotsHover === !0 && t.options.autoplay === !0 && e("li", t.$dots).off("mouseenter.slick", t.setPaused.bind(t, !0)).off("mouseleave.slick", t.setPaused.bind(t, !1)), t.options.arrows === !0 && t.slideCount > t.options.slidesToShow && (t.$prevArrow && t.$prevArrow.off("click.slick", t.changeSlide), t.$nextArrow && t.$nextArrow.off("click.slick", t.changeSlide)), t.$list.off("touchstart.slick mousedown.slick", t.swipeHandler), t.$list.off("touchmove.slick mousemove.slick", t.swipeHandler), t.$list.off("touchend.slick mouseup.slick", t.swipeHandler), t.$list.off("touchcancel.slick mouseleave.slick", t.swipeHandler), t.$list.off("click.slick", t.clickHandler), t.options.autoplay === !0 && e(document).off(t.visibilityChange, t.visibility), t.$list.off("mouseenter.slick", t.setPaused.bind(t, !0)), t.$list.off("mouseleave.slick", t.setPaused.bind(t, !1)), t.options.accessibility === !0 && t.$list.off("keydown.slick", t.keyHandler), t.options.focusOnSelect === !0 && e(t.$slideTrack).children().off("click.slick", t.selectHandler), e(window).off("orientationchange.slick.slick-" + t.instanceUid, t.orientationChange), e(window).off("resize.slick.slick-" + t.instanceUid, t.resize), e("[draggable!=true]", t.$slideTrack).off("dragstart", t.preventDefault), e(window).off("load.slick.slick-" + t.instanceUid, t.setPosition), e(document).off("ready.slick.slick-" + t.instanceUid, t.setPosition)
        }, t.prototype.cleanUpRows = function() {
            var e, t = this;
            t.options.rows > 1 && (e = t.$slides.children().children(), e.removeAttr("style"), t.$slider.html(e))
        }, t.prototype.clickHandler = function(e) {
            var t = this;
            t.shouldClick === !1 && (e.stopImmediatePropagation(), e.stopPropagation(), e.preventDefault())
        }, t.prototype.destroy = function() {
            var t = this;
            t.autoPlayClear(), t.touchObject = {}, t.cleanUpEvents(), e(".slick-cloned", t.$slider).remove(), t.$dots && t.$dots.remove(), t.$prevArrow && "object" != typeof t.options.prevArrow && t.$prevArrow.remove(), t.$nextArrow && "object" != typeof t.options.nextArrow && t.$nextArrow.remove(), t.$slides && (t.$slides.removeClass("slick-slide slick-active slick-center slick-visible").attr("aria-hidden", "true").removeAttr("data-slick-index").css({
                position: "",
                left: "",
                top: "",
                zIndex: "",
                opacity: "",
                width: ""
            }), t.$slider.html(t.$slides)), t.cleanUpRows(), t.$slider.removeClass("slick-slider"), t.$slider.removeClass("slick-initialized")
        }, t.prototype.disableTransition = function(e) {
            var t = this,
                i = {};
            i[t.transitionType] = "", t.options.fade === !1 ? t.$slideTrack.css(i) : t.$slides.eq(e).css(i)
        }, t.prototype.fadeSlide = function(e, t) {
            var i = this;
            i.cssTransitions === !1 ? (i.$slides.eq(e).css({
                zIndex: 1e3
            }), i.$slides.eq(e).animate({
                opacity: 1
            }, i.options.speed, i.options.easing, t)) : (i.applyTransition(e), i.$slides.eq(e).css({
                opacity: 1,
                zIndex: 1e3
            }), t && setTimeout(function() {
                i.disableTransition(e), t.call()
            }, i.options.speed))
        }, t.prototype.filterSlides = t.prototype.slickFilter = function(e) {
            var t = this;
            null !== e && (t.unload(), t.$slideTrack.children(this.options.slide).detach(), t.$slidesCache.filter(e).appendTo(t.$slideTrack), t.reinit())
        }, t.prototype.getCurrent = t.prototype.slickCurrentSlide = function() {
            var e = this;
            return e.currentSlide
        }, t.prototype.getDotCount = function() {
            var e = this,
                t = 0,
                i = 0,
                n = 0;
            if (e.options.infinite === !0) n = Math.ceil(e.slideCount / e.options.slidesToScroll);
            else if (e.options.centerMode === !0) n = e.slideCount;
            else
                for (; t < e.slideCount;) ++n, t = i + e.options.slidesToShow, i += e.options.slidesToScroll <= e.options.slidesToShow ? e.options.slidesToScroll : e.options.slidesToShow;
            return n - 1
        }, t.prototype.getLeft = function(e) {
            var t, i, n, o = this,
                r = 0;
            return o.slideOffset = 0, i = o.$slides.first().outerHeight(), o.options.infinite === !0 ? (o.slideCount > o.options.slidesToShow && (o.slideOffset = -1 * o.slideWidth * o.options.slidesToShow, r = -1 * i * o.options.slidesToShow), 0 !== o.slideCount % o.options.slidesToScroll && e + o.options.slidesToScroll > o.slideCount && o.slideCount > o.options.slidesToShow && (e > o.slideCount ? (o.slideOffset = -1 * (o.options.slidesToShow - (e - o.slideCount)) * o.slideWidth, r = -1 * (o.options.slidesToShow - (e - o.slideCount)) * i) : (o.slideOffset = -1 * o.slideCount % o.options.slidesToScroll * o.slideWidth, r = -1 * o.slideCount % o.options.slidesToScroll * i))) : e + o.options.slidesToShow > o.slideCount && (o.slideOffset = (e + o.options.slidesToShow - o.slideCount) * o.slideWidth, r = (e + o.options.slidesToShow - o.slideCount) * i), o.slideCount <= o.options.slidesToShow && (o.slideOffset = 0, r = 0), o.options.centerMode === !0 && o.options.infinite === !0 ? o.slideOffset += o.slideWidth * Math.floor(o.options.slidesToShow / 2) - o.slideWidth : o.options.centerMode === !0 && (o.slideOffset = 0, o.slideOffset += o.slideWidth * Math.floor(o.options.slidesToShow / 2)), t = o.options.vertical === !1 ? -1 * e * o.slideWidth + o.slideOffset : -1 * e * i + r, o.options.variableWidth === !0 && (n = o.slideCount <= o.options.slidesToShow || o.options.infinite === !1 ? o.$slideTrack.children(".slick-slide").eq(e) : o.$slideTrack.children(".slick-slide").eq(e + o.options.slidesToShow), t = n[0] ? -1 * n[0].offsetLeft : 0, o.options.centerMode === !0 && (n = o.options.infinite === !1 ? o.$slideTrack.children(".slick-slide").eq(e) : o.$slideTrack.children(".slick-slide").eq(e + o.options.slidesToShow + 1), t = n[0] ? -1 * n[0].offsetLeft : 0, t += (o.$list.width() - n.outerWidth()) / 2)), t
        }, t.prototype.getOption = t.prototype.slickGetOption = function(e) {
            var t = this;
            return t.options[e]
        }, t.prototype.getNavigableIndexes = function() {
            var e, t = this,
                i = 0,
                n = 0,
                o = [];
            for (t.options.infinite === !1 ? (e = t.slideCount - t.options.slidesToShow + 1, t.options.centerMode === !0 && (e = t.slideCount)) : (i = -1 * t.options.slidesToScroll, n = -1 * t.options.slidesToScroll, e = 2 * t.slideCount); e > i;) o.push(i), i = n + t.options.slidesToScroll, n += t.options.slidesToScroll <= t.options.slidesToShow ? t.options.slidesToScroll : t.options.slidesToShow;
            return o
        }, t.prototype.getSlick = function() {
            return this
        }, t.prototype.getSlideCount = function() {
            var t, i, n, o = this;
            return n = o.options.centerMode === !0 ? o.slideWidth * Math.floor(o.options.slidesToShow / 2) : 0, o.options.swipeToSlide === !0 ? (o.$slideTrack.find(".slick-slide").each(function(t, r) {
                return r.offsetLeft - n + e(r).outerWidth() / 2 > -1 * o.swipeLeft ? (i = r, !1) : void 0
            }), t = Math.abs(e(i).attr("data-slick-index") - o.currentSlide) || 1) : o.options.slidesToScroll
        }, t.prototype.goTo = t.prototype.slickGoTo = function(e, t) {
            var i = this;
            i.changeSlide({
                data: {
                    message: "index",
                    index: parseInt(e)
                }
            }, t)
        }, t.prototype.init = function() {
            var t = this;
            e(t.$slider).hasClass("slick-initialized") || (e(t.$slider).addClass("slick-initialized"), t.buildRows(), t.buildOut(), t.setProps(), t.startLoad(), t.loadSlider(), t.initializeEvents(), t.updateArrows(), t.updateDots()), t.$slider.trigger("init", [t])
        }, t.prototype.initArrowEvents = function() {
            var e = this;
            e.options.arrows === !0 && e.slideCount > e.options.slidesToShow && (e.$prevArrow.on("click.slick", {
                message: "previous"
            }, e.changeSlide), e.$nextArrow.on("click.slick", {
                message: "next"
            }, e.changeSlide))
        }, t.prototype.initDotEvents = function() {
            var t = this;
            t.options.dots === !0 && t.slideCount > t.options.slidesToShow && e("li", t.$dots).on("click.slick", {
                message: "index"
            }, t.changeSlide), t.options.dots === !0 && t.options.pauseOnDotsHover === !0 && t.options.autoplay === !0 && e("li", t.$dots).on("mouseenter.slick", t.setPaused.bind(t, !0)).on("mouseleave.slick", t.setPaused.bind(t, !1))
        }, t.prototype.initializeEvents = function() {
            var t = this;
            t.initArrowEvents(), t.initDotEvents(), t.$list.on("touchstart.slick mousedown.slick", {
                action: "start"
            }, t.swipeHandler), t.$list.on("touchmove.slick mousemove.slick", {
                action: "move"
            }, t.swipeHandler), t.$list.on("touchend.slick mouseup.slick", {
                action: "end"
            }, t.swipeHandler), t.$list.on("touchcancel.slick mouseleave.slick", {
                action: "end"
            }, t.swipeHandler), t.$list.on("click.slick", t.clickHandler), t.options.autoplay === !0 && e(document).on(t.visibilityChange, t.visibility.bind(t)), t.$list.on("mouseenter.slick", t.setPaused.bind(t, !0)), t.$list.on("mouseleave.slick", t.setPaused.bind(t, !1)), t.options.accessibility === !0 && t.$list.on("keydown.slick", t.keyHandler), t.options.focusOnSelect === !0 && e(t.$slideTrack).children().on("click.slick", t.selectHandler), e(window).on("orientationchange.slick.slick-" + t.instanceUid, t.orientationChange.bind(t)), e(window).on("resize.slick.slick-" + t.instanceUid, t.resize.bind(t)), e("[draggable!=true]", t.$slideTrack).on("dragstart", t.preventDefault), e(window).on("load.slick.slick-" + t.instanceUid, t.setPosition), e(document).on("ready.slick.slick-" + t.instanceUid, t.setPosition)
        }, t.prototype.initUI = function() {
            var e = this;
            e.options.arrows === !0 && e.slideCount > e.options.slidesToShow && (e.$prevArrow.show(), e.$nextArrow.show()), e.options.dots === !0 && e.slideCount > e.options.slidesToShow && e.$dots.show(), e.options.autoplay === !0 && e.autoPlay()
        }, t.prototype.keyHandler = function(e) {
            var t = this;
            37 === e.keyCode && t.options.accessibility === !0 ? t.changeSlide({
                data: {
                    message: "previous"
                }
            }) : 39 === e.keyCode && t.options.accessibility === !0 && t.changeSlide({
                data: {
                    message: "next"
                }
            })
        }, t.prototype.lazyLoad = function() {
            function t(t) {
                e("img[data-lazy]", t).each(function() {
                    var t = e(this),
                        i = e(this).attr("data-lazy"),
                        n = document.createElement("img");
                    n.onload = function() {
                        t.animate({
                            opacity: 1
                        }, 200)
                    }, n.src = i, t.css({
                        opacity: 0
                    }).attr("src", i).removeAttr("data-lazy").removeClass("slick-loading")
                })
            }
            var i, n, o, r, s = this;
            s.options.centerMode === !0 ? s.options.infinite === !0 ? (o = s.currentSlide + (s.options.slidesToShow / 2 + 1), r = o + s.options.slidesToShow + 2) : (o = Math.max(0, s.currentSlide - (s.options.slidesToShow / 2 + 1)), r = 2 + (s.options.slidesToShow / 2 + 1) + s.currentSlide) : (o = s.options.infinite ? s.options.slidesToShow + s.currentSlide : s.currentSlide, r = o + s.options.slidesToShow, s.options.fade === !0 && (o > 0 && o--, r <= s.slideCount && r++)), i = s.$slider.find(".slick-slide").slice(o, r), t(i), s.slideCount <= s.options.slidesToShow ? (n = s.$slider.find(".slick-slide"), t(n)) : s.currentSlide >= s.slideCount - s.options.slidesToShow ? (n = s.$slider.find(".slick-cloned").slice(0, s.options.slidesToShow), t(n)) : 0 === s.currentSlide && (n = s.$slider.find(".slick-cloned").slice(-1 * s.options.slidesToShow), t(n))
        }, t.prototype.loadSlider = function() {
            var e = this;
            e.setPosition(), e.$slideTrack.css({
                opacity: 1
            }), e.$slider.removeClass("slick-loading"), e.initUI(), "progressive" === e.options.lazyLoad && e.progressiveLazyLoad()
        }, t.prototype.next = t.prototype.slickNext = function() {
            var e = this;
            e.changeSlide({
                data: {
                    message: "next"
                }
            })
        }, t.prototype.orientationChange = function() {
            var e = this;
            e.checkResponsive(), e.setPosition()
        }, t.prototype.pause = t.prototype.slickPause = function() {
            var e = this;
            e.autoPlayClear(), e.paused = !0
        }, t.prototype.play = t.prototype.slickPlay = function() {
            var e = this;
            e.paused = !1, e.autoPlay()
        }, t.prototype.postSlide = function(e) {
            var t = this;
            t.$slider.trigger("afterChange", [t, e]), t.animating = !1, t.setPosition(), t.swipeLeft = null, t.options.autoplay === !0 && t.paused === !1 && t.autoPlay()
        }, t.prototype.prev = t.prototype.slickPrev = function() {
            var e = this;
            e.changeSlide({
                data: {
                    message: "previous"
                }
            })
        }, t.prototype.preventDefault = function(e) {
            e.preventDefault()
        }, t.prototype.progressiveLazyLoad = function() {
            var t, i, n = this;
            t = e("img[data-lazy]", n.$slider).length, t > 0 && (i = e("img[data-lazy]", n.$slider).first(), i.attr("src", i.attr("data-lazy")).removeClass("slick-loading").load(function() {
                i.removeAttr("data-lazy"), n.progressiveLazyLoad(), n.options.adaptiveHeight === !0 && n.setPosition()
            }).error(function() {
                i.removeAttr("data-lazy"), n.progressiveLazyLoad()
            }))
        }, t.prototype.refresh = function() {
            var t = this,
                i = t.currentSlide;
            t.destroy(), e.extend(t, t.initials), t.init(), t.changeSlide({
                data: {
                    message: "index",
                    index: i
                }
            }, !1)
        }, t.prototype.reinit = function() {
            var t = this;
            t.$slides = t.$slideTrack.children(t.options.slide).addClass("slick-slide"), t.slideCount = t.$slides.length, t.currentSlide >= t.slideCount && 0 !== t.currentSlide && (t.currentSlide = t.currentSlide - t.options.slidesToScroll), t.slideCount <= t.options.slidesToShow && (t.currentSlide = 0), t.setProps(), t.setupInfinite(), t.buildArrows(), t.updateArrows(), t.initArrowEvents(), t.buildDots(), t.updateDots(), t.initDotEvents(), t.options.focusOnSelect === !0 && e(t.$slideTrack).children().on("click.slick", t.selectHandler), t.setSlideClasses(0), t.setPosition(), t.$slider.trigger("reInit", [t])
        }, t.prototype.resize = function() {
            var t = this;
            e(window).width() !== t.windowWidth && (clearTimeout(t.windowDelay), t.windowDelay = window.setTimeout(function() {
                t.windowWidth = e(window).width(), t.checkResponsive(), t.setPosition()
            }, 50))
        }, t.prototype.removeSlide = t.prototype.slickRemove = function(e, t, i) {
            var n = this;
            return "boolean" == typeof e ? (t = e, e = t === !0 ? 0 : n.slideCount - 1) : e = t === !0 ? --e : e, !(n.slideCount < 1 || 0 > e || e > n.slideCount - 1) && (n.unload(), i === !0 ? n.$slideTrack.children().remove() : n.$slideTrack.children(this.options.slide).eq(e).remove(), n.$slides = n.$slideTrack.children(this.options.slide), n.$slideTrack.children(this.options.slide).detach(), n.$slideTrack.append(n.$slides), n.$slidesCache = n.$slides, void n.reinit())
        }, t.prototype.setCSS = function(e) {
            var t, i, n = this,
                o = {};
            n.options.rtl === !0 && (e = -e), t = "left" == n.positionProp ? Math.ceil(e) + "px" : "0px", i = "top" == n.positionProp ? Math.ceil(e) + "px" : "0px", o[n.positionProp] = e, n.transformsEnabled === !1 ? n.$slideTrack.css(o) : (o = {}, n.cssTransitions === !1 ? (o[n.animType] = "translate(" + t + ", " + i + ")", n.$slideTrack.css(o)) : (o[n.animType] = "translate3d(" + t + ", " + i + ", 0px)", n.$slideTrack.css(o)))
        }, t.prototype.setDimensions = function() {
            var e = this;
            e.options.vertical === !1 ? e.options.centerMode === !0 && e.$list.css({
                padding: "0px " + e.options.centerPadding
            }) : (e.$list.height(e.$slides.first().outerHeight(!0) * e.options.slidesToShow), e.options.centerMode === !0 && e.$list.css({
                padding: e.options.centerPadding + " 0px"
            })), e.listWidth = e.$list.width(), e.listHeight = e.$list.height(), e.options.vertical === !1 && e.options.variableWidth === !1 ? (e.slideWidth = Math.ceil(e.listWidth / e.options.slidesToShow), e.$slideTrack.width(Math.ceil(e.slideWidth * e.$slideTrack.children(".slick-slide").length))) : e.options.variableWidth === !0 ? e.$slideTrack.width(5e3 * e.slideCount) : (e.slideWidth = Math.ceil(e.listWidth), e.$slideTrack.height(Math.ceil(e.$slides.first().outerHeight(!0) * e.$slideTrack.children(".slick-slide").length)));
            var t = e.$slides.first().outerWidth(!0) - e.$slides.first().width();
            e.options.variableWidth === !1 && e.$slideTrack.children(".slick-slide").width(e.slideWidth - t)
        }, t.prototype.setFade = function() {
            var t, i = this;
            i.$slides.each(function(n, o) {
                t = -1 * i.slideWidth * n, i.options.rtl === !0 ? e(o).css({
                    position: "relative",
                    right: t,
                    top: 0,
                    zIndex: 800,
                    opacity: 0
                }) : e(o).css({
                    position: "relative",
                    left: t,
                    top: 0,
                    zIndex: 800,
                    opacity: 0
                })
            }), i.$slides.eq(i.currentSlide).css({
                zIndex: 900,
                opacity: 1
            })
        }, t.prototype.setHeight = function() {
            var e = this;
            if (1 === e.options.slidesToShow && e.options.adaptiveHeight === !0 && e.options.vertical === !1) {
                var t = e.$slides.eq(e.currentSlide).outerHeight(!0);
                e.$list.css("height", t)
            }
        }, t.prototype.setOption = t.prototype.slickSetOption = function(e, t, i) {
            var n = this;
            n.options[e] = t, i === !0 && (n.unload(), n.reinit())
        }, t.prototype.setPosition = function() {
            var e = this;
            e.setDimensions(), e.setHeight(), e.options.fade === !1 ? e.setCSS(e.getLeft(e.currentSlide)) : e.setFade(), e.$slider.trigger("setPosition", [e])
        }, t.prototype.setProps = function() {
            var e = this,
                t = document.body.style;
            e.positionProp = e.options.vertical === !0 ? "top" : "left", "top" === e.positionProp ? e.$slider.addClass("slick-vertical") : e.$slider.removeClass("slick-vertical"), (void 0 !== t.WebkitTransition || void 0 !== t.MozTransition || void 0 !== t.msTransition) && e.options.useCSS === !0 && (e.cssTransitions = !0), void 0 !== t.OTransform && (e.animType = "OTransform", e.transformType = "-o-transform", e.transitionType = "OTransition", void 0 === t.perspectiveProperty && void 0 === t.webkitPerspective && (e.animType = !1)), void 0 !== t.MozTransform && (e.animType = "MozTransform", e.transformType = "-moz-transform", e.transitionType = "MozTransition", void 0 === t.perspectiveProperty && void 0 === t.MozPerspective && (e.animType = !1)), void 0 !== t.webkitTransform && (e.animType = "webkitTransform", e.transformType = "-webkit-transform", e.transitionType = "webkitTransition", void 0 === t.perspectiveProperty && void 0 === t.webkitPerspective && (e.animType = !1)), void 0 !== t.msTransform && (e.animType = "msTransform", e.transformType = "-ms-transform", e.transitionType = "msTransition", void 0 === t.msTransform && (e.animType = !1)), void 0 !== t.transform && e.animType !== !1 && (e.animType = "transform", e.transformType = "transform", e.transitionType = "transition"), e.transformsEnabled = null !== e.animType && e.animType !== !1
        }, t.prototype.setSlideClasses = function(e) {
            var t, i, n, o, r = this;
            r.$slider.find(".slick-slide").removeClass("slick-active").attr("aria-hidden", "true").removeClass("slick-center"), i = r.$slider.find(".slick-slide"), r.options.centerMode === !0 ? (t = Math.floor(r.options.slidesToShow / 2), r.options.infinite === !0 && (e >= t && e <= r.slideCount - 1 - t ? r.$slides.slice(e - t, e + t + 1).addClass("slick-active").attr("aria-hidden", "false") : (n = r.options.slidesToShow + e, i.slice(n - t + 1, n + t + 2).addClass("slick-active").attr("aria-hidden", "false")), 0 === e ? i.eq(i.length - 1 - r.options.slidesToShow).addClass("slick-center") : e === r.slideCount - 1 && i.eq(r.options.slidesToShow).addClass("slick-center")), r.$slides.eq(e).addClass("slick-center")) : e >= 0 && e <= r.slideCount - r.options.slidesToShow ? r.$slides.slice(e, e + r.options.slidesToShow).addClass("slick-active").attr("aria-hidden", "false") : i.length <= r.options.slidesToShow ? i.addClass("slick-active").attr("aria-hidden", "false") : (o = r.slideCount % r.options.slidesToShow, n = r.options.infinite === !0 ? r.options.slidesToShow + e : e, r.options.slidesToShow == r.options.slidesToScroll && r.slideCount - e < r.options.slidesToShow ? i.slice(n - (r.options.slidesToShow - o), n + o).addClass("slick-active").attr("aria-hidden", "false") : i.slice(n, n + r.options.slidesToShow).addClass("slick-active").attr("aria-hidden", "false")), "ondemand" === r.options.lazyLoad && r.lazyLoad()
        }, t.prototype.setupInfinite = function() {
            var t, i, n, o = this;
            if (o.options.fade === !0 && (o.options.centerMode = !1), o.options.infinite === !0 && o.options.fade === !1 && (i = null, o.slideCount > o.options.slidesToShow)) {
                for (n = o.options.centerMode === !0 ? o.options.slidesToShow + 1 : o.options.slidesToShow, t = o.slideCount; t > o.slideCount - n; t -= 1) i = t - 1, e(o.$slides[i]).clone(!0).attr("id", "").attr("data-slick-index", i - o.slideCount).prependTo(o.$slideTrack).addClass("slick-cloned");
                for (t = 0; n > t; t += 1) i = t, e(o.$slides[i]).clone(!0).attr("id", "").attr("data-slick-index", i + o.slideCount).appendTo(o.$slideTrack).addClass("slick-cloned");
                o.$slideTrack.find(".slick-cloned").find("[id]").each(function() {
                    e(this).attr("id", "")
                })
            }
        }, t.prototype.setPaused = function(e) {
            var t = this;
            t.options.autoplay === !0 && t.options.pauseOnHover === !0 && (t.paused = e, t.autoPlayClear())
        }, t.prototype.selectHandler = function(t) {
            var i = this,
                n = e(t.target).is(".slick-slide") ? e(t.target) : e(t.target).parents(".slick-slide"),
                o = parseInt(n.attr("data-slick-index"));
            return o || (o = 0), i.slideCount <= i.options.slidesToShow ? (i.$slider.find(".slick-slide").removeClass("slick-active").attr("aria-hidden", "true"), i.$slides.eq(o).addClass("slick-active").attr("aria-hidden", "false"), i.options.centerMode === !0 && (i.$slider.find(".slick-slide").removeClass("slick-center"), i.$slides.eq(o).addClass("slick-center")), void i.asNavFor(o)) : void i.slideHandler(o)
        }, t.prototype.slideHandler = function(e, t, i) {
            var n, o, r, s, a = null,
                l = this;
            return t = t || !1, l.animating === !0 && l.options.waitForAnimate === !0 || l.options.fade === !0 && l.currentSlide === e || l.slideCount <= l.options.slidesToShow ? void 0 : (t === !1 && l.asNavFor(e), n = e, a = l.getLeft(n), s = l.getLeft(l.currentSlide), l.currentLeft = null === l.swipeLeft ? s : l.swipeLeft, l.options.infinite === !1 && l.options.centerMode === !1 && (0 > e || e > l.getDotCount() * l.options.slidesToScroll) ? void(l.options.fade === !1 && (n = l.currentSlide, i !== !0 ? l.animateSlide(s, function() {
                l.postSlide(n)
            }) : l.postSlide(n))) : l.options.infinite === !1 && l.options.centerMode === !0 && (0 > e || e > l.slideCount - l.options.slidesToScroll) ? void(l.options.fade === !1 && (n = l.currentSlide, i !== !0 ? l.animateSlide(s, function() {
                l.postSlide(n)
            }) : l.postSlide(n))) : (l.options.autoplay === !0 && clearInterval(l.autoPlayTimer), o = 0 > n ? 0 !== l.slideCount % l.options.slidesToScroll ? l.slideCount - l.slideCount % l.options.slidesToScroll : l.slideCount + n : n >= l.slideCount ? 0 !== l.slideCount % l.options.slidesToScroll ? 0 : n - l.slideCount : n, l.animating = !0, l.$slider.trigger("beforeChange", [l, l.currentSlide, o]), r = l.currentSlide, l.currentSlide = o, l.setSlideClasses(l.currentSlide), l.updateDots(), l.updateArrows(), l.options.fade === !0 ? (i !== !0 ? l.fadeSlide(o, function() {
                l.postSlide(o)
            }) : l.postSlide(o), void l.animateHeight()) : void(i !== !0 ? l.animateSlide(a, function() {
                l.postSlide(o)
            }) : l.postSlide(o))))
        }, t.prototype.startLoad = function() {
            var e = this;
            e.options.arrows === !0 && e.slideCount > e.options.slidesToShow && (e.$prevArrow.hide(), e.$nextArrow.hide()), e.options.dots === !0 && e.slideCount > e.options.slidesToShow && e.$dots.hide(), e.$slider.addClass("slick-loading")
        }, t.prototype.swipeDirection = function() {
            var e, t, i, n, o = this;
            return e = o.touchObject.startX - o.touchObject.curX, t = o.touchObject.startY - o.touchObject.curY, i = Math.atan2(t, e), n = Math.round(180 * i / Math.PI), 0 > n && (n = 360 - Math.abs(n)), 45 >= n && n >= 0 ? o.options.rtl === !1 ? "left" : "right" : 360 >= n && n >= 315 ? o.options.rtl === !1 ? "left" : "right" : n >= 135 && 225 >= n ? o.options.rtl === !1 ? "right" : "left" : o.options.verticalSwiping === !0 ? n >= 35 && 135 >= n ? "left" : "right" : "vertical"
        }, t.prototype.swipeEnd = function() {
            var e, t = this;
            if (t.dragging = !1, t.shouldClick = !(t.touchObject.swipeLength > 10), void 0 === t.touchObject.curX) return !1;
            if (t.touchObject.edgeHit === !0 && t.$slider.trigger("edge", [t, t.swipeDirection()]), t.touchObject.swipeLength >= t.touchObject.minSwipe) switch (t.swipeDirection()) {
                case "left":
                    e = t.options.swipeToSlide ? t.checkNavigable(t.currentSlide + t.getSlideCount()) : t.currentSlide + t.getSlideCount(), t.slideHandler(e), t.currentDirection = 0, t.touchObject = {}, t.$slider.trigger("swipe", [t, "left"]);
                    break;
                case "right":
                    e = t.options.swipeToSlide ? t.checkNavigable(t.currentSlide - t.getSlideCount()) : t.currentSlide - t.getSlideCount(), t.slideHandler(e), t.currentDirection = 1, t.touchObject = {}, t.$slider.trigger("swipe", [t, "right"])
            } else t.touchObject.startX !== t.touchObject.curX && (t.slideHandler(t.currentSlide), t.touchObject = {})
        }, t.prototype.swipeHandler = function(e) {
            var t = this;
            if (!(t.options.swipe === !1 || "ontouchend" in document && t.options.swipe === !1 || t.options.draggable === !1 && -1 !== e.type.indexOf("mouse"))) switch (t.touchObject.fingerCount = e.originalEvent && void 0 !== e.originalEvent.touches ? e.originalEvent.touches.length : 1, t.touchObject.minSwipe = t.listWidth / t.options.touchThreshold, t.options.verticalSwiping === !0 && (t.touchObject.minSwipe = t.listHeight / t.options.touchThreshold), e.data.action) {
                case "start":
                    t.swipeStart(e);
                    break;
                case "move":
                    t.swipeMove(e);
                    break;
                case "end":
                    t.swipeEnd(e)
            }
        }, t.prototype.swipeMove = function(e) {
            var t, i, n, o, r, s = this;
            return r = void 0 !== e.originalEvent ? e.originalEvent.touches : null, !(!s.dragging || r && 1 !== r.length) && (t = s.getLeft(s.currentSlide), s.touchObject.curX = void 0 !== r ? r[0].pageX : e.clientX, s.touchObject.curY = void 0 !== r ? r[0].pageY : e.clientY, s.touchObject.swipeLength = Math.round(Math.sqrt(Math.pow(s.touchObject.curX - s.touchObject.startX, 2))), s.options.verticalSwiping === !0 && (s.touchObject.swipeLength = Math.round(Math.sqrt(Math.pow(s.touchObject.curY - s.touchObject.startY, 2)))), i = s.swipeDirection(), "vertical" !== i ? (void 0 !== e.originalEvent && s.touchObject.swipeLength > 4 && e.preventDefault(), o = (s.options.rtl === !1 ? 1 : -1) * (s.touchObject.curX > s.touchObject.startX ? 1 : -1), s.options.verticalSwiping === !0 && (o = s.touchObject.curY > s.touchObject.startY ? 1 : -1), n = s.touchObject.swipeLength, s.touchObject.edgeHit = !1, s.options.infinite === !1 && (0 === s.currentSlide && "right" === i || s.currentSlide >= s.getDotCount() && "left" === i) && (n = s.touchObject.swipeLength * s.options.edgeFriction, s.touchObject.edgeHit = !0), s.swipeLeft = s.options.vertical === !1 ? t + n * o : t + n * (s.$list.height() / s.listWidth) * o, s.options.verticalSwiping === !0 && (s.swipeLeft = t + n * o), s.options.fade !== !0 && s.options.touchMove !== !1 && (s.animating === !0 ? (s.swipeLeft = null, !1) : void s.setCSS(s.swipeLeft))) : void 0)
        }, t.prototype.swipeStart = function(e) {
            var t, i = this;
            return 1 !== i.touchObject.fingerCount || i.slideCount <= i.options.slidesToShow ? (i.touchObject = {}, !1) : (void 0 !== e.originalEvent && void 0 !== e.originalEvent.touches && (t = e.originalEvent.touches[0]), i.touchObject.startX = i.touchObject.curX = void 0 !== t ? t.pageX : e.clientX, i.touchObject.startY = i.touchObject.curY = void 0 !== t ? t.pageY : e.clientY, void(i.dragging = !0))
        }, t.prototype.unfilterSlides = t.prototype.slickUnfilter = function() {
            var e = this;
            null !== e.$slidesCache && (e.unload(), e.$slideTrack.children(this.options.slide).detach(), e.$slidesCache.appendTo(e.$slideTrack), e.reinit())
        }, t.prototype.unload = function() {
            var t = this;
            e(".slick-cloned", t.$slider).remove(), t.$dots && t.$dots.remove(), t.$prevArrow && "object" != typeof t.options.prevArrow && t.$prevArrow.remove(), t.$nextArrow && "object" != typeof t.options.nextArrow && t.$nextArrow.remove(), t.$slides.removeClass("slick-slide slick-active slick-visible").attr("aria-hidden", "true").css("width", "")
        }, t.prototype.unslick = function() {
            var e = this;
            e.destroy()
        }, t.prototype.updateArrows = function() {
            var e, t = this;
            e = Math.floor(t.options.slidesToShow / 2), t.options.arrows === !0 && t.options.infinite !== !0 && t.slideCount > t.options.slidesToShow && (t.$prevArrow.removeClass("slick-disabled"), t.$nextArrow.removeClass("slick-disabled"), 0 === t.currentSlide ? (t.$prevArrow.addClass("slick-disabled"), t.$nextArrow.removeClass("slick-disabled")) : t.currentSlide >= t.slideCount - t.options.slidesToShow && t.options.centerMode === !1 ? (t.$nextArrow.addClass("slick-disabled"), t.$prevArrow.removeClass("slick-disabled")) : t.currentSlide >= t.slideCount - 1 && t.options.centerMode === !0 && (t.$nextArrow.addClass("slick-disabled"), t.$prevArrow.removeClass("slick-disabled")))
        }, t.prototype.updateDots = function() {
            var e = this;
            null !== e.$dots && (e.$dots.find("li").removeClass("slick-active").attr("aria-hidden", "true"), e.$dots.find("li").eq(Math.floor(e.currentSlide / e.options.slidesToScroll)).addClass("slick-active").attr("aria-hidden", "false"))
        }, t.prototype.visibility = function() {
            var e = this;
            document[e.hidden] ? (e.paused = !0, e.autoPlayClear()) : (e.paused = !1, e.autoPlay())
        }, e.fn.slick = function() {
            var e, i = this,
                n = arguments[0],
                o = Array.prototype.slice.call(arguments, 1),
                r = i.length,
                s = 0;
            for (s; r > s; s++)
                if ("object" == typeof n || "undefined" == typeof n ? i[s].slick = new t(i[s], n) : e = i[s].slick[n].apply(i[s].slick, o), "undefined" != typeof e) return e;
            return i
        }
    }), ! function(e, t, i, n) {
        "use strict";

        function o(t, n) {
            if (this.el = t, this.$el = e(t), this.s = e.extend({}, r, n), this.s.dynamic && "undefined" !== this.s.dynamicEl && this.s.dynamicEl.constructor === Array && !this.s.dynamicEl.length) throw "When using dynamic mode, you must also define dynamicEl as an Array.";
            return this.modules = {}, this.lGalleryOn = !1, this.lgBusy = !1, this.hideBartimeout = !1, this.isTouch = "ontouchstart" in i.documentElement, this.s.slideEndAnimatoin && (this.s.hideControlOnEnd = !1), this.s.dynamic ? this.$items = this.s.dynamicEl : "this" === this.s.selector ? this.$items = this.$el : "" !== this.s.selector ? this.s.selectWithin ? this.$items = e(this.s.selectWithin).find(this.s.selector) : this.$items = this.$el.find(e(this.s.selector)) : this.$items = this.$el.children(), this.$slide = "", this.$outer = "", this.init(), this
        }
        var r = {
            mode: "lg-slide",
            cssEasing: "ease",
            easing: "linear",
            speed: 600,
            height: "100%",
            width: "100%",
            addClass: "",
            startClass: "lg-start-zoom",
            backdropDuration: 150,
            hideBarsDelay: 6e3,
            useLeft: !1,
            closable: !0,
            loop: !0,
            escKey: !0,
            keyPress: !0,
            controls: !0,
            slideEndAnimatoin: !0,
            hideControlOnEnd: !1,
            mousewheel: !0,
            appendSubHtmlTo: ".lg-sub-html",
            preload: 1,
            showAfterLoad: !0,
            selector: "",
            selectWithin: "",
            nextHtml: "",
            prevHtml: "",
            index: !1,
            iframeMaxWidth: "100%",
            download: !0,
            counter: !0,
            appendCounterTo: ".lg-toolbar",
            swipeThreshold: 50,
            enableSwipe: !0,
            enableDrag: !0,
            dynamic: !1,
            dynamicEl: [],
            galleryId: 1
        };
        o.prototype.init = function() {
            var i = this;
            i.s.preload > i.$items.length && (i.s.preload = i.$items.length);
            var n = t.location.hash;
            n.indexOf("lg=" + this.s.galleryId) > 0 && (i.index = parseInt(n.split("&slide=")[1], 10), e("body").addClass("lg-from-hash"), e("body").hasClass("lg-on") || setTimeout(function() {
                i.build(i.index), e("body").addClass("lg-on")
            })), i.s.dynamic ? (i.$el.trigger("onBeforeOpen.lg"), i.index = i.s.index || 0, e("body").hasClass("lg-on") || setTimeout(function() {
                i.build(i.index), e("body").addClass("lg-on")
            })) : i.$items.on("click.lgcustom", function(t) {
                try {
                    t.preventDefault(), t.preventDefault()
                } catch (n) {
                    t.returnValue = !1
                }
                i.$el.trigger("onBeforeOpen.lg"), i.index = i.s.index || i.$items.index(this), e("body").hasClass("lg-on") || (i.build(i.index), e("body").addClass("lg-on"))
            })
        }, o.prototype.build = function(t) {
            var i = this;
            i.structure(), e.each(e.fn.lightGallery.modules, function(t) {
                i.modules[t] = new e.fn.lightGallery.modules[t](i.el)
            }), i.slide(t, !1, !1), i.s.keyPress && i.keyPress(), i.$items.length > 1 && (i.arrow(), setTimeout(function() {
                i.enableDrag(), i.enableSwipe()
            }, 50), i.s.mousewheel && i.mousewheel()), i.counter(), i.closeGallery(), i.$el.trigger("onAfterOpen.lg"), i.$outer.on("mousemove.lg click.lg touchstart.lg", function() {
                i.$outer.removeClass("lg-hide-items"), clearTimeout(i.hideBartimeout), i.hideBartimeout = setTimeout(function() {
                    i.$outer.addClass("lg-hide-items")
                }, i.s.hideBarsDelay)
            })
        }, o.prototype.structure = function() {
            var i, n = "",
                o = "",
                r = 0,
                s = "",
                a = this;
            for (e("body").append('<div class="lg-backdrop"></div>'), e(".lg-backdrop").css("transition-duration", this.s.backdropDuration + "ms"), r = 0; r < this.$items.length; r++) n += '<div class="lg-item"></div>';
            if (this.s.controls && this.$items.length > 1 && (o = '<div class="lg-actions"><div class="lg-prev lg-icon">' + this.s.prevHtml + '</div><div class="lg-next lg-icon">' + this.s.nextHtml + "</div></div>"), ".lg-sub-html" === this.s.appendSubHtmlTo && (s = '<div class="lg-sub-html"></div>'), i = '<div class="lg-outer ' + this.s.addClass + " " + this.s.startClass + '"><div class="lg" style="width:' + this.s.width + "; height:" + this.s.height + '"><div class="lg-inner">' + n + '</div><div class="lg-toolbar group"><span class="lg-close lg-icon"></span></div>' + o + s + "</div></div>", e("body").append(i), this.$outer = e(".lg-outer"), this.$slide = this.$outer.find(".lg-item"), this.s.useLeft ? (this.$outer.addClass("lg-use-left"), this.s.mode = "lg-slide") : this.$outer.addClass("lg-use-css3"), a.setTop(), e(t).on("resize.lg orientationchange.lg", function() {
                    setTimeout(function() {
                        a.setTop()
                    }, 100)
                }), this.$slide.eq(this.index).addClass("lg-current"), this.doCss() ? this.$outer.addClass("lg-css3") : (this.$outer.addClass("lg-css"), this.s.speed = 0), this.$outer.addClass(this.s.mode), this.s.enableDrag && this.$items.length > 1 && this.$outer.addClass("lg-grab"), this.s.showAfterLoad && this.$outer.addClass("lg-show-after-load"), this.doCss()) {
                var l = this.$outer.find(".lg-inner");
                l.css("transition-timing-function", this.s.cssEasing), l.css("transition-duration", this.s.speed + "ms")
            }
            e(".lg-backdrop").addClass("in"), setTimeout(function() {
                a.$outer.addClass("lg-visible")
            }, this.s.backdropDuration), this.s.download && this.$outer.find(".lg-toolbar").append('<a id="lg-download" target="_blank" download class="lg-download lg-icon"></a>'), this.prevScrollTop = e(t).scrollTop()
        }, o.prototype.setTop = function() {
            if ("100%" !== this.s.height) {
                var i = e(t).height(),
                    n = (i - parseInt(this.s.height, 10)) / 2,
                    o = this.$outer.find(".lg");
                i >= parseInt(this.s.height, 10) ? o.css("top", n + "px") : o.css("top", "0px")
            }
        }, o.prototype.doCss = function() {
            var e = function() {
                var e = ["transition", "MozTransition", "WebkitTransition", "OTransition", "msTransition", "KhtmlTransition"],
                    t = i.documentElement,
                    n = 0;
                for (n = 0; n < e.length; n++)
                    if (e[n] in t.style) return !0
            };
            return !!e()
        }, o.prototype.isVideo = function(e, t) {
            var i;
            if (i = this.s.dynamic ? this.s.dynamicEl[t].html : this.$items.eq(t).attr("data-html"), !e && i) return {
                html5: !0
            };
            var n = e.match(/\/\/(?:www\.)?youtu(?:\.be|be\.com)\/(?:watch\?v=|embed\/)?([a-z0-9\-\_\%]+)/i),
                o = e.match(/\/\/(?:www\.)?vimeo.com\/([0-9a-z\-_]+)/i),
                r = e.match(/\/\/(?:www\.)?dai.ly\/([0-9a-z\-_]+)/i);
            return n ? {
                youtube: n
            } : o ? {
                vimeo: o
            } : r ? {
                dailymotion: r
            } : void 0
        }, o.prototype.counter = function() {
            this.s.counter && e(this.s.appendCounterTo).append('<div id="lg-counter"><span id="lg-counter-current">' + (parseInt(this.index, 10) + 1) + '</span> / <span id="lg-counter-all">' + this.$items.length + "</span></div>")
        }, o.prototype.addHtml = function(t) {
            var i, n = null;
            if (this.s.dynamic ? this.s.dynamicEl[t].subHtmlUrl ? i = this.s.dynamicEl[t].subHtmlUrl : n = this.s.dynamicEl[t].subHtml : this.$items.eq(t).attr("data-sub-html-url") ? i = this.$items.eq(t).attr("data-sub-html-url") : n = this.$items.eq(t).attr("data-sub-html"), !i)
                if ("undefined" != typeof n && null !== n) {
                    var o = n.substring(0, 1);
                    n = "." === o || "#" === o ? e(n).html() : n
                } else n = "";
                ".lg-sub-html" === this.s.appendSubHtmlTo ? i ? this.$outer.find(this.s.appendSubHtmlTo).load(i) : this.$outer.find(this.s.appendSubHtmlTo).html(n) : i ? this.$slide.eq(t).load(i) : this.$slide.eq(t).append(n), "undefined" != typeof n && null !== n && ("" === n ? this.$outer.find(this.s.appendSubHtmlTo).addClass("lg-empty-html") : this.$outer.find(this.s.appendSubHtmlTo).removeClass("lg-empty-html")), this.$el.trigger("onAfterAppendSubHtml.lg", [t])
        }, o.prototype.preload = function(e) {
            var t = 1,
                i = 1;
            for (t = 1; t <= this.s.preload && !(t >= this.$items.length - e); t++) this.loadContent(e + t, !1, 0);
            for (i = 1; i <= this.s.preload && !(0 > e - i); i++) this.loadContent(e - i, !1, 0)
        }, o.prototype.loadContent = function(i, n, o) {
            var r, s, a, l, c, d, u = this,
                p = !1,
                h = function(i) {
                    for (var n = [], o = [], r = 0; r < i.length; r++) {
                        var a = i[r].split(" ");
                        "" === a[0] && a.splice(0, 1), o.push(a[0]), n.push(a[1])
                    }
                    for (var l = e(t).width(), c = 0; c < n.length; c++)
                        if (parseInt(n[c], 10) > l) {
                            s = o[c];
                            break
                        }
                };
            if (u.s.dynamic) {
                if (u.s.dynamicEl[i].poster && (p = !0, a = u.s.dynamicEl[i].poster), d = u.s.dynamicEl[i].html, s = u.s.dynamicEl[i].src, u.s.dynamicEl[i].responsive) {
                    var f = u.s.dynamicEl[i].responsive.split(",");
                    h(f)
                }
                l = u.s.dynamicEl[i].srcset, c = u.s.dynamicEl[i].sizes
            } else {
                if (u.$items.eq(i).attr("data-poster") && (p = !0, a = u.$items.eq(i).attr("data-poster")), d = u.$items.eq(i).attr("data-html"), s = u.$items.eq(i).attr("href") || u.$items.eq(i).attr("data-src"), u.$items.eq(i).attr("data-responsive")) {
                    var g = u.$items.eq(i).attr("data-responsive").split(",");
                    h(g)
                }
                l = u.$items.eq(i).attr("data-srcset"), c = u.$items.eq(i).attr("data-sizes")
            }
            var m = !1;
            u.s.dynamic ? u.s.dynamicEl[i].iframe && (m = !0) : "true" === u.$items.eq(i).attr("data-iframe") && (m = !0);
            var v = u.isVideo(s, i);
            if (!u.$slide.eq(i).hasClass("lg-loaded")) {
                if (m) u.$slide.eq(i).prepend('<div class="lg-video-cont" style="max-width:' + u.s.iframeMaxWidth + '"><div class="lg-video"><iframe class="lg-object" frameborder="0" src="' + s + '"  allowfullscreen="true"></iframe></div></div>');
                else if (p) {
                    var b = "";
                    b = v && v.youtube ? "lg-has-youtube" : v && v.vimeo ? "lg-has-vimeo" : "lg-has-html5", u.$slide.eq(i).prepend('<div class="lg-video-cont ' + b + ' "><div class="lg-video"><span class="lg-video-play"></span><img class="lg-object lg-has-poster" src="' + a + '" /></div></div>')
                } else v ? (u.$slide.eq(i).prepend('<div class="lg-video-cont "><div class="lg-video"></div></div>'), u.$el.trigger("hasVideo.lg", [i, s, d])) : u.$slide.eq(i).prepend('<div class="lg-img-wrap"><img class="lg-object lg-image" src="' + s + '" /></div>');
                if (u.$el.trigger("onAferAppendSlide.lg", [i]), r = u.$slide.eq(i).find(".lg-object"), c && r.attr("sizes", c), l) {
                    r.attr("srcset", l);
                    try {
                        picturefill({
                            elements: [r[0]]
                        })
                    } catch (y) {
                        console.error("Make sure you have included Picturefill version 2")
                    }
                }
                ".lg-sub-html" !== this.s.appendSubHtmlTo && u.addHtml(i), u.$slide.eq(i).addClass("lg-loaded")
            }
            u.$slide.eq(i).find(".lg-object").on("load.lg error.lg", function() {
                var t = 0;
                o && !e("body").hasClass("lg-from-hash") && (t = o), setTimeout(function() {
                    u.$slide.eq(i).addClass("lg-complete"), u.$el.trigger("onSlideItemLoad.lg", [i, o || 0])
                }, t)
            }), v && v.html5 && !p && u.$slide.eq(i).addClass("lg-complete"), n === !0 && (u.$slide.eq(i).hasClass("lg-complete") ? u.preload(i) : u.$slide.eq(i).find(".lg-object").on("load.lg error.lg", function() {
                u.preload(i)
            }))
        }, o.prototype.slide = function(t, i, n) {
            var o = this.$outer.find(".lg-current").index(),
                r = this;
            if (!r.lGalleryOn || o !== t) {
                var s = this.$slide.length,
                    a = r.lGalleryOn ? this.s.speed : 0,
                    l = !1,
                    c = !1;
                if (!r.lgBusy) {
                    if (this.s.download) {
                        var d;
                        d = r.s.dynamic ? r.s.dynamicEl[t].downloadUrl !== !1 && (r.s.dynamicEl[t].downloadUrl || r.s.dynamicEl[t].src) : "false" !== r.$items.eq(t).attr("data-download-url") && (r.$items.eq(t).attr("data-download-url") || r.$items.eq(t).attr("href") || r.$items.eq(t).attr("data-src")), d ? (e("#lg-download").attr("href", d), r.$outer.removeClass("lg-hide-download")) : r.$outer.addClass("lg-hide-download")
                    }
                    if (this.$el.trigger("onBeforeSlide.lg", [o, t, i, n]), r.lgBusy = !0, clearTimeout(r.hideBartimeout), ".lg-sub-html" === this.s.appendSubHtmlTo && setTimeout(function() {
                            r.addHtml(t)
                        }, a), this.arrowDisable(t), i) {
                        var u = t - 1,
                            p = t + 1;
                        0 === t && o === s - 1 ? (p = 0, u = s - 1) : t === s - 1 && 0 === o && (p = 0, u = s - 1), this.$slide.removeClass("lg-prev-slide lg-current lg-next-slide"), r.$slide.eq(u).addClass("lg-prev-slide"), r.$slide.eq(p).addClass("lg-next-slide"), r.$slide.eq(t).addClass("lg-current")
                    } else r.$outer.addClass("lg-no-trans"), this.$slide.removeClass("lg-prev-slide lg-next-slide"), o > t ? (c = !0, 0 !== t || o !== s - 1 || n || (c = !1, l = !0)) : t > o && (l = !0, t !== s - 1 || 0 !== o || n || (c = !0, l = !1)), c ? (this.$slide.eq(t).addClass("lg-prev-slide"), this.$slide.eq(o).addClass("lg-next-slide")) : l && (this.$slide.eq(t).addClass("lg-next-slide"), this.$slide.eq(o).addClass("lg-prev-slide")), setTimeout(function() {
                        r.$slide.removeClass("lg-current"), r.$slide.eq(t).addClass("lg-current"), r.$outer.removeClass("lg-no-trans")
                    }, 50);
                    r.lGalleryOn ? (setTimeout(function() {
                        r.loadContent(t, !0, 0)
                    }, this.s.speed + 50), setTimeout(function() {
                        r.lgBusy = !1, r.$el.trigger("onAfterSlide.lg", [o, t, i, n])
                    }, this.s.speed)) : (r.loadContent(t, !0, r.s.backdropDuration), r.lgBusy = !1, r.$el.trigger("onAfterSlide.lg", [o, t, i, n])), r.lGalleryOn = !0, this.s.counter && e("#lg-counter-current").text(t + 1)
                }
            }
        }, o.prototype.goToNextSlide = function(e) {
            var t = this;
            t.lgBusy || (t.index + 1 < t.$slide.length ? (t.index++, t.$el.trigger("onBeforeNextSlide.lg", [t.index]), t.slide(t.index, e, !1)) : t.s.loop ? (t.index = 0, t.$el.trigger("onBeforeNextSlide.lg", [t.index]), t.slide(t.index, e, !1)) : t.s.slideEndAnimatoin && (t.$outer.addClass("lg-right-end"), setTimeout(function() {
                t.$outer.removeClass("lg-right-end")
            }, 400)))
        }, o.prototype.goToPrevSlide = function(e) {
            var t = this;
            t.lgBusy || (t.index > 0 ? (t.index--, t.$el.trigger("onBeforePrevSlide.lg", [t.index, e]), t.slide(t.index, e, !1)) : t.s.loop ? (t.index = t.$items.length - 1, t.$el.trigger("onBeforePrevSlide.lg", [t.index, e]), t.slide(t.index, e, !1)) : t.s.slideEndAnimatoin && (t.$outer.addClass("lg-left-end"), setTimeout(function() {
                t.$outer.removeClass("lg-left-end")
            }, 400)))
        }, o.prototype.keyPress = function() {
            var i = this;
            this.$items.length > 1 && e(t).on("keyup.lg", function(e) {
                i.$items.length > 1 && (37 === e.keyCode && (e.preventDefault(), i.goToPrevSlide()), 39 === e.keyCode && (e.preventDefault(), i.goToNextSlide()))
            }), e(t).on("keydown.lg", function(e) {
                i.s.escKey === !0 && 27 === e.keyCode && (e.preventDefault(), i.$outer.hasClass("lg-thumb-open") ? i.$outer.removeClass("lg-thumb-open") : i.destroy())
            })
        }, o.prototype.arrow = function() {
            var e = this;
            this.$outer.find(".lg-prev").on("click.lg", function() {
                e.goToPrevSlide()
            }), this.$outer.find(".lg-next").on("click.lg", function() {
                e.goToNextSlide()
            })
        }, o.prototype.arrowDisable = function(e) {
            !this.s.loop && this.s.hideControlOnEnd && (e + 1 < this.$slide.length ? this.$outer.find(".lg-next").removeAttr("disabled").removeClass("disabled") : this.$outer.find(".lg-next").attr("disabled", "disabled").addClass("disabled"), e > 0 ? this.$outer.find(".lg-prev").removeAttr("disabled").removeClass("disabled") : this.$outer.find(".lg-prev").attr("disabled", "disabled").addClass("disabled"))
        }, o.prototype.setTranslate = function(e, t, i) {
            this.s.useLeft ? e.css("left", t) : e.css({
                transform: "translate3d(" + t + "px, " + i + "px, 0px)"
            })
        }, o.prototype.touchMove = function(t, i) {
            var n = i - t;
            Math.abs(n) > 15 && (this.$outer.addClass("lg-dragging"), this.setTranslate(this.$slide.eq(this.index), n, 0), this.setTranslate(e(".lg-prev-slide"), -this.$slide.eq(this.index).width() + n, 0), this.setTranslate(e(".lg-next-slide"), this.$slide.eq(this.index).width() + n, 0))
        }, o.prototype.touchEnd = function(e) {
            var t = this;
            "lg-slide" !== t.s.mode && t.$outer.addClass("lg-slide"), this.$slide.not(".lg-current, .lg-prev-slide, .lg-next-slide").css("opacity", "0"), setTimeout(function() {
                t.$outer.removeClass("lg-dragging"), 0 > e && Math.abs(e) > t.s.swipeThreshold ? t.goToNextSlide(!0) : e > 0 && Math.abs(e) > t.s.swipeThreshold ? t.goToPrevSlide(!0) : Math.abs(e) < 5 && t.$el.trigger("onSlideClick.lg"), t.$slide.removeAttr("style")
            }), setTimeout(function() {
                t.$outer.hasClass("lg-dragging") || "lg-slide" === t.s.mode || t.$outer.removeClass("lg-slide")
            }, t.s.speed + 100)
        }, o.prototype.enableSwipe = function() {
            var e = this,
                t = 0,
                i = 0,
                n = !1;
            e.s.enableSwipe && e.isTouch && e.doCss() && (e.$slide.on("touchstart.lg", function(i) {
                e.$outer.hasClass("lg-zoomed") || e.lgBusy || (i.preventDefault(), e.manageSwipeClass(), t = i.originalEvent.targetTouches[0].pageX)
            }), e.$slide.on("touchmove.lg", function(o) {
                e.$outer.hasClass("lg-zoomed") || (o.preventDefault(), i = o.originalEvent.targetTouches[0].pageX, e.touchMove(t, i), n = !0)
            }), e.$slide.on("touchend.lg", function() {
                e.$outer.hasClass("lg-zoomed") || (n ? (n = !1, e.touchEnd(i - t)) : e.$el.trigger("onSlideClick.lg"))
            }))
        }, o.prototype.enableDrag = function() {
            var i = this,
                n = 0,
                o = 0,
                r = !1,
                s = !1;
            i.s.enableDrag && !i.isTouch && i.doCss() && (i.$slide.on("mousedown.lg", function(t) {
                i.$outer.hasClass("lg-zoomed") || (e(t.target).hasClass("lg-object") || e(t.target).hasClass("lg-video-play")) && (t.preventDefault(), i.lgBusy || (i.manageSwipeClass(), n = t.pageX, r = !0, i.$outer.scrollLeft += 1, i.$outer.scrollLeft -= 1, i.$outer.removeClass("lg-grab").addClass("lg-grabbing"), i.$el.trigger("onDragstart.lg")))
            }), e(t).on("mousemove.lg", function(e) {
                r && (s = !0, o = e.pageX, i.touchMove(n, o), i.$el.trigger("onDragmove.lg"))
            }), e(t).on("mouseup.lg", function(t) {
                s ? (s = !1, i.touchEnd(o - n), i.$el.trigger("onDragend.lg")) : (e(t.target).hasClass("lg-object") || e(t.target).hasClass("lg-video-play")) && i.$el.trigger("onSlideClick.lg"), r && (r = !1, i.$outer.removeClass("lg-grabbing").addClass("lg-grab"))
            }))
        }, o.prototype.manageSwipeClass = function() {
            var e = this.index + 1,
                t = this.index - 1,
                i = this.$slide.length;
            this.s.loop && (0 === this.index ? t = i - 1 : this.index === i - 1 && (e = 0)), this.$slide.removeClass("lg-next-slide lg-prev-slide"), t > -1 && this.$slide.eq(t).addClass("lg-prev-slide"), this.$slide.eq(e).addClass("lg-next-slide")
        }, o.prototype.mousewheel = function() {
            var e = this;
            e.$outer.on("mousewheel.lg", function(t) {
                t.deltaY && (t.deltaY > 0 ? e.goToPrevSlide() : e.goToNextSlide(), t.preventDefault())
            })
        }, o.prototype.closeGallery = function() {
            var t = this,
                i = !1;
            this.$outer.find(".lg-close").on("click.lg", function() {
                t.destroy()
            }), t.s.closable && (t.$outer.on("mousedown.lg", function(t) {
                i = !!(e(t.target).is(".lg-outer") || e(t.target).is(".lg-item ") || e(t.target).is(".lg-img-wrap"))
            }), t.$outer.on("mouseup.lg", function(n) {
                (e(n.target).is(".lg-outer") || e(n.target).is(".lg-item ") || e(n.target).is(".lg-img-wrap") && i) && (t.$outer.hasClass("lg-dragging") || t.destroy())
            }))
        }, o.prototype.destroy = function(i) {
            var n = this;
            i || n.$el.trigger("onBeforeClose.lg"), e(t).scrollTop(n.prevScrollTop), i && (n.s.dynamic || this.$items.off("click.lg click.lgcustom"), e.removeData(n.el, "lightGallery")), this.$el.off(".lg.tm"), e.each(e.fn.lightGallery.modules, function(e) {
                n.modules[e] && n.modules[e].destroy()
            }), this.lGalleryOn = !1, clearTimeout(n.hideBartimeout), this.hideBartimeout = !1, e(t).off(".lg"), e("body").removeClass("lg-on lg-from-hash"), n.$outer && n.$outer.removeClass("lg-visible"), e(".lg-backdrop").removeClass("in"), setTimeout(function() {
                n.$outer && n.$outer.remove(), e(".lg-backdrop").remove(), i || n.$el.trigger("onCloseAfter.lg")
            }, n.s.backdropDuration + 50)
        }, e.fn.lightGallery = function(t) {
            return this.each(function() {
                if (e.data(this, "lightGallery")) try {
                    e(this).data("lightGallery").init()
                } catch (i) {
                    console.error("lightGallery has not initiated properly")
                } else e.data(this, "lightGallery", new o(this, t))
            })
        }, e.fn.lightGallery.modules = {}
    }(jQuery, window, document), ! function(e, t, i, n) {
        "use strict";
        var o = {
                thumbnail: !0,
                animateThumb: !0,
                currentPagerPosition: "middle",
                thumbWidth: 100,
                thumbContHeight: 100,
                thumbMargin: 5,
                exThumbImage: !1,
                showThumbByDefault: !0,
                toogleThumb: !0,
                pullCaptionUp: !0,
                enableThumbDrag: !0,
                enableThumbSwipe: !0,
                swipeThreshold: 50,
                loadYoutubeThumbnail: !0,
                youtubeThumbSize: 1,
                loadVimeoThumbnail: !0,
                vimeoThumbSize: "thumbnail_small",
                loadDailymotionThumbnail: !0
            },
            r = function(t) {
                return this.core = e(t).data("lightGallery"), this.core.s = e.extend({}, o, this.core.s), this.$el = e(t), this.$thumbOuter = null, this.thumbOuterWidth = 0, this.thumbTotalWidth = this.core.$items.length * (this.core.s.thumbWidth + this.core.s.thumbMargin), this.thumbIndex = this.core.index, this.left = 0, this.init(), this
            };
        r.prototype.init = function() {
            var e = this;
            this.core.s.thumbnail && this.core.$items.length > 1 && (this.core.s.showThumbByDefault && setTimeout(function() {
                e.core.$outer.addClass("lg-thumb-open")
            }, 700), this.core.s.pullCaptionUp && this.core.$outer.addClass("lg-pull-caption-up"), this.build(), this.core.s.animateThumb ? (this.core.s.enableThumbDrag && !this.core.isTouch && this.core.doCss() && this.enableThumbDrag(), this.core.s.enableThumbSwipe && this.core.isTouch && this.core.doCss() && this.enableThumbSwipe(), this.thumbClickable = !1) : this.thumbClickable = !0, this.toogle(), this.thumbkeyPress())
        }, r.prototype.build = function() {
            function i(e, t, i) {
                var n, a = o.core.isVideo(e, i) || {},
                    l = "";
                a.youtube || a.vimeo || a.dailymotion ? a.youtube ? n = o.core.s.loadYoutubeThumbnail ? "//img.youtube.com/vi/" + a.youtube[1] + "/" + o.core.s.youtubeThumbSize + ".jpg" : t : a.vimeo ? o.core.s.loadVimeoThumbnail ? (n = "//i.vimeocdn.com/video/error_" + s + ".jpg", l = a.vimeo[1]) : n = t : a.dailymotion && (n = o.core.s.loadDailymotionThumbnail ? "//www.dailymotion.com/thumbnail/video/" + a.dailymotion[1] : t) : n = t, r += '<div data-vimeo-id="' + l + '" class="lg-thumb-item" style="width:' + o.core.s.thumbWidth + "px; margin-right: " + o.core.s.thumbMargin + 'px"><img src="' + n + '" /></div>', l = ""
            }
            var n, o = this,
                r = "",
                s = "",
                a = '<div class="lg-thumb-outer"><div class="lg-thumb group"></div></div>';
            switch (this.core.s.vimeoThumbSize) {
                case "thumbnail_large":
                    s = "640";
                    break;
                case "thumbnail_medium":
                    s = "200x150";
                    break;
                case "thumbnail_small":
                    s = "100x75"
            }
            if (o.core.$outer.addClass("lg-has-thumb"), o.core.$outer.find(".lg").append(a), o.$thumbOuter = o.core.$outer.find(".lg-thumb-outer"), o.thumbOuterWidth = o.$thumbOuter.width(), o.core.s.animateThumb && o.core.$outer.find(".lg-thumb").css({
                    width: o.thumbTotalWidth + "px",
                    position: "relative"
                }), this.core.s.animateThumb && o.$thumbOuter.css("height", o.core.s.thumbContHeight + "px"), o.core.s.dynamic)
                for (var l = 0; l < o.core.s.dynamicEl.length; l++) i(o.core.s.dynamicEl[l].src, o.core.s.dynamicEl[l].thumb, l);
            else o.core.$items.each(function(t) {
                o.core.s.exThumbImage ? i(e(this).attr("href") || e(this).attr("data-src"), e(this).attr(o.core.s.exThumbImage), t) : i(e(this).attr("href") || e(this).attr("data-src"), e(this).find("img").attr("src"), t)
            });
            o.core.$outer.find(".lg-thumb").html(r), n = o.core.$outer.find(".lg-thumb-item"), n.each(function() {
                var t = e(this),
                    i = t.attr("data-vimeo-id");
                i && e.getJSON("http://www.vimeo.com/api/v2/video/" + i + ".json?callback=?", {
                    format: "json"
                }, function(e) {
                    t.find("img").attr("src", e[0][o.core.s.vimeoThumbSize])
                })
            }), n.eq(o.core.index).addClass("active"), o.core.$el.on("onBeforeSlide.lg.tm", function() {
                n.removeClass("active"), n.eq(o.core.index).addClass("active")
            }), n.on("click.lg touchend.lg", function() {
                var t = e(this);
                setTimeout(function() {
                    (o.thumbClickable && !o.core.lgBusy || !o.core.doCss()) && (o.core.index = t.index(), o.core.slide(o.core.index, !1, !0))
                }, 50)
            }), o.core.$el.on("onBeforeSlide.lg.tm", function() {
                o.animateThumb(o.core.index)
            }), e(t).on("resize.lg.thumb orientationchange.lg.thumb", function() {
                setTimeout(function() {
                    o.animateThumb(o.core.index), o.thumbOuterWidth = o.$thumbOuter.width()
                }, 200)
            })
        }, r.prototype.setTranslate = function(e) {
            this.core.$outer.find(".lg-thumb").css({
                transform: "translate3d(-" + e + "px, 0px, 0px)"
            })
        }, r.prototype.animateThumb = function(e) {
            var t = this.core.$outer.find(".lg-thumb");
            if (this.core.s.animateThumb) {
                var i;
                switch (this.core.s.currentPagerPosition) {
                    case "left":
                        i = 0;
                        break;
                    case "middle":
                        i = this.thumbOuterWidth / 2 - this.core.s.thumbWidth / 2;
                        break;
                    case "right":
                        i = this.thumbOuterWidth - this.core.s.thumbWidth
                }
                this.left = (this.core.s.thumbWidth + this.core.s.thumbMargin) * e - 1 - i, this.left > this.thumbTotalWidth - this.thumbOuterWidth && (this.left = this.thumbTotalWidth - this.thumbOuterWidth), this.left < 0 && (this.left = 0), this.core.lGalleryOn ? (t.hasClass("on") || this.core.$outer.find(".lg-thumb").css("transition-duration", this.core.s.speed + "ms"), this.core.doCss() || t.animate({
                    left: -this.left + "px"
                }, this.core.s.speed)) : this.core.doCss() || t.css("left", -this.left + "px"), this.setTranslate(this.left)
            }
        }, r.prototype.enableThumbDrag = function() {
            var i = this,
                n = 0,
                o = 0,
                r = !1,
                s = !1,
                a = 0;
            i.$thumbOuter.addClass("lg-grab"), i.core.$outer.find(".lg-thumb").on("mousedown.lg.thumb", function(e) {
                i.thumbTotalWidth > i.thumbOuterWidth && (e.preventDefault(), n = e.pageX, r = !0, i.core.$outer.scrollLeft += 1, i.core.$outer.scrollLeft -= 1, i.thumbClickable = !1, i.$thumbOuter.removeClass("lg-grab").addClass("lg-grabbing"))
            }), e(t).on("mousemove.lg.thumb", function(e) {
                r && (a = i.left, s = !0, o = e.pageX, i.$thumbOuter.addClass("lg-dragging"), a -= o - n, a > i.thumbTotalWidth - i.thumbOuterWidth && (a = i.thumbTotalWidth - i.thumbOuterWidth), 0 > a && (a = 0), i.setTranslate(a))
            }), e(t).on("mouseup.lg.thumb", function() {
                s ? (s = !1, i.$thumbOuter.removeClass("lg-dragging"), i.left = a, Math.abs(o - n) < i.core.s.swipeThreshold && (i.thumbClickable = !0)) : i.thumbClickable = !0, r && (r = !1, i.$thumbOuter.removeClass("lg-grabbing").addClass("lg-grab"))
            })
        }, r.prototype.enableThumbSwipe = function() {
            var e = this,
                t = 0,
                i = 0,
                n = !1,
                o = 0;
            e.core.$outer.find(".lg-thumb").on("touchstart.lg", function(i) {
                e.thumbTotalWidth > e.thumbOuterWidth && (i.preventDefault(), t = i.originalEvent.targetTouches[0].pageX, e.thumbClickable = !1)
            }), e.core.$outer.find(".lg-thumb").on("touchmove.lg", function(r) {
                e.thumbTotalWidth > e.thumbOuterWidth && (r.preventDefault(), i = r.originalEvent.targetTouches[0].pageX, n = !0, e.$thumbOuter.addClass("lg-dragging"), o = e.left, o -= i - t, o > e.thumbTotalWidth - e.thumbOuterWidth && (o = e.thumbTotalWidth - e.thumbOuterWidth), 0 > o && (o = 0), e.setTranslate(o))
            }), e.core.$outer.find(".lg-thumb").on("touchend.lg", function() {
                e.thumbTotalWidth > e.thumbOuterWidth && n ? (n = !1, e.$thumbOuter.removeClass("lg-dragging"), Math.abs(i - t) < e.core.s.swipeThreshold && (e.thumbClickable = !0), e.left = o) : e.thumbClickable = !0
            })
        }, r.prototype.toogle = function() {
            var e = this;
            e.core.s.toogleThumb && (e.core.$outer.addClass("lg-can-toggle"), e.$thumbOuter.append('<span class="lg-toogle-thumb lg-icon"></span>'), e.core.$outer.find(".lg-toogle-thumb").on("click.lg", function() {
                e.core.$outer.toggleClass("lg-thumb-open")
            }))
        }, r.prototype.thumbkeyPress = function() {
            var i = this;
            e(t).on("keydown.lg.thumb", function(e) {
                38 === e.keyCode ? (e.preventDefault(), i.core.$outer.addClass("lg-thumb-open")) : 40 === e.keyCode && (e.preventDefault(), i.core.$outer.removeClass("lg-thumb-open"))
            })
        }, r.prototype.destroy = function() {
            this.core.s.thumbnail && this.core.$items.length > 1 && (e(t).off("resize.lg.thumb orientationchange.lg.thumb keydown.lg.thumb"), this.$thumbOuter.remove(), this.core.$outer.removeClass("lg-has-thumb"))
        }, e.fn.lightGallery.modules.Thumbnail = r
    }(jQuery, window, document), ! function(e, t, i, n) {
        "use strict";
        var o = {
                fullScreen: !0
            },
            r = function(t) {
                return this.core = e(t).data("lightGallery"), this.$el = e(t), this.core.s = e.extend({}, o, this.core.s), this.init(), this
            };
        r.prototype.init = function() {
            var e = "";
            if (this.core.s.fullScreen) {
                if (!(i.fullscreenEnabled || i.webkitFullscreenEnabled || i.mozFullScreenEnabled || i.msFullscreenEnabled)) return;
                e = '<span class="lg-fullscreen lg-icon"></span>', this.core.$outer.find(".lg-toolbar").append(e), this.fullScreen()
            }
        }, r.prototype.requestFullscreen = function() {
            var e = i.documentElement;
            e.requestFullscreen ? e.requestFullscreen() : e.msRequestFullscreen ? e.msRequestFullscreen() : e.mozRequestFullScreen ? e.mozRequestFullScreen() : e.webkitRequestFullscreen && e.webkitRequestFullscreen()
        }, r.prototype.exitFullscreen = function() {
            i.exitFullscreen ? i.exitFullscreen() : i.msExitFullscreen ? i.msExitFullscreen() : i.mozCancelFullScreen ? i.mozCancelFullScreen() : i.webkitExitFullscreen && i.webkitExitFullscreen()
        }, r.prototype.fullScreen = function() {
            var t = this;
            e(i).on("fullscreenchange.lg webkitfullscreenchange.lg mozfullscreenchange.lg MSFullscreenChange.lg", function() {
                t.core.$outer.toggleClass("lg-fullscreen-on")
            }), this.core.$outer.find(".lg-fullscreen").on("click.lg", function() {
                i.fullscreenElement || i.mozFullScreenElement || i.webkitFullscreenElement || i.msFullscreenElement ? t.exitFullscreen() : t.requestFullscreen()
            })
        }, r.prototype.destroy = function() {
            this.exitFullscreen(), e(i).off("fullscreenchange.lg webkitfullscreenchange.lg mozfullscreenchange.lg MSFullscreenChange.lg")
        }, e.fn.lightGallery.modules.fullscreen = r
    }(jQuery, window, document), ! function(e, t, i, n) {
        "use strict";
        var o = {
                scale: 1,
                zoom: !0,
                enableZoomAfter: 300
            },
            r = function(i) {
                return this.core = e(i).data("lightGallery"), this.core.s = e.extend({}, o, this.core.s), this.core.s.zoom && this.core.doCss() && (this.init(), this.zoomabletimeout = !1, this.pageX = e(t).width() / 2, this.pageY = e(t).height() / 2 + e(t).scrollTop()), this
            };
        r.prototype.init = function() {
            var i = this,
                n = '<span id="lg-zoom-in" class="lg-icon"></span><span id="lg-zoom-out" class="lg-icon"></span>';
            this.core.$outer.find(".lg-toolbar").append(n), i.core.$el.on("onSlideItemLoad.lg.tm.zoom", function(t, n, o) {
                var r = i.core.s.enableZoomAfter + o;
                e("body").hasClass("lg-from-hash") && o ? r = 0 : e("body").removeClass("lg-from-hash"), i.zoomabletimeout = setTimeout(function() {
                    i.core.$slide.eq(n).addClass("lg-zoomable")
                }, r + 30)
            });
            var o = 1,
                r = function(n) {
                    var o, r, s = i.core.$outer.find(".lg-current .lg-image"),
                        a = (e(t).width() - s.width()) / 2,
                        l = (e(t).height() - s.height()) / 2 + e(t).scrollTop();
                    o = i.pageX - a, r = i.pageY - l;
                    var c = (n - 1) * o,
                        d = (n - 1) * r;
                    s.css("transform", "scale3d(" + n + ", " + n + ", 1)").attr("data-scale", n), s.parent().css("transform", "translate3d(-" + c + "px, -" + d + "px, 0)").attr("data-x", c).attr("data-y", d)
                },
                s = function() {
                    o > 1 ? i.core.$outer.addClass("lg-zoomed") : i.resetZoom(), 1 > o && (o = 1), r(o)
                },
                a = function(e, t, n) {
                    var r, a = t.width();
                    r = i.core.s.dynamic ? i.core.s.dynamicEl[n].width || t[0].naturalWidth || a : i.core.$items.eq(n).attr("data-width") || t[0].naturalWidth || a;
                    var l;
                    i.core.$outer.hasClass("lg-zoomed") ? o = 1 : r > a && (l = r / a, o = l || 2), i.pageX = e.pageX || e.originalEvent.targetTouches[0].pageX, i.pageY = e.pageY || e.originalEvent.targetTouches[0].pageY, s(), setTimeout(function() {
                        i.core.$outer.removeClass("lg-grabbing").addClass("lg-grab")
                    }, 10)
                },
                l = !1;
            i.core.$el.on("onAferAppendSlide.lg.tm.zoom", function(e, t) {
                var n = i.core.$slide.eq(t).find(".lg-image");
                n.on("dblclick", function(e) {
                    a(e, n, t)
                }), n.on("touchstart", function(e) {
                    l ? (clearTimeout(l), l = null, a(e, n, t)) : l = setTimeout(function() {
                        l = null
                    }, 300), e.preventDefault()
                })
            }), e(t).on("resize.lg.zoom scroll.lg.zoom orientationchange.lg.zoom", function() {
                i.pageX = e(t).width() / 2, i.pageY = e(t).height() / 2 + e(t).scrollTop(), r(o)
            }), e("#lg-zoom-out").on("click.lg", function() {
                i.core.$outer.find(".lg-current .lg-image").length && (o -= i.core.s.scale, s())
            }), e("#lg-zoom-in").on("click.lg", function() {
                i.core.$outer.find(".lg-current .lg-image").length && (o += i.core.s.scale, s())
            }), i.core.$el.on("onBeforeSlide.lg.tm", function() {
                o = 1, i.resetZoom()
            }), i.core.isTouch || i.zoomDrag(), i.core.isTouch && i.zoomSwipe()
        }, r.prototype.resetZoom = function() {
            this.core.$outer.removeClass("lg-zoomed"), this.core.$slide.find(".lg-img-wrap").removeAttr("style data-x data-y"), this.core.$slide.find(".lg-image").removeAttr("style data-scale"), this.pageX = e(t).width() / 2, this.pageY = e(t).height() / 2 + e(t).scrollTop()
        }, r.prototype.zoomSwipe = function() {
            var e = this,
                t = {},
                i = {},
                n = !1,
                o = !1,
                r = !1;
            e.core.$slide.on("touchstart.lg", function(i) {
                if (e.core.$outer.hasClass("lg-zoomed")) {
                    var n = e.core.$slide.eq(e.core.index).find(".lg-object");
                    r = n.outerHeight() * n.attr("data-scale") > e.core.$outer.find(".lg").height(), o = n.outerWidth() * n.attr("data-scale") > e.core.$outer.find(".lg").width(), (o || r) && (i.preventDefault(), t = {
                        x: i.originalEvent.targetTouches[0].pageX,
                        y: i.originalEvent.targetTouches[0].pageY
                    })
                }
            }), e.core.$slide.on("touchmove.lg", function(s) {
                if (e.core.$outer.hasClass("lg-zoomed")) {
                    var a, l, c = e.core.$slide.eq(e.core.index).find(".lg-img-wrap");
                    s.preventDefault(), n = !0, i = {
                        x: s.originalEvent.targetTouches[0].pageX,
                        y: s.originalEvent.targetTouches[0].pageY
                    }, e.core.$outer.addClass("lg-zoom-dragging"), l = r ? -Math.abs(c.attr("data-y")) + (i.y - t.y) : -Math.abs(c.attr("data-y")), a = o ? -Math.abs(c.attr("data-x")) + (i.x - t.x) : -Math.abs(c.attr("data-x")), (Math.abs(i.x - t.x) > 15 || Math.abs(i.y - t.y) > 15) && c.css("transform", "translate3d(" + a + "px, " + l + "px, 0)")
                }
            }), e.core.$slide.on("touchend.lg", function() {
                e.core.$outer.hasClass("lg-zoomed") && n && (n = !1, e.core.$outer.removeClass("lg-zoom-dragging"), e.touchendZoom(t, i, o, r))
            })
        }, r.prototype.zoomDrag = function() {
            var i = this,
                n = {},
                o = {},
                r = !1,
                s = !1,
                a = !1,
                l = !1;
            i.core.$slide.on("mousedown.lg.zoom", function(t) {
                var o = i.core.$slide.eq(i.core.index).find(".lg-object");
                l = o.outerHeight() * o.attr("data-scale") > i.core.$outer.find(".lg").height(), a = o.outerWidth() * o.attr("data-scale") > i.core.$outer.find(".lg").width(), i.core.$outer.hasClass("lg-zoomed") && e(t.target).hasClass("lg-object") && (a || l) && (t.preventDefault(), n = {
                    x: t.pageX,
                    y: t.pageY
                }, r = !0, i.core.$outer.scrollLeft += 1, i.core.$outer.scrollLeft -= 1, i.core.$outer.removeClass("lg-grab").addClass("lg-grabbing"))
            }), e(t).on("mousemove.lg.zoom", function(e) {
                if (r) {
                    var t, c, d = i.core.$slide.eq(i.core.index).find(".lg-img-wrap");
                    s = !0, o = {
                        x: e.pageX,
                        y: e.pageY
                    }, i.core.$outer.addClass("lg-zoom-dragging"), c = l ? -Math.abs(d.attr("data-y")) + (o.y - n.y) : -Math.abs(d.attr("data-y")), t = a ? -Math.abs(d.attr("data-x")) + (o.x - n.x) : -Math.abs(d.attr("data-x")), d.css("transform", "translate3d(" + t + "px, " + c + "px, 0)")
                }
            }), e(t).on("mouseup.lg.zoom", function(e) {
                r && (r = !1, i.core.$outer.removeClass("lg-zoom-dragging"), !s || n.x === o.x && n.y === o.y || (o = {
                    x: e.pageX,
                    y: e.pageY
                }, i.touchendZoom(n, o, a, l)), s = !1), i.core.$outer.removeClass("lg-grabbing").addClass("lg-grab")
            })
        }, r.prototype.touchendZoom = function(e, t, i, n) {
            var o = this,
                r = o.core.$slide.eq(o.core.index).find(".lg-img-wrap"),
                s = o.core.$slide.eq(o.core.index).find(".lg-object"),
                a = -Math.abs(r.attr("data-x")) + (t.x - e.x),
                l = -Math.abs(r.attr("data-y")) + (t.y - e.y),
                c = (o.core.$outer.find(".lg").height() - s.outerHeight()) / 2,
                d = Math.abs(s.outerHeight() * Math.abs(s.attr("data-scale")) - o.core.$outer.find(".lg").height() + c),
                u = (o.core.$outer.find(".lg").width() - s.outerWidth()) / 2,
                p = Math.abs(s.outerWidth() * Math.abs(s.attr("data-scale")) - o.core.$outer.find(".lg").width() + u);
            (Math.abs(t.x - e.x) > 15 || Math.abs(t.y - e.y) > 15) && (n && (-d >= l ? l = -d : l >= -c && (l = -c)), i && (-p >= a ? a = -p : a >= -u && (a = -u)), n ? r.attr("data-y", Math.abs(l)) : l = -Math.abs(r.attr("data-y")), i ? r.attr("data-x", Math.abs(a)) : a = -Math.abs(r.attr("data-x")), r.css("transform", "translate3d(" + a + "px, " + l + "px, 0)"))
        }, r.prototype.destroy = function() {
            var i = this;
            i.core.$el.off(".lg.zoom"), e(t).off(".lg.zoom"), i.core.$slide.off(".lg.zoom"), i.core.$el.off(".lg.tm.zoom"), i.resetZoom(), clearTimeout(i.zoomabletimeout), i.zoomabletimeout = !1
        }, e.fn.lightGallery.modules.zoom = r
    }(jQuery, window, document);
